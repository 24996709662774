import React from 'react';
import Checkbox, { CheckboxProps } from 'antd/es/checkbox/Checkbox';

export interface CustomCheckboxProps extends Omit<CheckboxProps, 'checked' | 'onChange'> {
  value: boolean,
  onChange: (val: boolean) => void,
  innerChildren?: React.ReactNode,
}

function CustomCheckbox({ value, onChange, children, innerChildren, ...rest }: CustomCheckboxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={({ target }) => onChange(target.checked)}
      {...rest}
    >
      {children}
      {innerChildren}
    </Checkbox>
  );
}

export default CustomCheckbox;
