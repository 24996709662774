import { BillingActions, Detailing, SET_DETAILING_LIST } from './types';

type BillingState = {
  detailingList: Detailing[],
};

const initialState: BillingState = {
  detailingList: [],
};

export default function (state = initialState, action: BillingActions): BillingState {
  switch (action.type) {
    case SET_DETAILING_LIST:
      return { ...state, detailingList: action.list };
    default: return state;
  }
}
