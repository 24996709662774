import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CounterpartyCreate = React.lazy(() => import('../components/CounterpartyCreate'));
function CounterpartyCreateView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CounterpartyCreate />
    </Suspense>
  );
}

export default CounterpartyCreateView;
