export const SET_CURRENT_COUNTERPARTY_IMPORT_STATE = 'SET_CURRENT_COUNTERPARTY_IMPORT_STATE';
export const SET_CURRENT_COUNTERPARTY_IMPORT_ID = 'SET_CURRENT_COUNTERPARTY_IMPORT_ID';
export const RESET_CURRENT_COUNTERPARTY_IMPORT = 'RESET_CURRENT_COUNTERPARTY_IMPORT';
export const SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS = 'SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS';
export const SET_CURRENT_COUNTERPARTY_IMPORT_COUNT = 'SET_CURRENT_COUNTERPARTY_IMPORT_COUNT';

export enum CurrentCounterpartyImportState {
  NEW,
  RUNNING,
  DONE
}

export type CounterpartyImportParsedData = {
  uid: string;
  inn: string;
  ogrn: string;
  kpp: string;
  addressLine: string;
  opfFull: string;
  opfShort: string;
  titleFull: string;
  titleShort: string;
  name: string;
  email: string;
  phones: string;
  comment: string;

};

export type CounterpartyImportOriginalData = {
  inn: string;
  companyName: string;
  companyOwner: string;
  phone: string;
  email: string;
  address: string;
  comment: string;

};

export type CounterpartyImportResult = {
  parsedData?: CounterpartyImportParsedData;
  originalData: CounterpartyImportOriginalData;
};

export interface SetCurrentCounterpartyImportState {
  type: typeof SET_CURRENT_COUNTERPARTY_IMPORT_STATE,
  state: CurrentCounterpartyImportState,
}

export interface SetCurrentCounterpartyImportId {
  type: typeof SET_CURRENT_COUNTERPARTY_IMPORT_ID,
  importId: string,
}

export interface ResetCurrentCounterpartyImport {
  type: typeof RESET_CURRENT_COUNTERPARTY_IMPORT,
}

export interface SetCurrentCounterpartyImportItems {
  type: typeof SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS,
  items: CounterpartyImportResult[]
}

export interface SetCurrentCounterpartyImportCount {
  type: typeof SET_CURRENT_COUNTERPARTY_IMPORT_COUNT,
  payload: number
}

export type CounterpartyImportActions = SetCurrentCounterpartyImportState | 
  SetCurrentCounterpartyImportId | 
  ResetCurrentCounterpartyImport | 
  SetCurrentCounterpartyImportItems |
  SetCurrentCounterpartyImportCount;
