import React from 'react';
import { match } from 'react-router';
import useDispatch from '../../../util/hooks/useDispatch';
import useSelector from '../../../util/hooks/useSelector';
import { Button, Card } from 'antd';
import CircularProgress from '../../../components_base/CircularProgress';
import { authorizeTelegramBot } from '../action-creators';
import { ExclamationCircleOutlined, WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { Trans } from '@lingui/macro';

type TelegramBotAuthorizeViewParams = {
  code: string,
};

type TelegramBotAuthorizeViewProps = {
  match: match<TelegramBotAuthorizeViewParams>,
};

enum AuthorizeState {
  INITIAL,
  SUBMITTING,
  SUCCESS,
  ERROR
}

function TelegramBotAuthorizeView({ match: { params: { code } } }: TelegramBotAuthorizeViewProps) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [state, setState] = React.useState<AuthorizeState>(AuthorizeState.INITIAL);

  const handleOk = React.useCallback(
    () => {
      setState(AuthorizeState.SUBMITTING);
      dispatch(authorizeTelegramBot(code)).then(
        () => {
          setState(AuthorizeState.SUCCESS);
        },
        () => {
          setState(AuthorizeState.ERROR);
        },
      );
    },
    [code],
  );


  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card
          className="gx-login-content ant-card__paddingless"
        >
          {(() => {
            switch (state) {
              case AuthorizeState.INITIAL:
                return (
                  <div className="gx-text-center">
                    <div className="gx-fs-lg gx-mb-3">
                      <Trans>
                        Вы пытаетесь авторизовать личный кабинет <span
                        className="gx-font-weight-bold">"{user?.accountTitle}"</span> в нашем телеграм боте.
                      </Trans>
                    </div>
                    <div className="gx-fs-lg gx-mb-3">
                      <Trans>
                        Вы хотите продолжить?
                      </Trans>
                    </div>
                    <div className="gx-d-flex">
                      <Button className="gx-flex-1" type="primary" size="large"
                              onClick={handleOk}><Trans>Да</Trans></Button>
                      <Button className="gx-flex-1" size="large"
                              onClick={() => window.close()}><Trans>Нет</Trans></Button>
                    </div>
                  </div>
                );
              case AuthorizeState.SUBMITTING:
                return <CircularProgress className="gx-my-5 gx-h-100"/>;
              case AuthorizeState.ERROR:
                return (
                  <div className="gx-text-center">
                    <div className="gx-mb-3">
                      <WarningFilled className="gx-text-warning" style={{ fontSize: '72px' }}/>
                    </div>
                    <div className="gx-fs-lg gx-mb-3">
                      <Trans>
                        Ошибка
                      </Trans>
                    </div>
                    <div className="gx-fs-lg">
                      <Trans>
                        Пожалуйста, попробуйте позже
                      </Trans>
                    </div>
                  </div>
                );
              case AuthorizeState.SUCCESS:
                return (
                  <div>
                    <div className="gx-text-center">
                      <div className="gx-mb-3">
                        <CheckCircleFilled className="gx-text-success" style={{ fontSize: '72px' }}/>
                      </div>
                      <div className="gx-fs-lg">
                        <Trans>
                          Бот успешно авторизован
                        </Trans>
                      </div>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })()}

        </Card>
      </div>
    </div>
  );
}

export default TelegramBotAuthorizeView;
