import {
  Dispatcher, Mapper, Observer, Options, observer as origObserver,
} from 'redux-observers';
import { RootState } from '../reducers';

interface ObserverCreator {
  <S extends RootState, MS>(
    mapper: Mapper<S, MS>,
    dispatcher: Dispatcher<MS>,
    locals?: Options
  ): Observer<S>;
}

const observer: ObserverCreator = (mapper, dispatcher, locals) =>
  // @ts-ignore
  origObserver(mapper, dispatcher, locals);
export default observer;
