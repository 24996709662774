import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const UserSettings = React.lazy(() => import('../components/UserSettings'));
function UserSettingsView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <UserSettings />
    </Suspense>
  );
}

export default UserSettingsView;
