import React, { Suspense } from 'react';
import MainView from '../../../views/MainView';
import CircularProgress from '../../../components_base/CircularProgress';
import DialogList from '../components/DialogList';

function DialogListView(params: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <DialogList {...params} />
    </Suspense>
  );
}

export default DialogListView;
