import React, { CSSProperties } from 'react';
import loader from '../../assets/images/loading.svg';

export interface CircularProgressProps {
  className?: string,
  style?: CSSProperties,
}

const CircularProgress = ({ className, style }: CircularProgressProps) => (
  <div className={`loader ${className}`} style={style}>
    <img src={loader} alt="loader" />
  </div>
);
export default CircularProgress;
