import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import useDispatch from '../../../util/hooks/useDispatch';
import { loginAs, signOut } from '../action-creators';

function LoginAsView({ location: { search } }: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { accessToken, refreshToken } = qs.parse(search);
    if (typeof accessToken === 'string' && typeof refreshToken === 'string') {
      dispatch(loginAs(accessToken, refreshToken)).then(
        () => {
          history.replace('/');
        },
        () => {
          dispatch(signOut());
        },
      );
    }
  }, [search]);

  return (
    <div />
  );
}

export default LoginAsView;
