import {
  PreCalcStateType,
  RESET_TERMINAL_CALC,
  SET_CURRENT_TERMINAL,
  SET_TERMINAL_CALCULATION_TYPE,
  SET_TERMINAL_LIST,
  SET_TERMINAL_PRE_CALC_STATE,
  SET_TERMINAL_RECEIVER,
  SET_TERMINAL_SENDER,
  TerminalAction,
  TerminalCalculationType,
  TerminalContact,
  TerminalListItem,
} from './types';

export interface TerminalState {
  terminalList: Array<TerminalListItem>,
  currentTerminal: TerminalListItem | null,
  preCalcState: PreCalcStateType,
  terminalSender: null | TerminalContact,
  terminalReceiver: null | TerminalContact,
  calculationType: TerminalCalculationType,
}

const initialState: TerminalState = {
  terminalList: [],
  currentTerminal: null,
  preCalcState: 'cargo',
  terminalSender: null,
  terminalReceiver: null,
  calculationType: TerminalCalculationType.D2W,
};

export default function (state = initialState, action: TerminalAction): TerminalState {
  switch (action.type) {
    case SET_TERMINAL_LIST:
      return { ...state, terminalList: action.terminals };
    case SET_CURRENT_TERMINAL:
      return { ...state, currentTerminal: action.terminal };
    case SET_TERMINAL_PRE_CALC_STATE:
      return { ...state, preCalcState: action.state };
    case SET_TERMINAL_SENDER:
      return { ...state, terminalSender: action.contact };
    case SET_TERMINAL_RECEIVER:
      return { ...state, terminalReceiver: action.contact };
    case RESET_TERMINAL_CALC:
      return {
        ...state,
        preCalcState: initialState.preCalcState,
        terminalReceiver: initialState.terminalReceiver,
        terminalSender: initialState.terminalSender,
        currentTerminal: initialState.currentTerminal,
        calculationType: initialState.calculationType,
      };
    case SET_TERMINAL_CALCULATION_TYPE:
      return { ...state, calculationType: action.calculationType }
    default: return state;
  }
}
