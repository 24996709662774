import { 
  CounterpartyImportResult, 
  CurrentCounterpartyImportState, 
  RESET_CURRENT_COUNTERPARTY_IMPORT, 
  ResetCurrentCounterpartyImport, 
  SET_CURRENT_COUNTERPARTY_IMPORT_COUNT, 
  SET_CURRENT_COUNTERPARTY_IMPORT_ID, 
  SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS, 
  SET_CURRENT_COUNTERPARTY_IMPORT_STATE, 
  SetCurrentCounterpartyImportCount, 
  SetCurrentCounterpartyImportId, 
  SetCurrentCounterpartyImportItems, 
  SetCurrentCounterpartyImportState 
} from './types';
import {ThunkAction} from '../../util/types';
import blobToBase64 from '../../util/blobToBase64';
import {RcFile} from "antd/lib/upload/interface";

export const setCurrentCounterpartyImportState = (state: CurrentCounterpartyImportState): SetCurrentCounterpartyImportState => ({
  type: SET_CURRENT_COUNTERPARTY_IMPORT_STATE,
  state,
});

export const setCurrentCounterpartyImportId = (importId: string): SetCurrentCounterpartyImportId => ({
  type: SET_CURRENT_COUNTERPARTY_IMPORT_ID,
  importId,
});

export const resetCurrentCounterpartyImport = (): ResetCurrentCounterpartyImport => ({
  type: RESET_CURRENT_COUNTERPARTY_IMPORT,
});

export const setCurrentCounterpartyImportItems = (items: CounterpartyImportResult[]): SetCurrentCounterpartyImportItems => ({
  type: SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS,
  items,
});

export const setCurrentCounterpartyImportCount = (payload: number): SetCurrentCounterpartyImportCount => ({
  type: SET_CURRENT_COUNTERPARTY_IMPORT_COUNT,
  payload,
});

export const startCounterpartyImport = (file: RcFile | null, inn?: string): ThunkAction => async (dispatch, getState, http) => {
  const data: anyObject = {};

  if (file) {
    data.file = await blobToBase64(file);
  }

  if (inn) {
    data.inn = inn;
  }

  return http.post(
    '/api/v1/contact/import_counterparty/start/', data,
  ).then(
    (response: { importId: string }) => {
      dispatch(setCurrentCounterpartyImportState(CurrentCounterpartyImportState.RUNNING));
      dispatch(setCurrentCounterpartyImportId(response.importId));
      return Promise.resolve(response);
    },
  );
};

export const loadCurrentCounterpartyImportItems = (importId: string, page: number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/contact/import_counterparty/${importId}/result/`, {page}
).then(
  (response: { results: CounterpartyImportResult[], count: number, status: 'new' | 'done' }) => {
    dispatch(setCurrentCounterpartyImportItems(response.results));
    dispatch(setCurrentCounterpartyImportCount(response.count));
    return Promise.resolve(response);
  },
);

export const handleCounterpartyImportWS = (data: anyObject): ThunkAction<void> => (dispatch, getState) => {
  const { currentImportId } = getState().counterparty_import;
  if (currentImportId && data.importId === currentImportId) {
    if (data.command === 'done') {
      dispatch(setCurrentCounterpartyImportState(CurrentCounterpartyImportState.DONE));
      dispatch(loadCurrentCounterpartyImportItems(currentImportId, 1));
    }
  }
};

export const uploadCounterpartyImportFile = (file: RcFile | null): ThunkAction => (dispatch, getState, http) => {
  const req = new FormData();
  // @ts-ignore
  req.append('file', file);
  return http.post(
    '/api/v1/contact/import_counterparty/start/',
    req, { json: false },
  ).then(
    (response: { importId: string }) => {
      dispatch(setCurrentCounterpartyImportState(CurrentCounterpartyImportState.RUNNING));
      dispatch(setCurrentCounterpartyImportId(response.importId));
      return Promise.resolve(response);
    },
  );
};

export const saveCounterpartyImport = (importId: string, uids: string[]): ThunkAction => async (dispatch, getState, http) => {
  return http.post(
    `/api/v1/contact/import_counterparty/save/`, {uids, import_id: importId},
  ).then(
    (response: any) => {
      return Promise.resolve(response);
    },
  );
}; 