import React, { useEffect, Suspense, useState } from 'react';
import { Layout } from 'antd';
import qs from 'query-string';
import { Location } from 'history';
import { useHistory } from 'react-router';
import TopBar from '../components/TopBar';
import TopBarOld from '../components/TopBarOld';
import TermsOfUsePopup from '../app/user/components/TermsOfUsePopup';
import useSelector from '../util/hooks/useSelector';
import useDispatch from '../util/hooks/useDispatch';
import { setNewAccount } from '../app/user/action-creators';
import { readNotice } from '../app/notifications/action-creators';
import { useDebugUser } from '../util/hooks/useWIPFeatures';
import NotificationsList from '../app/notifications/components/NotificationsList';
import Alert from 'antd/es/alert';
import classNames from 'classnames';
import UpdateDesignPopup from '../app/user/components/UpdateDesignPopup';
import Sidebar from '../containers_base/Sidebar';
import { Footer } from '../components/Footer';

const DebugToolbar = React.lazy(() => import('../components/DebugToolbar'));

export interface MainViewProps {
  children: React.ReactNode,
  header?: React.ReactNode,
  location?: Location
}

function MainView(props: MainViewProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const debug = useDebugUser();

  const { children, header, location } = props;

  const { notificationsOpen } = useSelector((state) => state.notifications);
  const { user } = useSelector((state) => state.user);

  const alerts = useSelector((state) => state.alert.alerts);

  useEffect(() => {
    if (location) {
      const params = qs.parse(location.search);
      if (Object.keys(params).length) {
        let replace = false;
        if (params.new_account) {
          dispatch(setNewAccount(true));
          replace = true;
        }
        if (params.notification && typeof params.notification === 'string') {
          dispatch(readNotice(params.notification));
          replace = true;
        }
        if (replace) history.replace(location.pathname);
      }
    }
  }, []);

  const isMapPage = window.location.pathname.includes('/details');
  const isMybonusesPage = window.location.pathname.includes('/bonuses');
  const isMainPage = window.location.pathname === '/';

  return (
    <>
      <NotificationsList />
      {alerts.map((alert) => (
        <Alert
          message={alert.message}
          banner
          description={alert.description}
          closable={alert.closable}
          icon={alert.icon}
          type={alert.type}
          className="gx-mb-0"
        />
      ))}
      <Layout className="gx-app-layout">
        { !user?.isUpdatedDesign ? <Sidebar/> : <UpdateDesignPopup /> }
        {/* <UpdateDesignPopup /> */}
        <TermsOfUsePopup />
        <div id="new-team" />
        <Layout>
          <Layout.Header id='main-layout-header' className={classNames('', {'gx-p-0': user?.isUpdatedDesign, 'gx-pr-0': !user?.isUpdatedDesign, 'pls-header': notificationsOpen})}>
          {/* <Layout.Header 
            id='main-layout-header' 
            className={classNames('gx-p-0', {'pls-header': notificationsOpen})}> */}
            {!!header && header}
            { user?.isUpdatedDesign ? <TopBar /> : <TopBarOld /> }
            {/* <TopBar /> */}
          </Layout.Header>
          <Layout.Content className="gx-layout-content">
            <div className={classNames("gx-main-content-wrapper", {"gx-p-0 is-container-slim": user?.isUpdatedDesign, "custom-split-main": isMapPage, "custom-mybonuses": isMybonusesPage, "is-main-page": user?.isUpdatedDesign && isMainPage})}>
            {/* <div 
              className={classNames("gx-main-content-wrapper gx-p-0 is-container-slim", {"custom-split-main": isMapPage, "is-main-page": isMainPage})}> */}
              {children}
              { (isMainPage && user?.isUpdatedDesign) ? <Footer/> : null }
              {/* { isMainPage ? <Footer/> : null } */}
            </div>
          </Layout.Content>
        </Layout>
      </Layout>

      {debug && (
        <Suspense fallback={null}>
          <DebugToolbar />
        </Suspense>
      )}
    </>
  );
}

export default MainView;
