import { Moment } from 'moment';
import {
  MultiRateGroup, Registry, RegistryItem, RegistryListResponse,
} from './types';
import { Waybill } from '../../shipping/types';

export const ADD_REGISTRY_ITEM = 'ADD_REGISTRY_ITEM';
export const ADD_MULTIPLE_REGISTRY_ITEMS = 'ADD_MULTIPLE_REGISTRY_ITEMS';
export const REMOVE_REGISTRY_ITEM = 'REMOVE_REGISTRY_ITEM';
export const REPLACE_REGISTRY_ITEM = 'REPLACE_REGISTRY_ITEM';
export const SET_CURRENT_REGISTRY = 'SET_CURRENT_REGISTRY';
export const RESET_REGISTRY = 'RESET_REGISTRY';
export const SET_CURRENT_MULTI_CALC_TASK_ID = 'SET_CURRENT_MULTI_CALC_TASK_ID';
export const ADD_MULTI_RATE_GROUP = 'ADD_MULTI_RATE_GROUP';
export const SET_REGISTRY_LIST = 'SET_REGISTRY_LIST';
export const SET_REGISTRY_ITEMS = 'SET_REGISTRY_ITEMS';
export const RESET_CURRENT_REGISTRY = 'RESET_CURRENT_REGISTRY';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const RESET_REGISTRY_CALCULATION = 'RESET_REGISTRY_CALCULATION';
export const UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER = 'UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER';
export const CALC_AVAILABLE_REGISTRY_FILTERS = 'CALC_AVAILABLE_REGISTRY_FILTERS';
export const CHECK_CURRENT_REGISTRY_FILTERS = 'CHECK_CURRENT_REGISTRY_FILTERS';
export const SWITCH_CURRENT_REGISTRY_FILTER = 'SWITCH_CURRENT_REGISTRY_FILTER';
export const SET_CURRENT_REGISTRY_WAYBILL_LIST = 'SET_CURRENT_REGISTRY_WAYBILL_LIST';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';
export const SET_CURRENT_REGISTRY_DATE = 'SET_CURRENT_REGISTRY_DATE';

export interface SetCurrentRegistryDate {
  type: typeof SET_CURRENT_REGISTRY_DATE,
  date: Moment
}

export interface AddRegistryItem {
  type: typeof ADD_REGISTRY_ITEM,
  item: Omit<RegistryItem, 'uid'>
}

export interface AddMultipleRegistryItems {
  type: typeof ADD_MULTIPLE_REGISTRY_ITEMS,
  items: Omit<RegistryItem, 'uid'>[]
}

export interface RemoveRegistryItem {
  type: typeof REMOVE_REGISTRY_ITEM,
  uid: string,
}

export interface ReplaceRegistryItem {
  type: typeof REPLACE_REGISTRY_ITEM,
  item: RegistryItem
}

export interface SetCurrentRegistry {
  type: typeof SET_CURRENT_REGISTRY,
  registry: Registry | null,
}

export interface ResetRegistry {
  type: typeof RESET_REGISTRY,
}

export interface SetCurrentMultiTaskId {
  type: typeof SET_CURRENT_MULTI_CALC_TASK_ID,
  taskId: string,
}

export interface AddMultiRateGroup {
  type: typeof ADD_MULTI_RATE_GROUP,
  group: MultiRateGroup,
}

export interface SetRegistryList {
  type: typeof SET_REGISTRY_LIST,
  items: Array<RegistryListResponse>
}

export interface SetRegistryItems {
  type: typeof SET_REGISTRY_ITEMS,
  items: Array<Omit<RegistryItem, 'uid'>>
}

export interface ResetCurrentRegistry {
  type: typeof RESET_CURRENT_REGISTRY,
}

export interface SetCurrentDate {
  type: typeof SET_CURRENT_DATE,
  date: Moment
}

export interface ResetRegistryCalculation {
  type: typeof RESET_REGISTRY_CALCULATION,
}

export interface UpdateCurrentRegistryDeliveryServiceFilter {
  type: typeof UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER,
  deliveryServices: Array<string>
}

export interface CalcAvailableRegistryFilters {
  type: typeof CALC_AVAILABLE_REGISTRY_FILTERS
}

export interface CheckCurrentRegistryFilters {
  type: typeof CHECK_CURRENT_REGISTRY_FILTERS
}

export interface SwitchCurrentRegistryFilter {
  type: typeof SWITCH_CURRENT_REGISTRY_FILTER,
  filter: 'deliveryServices'
}

export interface SetCurrentRegistryWaybillList {
  type: typeof SET_CURRENT_REGISTRY_WAYBILL_LIST,
  waybills: Waybill[]
}

export interface SetSelectedItems {
  type: typeof SET_SELECTED_ITEMS,
  items: string[]
}

export type RegistryAction = AddRegistryItem | RemoveRegistryItem | ReplaceRegistryItem | SetCurrentRegistry |
ResetRegistry | SetCurrentMultiTaskId | AddMultiRateGroup | SetRegistryList | SetRegistryItems | ResetCurrentRegistry |
SetCurrentDate | ResetRegistryCalculation | UpdateCurrentRegistryDeliveryServiceFilter | CalcAvailableRegistryFilters |
CheckCurrentRegistryFilters | SwitchCurrentRegistryFilter | SetCurrentRegistryWaybillList | SetSelectedItems | AddMultipleRegistryItems | SetCurrentRegistryDate;
