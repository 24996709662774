import moment from 'moment';
import { ContactFormData } from '../app/calculation_new/redux';
import { AdditionalService, RateAdditionalServiceCode, SingleRate } from '../app/calculation/types';

const phoneRe = /^(\d)?(\d{3})(\d{3})(\d{2})(\d{2})$/;

export const renderPhone = (phone: string | number | null): string => {
  if (phone) {
    const clean = `${phone}`.replace(/\D/g, '');
    const match = clean.match(phoneRe);
    if (match) return `+${match[1] || ''} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return '';
};

const holidays = [
  {
    days: [1, 2, 3, 4, 5, 6, 7, 8],
    month: 1
  },
  {
    days: [23],
    month: 2
  },
  {
    days: [8],
    month: 3
  },
  {
    days: [1, 9],
    month: 5
  },
  {
    days: [12],
    month: 6
  },
  {
    days: [4],
    month: 11
  }
]

const getIsHoliday = (curDateMonth: number, curDateDays: number) => {
  for(let i = 0; i < holidays.length; i++) {
    const item = holidays[i];
    const { days, month } = item;
    if (curDateMonth === month && curDateDays in days) {
      return true;
    }
  }
  return false;
}

export const handleDeliveryDays = (days: number, isByTime?: boolean, isFakeService?: boolean) => {

  if (!days && !isByTime && !isFakeService) return 1000;

  let result = days;
  let count = 0;
  let now = moment();
	let curDate = now.clone();

  if (isFakeService && !result) {
    while(getIsHoliday(Number(curDate.format("M")), Number(curDate.format("D"))) || curDate.day() == 0 || curDate.day() == 6) {
      curDate = curDate.add(1, "days");
      count += 1;
    }
    
    return count;
  }

  while(result > 0){
    const tmp = curDate.add(1, "days");
    const isHoliday = getIsHoliday(Number(tmp.format("M")), Number(tmp.format("D")));
    if(tmp.day() != 0 && tmp.day() != 6 && !isHoliday){
			result -= 1;
    }
		count += 1;
  }

  return count;
};

export const formatAddressLine = (data: ContactFormData) => {
  let result = data.postcode ? `${data.postcode}, ` : '';
  if (data.street) {
    result += `${data.street}`;
  }
  if (data.house) {
    result += `, ${data.house}`;
  }
  if (data.office) {
    result += `, ${data.office}`;
  }
  if (!result && data.addressLine) result = data.addressLine;
  return result;
}

export const formatCityAddress = (data: ContactFormData) => {
  let result = data.city ? `${data.city}` : '';
  if (data.region) {
    result += result.length ? `, ${data.region}` : `${data.region}`
  }
  return result;
}

export const formatCityForGeoRequest = (data: ContactFormData) => {
  let result = data.cityType ? `${data.cityType} ` : '';
  if (data.city) {
    result += `${data.city}`
  }
  return result;
}

export const formatPriceWithSpace = (x: number) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${parts.join(",")} ₽`;
}

export const isSideNeedTerminals = (nextSide: "sender" | "receiver", rateType: string) => {
  if (rateType) {
      const [senderType, receiverType] = rateType.split('2');
      return nextSide === "sender" && senderType === "w" || nextSide === "receiver" && receiverType === "w";
  }
  return false;
}

export const getGroupName = (rate: SingleRate) => {
  const { name, deliveryService } = rate;
  const transformed = name.slice(0, name.indexOf("(") > -1 ? name.indexOf("(") : name.length);
  const essentialPartLowerCase = transformed.toLowerCase().replace("дверь-дверь", "").replace("дверь-склад", "").replace("склад-дверь", "").replace("склад-склад", "").trim().length;
  const essentialPart = transformed.trim().slice(0, essentialPartLowerCase);
  return `${essentialPart}__${deliveryService}`
}

export const getPriceWithServices = (
  price: number, insurancePrice: number, 
  selectedServices: string[], additionalServices: AdditionalService[], 
  isFormatted: boolean = true) => {
  let result = price;
  selectedServices.forEach(selected => {
    const service = additionalServices.find(_ => _.code === selected);
    result += service ? service.amount : 0;
    result += selected === RateAdditionalServiceCode.INSURANCE && insurancePrice ? insurancePrice : 0;
  })
  return isFormatted ? formatPriceWithSpace(result) : result;
}