export const SET_WAREHOUSE_LIST = 'SET_WAREHOUSE_LIST';
export const SET_SENDER_WAREHOUSE = 'SET_SENDER_WAREHOUSE';
export const SET_RECEIVER_CONTACT = 'SET_RECEIVER_CONTACT';
export const ADD_GOOD = 'ADD_GOOD';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const REMOVE_GOOD = 'REMOVE_GOOD';
export const SET_WAREHOUSE_LIST_COUNT = 'SET_WAREHOUSE_LIST_COUNT';
export const RESET_ONLINE_STORE = 'RESET_ONLINE_STORE';
export const REPLACE_GOOD = 'REPLACE_GOOD';

export type Warehouse = {
  warehouseId: string,
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  office: string,
  warehouseName: string,
  contactName: string,
  email: string,
  comment: string,
  longitude: number,
  latitude: number,
  phones: string,
};

export type Good = {
  description: string,
  weight: number,
  width: number,
  height: number,
  length: number,
  article: string,
  quantity: number,
  price: number,
};

export interface SetWarehouseList {
  type: typeof SET_WAREHOUSE_LIST,
  list: Array<Warehouse>
}

export interface SetSenderWarehouse {
  type: typeof SET_SENDER_WAREHOUSE,
  warehouse: Warehouse,
}

export interface SetReceiverContact {
  type: typeof SET_RECEIVER_CONTACT,
  contact: any
}

export interface AddGood {
  type: typeof ADD_GOOD,
  good: any,
}

export interface SetOrderId {
  type: typeof SET_ORDER_ID,
  orderId: any
}

export interface RemoveGood {
  type: typeof REMOVE_GOOD,
  good: any
}

export interface SetWarehouseListCount {
  type: typeof SET_WAREHOUSE_LIST_COUNT,
  count: number
}

export interface ResetOnlineStore {
  type: typeof RESET_ONLINE_STORE,
}

export interface ReplaceGood {
  type: typeof REPLACE_GOOD,
  good: anyObject,
}

export type OnlineStoreActions = SetWarehouseList | SetSenderWarehouse | SetReceiverContact | AddGood | SetOrderId
| RemoveGood | SetWarehouseListCount | ResetOnlineStore | ReplaceGood;
