import {
  AdditionalServiceDescription,
  Country,
  Currency,
  Operator,
  ReferenceAction,
  SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  SET_COUNTRIES_LIST,
  SET_CURRENCY_LIST,
  SET_OPERATORS,
} from './types';

export interface ReferenceState {
  operatorsList: Operator[],
  operators: {[key: string]: Operator},
  countries: Array<Country>,
  currencyList: Array<Currency>,
  additionalServicesDesc: Array<AdditionalServiceDescription>
}

const initialState: ReferenceState = {
  operatorsList: [],
  operators: {},
  countries: [],
  currencyList: [],
  additionalServicesDesc: []
};


function prepareOperators(rawOperators: Operator[]) {
  const operators = {};
  for (let i = 0; i < rawOperators.length; i++) {
    // @ts-ignore
    operators[rawOperators[i].key] = rawOperators[i];
  }
  return operators;
}

export default function (state = initialState, actions: ReferenceAction): ReferenceState {
  switch (actions.type) {
    case SET_OPERATORS:
      return { ...state, operatorsList: actions.operators, operators: prepareOperators(actions.operators) };
    case SET_COUNTRIES_LIST:
      return { ...state, countries: actions.countries };
    case SET_CURRENCY_LIST:
      return { ...state, currencyList: actions.list };
    case SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST:
      return { ...state, additionalServicesDesc: actions.payload };
    default: return state;
  }
}
