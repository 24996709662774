import { t } from '@lingui/macro';

type AdditionalServicesRenderType = {
  title: string,
  creationTitle: string,
  isWip?: boolean,
};

type DeliveryWayServicesRenderType = {
  title: string
};

export const ADDITIONAL_SERVICES: {[key: string]: AdditionalServicesRenderType} = {
  CARGO_LOADING: {
    title: t`Забор в городе-отправителе`,
    creationTitle: t`Пoгpyзo-paзгpyзoчные работы при приеме груза`,
    isWip: true,
  },
  CARGO_UNLOADING: {
    title: t`Доставка в городе-получателе`,
    creationTitle: t`Пoгpyзo-paзгpyзoчные работы при доставке груза`,
    isWip: true,
  },
  RETURN: {
    title: t`Возврат`,
    creationTitle: t`Возврат документов`,
  },
  BY_HAND: {
    title: t`Вручение лично в руки`,
    creationTitle: t`Вручение лично в руки`,
  },
};

export const DELIVERY_WAY_SERVICES: {[key: string]: DeliveryWayServicesRenderType} = {
  'd2d': {
    title: 'Дверь-дверь'
  },
  'd2w': {
    title: 'Дверь-склад'
  },
  'w2d': {
    title: 'Склад-дверь'
  },
  'w2w': {
    title: 'Склад-склад'
  }
}

export const CDEK = [540, 418, 564, 118, 136, 24, 39, 570, 495, 569, 72, 578, 534, 541, 435, 77, 414, 396, 407, 423, 421, 409, 418, 422, 88, 401, 180, 561, 441, 440, 449, 446, 437, 96, 386, 383, 451, 186, 76, 456, 380, 412, 464, 73, 160, 466, 473, 472, 487, 344, 353, 114, 475, 482, 311, 161, 174, 481, 442, 528, 397, 436, 511, 527, 124, 548, 317, 509, 411, 105, 558, 104, 555, 202, 301, 430, 426, 427, 429, 428, 531, 432, 565, 571, 132, 169, 589, 146, 98, 126, 590, 489, 529, 74, 585, 608, 102, 554, 125, 562, 525, 177, 579, 101, 342, 171, 607, 355, 354, 618, 162, 471, 459, 514, 416, 178, 109, 634, 628, 385, 94, 173, 454, 479, 138, 625, 639, 643, 644, 614, 646, 648, 231, 650]
