export interface ResolvedPromise {
  response: any,
  status: 'resolved'
}

export interface ErrorPromise {
  errors: any,
  status: 'rejected'
}

const executeAll = (promises: Array<Promise<any>>): Array<Promise<ResolvedPromise | ErrorPromise>> => promises.map((_) => _.then((response: any) => ({
    response,
    status: 'resolved'
  }),
  (errors: any) => ({ errors, status: 'rejected' })));

enum SuspendPromiseStatus {
  PENDING,
  SUCCESS,
  ERROR
}

export enum SuspendPromiseResponseStatus {
  SUCCESS,
  ERROR
}

type SuspendPromiseResponse<T extends any> = {
  status: SuspendPromiseResponseStatus,
  response: T,
};

export type SuspendPromiseReturnType<T extends any> = {
  read: () => SuspendPromiseResponse<T>,
};

function suspendPromise<T extends any>(promise: Promise<T>): SuspendPromiseReturnType<T> {
  let status = SuspendPromiseStatus.PENDING;
  let result: any;
  const suspender = promise.then(
    (r) => {
      status = SuspendPromiseStatus.SUCCESS;
      result = r;
    },
    (e) => {
      status = SuspendPromiseStatus.ERROR;
      result = e;
    },
  );
  return {
    read() {
      switch (status) {
        case SuspendPromiseStatus.PENDING:
          throw suspender;
        case SuspendPromiseStatus.ERROR:
          return {
            status: SuspendPromiseResponseStatus.ERROR,
            response: result,
          };
        case SuspendPromiseStatus.SUCCESS:
          return {
            status: SuspendPromiseResponseStatus.SUCCESS,
            response: result,
          };

        default:
          throw result;
      }
    },
  };
}


export { executeAll, suspendPromise };
