import Centrifuge from 'centrifuge';
import { setCent, setToken, signOut } from '../app/user/action-creators';
import { adaptFromApi } from './adapter';
import { acceptWsCalculation, acceptWsRecalculation } from '../app/calculation/action-creators';
import { ThunkAction } from './types';
import { WSDialogEvent } from '../app/chat/action-creators';
import {acceptMultiRateGroupWS, acceptRegistryWs} from '../app/registry/action-creators';
import {acceptWsNotice} from '../app/notifications/action-creators';
import {handleImportWS} from '../app/contacts_import/action-creators';
import { acceptCartWS } from '../app/cart/action-creators';
import { acceptUpdateWS, acceptGlobalUpdateWS } from '../app/external/action-creators';
import getAppConfig from './getAppConfig';
import { handleCounterpartyImportWS } from '../app/counterparty_import/action-creators';

export default function subscribe(): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { user: { user, cent, token } } = getState();
    if (!user || !token) return;
    const { userId, accountId } = user;
    if (cent) cent.disconnect();
    const { centrifugeHost } = getAppConfig();

    const centrifuge = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, {
      // debug: true,
      onRefresh: (context, sendReply) => {
        http.post('/api/v1/user/token_refresh/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
          (response: any) => {
            const { accessToken, refreshToken } = response;
            const expiredAt = new Date().getTime() + 300000;
            dispatch(setToken(accessToken, refreshToken, expiredAt));
            sendReply({ status: 200, data: { token: accessToken } });
          },
          () => {
            dispatch(signOut());
            centrifuge.disconnect();
          },
        );
      },
      onPrivateSubscribe: (context, sendReply) => {
        http.post('/api/v1/user/cent/subscribe/', context.data).then(
          (response: any) => {
            sendReply({ status: 200, data: response });
          },
        );
      },
    });
    if (token.accessToken) centrifuge.setToken(token.accessToken);
    centrifuge.subscribe(`calculation#${userId}`, (message) => {
      const {
        data: {
          singleTaskId, command, data, multiTaskId,
        },
      } = adaptFromApi(message);
      if (singleTaskId) {
        dispatch(acceptWsCalculation(singleTaskId, command, data));
      }
      if (multiTaskId) {
        dispatch(acceptMultiRateGroupWS(multiTaskId, command, data));
      }
    });
    centrifuge.subscribe(`final_recalc#${userId}`, (message) => {
      const {
        data: {
          singleTaskId, command, data
        },
      } = adaptFromApi(message);
      if (singleTaskId) {
        dispatch(acceptWsRecalculation(singleTaskId, command, data));
      }
    });
    centrifuge.subscribe(`chat$${accountId}`, (message) => {
      const { data: { dialogId, event } } = adaptFromApi(message);
      dispatch(WSDialogEvent(dialogId, event));
    });
    centrifuge.subscribe(`$registry:${accountId}`, (message) => {
      const { data: { data } } = adaptFromApi(message);
      dispatch(acceptRegistryWs(data));
    });
    centrifuge.subscribe(`$notice:${userId}_${accountId}`, (message) => {
      const { data: { data, command } } = adaptFromApi(message);
      if (command === 'new_notice') {
        dispatch(acceptWsNotice(data));
      }
    });
    centrifuge.subscribe(`$import:${accountId}`, (message) => {
      const { data } = adaptFromApi(message);
      console.debug('message', message);
      dispatch(handleImportWS(data));
    });
    centrifuge.subscribe(`import_counterparty#${userId}`, (message) => {
      const { data } = adaptFromApi(message);
      console.debug('message', message);
      dispatch(handleCounterpartyImportWS(data));
    });
    centrifuge.subscribe(`$cart:${accountId}`, (message) => {
      const { data } = message;
      dispatch(acceptCartWS(data));
    });
    centrifuge.subscribe(`$update:${accountId}`, (message) => {
      const { data } = message;
      dispatch(acceptUpdateWS(data));
    });
    centrifuge.subscribe('global_update', (message) => dispatch(acceptGlobalUpdateWS(message.data)));
    centrifuge.connect();
    dispatch(setCent(centrifuge));
  };
}
