import useSelector from './useSelector';
import { User } from '../../app/user/types';

enum WIPFeaturesState {
  ENABLED, // Доступно всем
  DISABLED // Доступно только указанным аккаунтам
}

export const WIPAccounts = [24, 39, 527];
export const WIPFeatures: {[key: string]: WIPFeaturesState} = {
  onlineShop: WIPFeaturesState.ENABLED,
  returnPackage: WIPFeaturesState.DISABLED,
  returnCargo: WIPFeaturesState.ENABLED,
  additionalContactForm: WIPFeaturesState.ENABLED,
  contactsImport: WIPFeaturesState.DISABLED,
  BETTER_RATE: WIPFeaturesState.DISABLED,
  BONUSES: WIPFeaturesState.ENABLED,
};

export const isWip = (user: User | null, feature?: string): boolean => {
  const wipAccount = user && user.accountId && WIPAccounts.includes(user.accountId);
  if (feature) return (wipAccount || (WIPFeatures[feature] ?? WIPFeaturesState.DISABLED) === WIPFeaturesState.ENABLED);
  return !!wipAccount;
};

export default function (feature?: string) {
  const { user } = useSelector((state) => state.user);
  return isWip(user, feature);
}

export function useDebugUser() {
  const { user } = useSelector((state) => state.user);
  return user?.userId === 2;
}
