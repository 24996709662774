import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CalculationResult = React.lazy(() => import('../components/CalculationResult'));

function CalculationResultView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <CalculationResult {...props} />
    </React.Suspense>
  );
}

export default CalculationResultView;
