import React, { useCallback, useState } from "react";
import { notification } from "antd";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { CustomField } from "../../../../../components/CustomField";
import { FastCalculationForeignContactFormData } from "../../../redux";
import transliterate from "../../../../../util/transliterate";
import CompanySelect from "../../SplitUI/components/CompanySelect";
import { transliteratedNames } from "../../../constant";


type Props = {
    side: "sender" | "receiver"
}

const SideInvoice = ({
    side,
}: Props) => {

    const {
        values, setFieldValue, errors,
    } = useFormikContext<FastCalculationForeignContactFormData>();

    const [loading, setLoading] = useState<boolean>(false);

    const handleGenerateTransliteration = useCallback(() => {
        const sideValues = values[side];
        const data: any = {};
        if (sideValues.contactName) data.contactName = sideValues.contactName;
        if (sideValues.companyName) data.companyName = sideValues.companyName;
        if (sideValues.addressLine) data.addressLine = sideValues.addressLine;
        if (sideValues.legalAddress) data.legalAddress = sideValues.legalAddress;
        if (Object.keys(data).length) {
            transliterate(data).then(
                (translit) => {
                    const response = Object.fromEntries(Object.entries(translit).map(_=> [transliteratedNames[_[0]], _[1]]))
                    setFieldValue(side, { ...values[side], ...response })
                },
            ).finally(() => setLoading(false));
        } else {
            notification.error({
                message: "Поле со значением на русском языке не заполнено",
            });
        }
    }, [values, side])

    return (
        <div className="gx-d-flex gx-flex-column gx-mb-4">
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice__sub-title gx-mb-2">{side === "sender" ? "Отправитель" : "Получатель"}</div>
                <div 
                    role="presentation"
                    className="gx-ml-auto gx-text-black gx-pointer custom-calc-invoice__generate-btn"
                    onClick={handleGenerateTransliteration}>Сгенерировать транслитерацию</div>
            </div>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice-group-half">
                    <Field
                        name={`${side}.contactName`}
                        type="text"
                        label="Контактное лицо (рус)"
                        component={CustomField}
                        disableTouch
                        />
                </div>
                <div className="custom-calc-invoice-group-half">
                    <CompanySelect
                        side={side}
                        label="Компания (рус)"
                        handleFocusField={() => {}}/>
                </div>
            </div>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice-group-half">
                    <Field
                        name={`${side}.phones[0].number`}
                        type="text"
                        label="Телефон"
                        component={CustomField}
                        isPhone={values[side]?.iso === "RU"}
                        disableTouch
                        errorName={`${side}.phones`}/>
                </div>
                <div className="custom-calc-invoice-group-half">
                    <Field
                        name={`${side}.email`}
                        type="text"
                        label="Email"
                        component={CustomField}
                        disableTouch/>
                </div>
            </div>
            <div className="gx-d-flex gx-flex-column">
                <div className="gx-w-100">
                    <Field
                        name={`${side}.addressLine`}
                        type="text"
                        label={`Адрес ${side === "sender" ? "забора" : "доставки"} груза (рус)`}
                        component={CustomField}
                        disableTouch
                        />
                </div>
                <div className={classNames("gx-w-100", {"gx-d-none": side === "receiver"})}>
                    <Field
                        name={`${side}.legalAddress`}
                        type="text"
                        label="Юридический адрес (рус)"
                        component={CustomField}
                        disableTouch
                        />
                </div>
            </div>

            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice-group-half">
                    <Field
                        name={`${side}.contactNameTransliterate`}
                        type="text"
                        label="Контактное лицо (англ)"
                        component={CustomField}
                        disableTouch
                        />
                </div>
                <div className="custom-calc-invoice-group-half">
                    <Field
                        name={`${side}.companyNameTransliterate`}
                        type="text"
                        label="Компания (англ)"
                        component={CustomField}
                        disableTouch
                        />
                </div>
            </div>
            <div className="gx-d-flex gx-flex-column">
                <div className="gx-w-100">
                    <Field
                        name={`${side}.addressTransliterate`}
                        type="text"
                        label={`Адрес ${side === "sender" ? "забора" : "доставки"} груза (англ)`}
                        component={CustomField}
                        disableTouch
                        />
                </div>
                <div className={classNames("gx-w-100", {"gx-d-none": side === "receiver"})}>
                    <Field
                        name={`${side}.legalAddressTransliterate`}
                        type="text"
                        label="Юридический адрес (англ)"
                        component={CustomField}
                        disableTouch
                        />
                </div>
            </div>
        </div>
    )
}

export { SideInvoice }