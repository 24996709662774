import {
  ADD_CART_DESTINATION,
  ADD_MULTIPLE_CART_DESTINATIONS,
  AddCartDestination,
  AddMultipleCartDestinations,
  CartDestination,
  CartDestinationResponse,
  CartDestinationStatus,
  CreationError,
  REMOVE_CART_DESTINATION,
  RESET_CART,
  RemoveCartDestination,
  ResetCart,
  SET_CART_DESTINATIONS,
  SetCartDestinations,
  UPDATE_MULTIPLE_DESTINATIONS,
  UPDATE_SINGLE_DESTINATION,
  UpdateMultipleDestinationS,
  UpdateSingleDestination,
} from './types';
import { ThunkAction } from '../../util/types';
import { getMultiResultDetail } from '../registry/action-creators';
import { getRateResult, setAmoId, setShareEmail } from '../calculation/action-creators';
import { loadWaybill } from '../shipping/action-creators';
import { adaptFromApi } from '../../util/adapter';
import parseJson from '../../util/json/parse';

export const setCartDestinations = (destinations: CartDestinationResponse[]): SetCartDestinations => ({
  type: SET_CART_DESTINATIONS,
  destinations,
});

export const addCartDestination = (destination: CartDestinationResponse): AddCartDestination => ({
  type: ADD_CART_DESTINATION,
  destination,
});

export const removeCartDestination = (destinationId: number): RemoveCartDestination => ({
  type: REMOVE_CART_DESTINATION,
  destinationId,
});

export const updateSingleDestination = (destination: Partial<CartDestination>): UpdateSingleDestination => ({
  type: UPDATE_SINGLE_DESTINATION,
  destination,
});

export const updateMultipleDestinations = (destinations: Partial<CartDestination>[]): UpdateMultipleDestinationS => ({
  type: UPDATE_MULTIPLE_DESTINATIONS,
  destinations,
});

export const addMultipleCartDestinations = (destinations: CartDestinationResponse[]): AddMultipleCartDestinations => ({
  type: ADD_MULTIPLE_CART_DESTINATIONS,
  destinations,
});

export const resetCart = (): ResetCart => ({
  type: RESET_CART,
});

export const loadCart = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/registry/cart/',
).then(
  ({ destinations }: { destinations: CartDestinationResponse[] }) => {
    dispatch(setCartDestinations(destinations));
    return Promise.resolve();
  },
);

export const addDestination = (data: anyObject): ThunkAction<Promise<number>> => (dispatch, getState, http) => {
  const { amoId } = getState().calculation;

  return http.post(
    '/api/v1/registry/cart/add/',
    { ...data, amo_id: amoId },
  ).then(
    ({ destinationId }: { destinationId: number }) => {
      dispatch(setAmoId(null));
      dispatch(setShareEmail(null));
      return Promise.resolve(destinationId);
    },
  );
};

export const removeDestination = (destinationId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/registry/cart/${destinationId}/remove/`,
).then(
  () => {
    dispatch(removeCartDestination(destinationId));
    return Promise.resolve();
  },
);

export const recalcCart = (): ThunkAction<void> => (dispatch, getState, http) => http.post(
  '/api/v1/registry/cart/recalc/',
);

export const cartCreationListeners: { [key: string]: any } = {};

export const handleCartCreationComplete = (plsWaybillNumber: string, destinationId: number, attempt = 0): ThunkAction<void> => (dispatch) => {
  console.debug('cartCreationListeners', cartCreationListeners);
  dispatch(loadWaybill(plsWaybillNumber, false)).then(
    (waybill) => {
      dispatch(updateSingleDestination({
        destinationId,
        status: CartDestinationStatus.CREATION_COMPLETE,
        waybill,
      }));
      cartCreationListeners[plsWaybillNumber] = undefined;
    },
    () => {
      if (attempt < 20) {
        cartCreationListeners[plsWaybillNumber] = {
          destinationId,
          timer: setTimeout(() => {
            dispatch(handleCartCreationComplete(plsWaybillNumber, destinationId, attempt + 1));
          }, 200 * attempt),
        };
      }
    },
  );
};

export const acceptCartWS = (_data: anyObject): ThunkAction<void> => (dispatch, getState) => {
  const { type, data } = _data;

  switch (type) {
    case 'RECALC_RUNNING':
      dispatch(updateMultipleDestinations((data as Array<number>).map((_) => ({
        status: CartDestinationStatus.RECALC_RUNNING,
        destinationId: _,
        result: null,
      }))));
      break;
    case 'RECALC_DONE':
      if (data.type === 'GROUP') {
        if (data.tariff === 'EMPTY') {
          dispatch(updateMultipleDestinations((data.destinations as Array<number>).map((destinationId) => ({
            destinationId,
            status: CartDestinationStatus.RECALC_DONE,
          }))));
        } else {
          dispatch(getMultiResultDetail(data.tariff)).then(
            (group) => {
              dispatch(updateMultipleDestinations((data.destinations as Array<number>).map((destinationId) => ({
                destinationId,
                single: false,
                status: CartDestinationStatus.RECALC_DONE,
                result: {
                  type: 'GROUP',
                  rate: group,
                },
              }))));
            },
          );
        }
      } else if (data.type === 'SINGLE') {
        if (data.tariff === 'EMPTY') {
          dispatch(updateSingleDestination({
            destinationId: data.destination,
            status: CartDestinationStatus.RECALC_DONE,
            single: true,
          }));
        } else {
          dispatch(getRateResult(data.tariff)).then(
            (rate) => {
              dispatch(updateSingleDestination({
                destinationId: data.destination,
                status: CartDestinationStatus.RECALC_DONE,
                single: true,
                result: {
                  type: 'SINGLE',
                  rate,
                },
              }));
            },
          );
        }
      }
      break;
    case 'CREATION':
      dispatch(updateSingleDestination({ destinationId: data.destination_id, status: CartDestinationStatus.CREATION }));
      break;
    case 'CREATION_COMPLETE':
      dispatch(handleCartCreationComplete(data.pls_waybill_number, data.destination_id));
      break;
    case 'CREATION_ERROR':
      console.debug('CREATION_ERROR', data, parseJson(data.creation_error));
      dispatch(updateSingleDestination({
        destinationId: data.destination_id,
        status: CartDestinationStatus.CREATION_ERROR,
        creationError: data.creation_error ? parseJson<CreationError>(data.creation_error) : null,
      }));
      break;
    case 'RECALC_SAVING':
      dispatch(updateMultipleDestinations(adaptFromApi(data)));
      break;
    default:
  }
};

export const createCartItems = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/shipping/create/cart/',
  data,
);

export const deleteCartDestination = (destinationId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/registry/cart/${destinationId}/remove/`,
).then(
  () => {
    dispatch(removeCartDestination(destinationId));
    // dispatch(recalcCart());
    return Promise.resolve();
  },
);

export const createCartClaim = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/shipping/claim/cart/create/', data);

export interface DestinationSavingsResponseData {
  savings: Array<{
    destinationId: number,
    saving: number,
    todaySaving: number
  }>,
}

export const getDestinationSavings = (destinationIdList: number[]): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/registry/cart/savings/',
  { destination_id_list: destinationIdList },
).then(
  ({ savings }: DestinationSavingsResponseData) => {
    dispatch(updateMultipleDestinations(savings));
    return Promise.resolve(savings);
  },
);

export const setDestinationPickupDate = (destinationId: number, pickupDate: string): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/registry/cart/${destinationId}/pickup/update/`,
  { pickup_date: pickupDate },
).then(
  () => {
    dispatch(updateSingleDestination({ destinationId, pickupDate }));
    return Promise.resolve();
  },
);

export const setMultipleDestinationPickupDate = (destinationIdList: number[], pickupDate: string): ThunkAction => (dispatch, getState, http) => http.patch(
  '/api/v1/registry/cart/multiple/pickup/update/',
  { destination_id_list: destinationIdList, pickup_date: pickupDate },
).then(
  ({ destinationIdList, pickupDate }: { destinationIdList: number[], pickupDate: string }) => {
    dispatch(updateMultipleDestinations(destinationIdList.map((destinationId) => ({ destinationId, pickupDate }))));
    return Promise.resolve();
  },
);

export const createMultipleCartDestinations = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/registry/cart/multiple/add/',
  data,
).then(
  ({ destinations }: { destinations: CartDestinationResponse[] }) => {
    dispatch(addMultipleCartDestinations(destinations));
    return Promise.resolve();
  },
);

export const getAccountCartDestinationForDate = (date: string): ThunkAction<Promise<CartDestinationResponse[]>> => (dispatch, getState, http) => http.get(
  '/api/v1/registry/cart/destinations/list/by_date/',
  { date },
).then(
  ({ destinations }: { destinations: CartDestinationResponse[] }) => Promise.resolve(destinations),
);
