import { UploadFile } from 'antd/es/upload/interface';
import { ShortUserInfo } from '../user/types';
import { Waybill } from '../shipping/types';

export const SET_CURRENT_DIALOG = 'SET_CURRENT_DIALOG';

export const SET_DIALOG_MESSAGES = 'SET_DIALOG_MESSAGES';
export const ADD_DIALOG_MESSAGES = 'ADD_DIALOG_MESSAGES';
export const ADD_DIALOG_MESSAGE = 'ADD_DIALOG_MESSAGE';
export const ADD_UPLOAD_FILE = 'ADD_UPLOAD_FILE';
export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';
export const REMOVE_UPLOAD_FILE = 'REMOVE_UPLOAD_FILE';
export const REPLACE_DIALOG_MESSAGE = 'REPLACE_DIALOG_MESSAGE';
export const SET_DIALOG_MESSAGES_COUNT = 'SET_DIALOG_MESSAGES_COUNT';
export const SET_DIALOG_LIST = 'SET_DIALOG_LIST';
export const SET_DIALOG_COUNT = 'SET_DIALOG_COUNT';
export const REPLACE_DIALOG = 'REPLACE_DIALOG';
export const SET_DIALOG_UNREAD_COUNT = 'SET_DIALOG_UNREAD_COUNT';

export interface Dialog {
  dialogId: string,
  accountId: number,
  userId: number,
  waybillId: string,
  deliveryService: string,
  createdAt: string,
  claimNumber?: string,
}

export type DialogListItem = Dialog & {
  waybill: Waybill | null,
  closed: boolean,
  hasUnread: boolean,
  lastMessage?: string,
  lastMessageDate?: string,
  lastMessageUserId?: number,
  lastMessageSender?: 'user' | 'logist' | 'bot',
  lastUser?: ShortUserInfo,
};

export interface SetCurrentDialogParams {
  type: typeof SET_CURRENT_DIALOG,
  dialog: Dialog | null
}

export interface DialogFile {
  name: string,
  fileName: string | undefined,
  url?: string,
  uploadPercent?: number,
  status?: 'error' | 'success' | 'done' | 'uploading' | 'removed';
}

export interface Message {
  messageId: string,
  accountId?: number,
  userId: number,
  message?: string,
  file?: DialogFile,
  dialogId: string,
  createdAt: string,
  type: string,
  sender: 'user' | 'logist' | 'bot',
  user?: ShortUserInfo
}

export interface SetDialogMessages {
  type: typeof SET_DIALOG_MESSAGES,
  messages: Message[]
}

export interface AddDialogMessages {
  type: typeof ADD_DIALOG_MESSAGES,
  messages: Message[]
}

export interface AddDialogMessage {
  type: typeof ADD_DIALOG_MESSAGE,
  message: Message
}

export interface AddUploadFile {
  type: typeof ADD_UPLOAD_FILE,
  file: UploadFile,
}

export interface ResetUploadFiles {
  type: typeof RESET_UPLOAD_FILES
}

export interface RemoveUploadFile {
  type: typeof REMOVE_UPLOAD_FILE,
  file: UploadFile
}

export interface ReplaceDialogMessage {
  type: typeof REPLACE_DIALOG_MESSAGE,
  message: Message,
  messageId: string
}

export interface SetDialogMessagesCount {
  type: typeof SET_DIALOG_MESSAGES_COUNT,
  count: number,
}

export interface SetDialogList {
  type: typeof SET_DIALOG_LIST,
  list: DialogListItem[],
}

export interface SetDialogCount {
  type: typeof SET_DIALOG_COUNT,
  count: number,
}

export interface ReplaceDialog {
  type: typeof REPLACE_DIALOG,
  dialog: DialogListItem,
}

export interface SetDialogUnreadCount {
  type: typeof SET_DIALOG_UNREAD_COUNT,
  count: number,
}

export type ChatReducerAction = SetCurrentDialogParams | SetDialogMessages | AddDialogMessage | AddDialogMessages |
AddUploadFile | ResetUploadFiles | RemoveUploadFile | ReplaceDialogMessage | SetDialogMessagesCount | SetDialogList |
SetDialogCount | ReplaceDialog | SetDialogUnreadCount;
