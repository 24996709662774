import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  amplitude.getInstance().init('2af616a1d35a74503fc388d5774f419f', { domain: 'a91d0034baac.ngrok.io' });
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
