import React, {
  useCallback, useEffect, useRef, useState, Suspense,
} from 'react';
import { Switch } from 'antd';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Avatar from 'antd/es/avatar';
import Truncate from 'react-truncate';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';
import remToPixel from '../../util/remToPixel';
import useSelector from '../../util/hooks/useSelector';
import { setNewAccount, updateUserUI } from '../../app/user/action-creators';
import useDispatch from '../../util/hooks/useDispatch';
import { User as UserType } from '../../app/user/types';
import UserNotifications from '../../app/notifications/components/UserNotifications';
import UserDropdown from './UserDropdown';
import Dropdown from '../CustomDropdown/Dropdown';
import useMobile from '../../util/hooks/useMobile';
import useWIPFeatures from '../../util/hooks/useWIPFeatures';
import { useIsGlobalNotificationOpen } from '../../app/notifications/selectors';
import GlobalNotificationButton from '../../app/notifications/components/GlobalNotificationButton';
import CartIndicatorOld from '../../app/cart/components/CartIndicatorOld';

const getUserAbr = (user: null | UserType) => {
  if (user) return `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`;
  return '';
};

function User() {
  const { t, i18n } = useTranslation('menu');
  const dispatch = useDispatch();
  const { user, newAccount, userAccountList } = useSelector((state) => state.user);
  const isGlobalNotificationOpen = useIsGlobalNotificationOpen();
  const globalNotification = useSelector(({ notifications }) => notifications.globalNotification);

  const hasClosedGlobalNotification = !isGlobalNotificationOpen && globalNotification;

  const mobile = useMobile();
  const col = useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = useState(200);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isWip = useWIPFeatures();

  const handleDropdown = useCallback(
    (visible: boolean) => {
      setDropdownOpen(visible);
      if (newAccount && visible) dispatch(setNewAccount(false));
    },
    [dispatch, newAccount],
  );

  useEffect(() => {
    if (col.current) setWidth(col.current.offsetWidth - 44 - remToPixel(2.5) - 36);
  });

  return (
    <Row>
      <Col xs={24}>
        <div ref={col} className="gx-text-right gx-d-flex gx-flex-row-reverse gx-align-items-center custom-old-navbar">
          <div className="gx-d-flex gx-align-items-center custom-old-navbar__menu-switch" title='новый личный кабинет'>
            <Switch 
                checked={user?.isUpdatedDesign} 
                size="small"
                onChange={(checked: boolean) => {
                  dispatch(updateUserUI(checked))
                }} />
            <div className="custom-old-navbar__label">Новый кабинет</div>
          </div>
          <Suspense fallback={null}>
            <UserNotifications />
          </Suspense>
          {hasClosedGlobalNotification && (
            <GlobalNotificationButton />
          )}

          <CartIndicatorOld />
          {!mobile && (
            <>
              <Tooltip
                title={t('previous_team_tooltip')}
                visible={newAccount && userAccountList.length === 2}
                placement="bottom"
              >
                <Dropdown
                  overlay={(
                    <Suspense fallback={null}>
                      <UserDropdown/>
                    </Suspense>
                  )}
                  trigger={['click']}
                  className="gx-pointer"
                  placement="bottomRight"
                  overlayStyle={{ zIndex: 10001 }}
                  visible={dropdownOpen}
                  onVisibleChange={handleDropdown}
                >
                  <div className="gx-text-right gx-d-flex ">
                    <span className="gx-mr-3 gx-text-black">
                      <Truncate
                        lines={1}
                        ellipsis="..."
                        trimWhitespace
                        width={width}
                      >
                        {user?.accountTitle}
                      </Truncate>
                      <br/>
                      <Truncate
                        lines={1}
                        ellipsis="..."
                        trimWhitespace
                        width={width}
                      >
                        {user?.firstName} {user?.lastName}
                      </Truncate>
                    </span>
                    <span>
                      <Avatar
                        className="pls-avatar"
                        src={user?.avatar}
                        alt={user?.firstName}
                        style={{ minWidth: 32, minHeight: 32 }}
                      >
                        {getUserAbr(user)}
                      </Avatar>
                    </span>

                  </div>
                </Dropdown>
              </Tooltip>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default User;
