import firebase from 'firebase/app';
import 'firebase/messaging';
import { ThunkAction } from './types';
import { addFCMToken } from '../app/notifications/action-creators';


firebase.initializeApp({
  apiKey: "AIzaSyDHZyweOnjuQARyPWNZ8jdedaEAoyhKrgk",
  authDomain: "test-belkapost.firebaseapp.com",
  databaseURL: "https://test-belkapost-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test-belkapost",
  storageBucket: "test-belkapost.appspot.com",
  messagingSenderId: "193710494646",
  appId: "1:193710494646:web:a01c88e9af38483e9fa717",
  measurementId: "G-YQ9BSBYHP4"
});


export default function (): ThunkAction<void> {
  return (dispatch, getState, http) => {
    if ('Notification' in window) {
      const messaging = firebase.messaging();
      try {
        messaging.usePublicVapidKey('BFPNVdzDmR5E4Y2-pHgriXmusRNkySu_qOVGnOgmG3wtf_0hlVCdJ4x0YDlzDfbMhGChuxVtCozoS4hS0gS0Ukk'); 
      } catch (e) {}
      Notification.requestPermission().then(
        (permission) => {
          if (permission === 'granted') {
            messaging.getToken().then(
              (token) => dispatch(addFCMToken(token)),
            );
            messaging.onTokenRefresh(() => {
              messaging.getToken().then(
                (token) => dispatch(addFCMToken(token)),
              );
            });
          }
        },
      );
    }
  };
}
