import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { acceptTerms } from '../action-creators';
import { Button, Row } from 'antd';
import Col from 'antd/lib/grid/col';

function TermsOfUsePopup() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(
    () => {
      setLoading(true);
      dispatch(acceptTerms()).then(
        () => { setLoading(false); },
        () => { setLoading(false); },
      );
    },
    [],
  );

  return (
    <SweetAlert
      show={!!user && !user.termsAccepted}
      title="Изменение правил работы сервиса"
      // confirmBtnText="Согласен"
      showConfirm={false}
      onConfirm={console.debug}
    >
      <Row>
        <Col span={24} className="gx-mb-3">
        Дорогой клиент, на сервисе были изменены <Link to="/terms.pdf" target="_blank">правила работы.</Link> <br />
        Пожалуйста, ознакомьтесь с новыми условиями и подтвердите свое согласие.
        </Col>
        <Col span={24}>
          <Button type="primary" loading={loading} onClick={handleConfirm}>Я согласен</Button>
        </Col>
      </Row>
    </SweetAlert>
  );
}

export default TermsOfUsePopup;
