import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import MainView from '../views/MainView';
import PrivateRouter from './PrivateRoute';
import ShippingListView from '../app/shipping/views/ShippingListView';
import ShippingDetailView from '../app/shipping/views/ShippingDetailView';
import CounterpartyRoute from './CounterpartyRoute';
import CalculationCreateView from '../app/calculation/views/CalculationCreateView';
import CalculationResultView from '../app/calculation/views/CalculationResultView';
import RegistryCalcResultView from '../app/registry/views/RegistryCalcResultView';
import TerminalCalcCreateView from '../app/terminal/views/TerminalCalcCreateView';
import UserInviteListView from '../app/user/views/UserInviteListView';
import UserSettingsView from '../app/user/views/UserSettingsView';
import InterCalculationCreateView from '../app/international/views/InterCalculationCreateView';
import InterCalculationResultListView from '../app/international/views/InterCalculationResultListView';
import RegistryView from '../app/registry/views/RegistryView';
import RegistryDetailView from '../app/registry/views/RegistryDetailView';
import AccountBalanceView from '../app/billing/views/AccountBalanceView';
import TerminalCalcResultView from '../app/terminal/views/TerminalCalcResultView';
import CreateUserInviteView from '../app/user/views/CreateUserInviteView';
import OrderCreateView from '../app/online_store/views/OrderCreateView';
import OrderListView from '../app/online_store/views/OrderListView';
import MyBonusesView from '../app/marketing/view/MyBonusesView';
import DialogListView from '../app/chat/views/DialogListView';
import ClaimDetailView from '../app/shipping/views/ClaimDetailView';
import AcceptOwnershipTransferView from '../app/user/views/AcceptOwnershipTransferView';
import FastCalcView from '../app/calculation/views/FastCalcView';
import CartDetailView from '../app/cart/views/CartDetailView';
import StoreView from '../app/marketing/view/StoreView';
import CalculationCreateViewNew from '../app/calculation_new/views/CalculationCreateView';
import CalculationResultViewNew from '../app/calculation_new/views/CalculationResultView';
import TagListView from '../app/shipping/views/TagListView';
import NotificationsView from '../app/notifications/view/NotificationsView';
import CalculationSplitView from '../app/calculation_new/views/CalculationSplitView';
import CalculationInvoiceView from '../app/calculation_new/views/CalculationInvoiceView';
import ForeignInvoiceDetailView from '../app/shipping/components/ForeignInvoiceDetailView';

function AuthorizedRoute(props: any) {
  return (
    <MainView {...props}>
      <Switch>
        {/* Waybill */}
        <PrivateRouter
          exact
          path="/"
          component={ShippingListView}
        />
        <PrivateRouter
          component={CalculationSplitView}
          path="/details"
          exact
        />
        <PrivateRouter
          component={CalculationInvoiceView}
          path="/invoice"
          exact
        />

        <PrivateRouter
          exact
          path="/orders"
          component={ShippingListView}
        />
        <PrivateRouter
          exact
          path="/waybill/:waybillId"
          component={ShippingDetailView}
        />

        <PrivateRouter
          exact
          path="/foreign_invoice/:foreignInvoiceNumber"
          component={ForeignInvoiceDetailView}
        />

        {/* Counterparty */}
        <PrivateRouter
          path="/counterparty"
          component={CounterpartyRoute}
        />

        {/* Calculation */}
        {/*<PrivateRouter*/}
        {/*  component={NewCalcView}*/}
        {/*  exact*/}
        {/*  path="/calculation/new"*/}
        {/*/>*/}

        <PrivateRouter
          exact
          path="/calculation/new"
          component={CalculationCreateViewNew}
        />

        {/*<PrivateRouter exact component={CalculationCreateViewNew} path="/calculation/wip" />*/}

        {/*<PrivateRouter*/}
        {/*  exact*/}
        {/*  path="/cart/calculation"*/}
        {/*  component={CalculationCreateView}*/}
        {/*/>*/}

        <PrivateRouter
          exact
          path="/wip_calculation"
          component={CalculationCreateView}
          wip
        />

        <PrivateRouter
          component={FastCalcView}
          path="/calculation/fast"
          exact
        />

        {/*<PrivateRouter*/}
        {/*  exact*/}
        {/*  path="/calculation/:taskId"*/}
        {/*  component={CalculationResultView}*/}
        {/*/>*/}

        <PrivateRouter
          exact
          path="/cart/calculation/:taskId"
          component={CalculationResultView}
        />

        <PrivateRouter
          exact
          path="/calculation/:taskId"
          component={CalculationResultViewNew}
        />

        {/* Terminal */}
        <PrivateRouter
          exact
          path="/terminal/calculation"
          component={TerminalCalcCreateView}
        />
        <PrivateRouter
          exact
          path="/terminal/calculation/:taskId"
          component={TerminalCalcResultView}
        />

        {/* User Invites */}
        <PrivateRouter
          exact
          path="/user/invite/create"
          component={CreateUserInviteView}
        />
        <PrivateRouter
          exact
          path="/user/invite"
          component={UserInviteListView}
        />
        <PrivateRouter exact path="/settings" component={UserSettingsView} />
        <PrivateRouter
          exact
          path="/finance"
          component={AccountBalanceView}
        />

        {/* International */}
        <PrivateRouter exact path="/international/calculation" component={InterCalculationCreateView} />
        <PrivateRouter exact path="/international/calculation/:taskId" component={InterCalculationResultListView} />

        {/* Registry */}
        <PrivateRouter exact path="/scheduler" component={RegistryView} />
        <PrivateRouter exact path="/scheduler/:date" component={RegistryDetailView} />

        <PrivateRouter
          exact
          path="/scheduler/calculation/:multiTaskId"
          component={RegistryCalcResultView}
        />

        {/* Online Store */}
        <PrivateRouter
          component={OrderCreateView}
          path="/online_store/create"
          exact
        />

        <PrivateRouter
          component={OrderListView}
          path="/online_store"
          exact
        />

        <PrivateRouter
          component={MyBonusesView}
          path="/bonuses"
          exact
        />

        <PrivateRouter
          component={StoreView}
          path="/bonuses/store"
          exact
          ownerOnly
        />

        <PrivateRouter
          component={DialogListView}
          path="/appeal"
          exact
        />
 
        <PrivateRouter
          component={ClaimDetailView}
          path="/appeal/:claimNumber"
          exact
        />

        {/* Ownership transfer accept */}
        <PrivateRouter
          component={AcceptOwnershipTransferView}
          path="/ownership/transfer/accept"
          exact
        />

        {/* Cart */}
        <PrivateRouter
          component={CartDetailView}
          path="/cart"
          exact
        />

        {/* Notifications */}
        <PrivateRouter
          component={NotificationsView}
          path="/notifications"
          exact
        />

        <PrivateRouter
          component={TagListView}
          path="/tags"
          exact
        />

        <Redirect to="/404" />

      </Switch>
    </MainView>
  );
}

export default AuthorizedRoute;
