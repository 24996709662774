import removeByFieldValue from '../../util/removeByFieldValue';
import {
  OnlineStoreActions,
  REPLACE_GOOD,
  RESET_ONLINE_STORE,
  SET_WAREHOUSE_LIST,
  SET_WAREHOUSE_LIST_COUNT,
  Warehouse,
} from './types';
import { replaceByFieldValue } from '../../util/array/replace';

interface onlineStoreState {
  senderWarehouse: Warehouse | null,
  receiverContact: any,
  goods: Array<any>,
  orderId: any,
  rates: Array<any>,
  warehouseList: Array<Warehouse>,
  warehouseListCount: number,
}
const initialState: onlineStoreState = {
  senderWarehouse: null,
  receiverContact: null,
  goods: [],
  orderId: null,
  rates: [],
  warehouseList: [],
  warehouseListCount: 0,
};

export default function (state = initialState, action: OnlineStoreActions) {
  switch (action.type) {
    case 'SET_SENDER_WAREHOUSE':
      return { ...state, senderWarehouse: action.warehouse };
    case 'SET_RECEIVER_CONTACT':
      return { ...state, receiverContact: action.contact };
    case 'ADD_GOOD':
      return { ...state, goods: [...state.goods, action.good] };
    case 'SET_ORDER_ID':
      return { ...state, orderId: action.orderId };
    case 'REMOVE_GOOD':
      return { ...state, goods: removeByFieldValue(state.goods, 'goodId', action.good.goodId) };
    case SET_WAREHOUSE_LIST:
      return { ...state, warehouseList: action.list };
    case SET_WAREHOUSE_LIST_COUNT:
      return { ...state, warehouseListCount: action.count };
    case RESET_ONLINE_STORE:
      return { ...initialState, warehouseListCount: state.warehouseListCount, warehouseList: state.warehouseList };
    case REPLACE_GOOD:
      return { ...state, goods: replaceByFieldValue(state.goods, 'goodId', action.good) };
    default: return state;
  }
}
