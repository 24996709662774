import { CargoCast } from "../cast/types";

export const ADD_PACKAGE = 'ADD_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const REPLACE_PACKAGE = 'REPLACE_PACKAGE';
export const SET_CARGO_ID = 'SET_CARGO_ID';
export const RESET_CARGO = 'RESET_CARGO';
export const RESET_CARGO_SEARCH = 'RESET_CARGO_SEARCH';
export const SET_CARGO_INSURANCE = 'SET_CARGO_INSURANCE';
export const SET_CARGO_INSURANCE_RATE_VALUE = 'SET_CARGO_INSURANCE_RATE_VALUE';
export const SET_INSURANCE_RESPONSE = 'SET_INSURANCE_RESPONSE';
export const ADD_INTER_PACKAGE = 'ADD_INTER_PACKAGE';
export const DELETE_INTER_PACKAGE = 'DELETE_INTER_PACKAGE';
export const REPLACE_INTER_PACKAGE = 'REPLACE_INTER_PACKAGE';
export const SET_INTER_REASON = 'SET_INTER_REASON';
export const RESET_INTER_REASON = 'RESET_INTER_REASON';
export const SET_INTER_CARGO_ID = 'SET_INTER_CARGO_ID';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_CARGO_TYPE = 'SET_CARGO_TYPE';
export const SET_INTER_CURRENCY = 'SET_INTER_CURRENCY';
export const SET_ADDITIONAL_SERVICES = 'SET_ADDITIONAL_SERVICES';

export const ADD_RETURN_PACKAGE = 'ADD_RETURN_PACKAGE';
export const DELETE_RETURN_PACKAGE = 'DELETE_RETURN_PACKAGE';
export const REPLACE_RETURN_PACKAGE = 'REPLACE_RETURN_PACKAGE';
export const SET_RETURN_CARGO_ID = 'SET_RETURN_CARGO_ID';
export const SET_RETURN_CARGO_TYPE = 'SET_RETURN_CARGO_TYPE';
export const SET_INTER_CARGO_FROM_LOCAL = 'SET_INTER_CARGO_FROM_LOCAL';
export const SET_INTER_CARGO_FROM_INVOICE = 'SET_INTER_CARGO_FROM_INVOICE';

export enum AdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  RETURN = 'RETURN',
  BY_HAND = 'BY_HAND',
  PICKUP_IN_60_MIN = 'PICKUP_IN_60_MIN',
  PRIORITY_CONTROL = 'PRIORITY_CONTROL'
}

export interface Package {
  width?: number | undefined,
  height?: number | undefined,
  length?: number | undefined,
  weight?: number | undefined,
  description: string,
  amount?: number | undefined
}

export interface Cargo {
  packages: Package[],
  cargoId: string | null
}

export interface InsuranceResponse {
  insuranceValueRate: number,
  insuranceAmount: number
}

export interface InterPackage {
  description: string,
  descriptionInter: string,
  tnved: string,
  country: string,
  quantity?: number,
  price?: number,
  width?: number,
  height?: number,
  length?: number,
  weight?: number,
  amount?: number
}

export type InterReason = {
  reasonDomestic: string,
  reasonInter: string,
};

export enum CargoType {
  PACKAGE='package',
  DOCS='docs'
}

export interface AddPackage {
  type: typeof ADD_PACKAGE
}

export interface DeletePackage {
  type: typeof DELETE_PACKAGE,
  packageId: number
}

export interface ReplacePackage {
  type: typeof REPLACE_PACKAGE,
  packageId: number,
  package: Package
}

export interface SetCargoId {
  type: typeof SET_CARGO_ID,
  cargoId: string,
}

export interface ResetCargo {
  type: typeof RESET_CARGO,
  insurance: boolean,
}

export interface ResetCargoSearch {
  type: typeof RESET_CARGO_SEARCH;
  insurance: boolean;
}

export interface SetCargoInsurance {
  type: typeof SET_CARGO_INSURANCE,
  insurance: boolean,
}

export interface SetCargoInsuranceRateValue {
  type: typeof SET_CARGO_INSURANCE_RATE_VALUE,
  rateValue: number
}

export interface SetInsuranceResponse {
  type: typeof SET_INSURANCE_RESPONSE,
  insurance: InsuranceResponse
}

export interface AddInterPackage {
  type: typeof ADD_INTER_PACKAGE
}

export interface DeleteInterPackage {
  type: typeof DELETE_INTER_PACKAGE,
  packageId: number,
}

export interface ReplaceInterPackage {
  type: typeof REPLACE_INTER_PACKAGE,
  packageId: number,
  package: InterPackage
}

export interface SetInterReason {
  type: typeof SET_INTER_REASON,
  reason: InterReason
}

export interface ResetInterReason {
  type: typeof RESET_INTER_REASON
}

export interface SetInterCargoId {
  type: typeof SET_INTER_CARGO_ID,
  cargoId: string,
}

export interface SetPackages {
  type: typeof SET_PACKAGES,
  packages: Array<Package>
}

export interface SetCargoType {
  type: typeof SET_CARGO_TYPE,
  cargoType: CargoType
}

export interface SetInterCurrency {
  type: typeof SET_INTER_CURRENCY,
  currency: string
}

export interface SetAdditionalServices {
  type: typeof SET_ADDITIONAL_SERVICES,
  services: string[],
}

export interface AddReturnPackage {
  type: typeof ADD_RETURN_PACKAGE
}

export interface DeleteReturnPackage {
  type: typeof DELETE_RETURN_PACKAGE,
  packageId: number
}

export interface ReplaceReturnPackage {
  type: typeof REPLACE_RETURN_PACKAGE,
  packageId: number,
  package: Package
}

export interface SetReturnCargoId {
  type: typeof SET_RETURN_CARGO_ID,
  cargoId: string,
}

export interface SetReturnCargoType {
  type: typeof SET_RETURN_CARGO_TYPE,
  cargoType: CargoType,
}

export interface SetInterCargoFromLocal {
  type: typeof SET_INTER_CARGO_FROM_LOCAL,
}

export interface SetInterCargoFromInvoice {
  type: typeof SET_INTER_CARGO_FROM_INVOICE,
  payload: CargoCast
}

export type CargoActions = AddPackage | DeletePackage | ReplacePackage | SetCargoId | ResetCargo | ResetCargoSearch | SetCargoInsurance
| SetCargoInsuranceRateValue | SetInsuranceResponse | AddInterPackage | DeleteInterPackage | ReplaceInterPackage
| SetInterReason | ResetInterReason | SetInterCargoId | SetPackages | SetCargoType | SetInterCurrency | SetAdditionalServices
| AddReturnPackage | DeleteReturnPackage | ReplaceReturnPackage | SetReturnCargoId | SetReturnCargoType | SetInterCargoFromLocal | SetInterCargoFromInvoice;
