import observer from '../../util/observer';
import {calcAvailableRegistryFilters, checkCurrentRegistryFilters} from './action-creators';

export const multiGroupObserver = observer((state) => state.registry.multiRateGroups, (dispatch) => {
  // console.debug('')
  dispatch(calcAvailableRegistryFilters());
  dispatch(checkCurrentRegistryFilters());
});


