import React, { useCallback } from 'react';
import { Card } from 'antd';
import { Col, Row } from 'antd/es/grid';
import Avatar from 'antd/es/avatar';
import { Link } from 'react-router-dom';
import Divider from 'antd/es/divider';
import Badge from 'antd/es/badge';
import { User as UserType } from '../../app/user/types';
import useSelector from '../../util/hooks/useSelector';
import { signOut, switchAccount } from '../../app/user/action-creators';
import useDispatch from '../../util/hooks/useDispatch';
import { renderPhone } from '../../util/helpers';
import {useTranslation} from 'react-i18next';
import SettingsIcon from '../../assets/navbar/settings.gif';
import ContactsIcon from '../../assets/navbar/contacts.gif';
import BalanceIcon from '../../assets/navbar/balance.gif';
import TeamIcon from '../../assets/navbar/team.gif';
import CalendarIcon from '../../assets/navbar/calendar.gif';


const getUserAbr = (user: null | UserType) => {
  if (user) return `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`;
  return '';
};


function UserDropdown() {
  const { t, i18n } = useTranslation('menu');
  const dispatch = useDispatch();
  const { user, userAccountList } = useSelector((state) => state.user);

  const handleSignOut = useCallback(
    () => {
      dispatch(signOut());
    },
    [dispatch],
  );

  const handleSwitch = useCallback(
    (accountId) => {
      dispatch(switchAccount(accountId));
    },
    [dispatch],
  );

  const getSellerDescription = (sellerId: number) => {
    if (sellerId === 2) {
      return " (с ндс)";
    }
    return " (без ндс)";
  }


  return (
    <Card className="gx-card gx-mb-0 custom-user-dropdown" style={{ width: 300 }}>
      <Row>
        <Col xs={24} className="gx-d-flex gx-justify-content-center gx-mb-3">
          <Avatar size="large" className="pls-avatar pls-avatar-lg" src={user?.avatar} alt={getUserAbr(user)}>
            {getUserAbr(user)}
          </Avatar>
        </Col>
        <Col xs={24} className="gx-d-flex gx-justify-content-center">
          {user?.firstName} {user?.lastName}
        </Col>
        <Col xs={24} className="gx-d-flex gx-justify-content-center gx-text-muted gx-mb-3">
          {renderPhone(user?.phone || '')}
        </Col>
        <Col xs={24} className="gx-d-flex gx-flex-column">
          <Link to="/settings" className='gx-d-flex gx-align-items-center gx-text-nowrap custom-user-dropdown__menu-item'>
            <img src={SettingsIcon} alt="settings" />
            {t('settings')}
          </Link>
          <Link to="/counterparty" className='gx-d-flex gx-align-items-center gx-text-nowrap custom-user-dropdown__menu-item'>
            <img src={ContactsIcon} alt="counterparty" />
            {t('counterparty_list')}
          </Link>
          <Link to="/finance" className='gx-d-flex gx-align-items-center gx-text-nowrap custom-user-dropdown__menu-item'>
            <img src={BalanceIcon} alt="balance" />
            {t('finance')}
          </Link>
          <Link to="/scheduler" className='gx-d-flex gx-align-items-center gx-text-nowrap custom-user-dropdown__menu-item'>
            <img src={CalendarIcon} alt="scheduler" />
            {t('scheduler')}
          </Link>
          <Link to="/user/invite" className='gx-d-flex gx-align-items-center gx-text-nowrap custom-user-dropdown__menu-item is-no-mb'>
            <img src={TeamIcon} alt="team" />
            {t('my_teams')}
          </Link>
          <Divider />
        </Col>
        {userAccountList.length > 1 && (
          <>
            {userAccountList.map((account) => (
              account.accountId === user?.accountId
                ? (
                  <Col xs={24} className='custom-user-dropdown__team'>
                    <Badge status="warning" className="gx-m-0" />
                    {account.title}
                    <span className='gx-font-weight-medium'>{getSellerDescription(account.sellerId)}</span>
                    <Divider />
                  </Col>
                )
                : (
                  <Col xs={24} className="gx-pointer custom-user-dropdown__team" onClick={() => handleSwitch(account.accountId)}>
                    {account.title}
                    <span className='gx-font-weight-medium'>{getSellerDescription(account.sellerId)}</span>
                    <Divider />
                  </Col>
                )
            ))}
          </>
        )}

        <Col xs={24} className="gx-d-flex gx-justify-content-center">
          <span onClick={handleSignOut} className="gx-pointer custom-user-dropdown__exit">
            {t('logout')}
          </span>
        </Col>
      </Row>
    </Card>
  );
}

export default UserDropdown;
