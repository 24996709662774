import React from 'react';
import { Notice } from '../../types';
import GenericNotification from './GenericNotification';


export interface NotificationItemProps {
  data: Notice,
  close: () => void,
}


function NotificationItem({ data, close }: NotificationItemProps) {
  return <GenericNotification data={data} close={close} />;
}

export default NotificationItem;
