import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CalculationCreate = React.lazy(() => import('../components/CalculationCreate'));

function CalculationCreateView() {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <CalculationCreate />
    </React.Suspense>
  );
}

export default CalculationCreateView;
