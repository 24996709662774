import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const InterCalculationCreate = React.lazy(() => import('../components/InterCalculationCreate'));
function InterCalculationCreateView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <InterCalculationCreate />
    </Suspense>
  );
}

export default InterCalculationCreateView;
