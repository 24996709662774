type ConfigType = {
  defaultLanguage: string,
  title: string
};

const config: {[key: string]: ConfigType} = {
  '*.belkapost.ru': {
    defaultLanguage: 'ru',
    title: 'Белкапост',
  },
  '*.belkapost.es': {
    defaultLanguage: 'en',
    title: 'Belkapost',
  },
};

export default function getDomainConfig() {
  const { location: { hostname } } = document;
  if (config[hostname]) return config[hostname];
  const wildcard = ['*', ...hostname.split('.').slice(1)].join('.');
  if (config[wildcard]) return config[wildcard];
  return config['*.belkapost.ru'];
}
