import React, {ComponentType, LazyExoticComponent} from 'react';
import {matchPath, RouteProps} from "react-router-dom";

type LazyPreloadExoticComponent<T> = LazyExoticComponent<any> & {
  preload?: () => void;
}

function lazyPreload<T extends ComponentType<any>>(importStatement: () => Promise<{ default: T }>): LazyPreloadExoticComponent<T> {
  const Component = React.lazy(importStatement) as LazyPreloadExoticComponent<T>;
  Component.preload = importStatement;
  return Component;
}

const findComponentForRoute = (path: string, routes: Array<RouteProps>) => {
  const matchingRoute = routes.find(route =>
    matchPath(path, {
      path: route.path,
      exact: route.exact
    })
  );
  return matchingRoute ? matchingRoute.component : null;
};

const preloadRouteComponent = (path: string, routes: Array<RouteProps>) => {
  const component = findComponentForRoute(path, routes) as LazyPreloadExoticComponent<any>;
  if (component && component.preload) {
    component.preload();
  }
};

export default lazyPreload;
export { preloadRouteComponent };
