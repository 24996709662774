import React, { useRef, useState } from 'react';
import AutoComplete from 'antd/lib/auto-complete';
import debounce from 'lodash.debounce';
import Spin from "antd/es/spin";
import { useTranslation } from 'react-i18next';

function GeoAutoComplete({ onChange, ...props }) {
  const { t, i18n } = useTranslation('components');
  const [results, setResults] = useState([]);
  const [fetching, setFetching] = useState(false);
  const autocompleteService = useRef(new window.google.maps.places.AutocompleteService()).current;
  const handleSelect = (e) => {
    onChange(e);
  };

  function fetchLocation(search) {
    const isShorterThanMinLength = search.length < props.minLength;

    if (isShorterThanMinLength || search.length === 0) {
      return;
    }
    const options = {
      input: `Россия ${search}`,
    };

    const mapOptions = ['location', 'radius', 'bounds', 'types'];
    mapOptions.forEach((option) => {
      if (props[option]) {
        options[option] = props[option];
      }
    });

    setResults([]);
    setFetching(true);

    autocompleteService.getPlacePredictions(
      options,
      (suggestsGoogle) => {
        const suggestions = suggestsGoogle || [];
        const data = suggestions.map((datum) => datum.description);

        setResults(data);
        setFetching(false);
      },
    );
  }

  const handleSearch = debounce(fetchLocation, 800);

  return (
    <AutoComplete
      dataSource={results}
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin size="small" /> : t('not_found')}
      filterOption={false}
      {...props}
    />
  );
}

export default GeoAutoComplete;
