import React, { useEffect, useState, Suspense } from 'react';
import Row from 'antd/lib/grid/row';
import Card from 'antd/lib/card';
import Col from 'antd/lib/grid/col';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { emailVerify } from '../action-creators';
import CircularProgress from '../../../components_base/CircularProgress';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

function EmailVerifyView(props) {
  const { t, i18n } = useTranslation('user');
  const history = useHistory();
  const { location: { search } } = props;
  const { code } = queryString.parse(search);
  const dispatch = useDispatch();
  const [state, setState] = useState('loading');

  useEffect(() => {
    if (code) {
      dispatch(emailVerify(code)).then(
        () => setState('success'),
        (errors) => {
          if (errors.nonFieldError === 'Код не найдет') return setState('codeNotFound');
          if (errors.nonFieldError === 'Код не действителен') return setState('codeExpired');
          if (errors.nonFieldError === 'Код уже использован') return setState('codeUsed');
          return setState('codeNotFound');
        },
      );
    } else history.push('/');
  }, [code]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card>
          <div className="gx-login-header gx-text-center">
            <h1 className="gx-login-title">{t('email_approve')}</h1>
          </div>

          <Row>
            <Col span={24}>
              {state === 'success'
              && (
              <h2>
                <CheckCircleOutlined className="gx-text-green" />
                {' '}
                {t('email_approve_success')}
              </h2>
              )}
              {state === 'codeNotFound'
              && (
              <h2>
                <CloseCircleOutlined className="gx-text-red" />
                {' '}
                {t('email_approve_email_not_found')}
              </h2>
              )}
              {state === 'codeExpired'
              && (
              <h2>
                <CloseCircleOutlined className="gx-text-red" />
                {' '}
                {t('email_approve_code_expire')}
              </h2>
              )}
              {state === 'codeUsed'
              && (
              <h2>
                <ExclamationCircleOutlined className="gx-text-yellow" />
                {' '}
                {t('email_approve_code_used')}
              </h2>
              )}
            </Col>
          </Row>

          <Row className="gx-text-center">
            <Col span={24}>
              {state !== 'loading'
              && (
              <Link to="/orders">
                <Button type="primary">{t('go_to_account')}</Button>
              </Link>
              )}
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default (props) => (
  <Suspense fallback={<CircularProgress />}>
    <EmailVerifyView {...props} />
  </Suspense>
);
