import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CalculationSplit = React.lazy(() => import('../components/SplitUI/CalculationSplit'));

function CalculationSplitView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <CalculationSplit {...props} />
    </React.Suspense>
  );
}

export default CalculationSplitView;
