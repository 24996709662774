import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CalculationResult = React.lazy(() => import('../components/CalculationResult'));

function CalculationResultView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CalculationResult {...props} />
    </Suspense>
  );
}

export default CalculationResultView;
