import {
  ADD_NOTICE,
  ADD_NOTICE_LIST,
  Notice,
  NotificationActions,
  REMOVE_NOTICE,
  SET_ALL_NOTICE_READ,
  SET_FCM_TOKEN,
  SET_GLOBAL_NOTIFICATION,
  SET_NOT_VIEWED_NOTICE_COUNT,
  SET_NOTICE_COUNT,
  SET_NOTICE_LIST,
  SET_NOTICE_READ,
  SET_NOTIFICATIONS_OPEN,
  SET_URGENT_NOTIFICATIONS,
  UPDATE_NOTICE_LIST,
  UrgentNotification,
  SET_CARRIER_INFO_COUNT,
  SET_BILL_COUNT,
  SET_CARGO_STATUS_COUNT,
  NotificationTypes,
  NotificationType,
  SET_CURRENT_NOTICE_TYPE,
} from './types';
import { sortArrayOfObject } from '../../util/sort';
import { genericConfig, notificationConfig } from './contants';

type NotificationState = {
  noticeList: Array<Notice>,
  noticeCount: number,
  notViewedNoticeCount: number,
  notificationsOpen: boolean,
  fcmToken: null | string,
  urgentNotifications: UrgentNotification[],
  globalNotification: string,
  closedGlobalNotification: string,
  currentNoticeType: NotificationType,
  carrierInfoCount: number,
  billCount: number,
  cargoStatusCount: number
};

const initialState: NotificationState = {
  noticeList: [],
  noticeCount: 0,
  notViewedNoticeCount: 0,
  notificationsOpen: false,
  fcmToken: null,
  urgentNotifications: [],
  globalNotification: '',
  closedGlobalNotification: '',
  currentNoticeType: NotificationTypes.ALL,
  carrierInfoCount: 0,
  billCount: 0,
  cargoStatusCount: 0
};

function removeNotice(noticeList: Array<Notice>, noticeId: string): Array<Notice> {
  const _ = noticeList.slice();
  const index = _.findIndex((n) => n.noticeId === noticeId);
  if (index > -1) _.splice(index, 1);
  return _;
}

function readNotice(list: Array<Notice>, noticeId: string, notViewedNoticeCount: number, carrierInfoCount: number, billCount: number, cargoStatusCount: number): { noticeList: Array<Notice>, notViewedNoticeCount: number, carrierInfoCount: number, billCount: number, cargoStatusCount: number } {
  const _ = list.slice();
  const index = _.findIndex((n) => n.noticeId === noticeId);
  let newViewedNoticeCount = notViewedNoticeCount;
  let newCarrierInfoCount = carrierInfoCount;
  let newBillCount = billCount;
  let newCargoStatusCount = cargoStatusCount;
  let alreadyViewed = false;
  if (index > -1) {
    alreadyViewed = _[index].isViewed;
    _[index].isViewed = true;
    if (!alreadyViewed) {
      let cfg = notificationConfig[_[index].type];
      if (!cfg) cfg = genericConfig;
      if (newViewedNoticeCount !== 0) {
        newViewedNoticeCount -= 1;
      }
      if (cfg.type === NotificationTypes.CARRIER_INFO && newCarrierInfoCount !== 0) {
        newCarrierInfoCount -= 1;
      } else if (cfg.type === NotificationTypes.BILL && newBillCount !== 0) {
        newBillCount -= 1;
      } else if (cfg.type === NotificationTypes.CARGO_STATUS && newCargoStatusCount !== 0) {
        newCargoStatusCount -= 1;
      }
    }
  }
  return { noticeList: _, notViewedNoticeCount: newViewedNoticeCount, carrierInfoCount: newCarrierInfoCount, billCount: newBillCount, cargoStatusCount: newCargoStatusCount };
}

function updateNoticeList(currentList: Array<Notice>, newList: Array<Notice>): Array<Notice> {
  const _ = newList.slice();
  const ids = _.map((notice) => notice.noticeId);
  const left = currentList.filter((notice) => !ids.includes(notice.noticeId));
  _.push(...left);
  return sortArrayOfObject(_, 'date', { date: true });
}

export default function (state = initialState, action: NotificationActions): NotificationState {
  switch (action.type) {
    case SET_NOTICE_COUNT:
      return { ...state, noticeCount: action.count };
    case SET_CARRIER_INFO_COUNT:
      return { ...state, carrierInfoCount: action.count };
    case SET_BILL_COUNT:
      return { ...state, billCount: action.count };
    case SET_CARGO_STATUS_COUNT:
      return { ...state, cargoStatusCount: action.count };
    case SET_NOTICE_LIST:
      return { ...state, noticeList: action.list };
    case REMOVE_NOTICE:
      return { ...state, noticeList: removeNotice(state.noticeList, action.noticeId), noticeCount: state.noticeCount !== 0 ? state.noticeCount - 1 : 0 };
    case ADD_NOTICE:
      return { ...state, noticeList: [action.notice, ...state.noticeList], noticeCount: state.noticeCount + 1 };
    case ADD_NOTICE_LIST:
      return { ...state, noticeList: [...state.noticeList, ...action.noticeList] };
    case SET_NOTICE_READ:
      return { ...state, ...readNotice(state.noticeList, action.noticeId, state.notViewedNoticeCount, state.carrierInfoCount, state.billCount, state.cargoStatusCount) };
    case SET_ALL_NOTICE_READ:
      return { ...state, noticeList: state.noticeList.map((_) => ({ ..._, isViewed: true })), notViewedNoticeCount: 0, carrierInfoCount: 0, billCount: 0, cargoStatusCount: 0 };
    case SET_NOT_VIEWED_NOTICE_COUNT:
      return { ...state, notViewedNoticeCount: action.count >= 0 ? action.count : 0 };
    case SET_NOTIFICATIONS_OPEN:
      return { ...state, notificationsOpen: action.open };
    case UPDATE_NOTICE_LIST:
      return { ...state, noticeList: updateNoticeList(state.noticeList, action.list) };
    case SET_FCM_TOKEN:
      return { ...state, fcmToken: action.token };
    case SET_URGENT_NOTIFICATIONS:
      return { ...state, urgentNotifications: action.notifications };
    case SET_GLOBAL_NOTIFICATION: {
      const newState = { ...state, globalNotification: action.notification };
      if (action.closedNotification != null) {
        newState.closedGlobalNotification = action.closedNotification;
      }
      return newState;
    }
    case SET_CURRENT_NOTICE_TYPE: 
      return { ...state, currentNoticeType: action.payload }
    default: return state;
  }
}
