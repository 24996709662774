import {WaybillPackage} from '../shipping/types';

export const docsPackage: WaybillPackage = {
  width: 27,
  height: 1,
  length: 34,
  weight: 0.5,
  description: 'Документы',
  amount: 1,
};

export const MIN_INSURANCE_VALUE = 500;
export const MAX_INSURANCE_VALUE = 10000;
export const INSURANCE_RATE = 0.01;
