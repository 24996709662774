import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import useDispatch from "../../../../util/hooks/useDispatch";
import { getTnvedCodes } from "../../../reference/action-creators";
import { AutoComplete } from "antd";

type Props = {
    value: string | undefined,
    errors: any
    onChange: (val: string) => void,
    onFocus: () => void,
}

const CargoInvoiceTnvedSelect = ({
    value,
    errors,
    onChange,
    onFocus,
}: Props) => {

    const dispatch = useDispatch();
    const [options, setOptions] = useState<Array<string>>([]);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleLoadMore = useCallback(
        (val) => {
          onChange(val);
          dispatch(getTnvedCodes(1, val)).then(
            ({ codes }) => {
              setOptions(codes.map((_) => (_.code)));
            },
          );
        },
        [onChange, dispatch],
    );
    
    useEffect(() => {
        dispatch(getTnvedCodes()).then(
          ({ codes }) => {
            setOptions(codes.map((_) => (_.code)));
          },
        );
    }, []);
      

    return (
        <div className={classNames("custom-select-field gx-flex-column", {"is-filled": value, "is-focused": isFocused, "is-error": errors})}>
            <label className="custom-select-field__label">ТН ВЭД</label>
            <AutoComplete
                value={value}
                onFocus={() => {
                  setIsFocused(true);
                  onFocus()
                }}
                onBlur={() => setIsFocused(false)}
                // @ts-ignore
                onChange={onChange}
                dataSource={options}
                onSearch={handleLoadMore}
                className="custom-select-field__input gx-w-100"

            />
        </div>
    );

}

export { CargoInvoiceTnvedSelect };