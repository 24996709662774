export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const SET_OPERATORS = 'SET_OPERATORS';
export const SET_CURRENCY_LIST = 'SET_CURRENCY_LIST';
export const SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST = 'SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST';

export type OperatorSettings = {
  loadingUnloadingEnabled: boolean,
  loadingUnloadingFreeLimit: number,
  autoLoadingUnloading: boolean,
};

export interface Operator {
  key: string,
  title: string,
  icon: string,
  rating: number
  settings: OperatorSettings,
}

export interface Country {
  iso: string,
  title: string,
  icon: string,
  calcEnabled: boolean,
  exportDocEnabled: boolean,
  exportParcelEnabled: boolean,
  mapLang: 'ru' | 'en',
  center: [number, number],
  capital: string
}

export interface AdditionalServiceDescription {
  key: string,
  title: string,
  description: string,
}

export interface Currency {
  code: string,
  title: string,
  titleIso: string,
  unicodeSymbol?: string,
}

type PickupPeriod = {
  begin: string,
  end: string,
};

export type DayScheduler = {
  day: string,
  pickupAvailable: boolean,
  pickupPeriodList: Array<PickupPeriod>,
  beginTime: string,
  endTime: string,
  minPeriod: number
};

export interface SetOperators {
  type: typeof SET_OPERATORS,
  operators: Array<Operator>
}

export interface SetCountriesList {
  type: typeof SET_COUNTRIES_LIST,
  countries: Array<Country>
}

export interface SetCurrencyList {
  type: typeof SET_CURRENCY_LIST,
  list: Array<Currency>
}

export interface SetAdditionalServiceDescList {
  type: typeof SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  payload: Array<AdditionalServiceDescription>
}

export type ReferenceAction = SetOperators | SetCountriesList | SetCurrencyList | SetAdditionalServiceDescList;
