import * as Sentry from '@sentry/browser';
import subscribeWs from '../../util/websocket';
import subscribePush from '../../util/firebase';
import { adaptToApi } from '../../util/adapter';
import { loadTagList, resetWaybills } from '../shipping/action-creators';
import i18n from '../../i18n';
import {
  AccountNotificationPreference, NotificationPreference,
  RESET_USER_INVITES,
  ResetUserInvites,
  SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  SET_NEW_ACCOUNT,
  SET_USER_ACCOUNTS,
  SET_USER_BALANCE,
  SET_USER_INVITE_COUNT,
  SET_USER_INVITE_LIST,
  SET_USER_UI,
  SetAccountNotificationPreferences,
  SetCent,
  SetNewAccount,
  SetShowPostRegistrationModal,
  SetToken,
  SetUser,
  SetUserAccounts,
  SetUserBalance,
  SetUserInviteCount,
  SetUserInviteList,
  SetUserUI,
  User,
  UserAccount,
  UserBalance,
  UserInvite,
} from './types';
import { DESTROY_SESSION, ThunkAction } from '../../util/types';
import { loadOperators } from '../reference/action-creators';
import { updateWindowWidth } from '../settings/action-creators';
import { loadGlobalNotification, loadUrgentNotifications, removeFCMToken } from '../notifications/action-creators';
import { loadAccountRank } from '../marketing/action-creators';
import { loadCart } from '../cart/action-creators';

export const setUser = (user: User | null): SetUser => ({
  type: 'SET_USER',
  user,
});

export const setToken = (accessToken: string | null, refreshToken?: string, expiredAt?: number, remember?: boolean): SetToken => ({
  type: 'SET_TOKEN',
  accessToken,
  refreshToken,
  expiredAt,
  remember,
});

export const setCent = (cent: any): SetCent => ({
  type: 'SET_CENT',
  cent,
});

export const setShowPostRegistrationModal = (show: boolean): SetShowPostRegistrationModal => ({
  type: 'SET_SHOW_POST_REGISTRATION_MODAL',
  show,
});

export const setUserInviteList = (invites: Array<UserInvite>): SetUserInviteList => ({
  type: SET_USER_INVITE_LIST,
  invites,
});

export const setUserInviteCount = (count: number): SetUserInviteCount => ({
  type: SET_USER_INVITE_COUNT,
  count,
});

export const resetUserInvites = (): ResetUserInvites => ({
  type: RESET_USER_INVITES,
});

export const setUserBalance = (balance: UserBalance): SetUserBalance => ({
  type: SET_USER_BALANCE,
  balance,
});

export const setUserAccounts = (list: Array<UserAccount>): SetUserAccounts => ({
  type: SET_USER_ACCOUNTS,
  list,
});

export const setNewAccount = (newAccount: boolean): SetNewAccount => ({
  type: SET_NEW_ACCOUNT,
  new: newAccount,
});

export const setAccountNotificationPreferences = (preferences: AccountNotificationPreference[]): SetAccountNotificationPreferences => ({
  type: SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  preferences,
});

export const setUserUI = (payload: boolean): SetUserUI => ({
  type: SET_USER_UI,
  payload
})

export const registerUser = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/register/', data)
  .then(
    (response: any) => {
      const { accessToken, refreshToken } = response;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('tokenExpire', String(new Date().getTime() + 300000));
      return Promise.resolve(response);
    },
  );

export const loadUserBalance = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/current/balance/').then(
  (response: UserBalance) => {
    dispatch(setUserBalance(response));
    return Promise.resolve(response);
  },
);

export const updateToken = (): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/token_update/').then(
  (response: any) => {
    const { accessToken, refreshToken } = response;
    const expiredAt = new Date().getTime() + 300000;
    dispatch(setToken(accessToken, refreshToken, expiredAt));
  },
  () => {
    dispatch(signOut());
  },
);

export const loadUserAccounts = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/account/list/').then(
  (response: { accounts: Array<UserAccount> }) => {
    dispatch(setUserAccounts(response.accounts));
    return Promise.resolve(response);
  },
);

export const switchAccount = (accountId: number, redirectQuery?: string): ThunkAction => (dispatch, getState, http) => http.put('/api/v1/user/account/switch/', { account_id: accountId }).then(
  () => dispatch(updateToken()).then(
    () => {
      if (redirectQuery) window.location.replace(`/?${redirectQuery}`);
      else window.location.replace('/');
      return Promise.resolve();
    },
  ),
);

export const createNewAccount = (): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/account/create/').then(
  (response: { accountId: number }) => dispatch(switchAccount(response.accountId, 'new_account=true')),
);

type GetCurrentUserDataParams = {
  refreshAvatar?: boolean
};

export const loadAccountNotificationPreferences = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/user/preferences/notifications/list/',
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
    return Promise.resolve(preferences);
  },
);

export const getCurrentUserData = (params?: GetCurrentUserDataParams): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const accessToken = localStorage.getItem('accessToken');
  // @ts-ignore
  const { router: { location: { query: { next } } } } = getState();
  dispatch(loadUserBalance());
  if (accessToken && accessToken !== 'NaN' && accessToken !== 'null') {
    return http.get('/api/v1/user/current/detail/')
      .then(
        (response: any) => {
          // try {
          //   if (window.$crisp) {
          //     window.$crisp.push(['set', 'user:nickname', [`${response.firstName} ${response.lastName}`]]);
          //     window.$crisp.push(['set', 'user:email', [response.email]]);
          //     if (response.accountTitle) {
          //       window.$crisp.push(['set', 'user:company', [response.accountTitle, { url: `https://crm.belkapost.ru/accounts/${response.accountId}` }]]);
          //     }
          //   }
          // } catch (e) {
          //   console.error('Error configure crisp', e);
          // }
          if (next) {
            // @ts-ignore
            window.location = decodeURIComponent(next);
            return Promise.resolve();
          }
          if (i18n.language !== response.language) {
            i18n.changeLanguage(response.language);
          }

          const user = { ...response };
          if (user.avatar && params?.refreshAvatar) {
            // user.avatar += `?cahce=${(new Date()).getTime()}`;
          }
          dispatch(setUser(user));
          dispatch(loadAccountNotificationPreferences());
          try {
            Sentry.configureScope((scope) => {
              scope.setUser({
                email: response.email,
                id: response.userId,
                username: `${response.firstName} ${response.lastName}`,
              });
            });
          } catch (e) {
            console.error('Error configure sentry');
          }
          dispatch(subscribeWs());
          dispatch(subscribePush());
          dispatch(loadUserAccounts());
          dispatch(loadAccountRank());
          dispatch(loadCart());
          dispatch(loadUrgentNotifications());
          dispatch(loadGlobalNotification());
          dispatch(loadTagList({}));
          // dispatch(loadCurrentUserMarketing());
          return Promise.resolve(response);
        },
        (reject: any) => Promise.reject(reject),
      );
  }
  return Promise.reject();
};

export const authorizeUser = (username: string, password: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/login/', {
  username,
  password,
})
  .then(
    (response: any) => {
      const { accessToken, refreshToken } = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt, true));
      dispatch(loadOperators());
      return dispatch(getCurrentUserData());
    },
  );

export const signOut = (): ThunkAction<void> => (dispatch, getState) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpired');
  const { cent } = getState().user;
  const { fcmToken } = getState().notifications;
  if (cent && typeof cent.disconnect === 'function') cent.disconnect();
  if (fcmToken) dispatch(removeFCMToken(fcmToken));
  dispatch(setUser(null));
  dispatch(setToken(null));
  dispatch(resetWaybills());
  dispatch({ type: DESTROY_SESSION });
  dispatch(updateWindowWidth(window.innerWidth));
};

export const phoneRegisterCode = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/register/code_request/', data).then(
  () => Promise.resolve(),
  (errors: any) => Promise.reject(errors),
);

export const phoneRegister = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/register/', data).then(
  (response: any) => {
    const { accessToken, refreshToken } = response;
    const expiredAt = new Date().getTime() + 300000;
    dispatch(setToken(accessToken, refreshToken, expiredAt, true));
    return dispatch(getCurrentUserData());
  },
  (errors: any) => Promise.reject(errors),
);

export const phoneRegisterExtra = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const {
    inn, kpp, ogrn, bik, ...rest
  } = data;
  return http.post('/api/v1/user/register/second_stage/', {
    INN: inn,
    KPP: kpp,
    OGRN: ogrn,
    BIK: bik,
    ...adaptToApi(rest),
  }).then(
    () => {
      dispatch(setShowPostRegistrationModal(true));
      return dispatch(updateToken()).then(
      ).finally(
        () => dispatch(getCurrentUserData()),
      );
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const emailVerify = (code: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/email/verify/', { code }).then(
  (response: any) => Promise.resolve(response),
  (errors: any) => Promise.reject(errors),
);

export const passwordReset = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/password/reset/', data).then(
  (response: any) => Promise.resolve(response),
  (errors: any) => Promise.reject(errors),
);

export const passwordResetConfirm = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/password/reset/confirm/', data).then(
  ({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }) => {
    const expiredAt = new Date().getTime() + 300000;
    dispatch(setToken(accessToken, refreshToken, expiredAt, true));
    return dispatch(getCurrentUserData());
  },
  (errors: any) => Promise.reject(errors),
);

export const acceptTerms = (): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/terms/accept/').then(
  () => dispatch(getCurrentUserData()),
);

export const UserUpdate = (data: any): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.patch('/api/v1/user/settings/update/', adaptToApi(data)).then(
  () => dispatch(getCurrentUserData({ refreshAvatar: true })),
  (reject: any) => Promise.reject(reject),
);

export const UserUpdateCode = (): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.post('/api/v1/user/settings/update/code/').then(
  (response: any) => Promise.resolve(response),
  (reject: any) => Promise.reject(reject),
);

export const resendEmailVerify = (): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const { user } = getState().user;
  if (user) {
    const { email } = user;
    return http.post('/api/v1/user/email/verify/resend/', { email });
  }
  return Promise.reject();
};

export const inviteUser = (firstName: string, lastName: string, phone: string, email: string, permissions: string[]): ThunkAction => (dispatch, getState, http) => {
  const baseUrl = document.location.origin;
  return http.post('/api/v1/user/invite/create/', {
    phone, email, first_name: firstName, last_name: lastName, return_url: `${baseUrl}/signup`, permissions,
  });
};

export const loadInviteList = (page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/invite/', { page }).then(
  (response: { count: number, invites: Array<UserInvite> }) => {
    dispatch(setUserInviteCount(response.count));
    dispatch(setUserInviteList(response.invites));
    return Promise.resolve(response);
  },
);

export const acceptInvite = (key: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/invite/accept/', { key });

export const deleteInvite = (key: number): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/user/account/membership/${key}/revoke/`, { id: key });

export const resendInvite = (key: number): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/invite/resend/', { id: key });

export const loginAs = (accessToken: string, refreshToken: string): ThunkAction => (dispatch) => {
  const expiredAt = new Date().getTime() + 300000;
  dispatch(setToken(accessToken, refreshToken, expiredAt, true));
  dispatch(loadOperators());
  return dispatch(getCurrentUserData());
};

export const getInviteInfo = (code: number, username: string): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/invite/info/', {
  code,
  username,
});

export const changeUserLanguage = (language: string): ThunkAction => (dispatch, getState, http) => http.put('/api/v1/user/language/change/', { language });

export const phoneRegisterCodeCheck = (code: string, phone: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/register/code_check/', {
  code,
  phone,
});

export const emailConfirm = (code: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/register/email_confirm/', { code }).then(
  () => dispatch(getCurrentUserData()),
);

export const resendEmailConfirm = (email: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/register/email_resend/', { email });

export const changeUserPermissions = (membershipId: number, permissions: string[]): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/user/${membershipId}/permissions/change/`,
  { permissions },
);

export const startTransferOwnership = (): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/ownership/transfer/start/');

export const transferOwnership = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/user/ownership/transfer/',
  data,
);

export const cancelTransferOwnership = (): ThunkAction => (dispatch, getState, http) => http.delete(
  '/api/v1/user/ownership/transfer/cancel/',
);

export const checkOwnershipTransfer = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/user/ownership/transfer/check/',
);

export const refreshTokenAuthorization = (): ThunkAction => (dispatch, getState, http) => {
  const { token } = getState().user;
  return http.post('/api/v1/user/token_refresh/', { refresh_token: token?.refreshToken }, { NO_AUTH: true }).then(
    (response: any) => {
      const { accessToken, refreshToken } = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt));
    },
    () => {
      dispatch(signOut());
    },
  );
};

export const acceptOwnershipTransfer = (): ThunkAction => (dispatch, getState, http) => http.put(
  '/api/v1/user/ownership/transfer/accept/',
).then(
  () => dispatch(refreshTokenAuthorization()).then(() => dispatch(getCurrentUserData())),
);

export const declineOwnershipTransfer = (): ThunkAction => (dispatch, getState, http) => http.put(
  '/api/v1/user/ownership/transfer/decline/',
);

export const updateAccountEmailNotificationPreferences = (receivers: string[], preference: NotificationPreference): ThunkAction => (
  dispatch, getState, http,
) => http.put('/api/v1/user/preferences/notifications/update/', { receivers, preference }).then(
  () => dispatch(loadAccountNotificationPreferences()),
);

export const addAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, item: anyObject): ThunkAction => (
  dispatch, getState, http,
) => http.post(
  '/api/v1/user/preferences/notifications/item/add/',
  {
    preference,
    item,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const updateAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, item: anyObject): ThunkAction => (
  dispatch, getState, http,
) => http.put(
  '/api/v1/user/preferences/notifications/item/update/',
  {
    preference,
    item,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const deleteAccountEmailNotificationPreferencesItem = (preference: NotificationPreference, itemId: number): ThunkAction => (
  dispatch, getState, http,
) => http.delete(
  `/api/v1/user/preferences/notifications/item/${itemId}/delete/`,
  {
    preference,
  },
).then(
  ({ preferences }: { preferences: AccountNotificationPreference[] }) => {
    dispatch(setAccountNotificationPreferences(preferences));
  },
);

export const authorizeTelegramBot = (code: string): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/user/bot/telegram/authorize/',
  { code },
);

export const updateUserUI = (value: boolean): ThunkAction => (
  dispatch, getState, http,
) => http.patch(`/api/v1/user/preferences/ui/update/`, { is_updated_design: value }).then(
  (response: any) => {
    window.location.pathname = "/";
  },
);