import React from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Badge from 'antd/es/badge';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import useSelector from '../../util/hooks/useSelector';
import { rankRender } from '../../app/marketing/contants';
import { AccountLoyaltyChoices, AccountRank } from '../../app/user/types';

function Bonus() {
  const { t: tMarketing } = useTranslation('marketing');
  const { currentBalance, user, decodedToken } = useSelector((state) => state.user);

  return (
    <div className="gs-d-flex gx-flex-row gx-align-items-center">
      <div className="gx-align-items-center">
        <div >
          <span className="gx-ml-2 gx-font-weight-bold"><Trans>Бонусный счет</Trans>:</span>

          <Badge
            className="gx-ml-2 gx-mt-1 bonus-badge gx-mr-1"
            count={currentBalance.bonusBalance}
            overflowCount={99999999}
            showZero
            style={{ background: '#06bb8a' }}
          />

          {!!currentBalance.bonusHoldBalance &&
          <span className="bonus-hold-balance">+{currentBalance.bonusHoldBalance}</span>}

        </div>
        {decodedToken?.loyalty !== AccountLoyaltyChoices.TENDER
        && (
          <div >
            <Link
              to="/bonuses"
            >
              <span
                className="gx-ml-2"
              >{tMarketing('marketing:current_status')}: {!!user && user?.rank !== AccountRank._ && tMarketing(`marketing:${rankRender[user.rank].title}`)}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Bonus;
