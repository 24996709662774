import {
  CalculationActions,
  CalculationSide,
  CalculationStage,
  FastCalculationSide,
  RESET_CALCULATION,
  RESET_SENDER_RECEIVER_FAST,
  SET_CALCULATION_STAGE,
  SET_CURRENT_RECEIVER_TERMINAL,
  SET_CURRENT_SENDER_TERMINAL,
  SET_RECEIVER_CAST,
  SET_RECEIVER_FAST_CAST,
  SET_SENDER_CAST, SET_SENDER_FAST_CAST, TerminalCast, FastOrderCast, SET_FAST_ORDER_CAST
} from './redux';
import { CIS_DELIVERY_COUNTRIES } from '../international/constants';

type CalculationState = {
  sender: CalculationSide | null,
  receiver: CalculationSide | null,
  senderFast: FastCalculationSide | null,
  receiverFast: FastCalculationSide | null,
  senderTerminal: TerminalCast | null,
  receiverTerminal: TerminalCast | null,
  stage: CalculationStage,
  international: boolean,
  fastOrder: FastOrderCast | null
};

const initialState: CalculationState = {
  sender: null,
  receiver: null,
  senderFast: null,
  receiverFast: null,
  senderTerminal: null,
  receiverTerminal: null,
  stage: CalculationStage.SENDER,
  international: false,
  fastOrder: null
};

const checkInternational = (sender: CalculationSide | null, receiver: CalculationSide | null) => (!!sender && !CIS_DELIVERY_COUNTRIES.includes(sender.cast.iso)) || (
  !!receiver && !CIS_DELIVERY_COUNTRIES.includes(receiver.cast.iso)
);

export default function (state = initialState, action: CalculationActions) {
  switch (action.type) {
    case SET_SENDER_CAST:
      return { ...state, sender: action.cast, international: checkInternational(action.cast, state.receiver) };
    case SET_RECEIVER_CAST:
      return { ...state, receiver: action.cast, international: checkInternational(state.sender, action.cast) };
    case SET_CALCULATION_STAGE:
      return { ...state, stage: action.stage };
    case RESET_CALCULATION:
      return { ...state, ...initialState, fastOrder: action.isFastOrder ? state.fastOrder : null };
    case SET_CURRENT_SENDER_TERMINAL:
      return { ...state, senderTerminal: action.terminal };
    case SET_CURRENT_RECEIVER_TERMINAL:
      return { ...state, receiverTerminal: action.terminal };
    case SET_SENDER_FAST_CAST:
      return { ...state, senderFast: action.cast }
    case SET_RECEIVER_FAST_CAST:
      return { ...state, receiverFast: action.cast }
    case RESET_SENDER_RECEIVER_FAST:
      return { ...state, senderFast: null, receiverFast: null }
    case SET_FAST_ORDER_CAST:
      return { ...state, fastOrder: action.payload }
    default: return state;
  }
}
