import {
  ADD_TAG_TO_LIST,
  ADD_TAGS_TO_LIST,
  AddTagsToList,
  AddTagToList,
  BasicWaybill,
  Claim,
  Pickup,
  REMOVE_TAG,
  RemoveTag,
  RESET_CURRENT_CLAIM,
  RESET_CURRENT_WAYBILL,
  RESET_SHARED_WAYBILL_DATA,
  ResetCurrentClaim,
  ResetCurrentWaybill,
  ResetSharedWaybillData,
  SET_CURRENT_CLAIM,
  SET_CURRENT_PICKUP,
  SET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL_SERVICE_LIST,
  SET_REVIEW_LIST_TOOL,
  SET_SHARED_BASIC_WAYBILL_DATA,
  SET_SHARED_EXTENDED_WAYBILL_DATA,
  SET_TAG_COUNT,
  SET_TAG_LIST,
  SET_TRACKING_LIST_TOOL,
  SET_WAYBILL_COUNT,
  SET_WAYBILL_FILTER_VALUE,
  SET_WAYBILL_LIST,
  SetCurrentClaim,
  SetCurrentPickup,
  SetCurrentWaybill,
  SetCurrentWaybillServiceList,
  SetReviewListTool,
  SetSharedBasicWaybillData,
  SetSharedExtendedWaybillData,
  SetSupportListTool,
  SetTagCount,
  SetTagList,
  SetTrackingListTool,
  SetWaybillCount,
  SetWaybillFilterValue,
  SetWaybillList,
  ShippingTag,
  ShortWaybillInfo,
  Waybill,
  WaybillDetail,
  WaybillService,
  SET_CLAIM_LIST,
  ForeignInvoice,
  SET_FOREIGN_INVOICE_LIST,
  SetCurrentForeignInvoice,
  SET_CURRENT_FOREIGN_INVOICE,
  ResetCurrentForeignInvoice,
  RESET_CURRENT_FOREIGN_INVOICE,
} from './types';
import { ThunkAction } from '../../util/types';
import { SERVER_DATE_FORMAT } from '../../util/constants';
import { cartCreationListeners, updateSingleDestination } from '../cart/action-creators';
import { CartDestinationStatus } from '../cart/types';
import { adaptToApi } from '../../util/adapter';
import { setInterCargoFromInvoice } from '../cargo/action-creators';

export const setCurrentWaybill = (waybill: WaybillDetail): SetCurrentWaybill => ({
  type: SET_CURRENT_WAYBILL,
  waybill,
});

export const setCurrentPickup = (pickup: Pickup): SetCurrentPickup => ({
  type: SET_CURRENT_PICKUP,
  pickup,
});

export const setWaybillList = (waybills: Array<Waybill>, hasWaybills: boolean): SetWaybillList => ({
  type: SET_WAYBILL_LIST,
  waybills,
  hasWaybills,
});

export const setWaybillCount = (count: number): SetWaybillCount => ({
  type: SET_WAYBILL_COUNT,
  count,
});

export const resetCurrentWaybill = (): ResetCurrentWaybill => ({
  type: RESET_CURRENT_WAYBILL,
});

export const setSupportListTool = (shipping: string | null): SetSupportListTool => ({
  type: 'SET_SUPPORT_LIST_TOOL',
  shipping,
});

export const setTrackingListTool = (shipping: string | null): SetTrackingListTool => ({
  type: SET_TRACKING_LIST_TOOL,
  shipping,
});

export const setWaybillFilterValue = (value: any, field: string): SetWaybillFilterValue => ({
  type: SET_WAYBILL_FILTER_VALUE,
  value,
  field,
});

export const setSharedBasicWaybillData = (waybill: BasicWaybill): SetSharedBasicWaybillData => ({
  type: SET_SHARED_BASIC_WAYBILL_DATA,
  waybill,
});

export const resetSharedWaybillData = (): ResetSharedWaybillData => ({
  type: RESET_SHARED_WAYBILL_DATA,
});

export const setSharedExtendedWaybillData = (waybill: Waybill): SetSharedExtendedWaybillData => ({
  type: SET_SHARED_EXTENDED_WAYBILL_DATA,
  waybill,
});

export const setCurrentWaybillServiceList = (list: WaybillService[]): SetCurrentWaybillServiceList => ({
  type: SET_CURRENT_WAYBILL_SERVICE_LIST,
  list,
});

export const setCurrentClaim = (claim: Claim): SetCurrentClaim => ({
  type: SET_CURRENT_CLAIM,
  claim,
});

export const resetCurrentClaim = (): ResetCurrentClaim => ({
  type: RESET_CURRENT_CLAIM,
});

export const resetWaybills = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch(setWaybillList([], !getState().shipping.waybillsEmpty));
  dispatch(setWaybillCount(0));
};

export const setReviewListTool = (shipping: null | string): SetReviewListTool => ({
  type: SET_REVIEW_LIST_TOOL,
  shipping,
});

export const setTagList = (list: ShippingTag[]): SetTagList => ({
  type: SET_TAG_LIST,
  list,
});

export const setTagCount = (count: number): SetTagCount => ({
  type: SET_TAG_COUNT,
  count,
});

export const addTagToList = (tag: ShippingTag): AddTagToList => ({
  type: ADD_TAG_TO_LIST,
  tag,
});

export const removeTag = (tagId: number): RemoveTag => ({
  type: REMOVE_TAG,
  tagId,
});

export const addTagsToList = (tags: ShippingTag[]): AddTagsToList => ({
  type: ADD_TAGS_TO_LIST,
  tags,
});

export const setClaimList = (claims: Claim[]) => ({
  type: SET_CLAIM_LIST,
  claims
})

export const setForeignInvoiceList = (payload: ForeignInvoice[]) => ({
  type: SET_FOREIGN_INVOICE_LIST,
  payload
})

export const setCurrentForeignInvoice = (payload: ForeignInvoice): SetCurrentForeignInvoice => ({
  type: SET_CURRENT_FOREIGN_INVOICE,
  payload,
});

export const resetCurrentForeignInvoice = (): ResetCurrentForeignInvoice => ({
  type: RESET_CURRENT_FOREIGN_INVOICE,
});

export const loadWaybillList = (page = 1, limit = 10, query: string | null | undefined = '', filters?: anyObject): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const params: anyObject = {
    skip: (page * limit) - limit,
    limit,
  };
  if (filters) {
    if (filters.status?.length) params.status = filters.status;
    if (filters.pickupDateFrom) params.pickup_date_from = filters.pickupDateFrom?.format(SERVER_DATE_FORMAT);
    if (filters.pickupDateTo) params.pickup_date_to = filters.pickupDateTo?.format(SERVER_DATE_FORMAT);
    if (filters.deliveryService) params.delivery_service = filters.deliveryService;
    if (filters.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom?.format(SERVER_DATE_FORMAT);
    if (filters.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo?.format(SERVER_DATE_FORMAT);
    if (filters.inn) params.inn = filters.inn;
    if (filters.tagList?.length) params.tag_list = filters.tagList;
  }
  if (query) params.query = query;
  return http.get('/api/v1/shipping/waybill/', params).then(
    ({ count, waybills, hasWaybills }: { count: number, waybills: Array<Waybill>, hasWaybills: boolean }) => {
      dispatch(setWaybillCount(count || 0));
      dispatch(setWaybillList(waybills || [], hasWaybills));
      return Promise.resolve({ count, waybills });
    },
  );
};

export const loadClaimList = (page = 1, limit = 30): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const params: anyObject = {
    page,
    limit
  };
  
  return http.get('/api/v1/shipping/claims/', params).then(
    ({claims}: {claims: Claim[]}) => {
      dispatch(setClaimList(claims));
      return Promise.resolve({ claims })
    }
  )
}

export const createForeignInvoice = (data: any): ThunkAction<Promise<string>> => async (dispatch, getState, http) => {
  return http.post('/api/v1/shipping/foreign_invoice/create/', adaptToApi(data)).then(
    ({ foreignInvoiceNumber }: { foreignInvoiceNumber: string }) => {
      return Promise.resolve(foreignInvoiceNumber);
    },
  );
};

export const loadForeignInvoiceList = (): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  
  return http.get('/api/v1/shipping/foreign_invoice/list/').then(
    ({items}: {items: ForeignInvoice[]}) => {
      dispatch(setForeignInvoiceList(items));
      return Promise.resolve({ items })
    }
  )
}

export const loadForeignInvoice = (foreingInvoiceNumber: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  
  return http.get(`/api/v1/shipping/foreign_invoice/${foreingInvoiceNumber}/detail/`).then(
    (result: ForeignInvoice) => {
      dispatch(setCurrentForeignInvoice(result));
      dispatch(setInterCargoFromInvoice(result.cargo));
      return Promise.resolve(result)
    }
  )
}

export const updateForeignInvoice = (foreingInvoiceNumber: string, pickupDate: string, pickupPeriod: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  
  return http.patch(`/api/v1/shipping/foreign_invoice/${foreingInvoiceNumber}/update/`, adaptToApi({pickupDate, pickupPeriod})).then(
    (result: ForeignInvoice) => {
      dispatch(setCurrentForeignInvoice(result));
      return Promise.resolve(result)
    }
  )
}

export const loadWaybill = (waybillNumber: string, useStore = true): ThunkAction<Promise<WaybillDetail>> => (dispatch, getState, http) => {
  if (useStore) dispatch(resetCurrentWaybill());
  return http.get(`/api/v1/shipping/waybill/${waybillNumber}/`).then(
    (response: WaybillDetail) => {
      if (useStore) dispatch(setCurrentWaybill(response));
      return Promise.resolve(response);
    },
  );
};

export const getWaybillInvoice = (waybillNumber: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.get(`/api/v1/shipping/waybill/${waybillNumber}/invoice/`);

export const getOrderWaybill = (orderNumber: string): ThunkAction<Promise<Waybill>> => (dispatch, getState, http) => http.get(`/api/v1/shipping/order_waybill/${orderNumber}/`);

export const getBasicSharedWaybillData = (key: string): ThunkAction<Promise<BasicWaybill>> => (dispatch, getState, http) => http.get(`/api/v1/shipping/shared/basic/${key}/`).then(
  (response: BasicWaybill) => {
    dispatch(setSharedBasicWaybillData(response));
    return Promise.resolve(response);
  },
);

export const getExtendedSharedWaybillData = (key: string, receiverPhone: string): ThunkAction<Promise<Waybill>> => (dispatch, getState, http) => http.post(
  '/api/v1/shipping/shared/extended/',
  { key, receiver_phone: receiverPhone },
).then(
  (response: Waybill) => {
    dispatch(setSharedExtendedWaybillData(response));
  },
);

export const getWaybillShareKey = (plsWaybillNumber: string): ThunkAction<Promise<{ key: string, extendedKey: string }>> => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/share/`,
);

export const getWaybillsForReview = (): ThunkAction<Promise<{ waybills: ShortWaybillInfo[] }>> => (dispatch, getState, http) => http.get(
  '/api/v1/shipping/waybill/review/',
);

export const loadWaybillServiceList = (orderNumber: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/billing/order/${orderNumber}/service/list/`,
).then(
  (response: { items: WaybillService[] }) => {
    dispatch(setCurrentWaybillServiceList(response.items));
    return Promise.resolve();
  },
);

export const loadClaim = (claimNumber: string): ThunkAction<Promise<Claim>> => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/claim/${claimNumber}/detail/`,
).then(
  (response: Claim) => {
    dispatch(setCurrentClaim(response));
    return Promise.resolve(response);
  },
);

type RejectWaybillResponse = {
  success: boolean,
};

export const rejectWaybill = (plsWaybillNumber: string, data: anyObject): ThunkAction<Promise<RejectWaybillResponse>> => (
  dispatch, getState, http,
) => http.delete(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/reject/`,
  data,
);

export const manualWaybillReject = (plsWaybillNumber: string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/reject_manual/`,
);

export const shareWaybillInvoice = (plsWaybillNumber: string, to: string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/invoice/share/`,
  { to },
);

export const getClaimShareKey = (claimNumber: string): ThunkAction<Promise<{ key: string }>> => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/claim/${claimNumber}/share/`,
);

export const shareWaybillByEmail = (plsWaybillNumber: string, email: string, currentShareType: string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/share/email/`,
  { email, share_type: currentShareType },
);

export const shareClaimByEmail = (claimNumber: string, email: string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/claim/${claimNumber}/share/email/`,
  { email },
);

enum SharedWaybillDataType {
  BASIC = 'BASIC',
  EXTENDED = 'EXTENDED'
}

type GetSharedWaybillDataBasicResponse = {
  dataType: SharedWaybillDataType.BASIC,
  basic: BasicWaybill,
};

type GetSharedWaybillDataExtendedResponse = {
  dataType: SharedWaybillDataType.EXTENDED,
  extended: Waybill,
};

type GetSharedWaybillDataResponse = GetSharedWaybillDataBasicResponse | GetSharedWaybillDataExtendedResponse;

export const getSharedWaybillData = (shareKey: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/shared/${shareKey}/detail/`,
).then(
  (response: GetSharedWaybillDataResponse) => {
    if (response.dataType === SharedWaybillDataType.BASIC) {
      dispatch(setSharedBasicWaybillData(response.basic));
    }
    if (response.dataType === SharedWaybillDataType.EXTENDED) {
      dispatch(setSharedExtendedWaybillData(response.extended));
    }
  },
);

export const loadTagList = ({ page = 1, query = '', useState = true }): ThunkAction<Promise<{ tags: ShippingTag[], count: number }>> => (
  dispatch, getState, http,
) => http.get(
  '/api/v1/shipping/tag/list/',
  { page, query },
).then(
  (response: { items: ShippingTag[], count: number }) => {
    if (useState) {
      dispatch(setTagList(response.items));
      dispatch(setTagCount(response.count));
    }
    return Promise.resolve({ tags: response.items, count: response.count });
  },
);

export const createTag = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/shipping/tag/create/',
  data,
).then(
  (tag: ShippingTag) => {
    dispatch(addTagToList(tag));
    return Promise.resolve(tag);
  },
);

export const deleteTag = (tagId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/shipping/tag/${tagId}/delete/`,
).then(
  () => {
    dispatch(removeTag(tagId));
    return Promise.resolve();
  },
);

export const getTagsScoped = (tagIdList: number[]): ThunkAction<Promise<ShippingTag[]>> => (dispatch, getState, http) => http.get(
  '/api/v1/shipping/tag/list/scoped/',
  { tag_id_list: tagIdList },
).then(
  ({ items }: { items: ShippingTag }) => Promise.resolve(items),
);

export const updateWaybillTags = (plsWaybillNumber: string, tagList: number[]): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/shipping/waybill/${plsWaybillNumber}/tags/update/`,
  { tag_list: tagList },
).then(
  () => dispatch(loadWaybill(plsWaybillNumber)),
);

export const createMultipleTags = (data: anyObject): ThunkAction<Promise<ShippingTag[]>> => (dispatch, getState, http) => http.post(
  '/api/v1/shipping/tag/create/multiple/',
  data,
).then(
  ({ tags }: { tags: ShippingTag[]}) => {
    dispatch(addTagsToList(tags));
    return Promise.resolve(tags);
  },
);

export const waybillUpdate = (plsWaybillNumber: string): ThunkAction<void> => async (dispatch, getState, http) => {
  const waybill = await dispatch(loadWaybill(plsWaybillNumber, false));

  const { shipping: { currentWaybill, waybillList } } = getState();

  if (currentWaybill?.plsWaybillNumber === waybill.plsWaybillNumber) {
    dispatch(setCurrentWaybill(waybill));
  }

  const index = waybillList.findIndex((el) => el.plsWaybillNumber === waybill.plsWaybillNumber);

  if (index > -1) {
    const tmp = [...waybillList];
    tmp[index] = waybill;
    dispatch(setWaybillList(tmp, true));
  }

  if (cartCreationListeners[plsWaybillNumber]) {
    console.info('UPDATE CART DESTINATION FROM WS');
    dispatch(updateSingleDestination({
      destinationId: cartCreationListeners[plsWaybillNumber].destinationId,
      status: CartDestinationStatus.CREATION_COMPLETE,
      waybill,
    }));

    if (cartCreationListeners[plsWaybillNumber].timer) {
      clearTimeout(cartCreationListeners[plsWaybillNumber].timer);
      cartCreationListeners[plsWaybillNumber] = undefined;
    }
  }
};