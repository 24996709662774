import React from 'react';
import { Alert } from 'antd';
import useSelector from '../../../../util/hooks/useSelector';
import { UrgentNotificationUrgency } from '../../types';

function UrgentNotificationsList() {
  const { urgentNotifications } = useSelector((state) => state.notifications);

  return (
    <>
      {urgentNotifications.map((notification) => (
        <Alert
          className="gx-mb-0"
          banner
          message={notification.content}
          type={(() => {
            switch (notification.urgency) {
              case UrgentNotificationUrgency.INFO:
                return 'info';
              case UrgentNotificationUrgency.ERROR:
                return 'error';
              case UrgentNotificationUrgency.SUCCESS:
                return 'success';
              case UrgentNotificationUrgency.WARNING:
                return 'warning';
              default: return 'error';
            }
          })()}
        />
      ))}
    </>
  );
}

export default UrgentNotificationsList;
