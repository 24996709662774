import {Contact, Counterparty, CounterpartyAddress, CounterpartyAddressContactInfo} from './types';

export const SET_CONTACTS_LIST = 'SET_CONTACTS_LIST';
export const SET_CONTACTS_PAGE = 'SET_CONTACTS_PAGE';
export const SET_CONTACTS_COUNT = 'SET_CONTACTS_COUNT';
export const SET_CONTACTS_QUERY = 'SET_CONTACTS_QUERY';
export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT';

export const SET_COUNTERPARTIES_LIST = 'SET_COUNTERPARTIES_LIST';
export const SET_COUNTERPARTIES_PAGE = 'SET_COUNTERPARTIES_PAGE';
export const SET_COUNTERPARTIES_COUNT = 'SET_COUNTERPARTIES_COUNT';
export const SET_COUNTERPARTIES_QUERY = 'SET_COUNTERPARTIES_QUERY';
export const SET_CURRENT_COUNTERPARTY = 'SET_CURRENT_COUNTERPARTY';
export const RESET_CURRENT_COUNTERPARTY = 'RESET_CURRENT_COUNTERPARTY';

export const SET_COUNTERPARTY_ADDRESS_LIST = 'SET_COUNTERPARTY_ADDRESS_LIST';

export const SET_COUNTERPARTY_ADDRESS_CONTACTS = 'SET_COUNTERPARTY_ADDRESS_CONTACTS';

export const ADD_ADDRESS_CONTACT_INFO = 'ADD_ADDRESS_CONTACT_INFO';
export const REMOVE_ADDRESS_CONTACT_INFO = 'REMOVE_ADDRESS_CONTACT_INFO';


export interface SetContactsList {
  type: typeof SET_CONTACTS_LIST,
  contacts: Array<Contact>
}

export interface SetContactsPage {
  type: typeof SET_CONTACTS_PAGE,
  page: number,
}

export interface SetContactsCount {
  type: typeof SET_CONTACTS_COUNT,
  count: number
}

export interface SetContactsQuery {
  type: typeof SET_CONTACTS_QUERY,
  query: string
}

export interface SetCurrentContact {
  type: typeof SET_CURRENT_CONTACT,
  contact: Contact
}

export interface SetCounterpartiesList {
  type: typeof SET_COUNTERPARTIES_LIST,
  counterparties: Array<Counterparty>
}

export interface SetCounterpartiesPage {
  type: typeof SET_COUNTERPARTIES_PAGE,
  page: number,
}

export interface SetCounterpartiesCount {
  type: typeof SET_COUNTERPARTIES_COUNT,
  count: number
}

export interface SetCounterpartiesQuery {
  type: typeof SET_COUNTERPARTIES_QUERY,
  query: string
}

export interface SetCurrentCounterparty {
  type: typeof SET_CURRENT_COUNTERPARTY,
  counterparty: Counterparty
}

export interface ResetCurrentCounterparty {
  type: typeof RESET_CURRENT_COUNTERPARTY,
}

export interface SetCounterpartyAddressList {
  type: typeof SET_COUNTERPARTY_ADDRESS_LIST,
  addresses: Array<CounterpartyAddress>,
}

export interface SetCounterpartyAddressContacts {
  type: typeof SET_COUNTERPARTY_ADDRESS_CONTACTS,
  contacts: Array<CounterpartyAddressContactInfo>,
  addressId: string,
}

export interface AddAddressContactInfo {
  type: typeof ADD_ADDRESS_CONTACT_INFO,
  addressId: string,
  contact: CounterpartyAddressContactInfo
}

export type contactsActions = SetContactsList | SetContactsPage | SetContactsCount | SetContactsQuery | SetCurrentContact |
  SetCounterpartiesList | SetCounterpartiesPage | SetCounterpartiesCount | SetCounterpartiesQuery | SetCurrentCounterparty |
  ResetCurrentCounterparty | SetCounterpartyAddressList | SetCounterpartyAddressContacts | AddAddressContactInfo
