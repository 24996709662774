import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const UserLogin = React.lazy(() => import('../components/UserLogin'));
function UserLoginView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <UserLogin {...props} />
    </Suspense>
  );
}

export default UserLoginView;
