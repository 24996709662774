import { ContactCast } from '../contact/types';
import { Package } from '../cargo/types';
import { SingleRate } from '../calculation/types';
import { MultiRateGroup } from '../registry/types';
import { Waybill } from '../shipping/types';
import { TerminalListItem } from '../terminal/types';

export const SET_CART_DESTINATIONS = 'SET_CART_DESTINATIONS';
export const ADD_CART_DESTINATION = 'ADD_CART_DESTINATION';
export const REMOVE_CART_DESTINATION = 'REMOVE_CART_DESTINATION';
export const UPDATE_SINGLE_DESTINATION = 'UPDATE_SINGLE_DESTINATION';
export const UPDATE_MULTIPLE_DESTINATIONS = 'UPDATE_MULTIPLE_DESTINATIONS';
export const ADD_MULTIPLE_CART_DESTINATIONS = 'ADD_MULTIPLE_CART_DESTINATIONS';
export const RESET_CART = 'RESET_CART';

export enum CartDestinationStatus {
  INIT = 'INIT',
  RECALC_RUNNING = 'RECALC_RUNNING',
  RECALC_DONE = 'RECALC_DONE',
  CREATION = 'CREATION',
  CREATION_ERROR = 'CREATION_ERROR',
  CREATION_COMPLETE = 'CREATION_COMPLETE'
}

export type CartGroupResult = {
  type: 'GROUP',
  rate: MultiRateGroup,
};

type SingleResult = {
  type: 'SINGLE',
  rate: SingleRate,
};

type AdditionalService = {
  service: string,
  price: number,
  extra_data?: string,
};

export enum CartDestinationMethod {
  SIMPLE = 'SIMPLE',
  TO_TERMINAL = 'TO_TERMINAL',
  FOREIGN = 'FOREIGN',
  ONLINE = 'ONLINE',
  CLAIM = 'CLAIM',
  TERMINAL_TERMINAL = 'TERMINAL_TERMINAL',
  FROM_TERMINAL = 'FROM_TERMINAL'
}

export type CreationErrorInner = {
  code?: string,
  title?: string,
  description?: string,
};

export type CreationError = {
  errors?: CreationErrorInner[],
};

export type CartDestination = {
  destinationId: number,

  senderAddressId?: string,
  senderContactInfoId: string,
  senderCounterpartyId?: string,
  senderTerminalId?: number,

  sender: ContactCast,
  senderTerminal?: TerminalListItem,

  receiverAddressId?: string,
  receiverContactInfoId: string,
  receiverCounterpartyId?: string,
  receiverTerminalId?: number,

  receiver: ContactCast,
  receiverTerminal?: TerminalListItem,

  cargoId: string,

  cargo: Required<Package>[],

  insurance?: number,

  calculationTaskId: string,
  rateResultId: string,

  rate: SingleRate,

  status: CartDestinationStatus,
  single?: boolean,
  result?: CartGroupResult | SingleResult | null,
  waybill?: Waybill,

  saving?: number,
  todaySaving?: number,
  courierCallPrice: number,
  additionalServices: AdditionalService[],

  method: CartDestinationMethod,
  pickupDate?: string,
  tagList: number[],

  creationError?: CreationError | null,
};

export type CartDestinationResponse = Omit<CartDestination, 'result' | 'single' | 'waybill'>;

export interface SetCartDestinations {
  type: typeof SET_CART_DESTINATIONS,
  destinations: CartDestinationResponse[],
}

export interface AddCartDestination {
  type: typeof ADD_CART_DESTINATION,
  destination: CartDestinationResponse,
}

export interface RemoveCartDestination {
  type: typeof REMOVE_CART_DESTINATION,
  destinationId: number,
}

export interface UpdateSingleDestination {
  type: typeof UPDATE_SINGLE_DESTINATION,
  destination: Partial<CartDestination>,
}

export interface UpdateMultipleDestinationS {
  type: typeof UPDATE_MULTIPLE_DESTINATIONS,
  destinations: Partial<CartDestination>[],
}

export interface AddMultipleCartDestinations {
  type: typeof ADD_MULTIPLE_CART_DESTINATIONS,
  destinations: CartDestinationResponse[],
}

export interface ResetCart {
  type: typeof RESET_CART,
}

export type CartActions =
  SetCartDestinations
  | AddCartDestination
  | RemoveCartDestination
  | UpdateSingleDestination
  | UpdateMultipleDestinationS
  | AddMultipleCartDestinations
  | ResetCart;
