import { 
  CounterpartyImportActions, 
  CounterpartyImportResult, 
  CurrentCounterpartyImportState, 
  SET_CURRENT_COUNTERPARTY_IMPORT_STATE,
  SET_CURRENT_COUNTERPARTY_IMPORT_ID,
  RESET_CURRENT_COUNTERPARTY_IMPORT,
  SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS,
  SET_CURRENT_COUNTERPARTY_IMPORT_COUNT
} from './types';

type ImportState = {
  currentImportState: CurrentCounterpartyImportState,
  currentImportId: string | null,
  currentImportItems: CounterpartyImportResult[],
  currentImportCount: number,
};

const initialState: ImportState = {
  currentImportState: CurrentCounterpartyImportState.NEW,
  currentImportId: null,
  currentImportItems: [],
  currentImportCount: 0,
};

export default function (state = initialState, action: CounterpartyImportActions) {
  switch (action.type) {
    case SET_CURRENT_COUNTERPARTY_IMPORT_STATE:
      return { ...state, currentImportState: action.state };
    case SET_CURRENT_COUNTERPARTY_IMPORT_ID:
      return { ...state, currentImportId: action.importId };
    case RESET_CURRENT_COUNTERPARTY_IMPORT:
      return { ...initialState };
    case SET_CURRENT_COUNTERPARTY_IMPORT_ITEMS:
      return { ...state, currentImportItems: action.items };
    case SET_CURRENT_COUNTERPARTY_IMPORT_COUNT:
      return { ...state, currentImportCount: action.payload }
    default: return state;
  }
}
