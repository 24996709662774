import { adaptToApi } from '../../util/adapter';
import {
  ADD_ADDRESS_CONTACT_INFO,
  AddAddressContactInfo,
  Contact, ContactAutocomplete, ContactCast,
  Counterparty,
  CounterpartyAddress,
  CounterpartyAddressContactInfo, FullContactInfoType,
  RESET_CURRENT_COUNTERPARTY,
  ResetCurrentCounterparty,
  SET_COUNTERPARTIES_COUNT,
  SET_COUNTERPARTIES_LIST,
  SET_COUNTERPARTIES_PAGE, SET_COUNTERPARTIES_QUERY,
  SET_COUNTERPARTY_ADDRESS_CONTACTS,
  SET_COUNTERPARTY_ADDRESS_LIST,
  SET_CURRENT_COUNTERPARTY,
  SetContactsCount,
  SetContactsList,
  SetContactsPage,
  SetContactsQuery,
  SetCounterpartiesCount,
  SetCounterpartiesList,
  SetCounterpartiesPage, SetCounterpartiesQuery,
  SetCounterpartyAddressContacts,
  SetCounterpartyAddressList,
  SetCurrentContact,
  SetCurrentCounterparty,
} from './types';
import { ThunkAction } from '../../util/types';
import { LIST_LIMIT } from '../../util/constants';
import { RecentTerminal } from '../terminal/types';

export const setContactsList = (contacts: Array<Contact>): SetContactsList => ({
  type: 'SET_CONTACTS_LIST',
  contacts,
});

export const setContactsCount = (count: number): SetContactsCount => ({
  type: 'SET_CONTACTS_COUNT',
  count,
});

export const setContactsPage = (page: number): SetContactsPage => ({
  type: 'SET_CONTACTS_PAGE',
  page,
});

export const setContactsQuery = (query: string): SetContactsQuery => ({
  type: 'SET_CONTACTS_QUERY',
  query,
});

export const setCurrentContact = (contact: Contact): SetCurrentContact => ({
  type: 'SET_CURRENT_CONTACT',
  contact,
});

export const resetCurrentContact = () => ({
  type: 'SET_CURRENT_CONTACT',
  contact: null,
});

export const setCounterpartiesList = (counterparties: Array<Counterparty>): SetCounterpartiesList => ({
  type: SET_COUNTERPARTIES_LIST,
  counterparties,
});

export const setCounterpartiesCount = (count: number): SetCounterpartiesCount => ({
  type: SET_COUNTERPARTIES_COUNT,
  count,
});

export const setCurrentCounterparty = (counterparty: Counterparty): SetCurrentCounterparty => ({
  type: SET_CURRENT_COUNTERPARTY,
  counterparty,
});

export const resetCurrentCounterparty = (): ResetCurrentCounterparty => ({
  type: RESET_CURRENT_COUNTERPARTY,
});

export const setCounterpartiesPage = (page: number): SetCounterpartiesPage => ({
  type: SET_COUNTERPARTIES_PAGE,
  page,
});

export const setCounterpartyAddressList = (addresses: Array<CounterpartyAddress>): SetCounterpartyAddressList => ({
  type: SET_COUNTERPARTY_ADDRESS_LIST,
  addresses,
});

export const setCounterpartyAddressContacts = (contacts: Array<CounterpartyAddressContactInfo>, addressId: string): SetCounterpartyAddressContacts => ({
  type: SET_COUNTERPARTY_ADDRESS_CONTACTS,
  contacts,
  addressId,
});

export const addAddressContactInfo = (addressId: string, contact: CounterpartyAddressContactInfo): AddAddressContactInfo => ({
  type: ADD_ADDRESS_CONTACT_INFO,
  addressId,
  contact,
});

export const setCounterpartiesQuery = (query: string): SetCounterpartiesQuery => ({
  type: SET_COUNTERPARTIES_QUERY,
  query,
});

export const createContact = (data: any): ThunkAction => (dispatch, getState, http) => {
  const {
    phone, lat, lng, ...rest
  } = data;
  const request = adaptToApi(rest);
  request.latitude = lat;
  request.longitude = lng;
  request.phones = [{ number: phone }];
  return http.post('/api/v1/contact/create/', request).then(
    (response: Contact) => Promise.resolve(response),
    (reject: any) => Promise.reject(reject),
  );
};

export const loadContacts = (query = '', page = 1, counterpartyId = ''): ThunkAction => (dispatch, getState, http) => {
  const limit = 10;
  const skip = page * limit - limit;
  const params: any = { limit, skip };
  if (query) params.query = query;
  if (counterpartyId) params.counterparty_id = counterpartyId;
  return http.get('/api/v1/contact/list/', params).then(
    (response: {count: number, contacts: Array<Contact>}) => {
      dispatch(setContactsCount(response.count || 0));
      dispatch(setContactsList(response.contacts || []));
      dispatch(setContactsPage(page));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadContact = (contactId: string): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/contact/${contactId}/`).then(
  (response: Contact) => {
    dispatch(setCurrentContact(response));
  },
  (reject: any) => {
  },
);

export const updateContact = (data: any, contactId: string): ThunkAction => (dispatch, getState, http) => {
  const {
    phone, lat, lng, ...rest
  } = data;
  const request = adaptToApi(rest);
  request.latitude = lat;
  request.longitude = lng;
  request.phones = [{ number: phone }];

  return http.patch(`/api/v1/contact/${contactId}/update/`, request).then(
    (response: Contact) => {
      dispatch(setCurrentContact(response));
      return Promise.resolve(response);
    },
  );
};

export const deleteContact = (contactId: string): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/contact/${contactId}/delete/`);

export const createCounterparty = ({
  phone, lat, lng, ...rest
}: any): ThunkAction => (dispatch, getState, http) => {
  const request = adaptToApi(rest);
  request.latitude = lat;
  request.longitude = lng;
  request.phones = [{ number: phone }];
  return http.post('/api/v1/contact/counterparty/create/complex/', request);
};

export const loadCounterparties = (page = 1, query = ''): ThunkAction => (dispatch, getState, http) => {
  const limit = 10;
  const skip = page * limit - limit;
  const params: any = { limit, skip };
  if (query) params.query = query;
  return http.get('/api/v1/contact/counterparty/', params).then(
    (response: {count: number, counterparties: Array<Counterparty>}) => {
      dispatch(setCounterpartiesPage(page));
      dispatch(setCounterpartiesCount(response.count));
      dispatch(setCounterpartiesList(response.counterparties));
      dispatch(setCounterpartiesQuery(query));
      return Promise.resolve(response);
    },
  );
};

export const loadCounterparty = (counterpartyId: string): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/contact/counterparty/${counterpartyId}/`).then(
  (response: Counterparty) => {
    dispatch(setCurrentCounterparty(response));
    return Promise.resolve(response);
  },
);

export const updateCounterparty = (data: any, counterpartyId: string): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/contact/counterparty/${counterpartyId}/update/`, adaptToApi(data)).then(
  (response: Counterparty) => {
    dispatch(setCurrentCounterparty(response));
    return Promise.resolve(response);
  },
);

export const deleteCounterparty = (counterpartyId: string): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/contact/counterparty/${counterpartyId}/delete/`);

export const datadaInnAutocomplete = (query: string, isLegal?: boolean): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/external/suggestions/api/4_1/rs/suggest/party', { query, count: 10, is_legal: !!isLegal }).then(
  (response: any) => {
    let data = response;
    try {
      data = JSON.parse(data);
    } catch (e) {}
    return Promise.resolve(data);
  },
);

export const loadCounterpartyAddressContacts = (addressId: string): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/contact/address/${addressId}/info/`).then(
  (response: {contactsInfo: Array<CounterpartyAddressContactInfo>}) => {
    dispatch(setCounterpartyAddressContacts(response.contactsInfo, addressId));
    return Promise.resolve(response);
  },
);

export const loadCounterpartyAddressList = (counterpartyId: string, page = 1): ThunkAction => (dispatch, getState, http) => {
  const limit = LIST_LIMIT;
  const skip = page * limit - limit;
  const params: any = { limit, skip };
  if (counterpartyId) params.counterparty_id = counterpartyId;
  return http.get('/api/v1/contact/address/', params).then(
    (response: {addresses: Array<CounterpartyAddress>}) => {
      dispatch(setCounterpartyAddressList(response.addresses));
      // response.addresses.forEach((address) => {
      //   dispatch(loadCounterpartyAddressContacts(address.addressId));
      // });
      return Promise.resolve(response);
    },
  );
};

export const loadCounterpartyContactInfo = (contactInfoId: string): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/contact/info/${contactInfoId}/`);

export const createAddressCast = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/cast/address/create/', data);
export const createContactCast = (data: any): ThunkAction<Promise<{contactInfoCastId: string}>> => (dispatch, getState, http) => http.post('/api/v1/contact/cast/contact/create/', data);

export const createInternationalContactCast = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/cast/inter/contact/create/', data).then(
  (response: {companyNameInter: string, contactNameInter: string, contactInfoCastId: string}) => Promise.resolve(response),
);

export const updateInternationalContactCast = (data: any, castId: string): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/contact/cast/inter/contact/${castId}/update/`, data).then(
  (response: {companyNameInter?: string, contactNameInter?: string, contactInfoCastId: string, passport?: string, inn?: string, companyName?: string, legalAddress?: string, opfFull?: string}) => Promise.resolve(response),
);

export const createForeignAddress = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/foreign/address/create/', data).then(
  (response: { addressId: string }) => Promise.resolve(response),
);

export const complexForeignAddressCreate = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/foreign/address/complex/create/', data).then(
  (response: { addressId: string, addressCastId: string, contactCastId: string }) => Promise.resolve(response),
);

export const createContactInfo = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/info/create/', data);
export const deleteContactInfo = (contactInfoId: string): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/contact/info/${contactInfoId}/delete/`);
export const updateContactInfo = (contactInfoId: string, data: any): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/contact/info/${contactInfoId}/update/`, data);

export const createAddress = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/address/create/', data);

export const createAddressComplex = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/contact/address/complex/create/', data);
export const deleteAddress = (addressId: string): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/contact/address/${addressId}/delete/`);

export const pinCounterparty = (counterpartyId: string, pin: boolean): ThunkAction => (dispatch, getState, http) => http.put(`/api/v1/contact/counterparty/${counterpartyId}/pin/`, { pin });

export const getRecentList = (side: string): ThunkAction<Promise<{items: Array<ContactCast>}>> => (dispatch, getState, http) => http.get('/api/v1/contact/recent/', { side });

export const getPostalCodeFromCoords = (latitude: number, longitude: number): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/geo/postal_code/', { latitude, longitude }).then(
  (response: { postalCode: string | null }) => Promise.resolve(response),
  () => Promise.resolve({ postalCode: null }),
);

export const contactCastComplexCreate = (data: any): ThunkAction<Promise<{addressCastId: string, contactCastId: string}>> => (dispatch, getState, http) => http.post(
  '/api/v1/contact/cast/complex/create/', data,
);

export const getRecentTerminalList = (side: 'receiver_terminal' | 'sender_terminal'): ThunkAction<Promise<{items: Array<RecentTerminal>}>> => (dispatch, getState, http) => http.get(
  '/api/v1/contact/recent/terminals/', { side },
);

export const updateAddress = (addressId: string, data: any): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/contact/address/${addressId}/update/`, data,
);

type GetContactAutocompleteResponse = {
  items: ContactAutocomplete[],
};

export const getContactAutocomplete = (filter: anyObject): ThunkAction<Promise<GetContactAutocompleteResponse>> => (dispatch, getState, http) => http.get(
  '/api/v1/contact/autocomplete/',
  filter,
);

export const deleteRecent = (recentId: string): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/contact/recent/${recentId}/delete/`,
);

export const createFastAddressCast = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/contact/cast/address/fast/create/', data,
);

export const getFullContactInfoDetail = (contactInfoId: string): ThunkAction<Promise<FullContactInfoType>> => (dispatch, getState, http) => http.get(
  `/api/v1/contact/info/${contactInfoId}/full/detail/`,
);
