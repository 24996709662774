import React, { memo, useCallback } from 'react';
import Badge from 'antd/es/badge';
import { WarningOutlined } from '@ant-design/icons';

import useDispatch from '../../../util/hooks/useDispatch';
import { toggleGlobalNotification } from '../action-creators';
import IconButton from '../../../components/IconButton';

const GlobalNotificationButton = () => {
  const dispatch = useDispatch();

  const openNotification = useCallback(() => {
    dispatch(toggleGlobalNotification(true));
  }, [dispatch]);

  return (
    <div className="gx-pt-1">
      <Badge
        offset={[-5, 5]}
        className="gx-mr-2"
      >
        <IconButton
          Icon={WarningOutlined}
          onClick={openNotification}
          className="gx-ml-2 gx-text-secondary-2"
          style={{ fontSize: 21 }}
        />
      </Badge>
    </div>
  );
};

export default memo(GlobalNotificationButton);
