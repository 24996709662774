import {
  ActiveListTool,
  ADD_TAG_TO_LIST,
  ADD_TAGS_TO_LIST,
  Claim,
  Pickup,
  REMOVE_TAG,
  RESET_CURRENT_CLAIM,
  RESET_CURRENT_WAYBILL,
  RESET_SHARED_WAYBILL_DATA,
  SET_CURRENT_CLAIM,
  SET_CURRENT_PICKUP,
  SET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL_SERVICE_LIST,
  SET_REVIEW_LIST_TOOL,
  SET_SHARED_BASIC_WAYBILL_DATA,
  SET_SHARED_EXTENDED_WAYBILL_DATA,
  SET_SUPPORT_LIST_TOOL,
  SET_TAG_COUNT,
  SET_TAG_LIST,
  SET_TRACKING_LIST_TOOL,
  SET_WAYBILL_COUNT,
  SET_WAYBILL_FILTER_VALUE,
  SET_WAYBILL_LIST,
  SharedWaybill,
  ShippingActions,
  ShippingTag,
  Waybill,
  WaybillDetail,
  WaybillService,
  SET_CLAIM_LIST,
  ForeignInvoice,
  SET_FOREIGN_INVOICE_LIST,
  SET_CURRENT_FOREIGN_INVOICE,
  RESET_CURRENT_FOREIGN_INVOICE,
} from './types';
import { removeByFieldValue } from '../../util/array/remove';
import { mergeAndReplaceByFieldValue } from '../../util/array/replace';
import { mergeByFieldValue } from '../../util/array/merge';

type Filter = {
  status: null | string
};

export interface ShippingState {
  currentWaybill: WaybillDetail | null,
  currentPickup: Pickup | null,
  waybillList: Waybill[],
  waybillCount: number,
  activeListTool: ActiveListTool,
  waybillsInited: boolean,
  waybillsEmpty: boolean,
  filter: Filter,
  sharedWaybill: SharedWaybill | null,
  currentWaybillServiceList: WaybillService[],
  currentClaim: Claim | null,
  currentForeignInvoice: ForeignInvoice | null
  claimList: Claim[],
  foreignInvoiceList: ForeignInvoice[],
  shippingTags: {
    tags: ShippingTag[],
    count: number,
  }
}

const initialState: ShippingState = {
  currentWaybill: null,
  currentPickup: null,
  waybillList: [],
  waybillCount: 0,
  activeListTool: {
    tracking: null,
    support: null,
    review: null,
  },
  waybillsInited: false,
  waybillsEmpty: false,
  filter: {
    status: null,
  },
  sharedWaybill: null,
  currentWaybillServiceList: [],
  currentClaim: null,
  currentForeignInvoice: null,
  foreignInvoiceList: [],
  claimList: [],
  shippingTags: {
    tags: [],
    count: 0,
  },
};

function isEmpty(inited: boolean, empty: boolean, length: boolean): boolean {
  if (!inited) return !length;
  return !(!empty || length);
}

function setFilter(value: any, field: string, currentFilter: Filter): Filter {
  const _ = { ...currentFilter };
  if ((_ as anyObject)[field]) (_ as anyObject)[field] = value;
  return _;
}

export default function (state = initialState, action: ShippingActions): ShippingState {
  switch (action.type) {
    case SET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: action.waybill };
    case SET_CURRENT_PICKUP:
      return { ...state, currentPickup: action.pickup };
    case SET_WAYBILL_LIST:
      return {
        ...state,
        waybillList: action.waybills,
        waybillsInited: true,
        waybillsEmpty: isEmpty(state.waybillsInited, state.waybillsEmpty, !!action.hasWaybills),
      };
    case SET_WAYBILL_COUNT:
      return { ...state, waybillCount: action.count };
    case RESET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: initialState.currentWaybill };
    case SET_SUPPORT_LIST_TOOL:
      return { ...state, activeListTool: { ...state.activeListTool, support: action.shipping } };
    case SET_TRACKING_LIST_TOOL:
      return { ...state, activeListTool: { ...state.activeListTool, tracking: action.shipping } };
    case SET_WAYBILL_FILTER_VALUE:
      return { ...state, filter: setFilter(action.value, action.field, state.filter) };
    case SET_SHARED_BASIC_WAYBILL_DATA:
      return { ...state, sharedWaybill: { type: 'basic', data: action.waybill } };
    case RESET_SHARED_WAYBILL_DATA:
      return { ...state, sharedWaybill: null };
    case SET_SHARED_EXTENDED_WAYBILL_DATA:
      return { ...state, sharedWaybill: { type: 'extended', data: action.waybill } };
    case SET_REVIEW_LIST_TOOL:
      return { ...state, activeListTool: { ...state.activeListTool, review: action.shipping } };
    case SET_CURRENT_WAYBILL_SERVICE_LIST:
      return { ...state, currentWaybillServiceList: action.list };
    case SET_CURRENT_CLAIM:
      return { ...state, currentClaim: action.claim };
    case RESET_CURRENT_CLAIM:
      return { ...state, currentClaim: initialState.currentClaim };
    case SET_TAG_LIST:
      return { ...state, shippingTags: { ...state.shippingTags, tags: action.list } };
    case SET_TAG_COUNT:
      return { ...state, shippingTags: { ...state.shippingTags, count: action.count } };
    case ADD_TAG_TO_LIST:
      return {
        ...state,
        shippingTags: {
          ...state.shippingTags,
          count: state.shippingTags.count + 1,
          tags: [...state.shippingTags.tags, action.tag],
        },
      };
    case REMOVE_TAG:
      return {
        ...state,
        shippingTags: {
          ...state.shippingTags,
          count: state.shippingTags.count - 1,
          tags: removeByFieldValue(state.shippingTags.tags, 'tagId', action.tagId),
        },
      };
    case ADD_TAGS_TO_LIST:
      return {
        ...state,
        shippingTags: {
          ...state.shippingTags,
          tags: mergeByFieldValue(state.shippingTags.tags, 'tagId', action.tags),
        },
      };
    case SET_CLAIM_LIST: 
      return {
        ...state,
        claimList: action.claims
      }

    case SET_FOREIGN_INVOICE_LIST:
      return {
        ...state,
        foreignInvoiceList: action.payload
      }
    case SET_CURRENT_FOREIGN_INVOICE:
      return {
        ...state,
        currentForeignInvoice: action.payload
      }
    case RESET_CURRENT_FOREIGN_INVOICE:
      return {
        ...state,
        currentForeignInvoice: null
      }
    default:
      return state;
  }
}
