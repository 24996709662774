import { CargoCastAdditionalServiceCode } from '../cast/types';
import {
  ADD_INTER_PACKAGE,
  ADD_RETURN_PACKAGE,
  CargoActions,
  CargoType,
  DELETE_INTER_PACKAGE,
  DELETE_PACKAGE,
  DELETE_RETURN_PACKAGE,
  InsuranceResponse,
  InterPackage,
  Package,
  REPLACE_INTER_PACKAGE,
  REPLACE_RETURN_PACKAGE,
  RESET_INTER_REASON,
  SET_ADDITIONAL_SERVICES,
  SET_CARGO_INSURANCE,
  SET_CARGO_INSURANCE_RATE_VALUE,
  SET_CARGO_TYPE,
  SET_INSURANCE_RESPONSE,
  SET_INTER_CARGO_ID,
  SET_INTER_CURRENCY,
  SET_INTER_REASON,
  SET_PACKAGES,
  SET_RETURN_CARGO_ID,
  SET_RETURN_CARGO_TYPE,
  RESET_CARGO_SEARCH,
  SET_INTER_CARGO_FROM_LOCAL,
  SET_INTER_CARGO_FROM_INVOICE,
} from './types';

export interface CargoState {
  cargoId: string | null,
  packages: Package[],
  insurance: boolean,
  insuranceRateValue: number,
  insuranceResponse: InsuranceResponse | null,
  interPackages: Array<InterPackage>,
  interReason: {
    reasonDomestic: string,
    reasonInter: string,
  },
  interCargoId: string | null,
  cargoType: CargoType,
  interCurrency: string,
  additionalServices: string[],
  returnPackages: Package[],
  returnCargoId: string | null,
  returnCargoType: CargoType,
}

const initialState: CargoState = {
  cargoId: null,
  packages: [{
    width: undefined,
    height: undefined,
    length: undefined,
    weight: undefined,
    description: '',
    amount: 1,
  }],
  insurance: false,
  insuranceRateValue: 3000,
  insuranceResponse: null,
  interPackages: [
    {
      description: '',
      descriptionInter: '',
      tnved: '',
      country: '',
      amount: 1,
    },
  ],
  interReason: {
    reasonDomestic: '',
    reasonInter: '',
  },
  interCurrency: '',
  interCargoId: null,
  cargoType: CargoType.DOCS,
  additionalServices: [],
  returnPackages: [{
    width: undefined,
    height: undefined,
    length: undefined,
    weight: undefined,
    description: '',
    amount: 1,
  }],
  returnCargoId: null,
  returnCargoType: CargoType.DOCS,
};

function removeAtIndex(index: number, arr: Array<Package> | Array<InterPackage>) {
  const tmp = [...arr];
  tmp.splice(index, 1);
  return tmp;
}

function replaceAtIndex(index: number, arr: [] | Package[], newItem: any) {
  const tmp = [...arr];
  tmp.splice(index, 1, newItem);
  return tmp;
}

export default function (state = initialState, action: CargoActions): CargoState {
  switch (action.type) {
    case 'ADD_PACKAGE':
      return { ...state, packages: [...state.packages, initialState.packages[0]] };
    case DELETE_PACKAGE:
      if (state.packages.length === 1) {
        return { ...state, packages: initialState.packages };
      }
      return { ...state, packages: removeAtIndex(action.packageId, state.packages) };

    case 'REPLACE_PACKAGE':
      return { ...state, packages: replaceAtIndex(action.packageId, state.packages, action.package) };
    case 'SET_CARGO_ID':
      return { ...state, cargoId: action.cargoId };
    case 'RESET_CARGO':
      return { ...state, ...initialState, insurance: action.insurance };
      
    case RESET_CARGO_SEARCH:
      return { ...state, ...initialState, packages: state.packages, interPackages: state.interPackages, 
        interReason: state.interReason, interCurrency: state.interCurrency, insurance: action.insurance };
    case SET_CARGO_INSURANCE:
      return { ...state, insurance: action.insurance };
    case SET_CARGO_INSURANCE_RATE_VALUE:
      return { ...state, insuranceRateValue: action.rateValue };
    case SET_INSURANCE_RESPONSE:
      return { ...state, insuranceResponse: action.insurance };
    case ADD_INTER_PACKAGE:
      return { ...state, interPackages: [...state.interPackages, initialState.interPackages[0]] };
    case DELETE_INTER_PACKAGE:
      if (state.interPackages.length === 1) {
        return { ...state, interPackages: initialState.interPackages };
      }
      // @ts-ignore
      return { ...state, interPackages: removeAtIndex(action.packageId, state.interPackages) };
    case REPLACE_INTER_PACKAGE:
      // @ts-ignore
      return { ...state, interPackages: replaceAtIndex(action.packageId, state.interPackages, action.package) };
    case SET_INTER_REASON:
      return { ...state, interReason: action.reason };
    case RESET_INTER_REASON:
      return { ...state, interReason: initialState.interReason };
    case SET_INTER_CARGO_ID:
      return { ...state, interCargoId: action.cargoId };
    case SET_PACKAGES:
      return { ...state, packages: action.packages };
    case SET_CARGO_TYPE:
      return { ...state, cargoType: action.cargoType };
    case SET_INTER_CURRENCY:
      return { ...state, interCurrency: action.currency };
    case SET_ADDITIONAL_SERVICES:
      return { ...state, additionalServices: action.services };
    case ADD_RETURN_PACKAGE:
      return { ...state, returnPackages: [...state.returnPackages, initialState.returnPackages[0]] };
    case DELETE_RETURN_PACKAGE:
      if (state.returnPackages.length === 1) {
        return { ...state, returnPackages: initialState.returnPackages };
      }
      return { ...state, returnPackages: removeAtIndex(action.packageId, state.returnPackages) };
    case REPLACE_RETURN_PACKAGE:
      return { ...state, returnPackages: replaceAtIndex(action.packageId, state.packages, action.package) };
    case SET_RETURN_CARGO_ID:
      return { ...state, returnCargoId: action.cargoId };
    case SET_RETURN_CARGO_TYPE:
      return { ...state, returnCargoType: action.cargoType };
    case SET_INTER_CARGO_FROM_LOCAL:
      return { ...state, interPackages: [...state.packages as InterPackage[]] }
    case SET_INTER_CARGO_FROM_INVOICE:
      return { 
        ...state, 
        interPackages: [...action.payload.packages as InterPackage[]], 
        cargoId: action.payload.cargoCastId, 
        insuranceResponse: action.payload.insurance,
        insurance: !!action.payload.services.find(i => i.serviceCode === CargoCastAdditionalServiceCode.INSURANCE),
        interReason: {
          reasonDomestic: action.payload.reasonDomestic || "",
          reasonInter: action.payload.reasonInter || ""
        },
        cargoType: action.payload.type as CargoType,
        insuranceRateValue: action.payload.insurance.insuranceValueRate,
        interCurrency: (action.payload.packages.length && action.payload.packages[0].currency) ? action.payload.packages[0].currency : ''
      }
    default:
      return state;
  }
}
