import moment, { Moment } from 'moment';
import {
  internationalCalculationPreStage,
  internationalConfig,
  internationalInvoice,
  internationalSideCast,
  RESET_CURRENT_SHIPPING_TYPE,
  RESET_INTERNATIONAL_CALCULATION,
  RESET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  ResetCurrentShippingType,
  ResetInternationalCalculationPreStage,
  SET_CURRENT_SHIPPING_TYPE,
  SET_FROM_COUNTRY,
  SET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  SET_INTERNATIONAL_CONFIG,
  SET_INTERNATIONAL_INVOICE,
  SET_INTERNATIONAL_RECEIVER_CAST,
  SET_INTERNATIONAL_SENDER_CAST,
  SET_TO_COUNTRY,
  SetCurrentShippingType,
  SetFromCountry,
  SetInternationalCalculationPreStage,
  SetInternationalConfig,
  SetInternationalInvoice,
  SetInternationalReceiverCast,
  SetInternationalSenderCast,
  SetToCountry,
  ShippingType,
  UPDATE_INTERNATIONAL_RECEIVER_CAST,
  UPDATE_INTERNATIONAL_SENDER_CAST,
  UpdateInternationalReceiverCast,
  UpdateInternationalSenderCast,
} from './types';
import { InternationalContactCast } from '../contact/types';
import { ThunkAction } from '../../util/types';
import { SERVER_DATE_FORMAT } from '../../util/constants';
import { resetCalculation, setCalculationResults, setCalculationTaskId } from '../calculation/action-creators';
import { resetCargo } from '../cargo/action-creators';
import { loadUserBalance } from '../user/action-creators';
import { setCreationResult } from '../creation/action_creators';
import { creationResultType } from '../creation/types';
import { adaptToApi } from '../../util/adapter';
import { setReceiverCast, setSenderCast } from '../calculation_new/action-creators';
import { CalculationSideCastType, ContactFormData, CalculationSide } from '../calculation_new/redux';

export const setCurrentShippingType = (currentType: ShippingType): SetCurrentShippingType => ({
  type: SET_CURRENT_SHIPPING_TYPE,
  currentType,
});

export const resetCurrentShippingType = (): ResetCurrentShippingType => ({
  type: RESET_CURRENT_SHIPPING_TYPE,
});

export const setInternationalCalculationPreStage = (preStage: internationalCalculationPreStage): SetInternationalCalculationPreStage => ({
  type: SET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  preStage,
});

export const resetInternationalCalculationPreStage = (): ResetInternationalCalculationPreStage => ({
  type: RESET_INTERNATIONAL_CALCULATION_PRE_STAGE,
});

export const setInternationalSenderCast = (cast: internationalSideCast): SetInternationalSenderCast => ({
  type: SET_INTERNATIONAL_SENDER_CAST,
  cast,
});

export const updateInternationalSenderCast = (cast: Partial<InternationalContactCast>): UpdateInternationalSenderCast => ({
  type: UPDATE_INTERNATIONAL_SENDER_CAST,
  cast,
});

export const setInternationalReceiverCast = (cast: internationalSideCast): SetInternationalReceiverCast => ({
  type: SET_INTERNATIONAL_RECEIVER_CAST,
  cast,
});

export const updateInternationalReceiverCast = (cast: Partial<InternationalContactCast>): UpdateInternationalReceiverCast => ({
  type: UPDATE_INTERNATIONAL_RECEIVER_CAST,
  cast,
});

export const setInternationalInvoice = (invoice: internationalInvoice): SetInternationalInvoice => ({
  type: SET_INTERNATIONAL_INVOICE,
  invoice,
});

export const resetInternationalCalculation = (): ThunkAction<void> => (dispatch) => {
  dispatch({ type: RESET_INTERNATIONAL_CALCULATION });
  dispatch(resetCargo());
  dispatch(resetCalculation());
};

export const setFromCountry = (country: string): SetFromCountry => ({
  type: SET_FROM_COUNTRY,
  country,
});

export const setToCountry = (country: string): SetToCountry => ({
  type: SET_TO_COUNTRY,
  country,
});

export const setInternationalConfig = (config: internationalConfig): SetInternationalConfig => ({
  type: SET_INTERNATIONAL_CONFIG,
  config,
});

export const getInvoice = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/cargo/inter/invoice/', data).then(
  (response: internationalInvoice) => {
    dispatch(setInternationalInvoice(response));
    return Promise.resolve(response);
  },
);

export const startForeignCalculation = (): ThunkAction => (dispatch, getState, http) => {
  const state = getState();
  dispatch(setCalculationResults([]));
  const { senderCast, receiverCast } = state.international;
  const { cargoId } = state.cargo;
  return http.post('/api/v1/calculation/foreign/start/', {
    sender_address_id: senderCast?.cast.addressCastId,
    receiver_address_id: receiverCast?.cast.addressCastId,
    cargo_id: cargoId,
    pickup_date: moment().format(SERVER_DATE_FORMAT),
  }).then(
    (response: { singleTaskId: string }) => {
      dispatch(setCalculationTaskId(response.singleTaskId));
      return Promise.resolve(response);
    },
  );
};

export const createInternationalShipping = (rateResultId: string): ThunkAction => (dispatch, getState, http) => {
  const state = getState();
  const { results } = state.calculation;
  const { senderCast, receiverCast, invoice } = state.international;
  const { cargoId } = state.cargo;
  const {
    pickupDate, bonusValue, availableCreationDates, pickupTimeStart, pickupTimeEnd,
  } = state.creation;
  const { currentBalance } = state.user;
  let pickup = null;
  if (pickupDate) pickup = availableCreationDates.find((_) => _.date.isSame(pickupDate, 'date'));
  const currentResult = results.find((result) => result.rateResultId === rateResultId);
  if (bonusValue && currentResult) {
    if (bonusValue > currentBalance.bonusBalance) return Promise.reject({ bonusValue: 'Недостаточно бонусов' });
    if (Math.floor(parseInt(currentResult.price, 10) / 2) < bonusValue) return Promise.reject({ bonusValue: 'Бонусами можно оплатить не более 50% стоимости отправки' });
  }
  let pickupPeriod;
  if (pickupTimeStart && pickupTimeEnd) pickupPeriod = `${pickupTimeStart.format('HH:mm')}-${pickupTimeEnd.format('HH:mm')}`;

  if (pickupDate) {
    return http.post('/api/v1/shipping/create/international/', {
      sender_contact_id: senderCast?.cast.contactCastId,
      receiver_contact_id: receiverCast?.cast.contactCastId,
      cargo_id: cargoId,
      rate_result_id: rateResultId,
      pickup_date: pickupDate?.format('YYYY-MM-DD'),
      bonus_value: bonusValue || 0,
      delivery_date: pickup?.deliveryDate ? pickup?.deliveryDate?.format(SERVER_DATE_FORMAT) : '',
      pickup_period: pickupPeriod,
    }).then(
      (response: creationResultType) => {
        dispatch(loadUserBalance());
        dispatch(setCreationResult(response));
        return Promise.resolve(response);
      },
    );
  }
  return Promise.reject();
};

export const getInternationalConfig = (senderIso: string, receiverIso: string): ThunkAction<Promise<internationalConfig>> => (dispatch, getState, http) => http.get('/api/v1/contract/international_config/', { sender_iso: senderIso, receiver_iso: receiverIso }).then(
  (response: internationalConfig) => {
    dispatch(setInternationalConfig(response));
    return Promise.resolve(response);
  },
);

type transliterateResponse = {
  companyName: string,
  contactName: string,
  comment: string,
  office: string,
  address: string,
};

export const getContactTransliterate = (contactInfoId: string): ThunkAction<Promise<transliterateResponse>> => (dispatch, getState, http) => http.get(`/api/v1/contact/info/${contactInfoId}/transliterate/`);

export const transliterateContact = (contactInfoId: string | null | undefined, data: any): ThunkAction<Promise<transliterateResponse>> => (dispatch, getState, http) => http.put(`/api/v1/contact/info/${contactInfoId}/transliterate/create/`, data);

type castResponse = {
  addressCastId: string,
  contactInfoCastId: string,
};

export const createTransliteratedCast = (data: any): ThunkAction<Promise<castResponse>> => (dispatch, getState, http) => http.post('/api/v1/contact/cast/transliterated/complex/create/', data);

export const createTransliteratedInvoiceCast = (data: any, side: "sender" | "receiver"): ThunkAction => (dispatch, getState, http) => {
  return http.post('/api/v1/contact/cast/transliterated/complex/create/', adaptToApi(data)).then(
    ({ addressCastId, contactInfoCastId }: castResponse) => {
      const cast: CalculationSide = {
        cast: {
          ...(data as NoUndefinedField<ContactFormData>), addressCastId, contactCastId: contactInfoCastId, side,
        },
        type: CalculationSideCastType.FOREIGN,
      };

      if (side === 'sender') dispatch(setSenderCast(cast));
      else dispatch(setReceiverCast(cast));
      return Promise.resolve(cast);
    },
  );
}

export const updateTransliteratedInvoiceCast = (data: any, side: "sender" | "receiver"): ThunkAction => (dispatch, getState, http) => {
  return http.patch(`/api/v1/contact/cast/transliterated/${data.contactCastId}/update/`, adaptToApi(data)).then(
    ({ addressCastId, contactCastId }: {
      addressCastId: string,
      contactCastId: string,
    }) => {
      const cast: CalculationSide = {
        cast: {
          ...(data as NoUndefinedField<ContactFormData>), addressCastId, contactCastId, side,
        },
        type: CalculationSideCastType.FOREIGN,
      };

      if (side === 'sender') dispatch(setSenderCast(cast));
      else dispatch(setReceiverCast(cast));
      return Promise.resolve(cast);
    },
  );
}

export const createForeignCast = (data: any): ThunkAction<Promise<castResponse>> => (dispatch, getState, http) => http.post('/api/v1/contact/cast/foreign/complex/create/', data);

type CreateClaimResponse = {
  claimNumber: string,
};

export const createForeignClaim = (data: anyObject): ThunkAction<Promise<CreateClaimResponse>> => (dispatch, getState, http) => http.post(
  '/api/v1/shipping/claim/inter/create/',
  data,
);

export const createInternationalCreationClaim = (rateResultId: string, pickupDate: Moment, comment: string): ThunkAction => (dispatch, getState, http) => {
  const state = getState();
  const { results } = state.calculation;
  const { senderCast, receiverCast } = state.international;
  const { cargoId } = state.cargo;
  const {
    bonusValue, availableCreationDates, pickupTimeStart, pickupTimeEnd,
  } = state.creation;
  const { currentBalance } = state.user;
  let pickup = null;
  if (pickupDate) pickup = availableCreationDates.find((_) => _.date.isSame(pickupDate, 'date'));
  const currentResult = results.find((result) => result.rateResultId === rateResultId);
  if (bonusValue && currentResult) {
    if (bonusValue > currentBalance.bonusBalance) return Promise.reject({ bonusValue: 'Недостаточно бонусов' });
    if (Math.floor(parseInt(currentResult.price, 10) / 2) < bonusValue) return Promise.reject({ bonusValue: 'Бонусами можно оплатить не более 50% стоимости отправки' });
  }
  let pickupPeriod;
  if (pickupTimeStart && pickupTimeEnd) pickupPeriod = `${pickupTimeStart.format('HH:mm')}-${pickupTimeEnd.format('HH:mm')}`;

  if (pickupDate) {
    return http.post('/api/v1/shipping/claim/inter/create/', {
      sender_contact_id: senderCast?.cast.contactCastId,
      receiver_contact_id: receiverCast?.cast.contactCastId,
      cargo_id: cargoId,
      rate_result_id: rateResultId,
      pickup_date: pickupDate?.format('YYYY-MM-DD'),
      bonus_value: bonusValue || 0,
      delivery_date: pickup?.deliveryDate?.format(SERVER_DATE_FORMAT),
      pickup_period: pickupPeriod,
      comment,
    }).then(
      (response: creationResultType) => {
        dispatch(loadUserBalance());
        dispatch(setCreationResult(response));
        return Promise.resolve(response);
      },
    );
  }
  return Promise.reject();
};


export const transliterateCast = (castId: string, data: anyObject): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/contact/cast/${castId}/transliterate/`,
  data,
);
