import React, { Suspense } from 'react';
import CircularProgress from '../../../../components_base/CircularProgress';
import lazyPreload from '../../../../util/lazyPreload';

const RegistrationFirstStage = lazyPreload(() => import('../../components/RegistrationFirstStage'));

function RegistrationFirstStageView(props: any) {
  return (
    <div className="gx-app-login-wrap">
      <Suspense fallback={CircularProgress}>
        <RegistrationFirstStage {...props} />
      </Suspense>
    </div>
  );
}

export default RegistrationFirstStageView;
