import React from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import PrivateRouter from './PrivateRoute';
import CounterpartyListView from '../app/contact/views/CounterpartyListView';
import CounterpartyCreateView from '../app/contact/views/CounterpartyCreateView';
import CounterpartyEditView from '../app/contact/views/CounterpartyEditView';
import CounterpartyDetailView from '../app/contact/views/CounterpartyDetailView';
import CounterpartyAddressAddView from '../app/contact/views/CounterpartyAddressAddView';
import CounterpartyChildCreateView from '../app/contact/views/CounterpartyChildCreateView';

function CounterpartyRoute({ match }) {
  return (
    <Switch>
      <PrivateRouter exact path={match.url} component={CounterpartyListView} />
      <PrivateRouter exact path={`${match.url}/create`} component={CounterpartyCreateView} />
      <PrivateRouter exact path={`${match.url}/:counterpartyId/edit`} component={CounterpartyEditView} />
      <PrivateRouter exact path={`${match.url}/:counterpartyId/detail`} component={CounterpartyDetailView} />
      <PrivateRouter exact path={`${match.url}/:counterpartyId/new_address`} component={CounterpartyAddressAddView} />
      <PrivateRouter exact path={`${match.url}/:counterpartyId/child/create`} component={CounterpartyChildCreateView} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default CounterpartyRoute;
