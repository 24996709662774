import { ADD_ALERT, AlertAction, CustomAlert } from './types';

type AlertSate = {
  alerts: CustomAlert[],
};

const initialState: AlertSate = {
  alerts: [],
};

export default function (state = initialState, action: AlertAction): AlertSate {
  switch (action.type) {
    case ADD_ALERT:
      return { ...state, alerts: [...state.alerts, action.alert] };
    default: return state;
  }
}
