import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import List from 'antd/es/list';
import Avatar from 'antd/es/avatar';
import { Badge } from 'antd';
import Icon from '@ant-design/compatible/es/icon';
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis';
import { useTranslation } from 'react-i18next';
import { GeneticNotice } from '../../types';
import { genericConfig, notificationConfig } from '../../contants';
import { readNotice } from '../../action-creators';
import useDispatch from '../../../../util/hooks/useDispatch';
import { formatContent } from './utils';

export interface GenericNotificationProps {
  data: GeneticNotice,
  close: () => void;
}

function GenericNotification({
  data: {
    date, type, content, isViewed, noticeId, context, url,
  }, close,
}: GenericNotificationProps) {
  const { t } = useTranslation('notifications');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = useCallback(
    () => {
      if (url) {
        history.push(url);
        close();
      }
      dispatch(readNotice(noticeId));
    },
    [close, noticeId, dispatch, url, history, context],
  );

  const noticeDate = useMemo(() => moment(date), [date]);
  const config = useMemo(() => {
    let cfg = notificationConfig[type];
    if (!cfg) cfg = genericConfig;
    return { text: content, ...cfg };
  }, [type, content, context]);

  return (
    <List.Item className="gx-pointer ant-list-item__hover" onClick={handleClick}>
      <List.Item.Meta
        avatar={(
          <Badge status={isViewed ? undefined : 'warning'} offset={[-5, 5]}>
            <Avatar className="gx-text-black" size="large" icon={<Icon type={config.icon} />} style={{ backgroundColor: config.color }} />
          </Badge>
        )}
        title={(
          <LinesEllipsis
            text={config.text}
            className="gx-font-weight-normal"
            maxLine={3}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        )}
        description={<span className="gx-text-muted">{t('notice_date', { date: noticeDate })}</span>}
      />

    </List.Item>
  );
}

export default GenericNotification;
