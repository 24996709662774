import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const ShippingList = React.lazy(() => import('../components/ShippingList'));
function ShippingListView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ShippingList {...props} />
    </Suspense>
  );
}

export default ShippingListView;
