import {
  AccountRank,
  ADD_ORDER_ITEM,
  clientMarketing,
  DELETE_ORDER_ITEM,
  MarketingAction,
  marketingAction,
  OrderItem,
  REPLACE_ORDER_ITEM,
  RESET_ORDER_ITEMS,
  RESET_ORDER_RECEIVER_CONTACT_CAST,
  SET_CLIENT_MARKETING,
  SET_CURRENT_ACCOUNT_RANK,
  SET_MARKETING_ACTION_COUNT,
  SET_MARKETING_ACTION_LIST,
  SET_ORDER_RECEIVER_CONTACT_CAST,
  SET_STORE_ITEMS,
  StoreItem,
} from './types';
import {removeByFieldValue} from '../../util/array/remove';
import {replaceByFieldValue} from '../../util/array/replace';
import {ContactCast} from '../contact/types';

type marketingStore = {
  currentMarketing: clientMarketing,
  marketingActionList: MarketingAction[],
  marketingActionCount: number,
  storeItems: StoreItem[],
  orderItems: OrderItem[],
  receiverCast: null | ContactCast,
  rank: AccountRank | null,
};

const initialState: marketingStore = {
  currentMarketing: {
    reviewMarketing: 0,
    newAccountUser: false,
    firstWaybill: false,
    secondCounterparty: false
  },
  marketingActionList: [],
  marketingActionCount: 0,
  storeItems: [],
  orderItems: [],
  receiverCast: null,
  rank: null,
};


export default function (state = initialState, action: marketingAction): marketingStore {
  switch (action.type) {
    case SET_CLIENT_MARKETING:
      return { ...state, currentMarketing: action.marketing };
    case SET_MARKETING_ACTION_LIST:
      return { ...state, marketingActionList: action.marketingActionList };
    case SET_MARKETING_ACTION_COUNT:
      return { ...state, marketingActionCount: action.count };
    case SET_STORE_ITEMS:
      return { ...state, storeItems: action.items };
    case ADD_ORDER_ITEM:
      return { ...state, orderItems: [...state.orderItems, action.item] };
    case DELETE_ORDER_ITEM:
      return { ...state, orderItems: removeByFieldValue(state.orderItems, 'itemId', action.itemId) };
    case REPLACE_ORDER_ITEM:
      return { ...state, orderItems: replaceByFieldValue(state.orderItems, 'itemId', action.item) };
    case RESET_ORDER_ITEMS:
      return { ...state, orderItems: initialState.orderItems };
    case SET_ORDER_RECEIVER_CONTACT_CAST:
      return { ...state, receiverCast: action.cast };
    case RESET_ORDER_RECEIVER_CONTACT_CAST:
      return { ...state, receiverCast: initialState.receiverCast };
    case SET_CURRENT_ACCOUNT_RANK:
      return { ...state, rank: action.rank };
    default: return state;
  }
}
