import React, { memo, useCallback } from 'react';
import Alert from 'antd/es/alert';

import useSelector from '../../../../util/hooks/useSelector';
import { toggleGlobalNotification } from '../../action-creators';
import useDispatch from '../../../../util/hooks/useDispatch';
import { useIsGlobalNotificationOpen } from '../../selectors';

const GlobalNotification = () => {
  const message = useSelector((state) => state.notifications.globalNotification);
  const isOpen = useIsGlobalNotificationOpen();
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(toggleGlobalNotification(false));
  }, [dispatch]);

  if (!(message && isOpen)) return null;

  return (
    <Alert
      banner
      closable
      afterClose={close}
      message={message}
      type="warning"
      className="gx-mb-0 gx-white-space-pre-line"
    />
  );
};

export default memo(GlobalNotification);
