import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const MyBonuses = React.lazy(() => import('../components/MyBonuses'));
function MyBonusesView() {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <MyBonuses />
    </React.Suspense>
  );
}

export default MyBonusesView;
