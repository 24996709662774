import {
  ADD_NOTICE,
  ADD_NOTICE_LIST,
  AddNotice,
  AddNoticeList,
  Notice,
  REMOVE_NOTICE,
  RemoveNotice,
  SET_ALL_NOTICE_READ,
  SET_FCM_TOKEN,
  SET_GLOBAL_NOTIFICATION,
  SET_NOT_VIEWED_NOTICE_COUNT,
  SET_NOTICE_COUNT,
  SET_NOTICE_LIST,
  SET_NOTICE_READ,
  SET_NOTIFICATIONS_OPEN,
  SET_URGENT_NOTIFICATIONS,
  SetAllNoticeRead,
  SetFCMToken,
  SetGlobalNotification,
  SetNotceRead,
  SetNoticeCount,
  SetNoticeList,
  SetNotificationsOpen,
  SetNotViewedNoticeCount,
  SetUrgentNotifications,
  UPDATE_NOTICE_LIST,
  UpdateNoticeList,
  UrgentNotification,
  SetCarrierInfoCount,
  SetBillCount,
  SET_CARRIER_INFO_COUNT,
  SET_BILL_COUNT,
  NotificationTypes,
  SetCargoStatusCount,
  SET_CARGO_STATUS_COUNT,
  SetCurrentNoticeType,
  SET_CURRENT_NOTICE_TYPE,
  NotificationType,
} from './types';
import { ThunkAction } from '../../util/types';
import { CLOSED_GLOBAL_NOTIFICATION_KEY, genericConfig, notificationConfig, NOTIFICATION_TYPES_CORRELATION } from './contants';

export const setNoticeList = (list: Array<Notice>): SetNoticeList => ({
  type: SET_NOTICE_LIST,
  list,
});

export const setNoticeCount = (count: number): SetNoticeCount => ({
  type: SET_NOTICE_COUNT,
  count,
});

export const setCurrentNoticeType = (payload: NotificationType): SetCurrentNoticeType => ({
  type: SET_CURRENT_NOTICE_TYPE,
  payload,
});

export const setCarrierInfoCount = (count: number): SetCarrierInfoCount => ({
  type: SET_CARRIER_INFO_COUNT,
  count,
});

export const setBillCount = (count: number): SetBillCount => ({
  type: SET_BILL_COUNT,
  count,
});

export const setCargoStatusCount = (count: number): SetCargoStatusCount => ({
  type: SET_CARGO_STATUS_COUNT,
  count,
});

export const removeNotice = (noticeId: string): RemoveNotice => ({
  type: REMOVE_NOTICE,
  noticeId,
});

export const addNotice = (notice: Notice): AddNotice => ({
  type: ADD_NOTICE,
  notice,
});

export const addNoticeList = (noticeList: Array<Notice>): AddNoticeList => ({
  type: ADD_NOTICE_LIST,
  noticeList,
});

export const setNoticeRead = (noticeId: string): SetNotceRead => ({
  type: SET_NOTICE_READ,
  noticeId,
});

export const setAllNoticeRead = (): SetAllNoticeRead => ({
  type: SET_ALL_NOTICE_READ,
});

export const setNotViewedNoticeCount = (count: number): SetNotViewedNoticeCount => ({
  type: SET_NOT_VIEWED_NOTICE_COUNT,
  count,
});

export const setNotificationsOpen = (open: boolean): SetNotificationsOpen => ({
  type: SET_NOTIFICATIONS_OPEN,
  open,
});

export const updateNoticeList = (list: Array<Notice>): UpdateNoticeList => ({
  type: UPDATE_NOTICE_LIST,
  list,
});

export const setFCMToken = (token: string | null): SetFCMToken => ({
  type: SET_FCM_TOKEN,
  token,
});

export const setUrgentNotifications = (notifications:UrgentNotification[]): SetUrgentNotifications => ({
  type: SET_URGENT_NOTIFICATIONS,
  notifications,
});

export const setGlobalNotification = (notification: string, closedNotification?: string): SetGlobalNotification => ({
  type: SET_GLOBAL_NOTIFICATION,
  notification,
  closedNotification,
});

export const getNoticeTypeCount = (type: string): ThunkAction => (dispatch, getState, http) => {
  // @ts-ignore
  const value = NOTIFICATION_TYPES_CORRELATION[type];
  return http.get('/api/v1/notifications/notice/', type ? { notice_type: value, limit: 1} : { limit: 1 }).then(
    ({ notViewedCount }: {notices: Array<Notice>, count: number, notViewedCount: number}) => {
      if (type === NotificationTypes.CARRIER_INFO) {
        dispatch(setCarrierInfoCount(notViewedCount));
      } else if (type === NotificationTypes.BILL) {
        dispatch(setBillCount(notViewedCount));
      } else if (type === NotificationTypes.CARGO_STATUS) {
        dispatch(setCargoStatusCount(notViewedCount));
      }
      return Promise.resolve();
    },
)};

export const loadNoticeList = (type?: string): ThunkAction => (dispatch, getState, http) => {
  return http.get('/api/v1/notifications/notice/', type ? { notice_type: type, limit: 20 } : { limit: 20 }).then(
    ({ notices, count, notViewedCount }: {notices: Array<Notice>, count: number, notViewedCount: number}) => {
      dispatch(setNoticeList(notices));
      dispatch(setNoticeCount(count));
      dispatch(setNotViewedNoticeCount(notViewedCount));
      return Promise.resolve();
    },
)};

export const loadMoreNoticeList = (page = 1, type?: string): ThunkAction => (dispatch, getState, http) => {
  const limit = 20;
  const skip = limit * page - limit;
  return http.get('/api/v1/notifications/notice/', type ? { notice_type: type, limit, skip } : { limit, skip }).then(
    ({ notices, count, notViewedCount }: {notices: Array<Notice>, count: number, notViewedCount: number}) => {
      dispatch(addNoticeList(notices));
      dispatch(setNoticeCount(count));
      dispatch(setNotViewedNoticeCount(notViewedCount));
      return Promise.resolve({ notices, count });
    },
  );
};

export const readNotice = (noticeId: string): ThunkAction => (dispatch, getState, http) => http.put(`/api/v1/notifications/notice/read/${noticeId}/`).then(
  () => {
    dispatch(setNoticeRead(noticeId));
    return Promise.resolve();
  },
);

export const readAllNotice = (): ThunkAction => (dispatch, getState, http) => http.put('/api/v1/notifications/notice/read/all/').then(
  () => {
    dispatch(setAllNoticeRead());
  },
);

interface WsNotice extends Omit<Notice, 'context'> {
  context: string
}

interface _Notice extends Omit<Notice, 'context'> {
  context: {
    [key: string]: any
  }
}

export const acceptWsNotice = (notice: WsNotice): ThunkAction<void> => (dispatch, getState) => {
  let context = {};
  try {
    context = JSON.parse(notice.context);
  } catch (e) {
    console.error('Fail convert to JSON');
  }
  // @ts-ignore
  const _notice: Notice = { ...notice, context };

  const { notViewedNoticeCount, carrierInfoCount, billCount, cargoStatusCount } = getState().notifications;
  dispatch(addNotice(_notice));
  dispatch(setNotViewedNoticeCount(notViewedNoticeCount + 1));
  let cfg = notificationConfig[_notice.type];
  if (!cfg) cfg = genericConfig;
  if (cfg.type === NotificationTypes.CARRIER_INFO) {
    dispatch(setCarrierInfoCount(carrierInfoCount + 1));
  } else if (cfg.type === NotificationTypes.BILL) {
    dispatch(setBillCount(billCount + 1));
  } else if (cfg.type === NotificationTypes.CARGO_STATUS) {
    dispatch(setCargoStatusCount(cargoStatusCount + 1));
  }
};

export const reloadNoticeList = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/notifications/notice/', { limit: 20 }).then(
  ({ notices, count, notViewedCount }: {notices: Array<Notice>, count: number, notViewedCount: number}) => {
    dispatch(updateNoticeList(notices));
    dispatch(setNoticeCount(count));
    dispatch(setNotViewedNoticeCount(notViewedCount));
    return Promise.resolve({ notices, count });
  },
);

export const addFCMToken = (token: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/fcm/add/', { token }).then(
  () => {
    dispatch(setFCMToken(token));
    return Promise.resolve();
  },
);

export const removeFCMToken = (token: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/fcm/remove/', { token }).then(
  () => {
    dispatch(setFCMToken(null));
    return Promise.resolve();
  },
);

export const loadUrgentNotifications = (): ThunkAction<void> => (dispatch, getState, http) => http.get('/api/v1/notifications/notice/urgent/').then(
  ({ notifications }: {notifications: UrgentNotification[]}) => dispatch(setUrgentNotifications(notifications)),
);

export interface GlobalNotificationResponseData {
  text: string;
}

export const loadGlobalNotification = (): ThunkAction<void> => (dispatch, getState, http) => http.get('/api/v1/notifications/global/').then(
  ({ text }: GlobalNotificationResponseData) => {
    const closedNotification = window.localStorage.getItem(CLOSED_GLOBAL_NOTIFICATION_KEY);
    dispatch(setGlobalNotification(text, closedNotification ?? ''));
  },
);

export const toggleGlobalNotification = (shouldOpen: boolean): ThunkAction<void> => (dispatch, getState) => {
  const { globalNotification } = getState().notifications;
  const closedNotification = shouldOpen ? '' : globalNotification;
  window.localStorage.setItem(CLOSED_GLOBAL_NOTIFICATION_KEY, closedNotification);
  dispatch(setGlobalNotification(globalNotification, closedNotification));
};
