import { Act } from '../../invoice/types';
import {
  CargoCast, ContactCast, RateCast, TerminalCast,
} from '../../cast/types';
import { Good } from '../../online_store/types';

export interface Pickup {
  plsOrderNumber: string,
  serviceOrderNumber: string
}

export interface WaybillPackage {
  width: number,
  height: number,
  length: number,
  weight: number,
  description: string,
  amount: number,
}

export type WaybillStatusType = 'registered' | 'processed' | 'performed' | 'canceled' | 'problem' | 'canceled_negotiation' | null;

// export type TmsStatusTypeChoice = 'new' | 'registered' | 'pickup_waiting' | 'delivery_waiting' | 'delivered' | 'canceled';

export enum TmsStatusTypeChoice {
  NEW = 'new',
  REGISTERED = 'registered',
  PICKUP_WAITING = 'pickup_waiting',
  DELIVERY_WAITING = 'delivery_waiting',
  COURIER_SEARCH = 'courier_search',
  COURIER_ON_WAY = 'courier_on_way',
  RECEIVED_FROM_SENDER = 'received_from_sender',
  READY_TO_SEND = 'ready_to_send',
  SENT_TO_RECEIVER_OFFICE = 'sent_to_receiver_office',
  MET_IN_RECEIVER_OFFICE = 'met_in_receiver_office',
  RECEIVED_IN_DELIVERY_OFFICE = 'received_in_delivery_office',
  COURIER_DELIVERY = 'courier_delivery',
  RECEIVER_WAITING_IN_OFFICE = 'receiver_waiting_in_office',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  LOST = 'lost',
  SCRAPPED = 'scrapped',
  PROBLEM = 'problem',
  CANCELED_NEGOTIATION = 'canceled_negotiation'
}

export type TmsStatus = {
  status: TmsStatusTypeChoice,
  data: { pickupDate: string } | { deliveryDate: string } | { cancelDate: string } | { canceledNegotiationDate: string },
};

export enum WaybillCancelBy {
  MANAGER = 'manager',
  USER = 'user',
  SERVICE = 'service'
}

export enum WaybillDeliveryType {
  D2D = 'd2d',
  D2W = 'd2w',
  W2W = 'w2w',
  W2D = 'w2d'
}

export enum WaybillCargoAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  RETURN = 'RETURN',
  BY_HAND = 'BY_HAND',
  PICKUP_IN_60_MIN = 'PICKUP_IN_60_MIN',
  PRIORITY_CONTROL = 'PRIORITY_CONTROL'
}

export type WaybillCargoAdditionalService = {
  serviceCode: string;
};

export type WaybillContactAdditions = {
  intercomNumber: string,
  intercomCode: string,
  needPass: boolean,
  needIdCard: boolean,
  workingFrom: string,
  workingTo: string,
  lunchFrom: string,
  lunchTo: string,
  preCallPeriod: number,
};

export interface Waybill {
  plsWaybillNumber: string,
  serviceWaybillNumber: string,

  senderAddress: string,
  senderContact: string,

  receiverAddress: string,
  receiverContact: string,

  description: string,

  cargo: WaybillPackage[],
  pickupDate: string,
  deliveryDate: string,
  deliveryService: string,

  invoiceUrl?: string,
  price?: number,
  status?: WaybillStatusType,
  tmsStatus?: TmsStatus,
  rateName: string,
  minDays: number,
  maxDays: number,
  hasReview?: boolean,
  reviewDone?: boolean,
  createdAt: string,

  senderCity: string,
  receiverCity: string,

  senderInn: string | null,
  receiverInn: string | null,
  plsOrderNumber: string,

  cancelBy?: WaybillCancelBy,
  hasCancelRequest?: boolean,
  senderContactCastId?: string,
  senderTerminalCastId?: string,
  receiverContactCastId?: string,
  receiverTerminalCastId?: string,
  cargoCastId?: string,
  deliveryType?: WaybillDeliveryType,
  services: WaybillCargoAdditionalService[],
  orderGoods?: Good[],
  forwardPlsWaybillNumber?: string,
  returnPlsWaybillNumber?: string,
  senderAdditions?: WaybillContactAdditions,
  receiverAdditions?: WaybillContactAdditions,
  tagList: number[],
  creationDeliveryService?: string,
  senderContactCast: ContactCast,
  receiverContactCast: ContactCast,
  senderTerminalCast: TerminalCast,
  receiverTerminalCast: TerminalCast
}

export type WaybillService = {
  serviceId: number,
  value: number,
  createdAt: string,
  consolidatedAt: string,
  number: string,
  act: Act | null,
};

const WaybillPricingItemCodes = ['RATE_PRICE', 'DISCOUNT', 'BONUS', 'INSURANCE', 'SURCHARGE'];

export type WaybillPricingItem = {
  code: typeof WaybillPricingItemCodes[number],
  amount: number,
  comment: string | null
};

export type WaybillDetail = Waybill & {
  pricing: WaybillPricingItem[]
};

export type BasicWaybill = {
  plsWaybillNumber: string,
  senderAddress: string,
  receiverAddress: string,
  cargo: WaybillPackage[],
  deliveryService: string,
  rateName: string,
  minDays: number,
  maxDays: number,
  tmsStatus?: TmsStatus,
  createdAt: string,
};

export type SharedWaybill = {
  type: 'basic',
  data: BasicWaybill,
} | {
  type: 'extended',
  data: Waybill
};

export type ShortWaybillInfo = {
  plsWaybillNumber: string,
  serviceWaybillNumber: string,
  fromCity: string,
  toCity: string,
  hasReview: boolean,
  reviewDone: boolean
};

export interface ActiveListTool {
  tracking: null | string,
  support: null | string,
  review: null | string,
}

export enum ClaimMethod {
  SIMPLE = 1,
  TO_TERMINAL = 2,
  FOREIGN = 3,
  ONLINE_STORE = 4
}

export const ClaimState = ['new', 'accept', 'complete', 'dismiss'];

export type Claim = {
  claimId: number,
  method: ClaimMethod,
  state: typeof ClaimState[number],
  claimNumber: string,
  accountId: number,
  createdAt: string,
  comment: string | null,

  senderContactId: string,
  receiverContactId: string,
  cargoId: string,

  rateResultId: string,
  receiverTerminalId: number,
  senderTerminalId: number,
  pickupAt: string,
  cargo: CargoCast,
  senderContact: null | ContactCast,
  receiverContact: null | ContactCast,
  rateResult: null | RateCast,
  senderTerminal: null | TerminalCast,
  receiverTerminal: null | TerminalCast,
  plsWaybillNumber?: string
};

export type ShippingTag = {
  tagId: number,
  title: string,
  key: string,
  usage: number,
};

export const ForeignInvoiceState = ['new', 'accept', 'wait', 'complete', 'dismiss'];

export type ForeignInvoice = {
  state: typeof ForeignInvoiceState[number],
  number: string,
  createdAt: string,
  senderContactId: string,
  receiverContactId: string,
  cargoId: string,
  rateResultId: string,
  rateCastId: string,
  pickupAt: string,
  deliveryService: string,
  senderIso: string,
  receiverIso: string,
  cargoType: string,
  estimatedProcessingDate: string,
  cargo: CargoCast,
  senderContact: null | ContactCast & {contactCastId: string},
  receiverContact: null | ContactCast & {contactCastId: string},
  rateResult: null | RateCast,
}