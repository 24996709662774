import React, { useEffect } from 'react';
import { Link, match as matchType } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useSelector from '../../../util/hooks/useSelector';
import { loadForeignInvoice, resetCurrentForeignInvoice } from '../action-creators';
import useDispatch from '../../../util/hooks/useDispatch';
import CircularProgress from '../../../components_base/CircularProgress';
import BreadcrumbDotSeparator from '../../../components/BreadcrumbDotSeparator';
import { ForeignInvoiceSteps } from './ForeignInvoiceSteps';

interface RouteProps {
  foreignInvoiceNumber: string,
}

export interface Props {
  match: matchType<RouteProps>
}

function ForeignInvoiceDetailView({ match }: Props) {
  const { t } = useTranslation('menu');
  const dispatch = useDispatch();
  const { params } = match;
  const { currentForeignInvoice } = useSelector((state) => state.shipping);

  useEffect(() => {
    if (!currentForeignInvoice || currentForeignInvoice.number !== params.foreignInvoiceNumber) {
      dispatch(loadForeignInvoice(params.foreignInvoiceNumber));
    }
    return () => {
      dispatch(resetCurrentForeignInvoice());
    };
  }, [params.foreignInvoiceNumber]);


  if (!currentForeignInvoice) return <CircularProgress />;

  return (
    <>
      <Breadcrumb className="gx-mb-4" separator={<BreadcrumbDotSeparator />}>
        <Breadcrumb.Item>
          <Link to="/orders">
            {t('menu:shipping_list')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {currentForeignInvoice.number}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="gx-card ant-card__paddingless">
        <ForeignInvoiceSteps/>
      </Card>
    </>

  );
}

export default ForeignInvoiceDetailView;
