import { NotificationTypes, UrgentNotification, UrgentNotificationUrgency } from './types';

const notificationConfig: {[key: string]: { icon: string, color: string, type: string }} = {
  WAYBILL_CHAT_MESSAGE: {
    icon: 'mail',
    color: '#f6f6f6',
    type: NotificationTypes.CARRIER_INFO
  },
  WAYBILL_DELIVERED: {
    icon: 'check-circle',
    color: '#c4e6b3',
    type: NotificationTypes.CARGO_STATUS
  },
  NEW_BILL: {
    icon: 'file-text',
    color: '#f7dab2',
    type: NotificationTypes.BILL
  },
  CLAIM_CHAT_MESSAGE: {
    icon: 'mail',
    color: '#f6f6f6',
    type: NotificationTypes.CARGO_STATUS
  },
};

const genericConfig = {
  icon: 'check-circle',
  color: '#c4e6b3',
  type: NotificationTypes.ALL
};

const ACCOUNT_STATUS_BLOCKED = 3;

export { notificationConfig, genericConfig, ACCOUNT_STATUS_BLOCKED };

export const URGENT_NOTIFICATION_EXAMPLES_MAP: Record<UrgentNotificationUrgency, UrgentNotification> = {
  [UrgentNotificationUrgency.INFO]: {
    content: 'Info notification',
    urgency: UrgentNotificationUrgency.INFO,
    hiddable: false,
    notification_id: 'info',
    notification_type: 'info',
  },
  [UrgentNotificationUrgency.WARNING]: {
    content: 'Warning',
    urgency: UrgentNotificationUrgency.WARNING,
    hiddable: false,
    notification_id: 'warning',
    notification_type: 'warning',
  },
  [UrgentNotificationUrgency.SUCCESS]: {
    content: 'Success',
    urgency: UrgentNotificationUrgency.SUCCESS,
    hiddable: false,
    notification_id: 'success',
    notification_type: 'success',
  },
  [UrgentNotificationUrgency.ERROR]: {
    content: 'Info notification',
    urgency: UrgentNotificationUrgency.ERROR,
    hiddable: false,
    notification_id: 'error',
    notification_type: 'error',
  },
};

export const CLOSED_GLOBAL_NOTIFICATION_KEY = 'closedGlobalNotification';

export const NOTIFICATION_TYPES_CORRELATION = {
  ALL: "",
  CARRIER_INFO: "0",
  CARGO_STATUS: "1,3",
  BILL: "2"
}