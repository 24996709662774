export const SET_DETAILING_LIST = 'SET_DETAILING_LIST';

export type Detailing = {
  detailId: number;
  startDate: string;
  endDate: string;
  downloadUrl: string;
};

export interface SetDetailingList {
  type: typeof SET_DETAILING_LIST,
  list: Detailing[]
}

export type BillingActions = SetDetailingList;
