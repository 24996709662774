import { ForeignContactCast, InternationalContactCast}  from '../contact/types';

export const SET_CURRENT_SHIPPING_TYPE = 'SET_CURRENT_SHIPPING_TYPE';
export const RESET_CURRENT_SHIPPING_TYPE = 'RESET_CURRENT_SHIPPING_TYPE';
export const SET_INTERNATIONAL_CALCULATION_PRE_STAGE = 'SET_INTERNATIONAL_CALCULATION_PRE_STAGE';
export const RESET_INTERNATIONAL_CALCULATION_PRE_STAGE = 'RESET_INTERNATIONAL_CALCULATION_PRE_STAGE';
export const SET_INTERNATIONAL_SENDER_CAST = 'SET_INTERNATIONAL_SENDER_CAST';
export const UPDATE_INTERNATIONAL_SENDER_CAST = 'UPDATE_INTERNATIONAL_SENDER_CAST';
export const SET_INTERNATIONAL_RECEIVER_CAST = 'SET_INTERNATIONAL_RECEIVER_CAST';
export const UPDATE_INTERNATIONAL_RECEIVER_CAST = 'UPDATE_INTERNATIONAL_RECEIVER_CAST';
export const SET_INTERNATIONAL_INVOICE = 'SET_INTERNATIONAL_INVOICE';
export const RESET_INTERNATIONAL_CALCULATION = 'RESET_INTERNATIONAL_CALCULATION';
export const SET_FROM_COUNTRY = 'SET_FROM_COUNTRY';
export const SET_TO_COUNTRY = 'SET_TO_COUNTRY';
export const SET_INTERNATIONAL_CONFIG = 'SET_INTERNATIONAL_CONFIG';

export type ShippingType = 'cargo_export' | 'cargo_import' | 'docs_export' | 'docs_import'
export type internationalCalculationPreStage = 'cargo' | 'sender' | 'receiver' | 'invoice'
export type internationalSideCast = {
  type: 'domestic',
  cast: InternationalContactCast
} | {
  type: 'foreign',
  cast: ForeignContactCast
}

export type internationalInvoice = {
  invoiceId: string,
  plsUrl: string
}

export type internationalConfig = {
  transliterateSender: boolean,
  senderOnlyInternational: boolean,
  transliterateReceiver: boolean,
  receiverOnlyInternational: boolean,
}

export interface SetCurrentShippingType {
  type: typeof SET_CURRENT_SHIPPING_TYPE,
  currentType: ShippingType
}

export interface ResetCurrentShippingType {
  type: typeof RESET_CURRENT_SHIPPING_TYPE
}

export interface SetInternationalCalculationPreStage {
  type: typeof SET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  preStage: internationalCalculationPreStage
}

export interface ResetInternationalCalculationPreStage {
  type: typeof RESET_INTERNATIONAL_CALCULATION_PRE_STAGE,
}

export interface SetInternationalSenderCast {
  type: typeof SET_INTERNATIONAL_SENDER_CAST,
  cast: internationalSideCast
}

export interface UpdateInternationalSenderCast {
  type: typeof UPDATE_INTERNATIONAL_SENDER_CAST,
  cast: Partial<InternationalContactCast> | Partial<ForeignContactCast>
}

export interface SetInternationalReceiverCast {
  type: typeof SET_INTERNATIONAL_RECEIVER_CAST,
  cast: internationalSideCast
}

export interface UpdateInternationalReceiverCast {
  type: typeof UPDATE_INTERNATIONAL_RECEIVER_CAST,
  cast: Partial<InternationalContactCast> | Partial<ForeignContactCast>
}

export interface SetInternationalInvoice {
  type: typeof SET_INTERNATIONAL_INVOICE,
  invoice: internationalInvoice
}

export interface ResetInternationalCalculation {
  type: typeof RESET_INTERNATIONAL_CALCULATION,
}

export interface SetFromCountry {
  type: typeof SET_FROM_COUNTRY,
  country: string,
}

export interface SetToCountry {
  type: typeof SET_TO_COUNTRY,
  country: string,
}

export interface SetInternationalConfig {
  type: typeof SET_INTERNATIONAL_CONFIG,
  config: internationalConfig
}

export type internationalAction = SetCurrentShippingType | ResetCurrentShippingType | SetInternationalCalculationPreStage
 | ResetInternationalCalculationPreStage | SetInternationalSenderCast | UpdateInternationalSenderCast | SetInternationalReceiverCast |
  UpdateInternationalReceiverCast | SetInternationalInvoice | ResetInternationalCalculation | SetFromCountry | SetToCountry |
  SetInternationalConfig
