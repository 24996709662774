import { CalculationSide, CalculationStage, FastCalculationSide, TerminalCast, FastOrderCast } from './objects';

export const SET_SENDER_CAST = 'SET_SENDER_CAST';
export const SET_RECEIVER_CAST = 'SET_RECEIVER_CAST';
export const SET_CALCULATION_STAGE = 'SET_CALCULATION_STAGE';
export const RESET_CALCULATION = 'RESET_CALCULATION';
export const SET_CARGO_TYPE = 'SET_CARGO_TYPE';
export const SET_CURRENT_RECEIVER_TERMINAL = 'SET_CURRENT_RECEIVER_TERMINAL';
export const SET_CURRENT_SENDER_TERMINAL = 'SET_CURRENT_SENDER_TERMINAL';

export const SET_SENDER_FAST_CAST = 'SET_SENDER_FAST_CAST';
export const SET_RECEIVER_FAST_CAST = 'SET_RECEIVER_FAST_CAST';
export const RESET_SENDER_RECEIVER_FAST = 'RESET_SENDER_RECEIVER_FAST';
export const SET_FAST_ORDER_CAST = 'SET_FAST_ORDER_CAST';

export interface ResetSenderReceiverFast {
  type: typeof RESET_SENDER_RECEIVER_FAST;
}

export interface SetSenderFastCast {
  type: typeof SET_SENDER_FAST_CAST;
  cast: FastCalculationSide | null,
}

export interface SetReceiverFastCast {
  type: typeof SET_RECEIVER_FAST_CAST;
  cast: FastCalculationSide | null,
}


export interface SetSenderCast {
  type: typeof SET_SENDER_CAST;
  cast: CalculationSide | null,
}

export interface SetReceiverCast {
  type: typeof SET_RECEIVER_CAST;
  cast: CalculationSide | null,
}

export interface SetCalculationStage {
  type: typeof SET_CALCULATION_STAGE;
  stage: CalculationStage,
}

export interface ResetCalculation {
  type: typeof RESET_CALCULATION,
  isFastOrder: boolean
}


export interface SetCurrentSenderTerminal {
  type: typeof SET_CURRENT_SENDER_TERMINAL,
  terminal: TerminalCast | null,
}

export interface SetCurrentReceiverTerminal {
  type: typeof SET_CURRENT_RECEIVER_TERMINAL,
  terminal: TerminalCast | null,
}

export interface SetFastOrderCast {
  type: typeof SET_FAST_ORDER_CAST,
  payload: FastOrderCast | null
}

export type CalculationActions = SetSenderCast | SetReceiverCast | SetCalculationStage | ResetCalculation
| SetCurrentSenderTerminal | SetCurrentReceiverTerminal | SetSenderFastCast | SetReceiverFastCast | ResetSenderReceiverFast | SetFastOrderCast;
