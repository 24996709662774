import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useDispatch from "../../../../util/hooks/useDispatch";
import { replaceInterPackage, setInterCurrency, setInterReason } from "../../action-creators";
import useSelector from "../../../../util/hooks/useSelector";
import { CargoInvoiceItem } from "./CargoInvoiceItem";
import { InterPackage } from "../../types";
import { CustomSeparatedInput } from "../../../../components/CustomSeparatedInput";
import { CargoCurrencySelect } from "./CargoCurrencySelect";

type Props = {
    errors: any
}

const CargoInvoice = ({
    errors
}: Props) => {
    
    const dispatch = useDispatch();

    const { interReason, interPackages, interCurrency } = useSelector((state) => state.cargo);


    const [commonErrors, setCommonErrors] = useState<any>({
        reasonDomestic: errors?.reasonDomestic, 
        reasonInter: errors?.reasonInter,
        interCurrency: errors?.packages ? errors.packages.find((_: any) => !!_.currency)?.currency : undefined
    })

    const handleChange = useCallback(
        (_package: InterPackage, packageId: number) => {
            dispatch(replaceInterPackage(packageId, _package));
        },
        [dispatch],
    );    

    const getErrors = useCallback(
        (index) => {
          if (errors?.packages && errors.packages.length > index) {
            return errors.packages[index];
          }
          return null;
        },
        [errors],
    );

    const handleReasonDomesticChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setInterReason({ ...interReason, reasonDomestic: e.target.value }));
    };
    
    const handleReasonInterChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setInterReason({ ...interReason, reasonInter: e.target.value }));
    };
    
    const handleCurrencyChange = (currency: string) => {
        dispatch(setInterCurrency(currency));
    };
    
    const handleCommonFieldsFocus = useCallback(
        (field: string) => {
            if (!!commonErrors && Object.prototype.hasOwnProperty.call(commonErrors, field)) {
                setCommonErrors({ ...commonErrors, [field]: undefined });
            }
        },
        [commonErrors],
    );

    useEffect(() => {
        setCommonErrors({
            reasonDomestic: errors?.reasonDomestic, 
            reasonInter: errors?.reasonInter,
            interCurrency: errors?.packages ? errors.packages.find((_: any) => !!_.currency)?.currency : undefined
        });
    }, [errors]);

    const handledInterPackages = useMemo(() => {
        let result: InterPackage[] = [];
        interPackages.forEach(item => {
            const { amount } = item;
            const tmp = Array.from({length: Number(amount)}, (_, i) => ({...item, amount: 1}));
            result = [...result, ...tmp];
        })
        return result;
    }, [interPackages])

    const children = handledInterPackages.map((interPackage, index) => 
        <CargoInvoiceItem data={interPackage} id={index} onChange={handleChange} errors={getErrors(index)}/>)

    return (
        <div className="gx-d-flex gx-flex-column">
            <div className="custom-calc-invoice__text gx-mb-2">
                Заполните данные груза обязательно указав в описании наименовение товара, код товара/артикул и бренд
            </div>
            {children}
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice-group-third">
                    <CustomSeparatedInput
                        label="Цель экспорта"
                        value={interReason.reasonDomestic}
                        onChange={handleReasonDomesticChange}
                        onFocus={() => handleCommonFieldsFocus('reasonDomestic')}
                        inputError={commonErrors.reasonDomestic}
                        customClassName="custom-cargo-invoice-input" />
                </div>
                <div className="custom-calc-invoice-group-third">
                    <CustomSeparatedInput
                        label="Цель экспорта англ"
                        value={interReason.reasonInter}
                        onChange={handleReasonInterChange}
                        onFocus={() => handleCommonFieldsFocus('reasonInter')}
                        inputError={commonErrors.reasonInter}
                        customClassName="custom-cargo-invoice-input"/>
                </div>
                <div className="custom-calc-invoice-group-third">
                    <CargoCurrencySelect 
                        onChange={handleCurrencyChange}
                        errors={commonErrors.interCurrency} 
                        value={interCurrency} 
                        onFocus={() => handleCommonFieldsFocus('interCurrency')}/>
                </div>
            </div>
        </div>
    )
}

export { CargoInvoice }