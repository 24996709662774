import React, { lazy, Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

/* eslint react/jsx-props-no-spreading: 0 */

const ShippingDetail = lazy(() => import('../components/ShippingDetailView'));
function ShippingDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ShippingDetail {...props} />
    </Suspense>
  );
}

export default ShippingDetailView;
