import {
  ADD_ADDRESS_CONTACT_INFO,
  Contact,
  contactsActions,
  Counterparty,
  CounterpartyAddress,
  CounterpartyAddressContactInfo,
  RESET_CURRENT_COUNTERPARTY,
  SET_COUNTERPARTIES_COUNT,
  SET_COUNTERPARTIES_LIST,
  SET_COUNTERPARTIES_PAGE,
  SET_COUNTERPARTIES_QUERY,
  SET_COUNTERPARTY_ADDRESS_LIST,
  SET_CURRENT_COUNTERPARTY,
} from './types';

export interface ContactState {
  contactsList: Contact[],
  contactsCount: number,
  query: string,
  currentPage: number,
  currentContact: Contact | null,

  counterpartiesList: Array<Counterparty>,
  counterpartiesCount: number,
  counterpartiesQuery: string,
  counterpartiesPage: number,
  currentCounterparty: Counterparty | null,
  counterpartyAddressList: Array<CounterpartyAddress>,
  counterpartyInited: boolean,
  counterpartyEmpty: boolean
}

const initialState: ContactState = {
  contactsList: [],
  contactsCount: 0,
  query: '',
  currentPage: 1,
  currentContact: null,

  counterpartiesList: [],
  counterpartiesCount: 0,
  counterpartiesQuery: '',
  counterpartiesPage: 1,
  currentCounterparty: null,
  counterpartyAddressList: [],
  counterpartyInited: false,
  counterpartyEmpty: false,
};

function isEmpty(inited: boolean, empty: boolean, length: boolean): boolean {
  if (!inited) return !length;
  return !(!empty || length);
}

function addContactInfo(addressList: Array<CounterpartyAddress>, addressId: string, contactInfo: CounterpartyAddressContactInfo): Array<CounterpartyAddress> {
  const _ = addressList.slice();
  const addressIndex = _.findIndex((address) => address.addressId === addressId);
  if (addressIndex > -1) {
    _[addressIndex].contactsInfo.push(contactInfo);
  }
  return _;
}

export default function (state = initialState, action: contactsActions): ContactState {
  switch (action.type) {
    case 'SET_CONTACTS_LIST':
      return {
        ...state,
        contactsList: action.contacts,
      };
    case 'SET_CONTACTS_PAGE':
      return { ...state, currentPage: action.page };
    case 'SET_CONTACTS_COUNT':
      return { ...state, contactsCount: action.count };
    case 'SET_CONTACTS_QUERY':
      return { ...state, query: action.query };
    case 'SET_CURRENT_CONTACT':
      return { ...state, currentContact: action.contact };
    case SET_COUNTERPARTIES_LIST:
      return {
        ...state,
        counterpartiesList: action.counterparties,
        counterpartyInited: true,
        counterpartyEmpty: isEmpty(state.counterpartyInited, state.counterpartyEmpty, !!action.counterparties.length),
      };
    case SET_COUNTERPARTIES_PAGE:
      return { ...state, counterpartiesPage: action.page };
    case SET_COUNTERPARTIES_COUNT:
      return { ...state, counterpartiesCount: action.count };
    case SET_COUNTERPARTIES_QUERY:
      return { ...state, counterpartiesQuery: action.query };
    case SET_CURRENT_COUNTERPARTY:
      return { ...state, currentCounterparty: action.counterparty };
    case RESET_CURRENT_COUNTERPARTY:
      return { ...state, currentCounterparty: initialState.currentCounterparty };
    case SET_COUNTERPARTY_ADDRESS_LIST:
      return { ...state, counterpartyAddressList: action.addresses };
    case ADD_ADDRESS_CONTACT_INFO:
      return { ...state, counterpartyAddressList: addContactInfo(state.counterpartyAddressList, action.addressId, action.contact) };
    default:
      return state;
  }
}
