import React, { useState, useRef, useCallback, useEffect } from "react";
import { FormikProps, Formik, Form } from "formik";
import {
    Button,
    Steps,
    notification,
} from 'antd';
import moment from 'moment';
import useDispatch from "../../../../util/hooks/useDispatch";
import { CargoInvoice } from "../../../cargo/components/InvoiceCargoForm/CargoInvoice";
import { updateTransliteratedInvoiceCast } from "../../../international/action-creators";
import { setInterCargoFromInvoice, updateCurrentInterCargo } from "../../../cargo/action-creators";
import useSelector from "../../../../util/hooks/useSelector";
import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT } from "../../../../util/constants";
import { Redirect } from "react-router";
import { CargoType } from "../../../cargo/types";
import { InvoiceStepsType } from "../../../calculation_new/redux";
import { updateForeignInvoice } from "../../action-creators";
import { SideInvoice } from "../../../calculation_new/components/InvoiceUI/components/SideInvoice";
import { PickupDateField } from "../../../calculation_new/components/SplitUI/components/PickupDateField";
import { ForeignInvoiceState } from "../../types";
import { ContactCast } from "../../../cast/types";

const ForeignInvoiceSteps = () => {

    const { Step } = Steps;

    const dispatch = useDispatch();
    const formRef = useRef<null | FormikProps<any>>(null);

    const { currentForeignInvoice } = useSelector((state) => state.shipping);

    const [cargoErrors, setCargoErrors] = useState<any>();
    const [currentStep, setCurrentStep] = useState<InvoiceStepsType>(InvoiceStepsType.SIDES);
    const [completedSteps, setCompletedSteps] = useState<InvoiceStepsType[]>([])
    
    const handleCreateContacts = useCallback((values, actions) => {
        dispatch(updateTransliteratedInvoiceCast({...values['sender'], contactCastId: currentForeignInvoice?.senderContactId}, 'sender'))
            .then(() => {
                dispatch(updateTransliteratedInvoiceCast({...values['receiver'], contactCastId: currentForeignInvoice?.receiverContactId}, 'receiver'))
                    .then(() => {
                        setCurrentStep(currentForeignInvoice?.cargoType === CargoType.DOCS ? InvoiceStepsType.DATES : InvoiceStepsType.CARGO);
                        if (!completedSteps.length) {
                            setCompletedSteps(currentForeignInvoice?.cargoType === CargoType.DOCS ? [InvoiceStepsType.SIDES, InvoiceStepsType.CARGO] : [InvoiceStepsType.SIDES]);
                        }
                        actions.setSubmitting(false);
                    }, (errors) => {
                        actions.setErrors({ "receiver": errors });
                        actions.setSubmitting(false);
                    })
            }, (errors) => {
                actions.setErrors({ "sender": errors });
                actions.setSubmitting(false);
            })
    }, [currentStep, completedSteps, currentForeignInvoice]);

    const handleCreateCargo = useCallback((values, actions) => {
        dispatch(updateCurrentInterCargo())
            .then(() => {
                setCurrentStep(InvoiceStepsType.DATES);
                setCargoErrors(undefined);
                if (!completedSteps.includes(InvoiceStepsType.CARGO)) {
                    setCompletedSteps([InvoiceStepsType.SIDES, InvoiceStepsType.CARGO]);
                }
                actions.setSubmitting(false);
            }, (errors) => {
                setCargoErrors(errors);
                actions.setSubmitting(false);
            })
    }, [currentStep, completedSteps]);

    const handleUpdateInvoice = useCallback((values, actions) => {
        if (!currentForeignInvoice) {
            notification.error({message: "Ошибка"})
            actions.setSubmitting(false);
            return;
        }
        if (!values.pickupDate) {
            actions.setFieldError("pickupDate", "Дата не указана");
            actions.setSubmitting(false);
            return;
        }
        dispatch(updateForeignInvoice(currentForeignInvoice.number, values.pickupDate?.format(SERVER_DATE_FORMAT), values.pickupPeriod || "09:00 - 18:00"))
            .then((result) => {
                if (result) {
                    notification.success({
                        message: "Инвойс отправлен на проверку"
                    });
                    setCurrentStep(InvoiceStepsType.FIN);
                    setCompletedSteps([InvoiceStepsType.SIDES, InvoiceStepsType.CARGO, InvoiceStepsType.DATES, InvoiceStepsType.FIN])
                }
            })
    }, [currentForeignInvoice])

    const handleInitialValues = useCallback(() => {
        return ({
            sender: (currentForeignInvoice?.senderContact || {}) as ContactCast,
            receiver: (currentForeignInvoice?.receiverContact || {}) as ContactCast,
            pickupDate: moment(currentForeignInvoice?.pickupAt) as (moment.Moment | null),
            pickupPeriod: null as (moment.Moment | null),
        })
    }, [])

    const submitFunction = useCallback((values, actions) => {
        switch(currentStep) {
            case InvoiceStepsType.SIDES:
                handleCreateContacts(values, actions);
                break;
            case InvoiceStepsType.CARGO:
                handleCreateCargo(values, actions);
                break;
            case InvoiceStepsType.DATES:
                handleUpdateInvoice(values, actions);
                break;
            default:
                break;
        }
    }, [currentStep, handleCreateContacts, handleCreateCargo, handleUpdateInvoice])

    if (currentForeignInvoice?.state !== ForeignInvoiceState[2]) {
        return <Redirect to="/"/>
    }

    return (
        <Formik
            initialValues={handleInitialValues()}
            onSubmit={submitFunction}
            innerRef={(el) => {
                formRef.current = el;
            }}>
            {({ values }) => (
                <Form>
                    <div className="gx-d-flex gx-flex-column gx-position-relative custom-calc-invoice gx-p-4">
                        <Steps
                            direction="vertical"
                            current={currentStep}
                        >
                            <Step 
                                title="Контактные данные" 
                                description={
                                    currentStep === InvoiceStepsType.SIDES
                                    ?
                                    <>
                                        <SideInvoice side="sender"/>
                                        <SideInvoice side="receiver"/>
                                        {
                                            completedSteps.includes(InvoiceStepsType.FIN)
                                            ?
                                            null
                                            :
                                            <Button 
                                                type="primary" 
                                                className="custom-calc-invoice__next-btn"
                                                htmlType="submit">Далее</Button>
                                        }
                                    </>
                                    : 
                                    <div className="gx-d-flex gx-flex-column">
                                        <div>
                                            Отправитель: {values.sender?.contactName}, {values.sender?.addressLine}
                                        </div>
                                        <div>
                                            Получатель: {values.receiver?.contactName}, {values.receiver?.addressLine}
                                        </div>
                                    </div>
                                } 
                                onClick={() => {
                                    if (currentStep) {
                                        setCurrentStep(InvoiceStepsType.SIDES);
                                    }
                                }}/>
                            <Step 
                                title="Груз" 
                                description={currentStep === InvoiceStepsType.CARGO ? (
                                    <>
                                        <CargoInvoice errors={cargoErrors}/>
                                        {
                                            completedSteps.includes(InvoiceStepsType.FIN)
                                            ?
                                            null
                                            :
                                            <Button 
                                                type="primary" 
                                                className="custom-calc-invoice__next-btn gx-mt-4"
                                                htmlType="submit">Далее</Button>
                                        }
                                    </>
                                ) : ("Проверьте данные груза")} 
                                onClick={() => {
                                    if (currentStep > InvoiceStepsType.CARGO && currentForeignInvoice.cargoType !== CargoType.DOCS) {
                                        setCurrentStep(InvoiceStepsType.CARGO);
                                    }
                                }} />
                            <Step 
                                title="Дата" 
                                description={currentStep === InvoiceStepsType.DATES ? (
                                    <>
                                        <PickupDateField currentRateCast={currentForeignInvoice.rateResult}/>
                                        {
                                            completedSteps.includes(InvoiceStepsType.FIN)
                                            ?
                                            null
                                            :
                                            <Button 
                                                type="primary" 
                                                className="custom-calc-invoice__next-btn gx-mt-4"
                                                htmlType="submit">Далее</Button>
                                        }
                                    </>
                                ) : (currentStep > InvoiceStepsType.DATES ? values.pickupDate?.format(CLIENT_DATE_FORMAT) : "Проверьте дату забора груза")} />
                            <Step title="Отправка данных" description={currentStep === InvoiceStepsType.FIN  ? "Инвойс на проверке" : "Отправьте инвойс"} />
                        </Steps>                        
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { ForeignInvoiceSteps };