import React from 'react';
import { TimePicker } from 'antd';
import { TimeRangePickerProps } from 'antd/lib/time-picker';
import moment from 'moment';

const { RangePicker } = TimePicker;

type ValueType = [string | null, string | null] | null;

type StringTimeRangePickerProps = TimeRangePickerProps & {
  value?: ValueType,
  onChange?: (values: [string, string]) => void,
  format?: string,
};

const DEFAULT_FORMAT = 'HH:mm';

function StringTimeRangePicker({
  format, value, onChange, ...props
}: StringTimeRangePickerProps) {
  const realValue: [moment.Moment | null, moment.Moment | null] | null = React.useMemo(() => {
    if (value) {
      return [value[0] ? moment(value[0], format ?? DEFAULT_FORMAT) : null, value[1] ? moment(value[1], format ?? DEFAULT_FORMAT) : null];
    }
    return null;
  }, [value, format]);

  const realOnChange = React.useCallback(
    (_, values) => {
      if (typeof onChange === 'function') onChange(values);
    },
    [onChange],
  );

  return (
    <RangePicker
      {...props}
      onChange={realOnChange}
      // @ts-ignore
      value={realValue}
      format={format}
    />
  );
}

export default StringTimeRangePicker;
