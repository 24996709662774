import { Phone } from '../contact/types';
import { Package } from '../cargo/types';

export const SET_CURRENT_IMPORT_STATE = 'SET_CURRENT_IMPORT_STATE';
export const SET_CURRENT_IMPORT_ID = 'SET_CURRENT_IMPORT_ID';
export const RESET_CURRENT_IMPORT = 'RESET_CURRENT_IMPORT';
export const SET_CURRENT_IMPORT_ITEMS = 'SET_CURRENT_IMPORT_ITEMS';

export enum CurrentImportState {
  NEW,
  RUNNING,
  DONE
}

export type ContactImportSideData = {
  iso: string;
  postcode: string;
  region: string;
  district: string;
  city: string;
  street: string;
  house: string;
  longitude: number;
  latitude: number;
  comment: string;
  cityType: string;
  contactName: string;
  companyName: string;
  phones: Phone[];
  addressCastId: string;
  contactCastId: string;
};

export type ContactImportCargo = {
  packages: Package[];
  cargoId: string;
};

export type ContactImportParsedData = {
  senderData: ContactImportSideData;
  receiverData: ContactImportSideData;
  cargoData: ContactImportCargo;
};

export type ContactImportOriginalData = {
  sender: string;
  receiver: string;
  cargo: string;
};

export type ContactImportResult = {
  parsedData?: ContactImportParsedData;
  originalData: ContactImportOriginalData;
};

export interface SetCurrentImportState {
  type: typeof SET_CURRENT_IMPORT_STATE,
  state: CurrentImportState,
}

export interface SetCurrentImportId {
  type: typeof SET_CURRENT_IMPORT_ID,
  importId: string,
}

export interface ResetCurrentImport {
  type: typeof RESET_CURRENT_IMPORT,
}

export interface SetCurrentImportItems {
  type: typeof SET_CURRENT_IMPORT_ITEMS,
  items: ContactImportResult[]
}

export type ImportActions = SetCurrentImportState | SetCurrentImportId | ResetCurrentImport | SetCurrentImportItems;
