import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CalculationInvoice = React.lazy(() => import('../components/InvoiceUI/CalculationInvoice'));

function CalculationInvoiceView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <CalculationInvoice {...props} />
    </React.Suspense>
  );
}

export default CalculationInvoiceView;
