import React  from 'react';
import Icon from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import ShoppingBasket from '../../../components/icons/ShoppingBasket';
import useSelector from '../../../util/hooks/useSelector';
import { calcDestinationStateList } from '../constants';

function CartIndicatorOld() {
  const { destinations } = useSelector((state) => state.cart);

  const calcDestinations = React.useMemo(() => destinations.filter((_) => calcDestinationStateList.includes(_.status)), [destinations]);

  return (
    <>
      <Link className="gx-text-secondary-2 gx-ml-5 gx-mr-4 gx-pointer gx-text-nowrap" to="/cart">
        <Badge status={calcDestinations.length ? 'error' : undefined}>
          <Icon component={ShoppingBasket} className="gx-text-secondary-2 gx-fs-xl" />
        </Badge>
        <span className="gx-ml-1 gx-font-weight-semi-bold">
          <Trans>Корзина</Trans> {!!calcDestinations.length && `(${calcDestinations.length})`}
        </span>
      </Link>
    </>
  );
}

export default CartIndicatorOld;
