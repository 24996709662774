import React, { useCallback, useMemo } from 'react';
import { Card, Table } from 'antd';
import { useHistory } from 'react-router';
import LinesEllipsis from 'react-lines-ellipsis';
import moment from 'moment';
import Avatar from 'antd/es/avatar';
import { useTranslation } from 'react-i18next';
import useDispatch from '../../../util/hooks/useDispatch';
import useSelector from '../../../util/hooks/useSelector';
import { loadDialogList, setDialogCount, setDialogList } from '../action-creators';
import withQueryParams, { ConfigValueType } from '../../../util/withQueryParams';
import { DialogListItem } from '../types';
import { CLIENT_DATE_FORMAT } from '../../../util/constants';
import { ShortUserInfo } from '../../user/types';

const QUERY_PARAMS = {
  page: { type: ConfigValueType.PAGE_NUMBER },
  status: { type: ConfigValueType.STRING },
};

type DialogListParams = {
  page: number,
  status: string | null,
};

interface DialogListProps {
  setFieldValue: (data: anyObject, callback?: (newParams: DialogListParams) => void) => void,
  params: DialogListParams
}

const getUserAbr = (user: undefined | ShortUserInfo) => {
  if (user) return `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`;
  return '';
};

function DialogList({ setFieldValue, params }: DialogListProps) {
  const { t } = useTranslation('chat');
  const { t: tMenu } = useTranslation('menu');
  const history = useHistory();
  const dispatch = useDispatch();
  const { dialogCount, dialogList } = useSelector((state) => state.chat);
  const { operators } = useSelector((state) => state.reference);

  const [loading, setLoading] = React.useState(false);

  const loadList = React.useCallback(
    ({ page, ...restFilters }: DialogListParams) => {
      setLoading(true);
      dispatch(loadDialogList(page, restFilters)).finally(() => setLoading(false));
    },
    [dispatch],
  );

  const handlePageChange = React.useCallback(
    (page) => {
      setFieldValue({ page }, loadList);
    },
    [loadList],
  );

  const renderOperator = React.useCallback(
    (operator: string) => {
      const _ = operators[operator];
      if (_) {
        return <img src={_.icon} alt={_.title} style={{ maxWidth: 80, maxHeight: 20 }} />;
      }
      return <span />;
    },
    [operators],
  );

  React.useEffect(() => {
    loadList(params);
    return () => {
      dispatch(setDialogList([]));
      dispatch(setDialogCount(0));
    };
  }, []);

  const handleFilterRemove = useCallback(
    (filter) => {
      setFieldValue({ [filter]: null }, loadList);
    },
    [loadList, setFieldValue],
  );

  const handleStatusChange = React.useCallback(
    (status) => {
      setFieldValue({ status }, loadList);
    },
    [loadList],
  );

  const handleRowClick = React.useCallback(
    (row: DialogListItem) => {
      if (row.waybill) {
        history.push(`/waybill/${row.waybill.plsWaybillNumber}#chat`);
      } else if (row.claimNumber) {
        history.push(`/appeal/${row.claimNumber}`);
      }
    },
    [history],
  );

  const filterTags = useMemo(() => {
    const tags = [];
    if (params.status) {
      tags.push({
        title: <span>{params.status === 'open' ? 'Открыт' : 'Закрыт'}</span>,
        key: 'status',
        onClose: () => handleFilterRemove('status'),
      });
    }

    return tags;
  }, [params]);

  const renderStatus = React.useCallback(
    (row: DialogListItem) => {
      if (row.waybill) {
        const operator = operators[row.waybill.deliveryService];
        return (
          <div>
            <span>Обращение по отправлению {row.waybill.serviceWaybillNumber} </span>
            { operator ? <img src={operator.icon} alt={operator.title} style={{ maxWidth: 80, maxHeight: 20 }} /> : <span />}
          </div>
        );
      }
      if (row.claimNumber) {
        return (
          <div>
            Заявка на ручную обработку {row.claimNumber}
          </div>
        );
      }
      return <div>{row.dialogId}</div>;
    },
    [operators],
  );

  const renderLastMessageDate = React.useCallback(
    (date: string | undefined) => {
      if (date) {
        const tmp = moment(date);
        if (tmp.isSame(moment(), 'day')) return tmp.format('HH:mm');
        return tmp.format(`${CLIENT_DATE_FORMAT} HH:mm`);
      }
      return '';
    },
    [],
  );

  const renderLastAvatar = React.useCallback(
    (dialog: DialogListItem) => {
      if (dialog.lastMessageSender === 'bot') {
        return (
          <Avatar
            className="pls-avatar gx-size-40 gx-align-self-end"
            src={require('../../../assets/images/robot.png')}
          />
        );
      }
      if (dialog.lastMessageSender === 'user') {
        return (
          <Avatar
            className="pls-avatar gx-size-40 gx-align-self-end"
            src={dialog.lastUser?.avatar || ''}
          >
            {getUserAbr(dialog.lastUser)}
          </Avatar>
        );
      }
      if (dialog.lastMessageSender === 'logist') {
        const operator = operators[dialog.deliveryService];
        if (operator) {
          return (
            <Avatar
              className="pls-avatar gx-size-40 gx-align-self-end"
              src={dialog.lastUser?.avatar || ''}
            >
              {`${t('chat:specialist')} ${operator.title}`[0]}
            </Avatar>
          );
        }
        return (
          <Avatar
            className="pls-avatar gx-size-40 gx-align-self-end"
            src={dialog.lastUser?.avatar || ''}
          >
            {getUserAbr(dialog.lastUser)}
          </Avatar>
        );
      }
      return null;
    },
    [operators],
  );

  return (
    <Card
      title={(
        <div>
          <h1 className="gx-mb-0">{ tMenu('menu:appeal') }</h1>
        </div>
      )}
      className="ant-card__paddingless"
    >
      <Table
        style={{
          marginTop: 1,
        }}
        dataSource={dialogList}
        showHeader={false}
        loading={loading}
        pagination={{
          total: dialogCount,
          pageSize: 25,
          onChange: handlePageChange,
          current: params.page,
          showSizeChanger: false,
          className: 'ant-table-pagination ant-table-pagination-right gx-pr-3',
        }}
        onRow={(row) => ({
          onClick: () => handleRowClick(row),
        })}
        rowClassName={() => 'gx-pointer'}
        columns={[
          {
            title: 'Статус',
            dataIndex: 'waybillId',
            render: (_, val) => (
              <div className="gx-align-items-center gx-d-flex">
                {val.hasUnread
                  ? <i className="icon icon-circle gx-fs-sm gx-mr-2 gx-text-warning" />
                  : <i className="icon icon-circle gx-fs-sm gx-mr-2 gx-text-success" />}
                {renderStatus(val)}
              </div>
            ),
          },
          {
            title: 'Последнее сообщение',
            dataIndex: 'lastMessage',
            render: (val, row) => (
              <div className="gx-d-flex gx-align-items-center">
                <div className="gx-mr-2">
                  {renderLastAvatar(row)}
                </div>
                <span className="gx-w-100">
                  <LinesEllipsis
                    text={val ? val.replace(/<\/?[^>]+(>|$)/g, "") : val}
                    maxLine={1}
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </span>
              </div>
            ),
            // width: 250,
          },
          {
            title: 'Дата последнего сообщения',
            render: (_, row) => renderLastMessageDate(row.lastMessageDate),
            width: 200,
          },
          // {
          //   title: 'Дата создания',
          //   render: (_, val) => moment(val.createdAt).format(`${CLIENT_DATE_FORMAT} HH:MM`),
          // },
        ]}
      />
    </Card>
  );
}

export default withQueryParams(QUERY_PARAMS)(DialogList);
