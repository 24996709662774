import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';


const AcceptInvite = React.lazy(() => import('../components/AcceptInvite'));
function AcceptInviteView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AcceptInvite />
    </Suspense>
  );
}

export default AcceptInviteView;
