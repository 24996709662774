import React from 'react';
import ReactInputMask from 'react-input-mask';
import Input from 'antd/lib/input';

function MaskInput({ disabled, ...rest }) {
  return (
    <ReactInputMask {...rest} disabled={disabled}>
      { (inputProps) => <Input {...inputProps} /> }
    </ReactInputMask>
  );
}

export default MaskInput;
