import moment, { Moment } from 'moment';
import {
  creationDate,
  creationResultType,
  RESET_AVAILABLE_CREATION_DATES,
  RESET_CREATION_RESULT,
  ResetAvailableCreationDates,
  ResetCreationParams,
  ResetCreationResult,
  SET_AVAILABLE_CREATION_DATES,
  SET_BONUS_VALUE,
  SET_CREATION_RESULT,
  SET_PICKUP_TIME_END,
  SET_PICKUP_TIME_START,
  SetAvailableCreationDates,
  SetBonusValue,
  SetCreationResult,
  SetPickupDate,
  SetPickupTimeEnd,
  SetPickupTimeStart,
} from './types';
import { ThunkAction } from '../../util/types';
import { loadUserBalance } from '../user/action-creators';
import { SERVER_DATE_FORMAT } from '../../util/constants';
import { DayScheduler } from '../reference/types';
import { updateCurrentCargo } from '../cargo/action-creators';
import { AdditionalServiceCode, CargoType } from '../cargo/types';
import { isWip } from '../../util/hooks/useWIPFeatures';

export const resetCreationParams = (): ResetCreationParams => ({
  type: 'RESET_CREATION_PARAMS',
});

export const setPickupDate = (date: Moment | null): SetPickupDate => ({
  type: 'SET_PICKUP_DATE',
  date,
});

export const setCreationResult = (result: creationResultType): SetCreationResult => ({
  type: SET_CREATION_RESULT,
  result,
});

export const resetCreationResult = (): ResetCreationResult => ({
  type: RESET_CREATION_RESULT,
});

export const setBonusValue = (bonus: number | null): SetBonusValue => ({
  type: SET_BONUS_VALUE,
  bonus,
});

export const setAvailableCreationDates = (dates: Array<creationDate>): SetAvailableCreationDates => ({
  type: SET_AVAILABLE_CREATION_DATES,
  dates,
});

export const resetAvailableCreationDates = (): ResetAvailableCreationDates => ({
  type: RESET_AVAILABLE_CREATION_DATES,
});

export const setPickupTimeStart = (time: Moment | null): SetPickupTimeStart => ({
  type: SET_PICKUP_TIME_START,
  time,
});

export const setPickupTimeEnd = (time: Moment | null): SetPickupTimeEnd => ({
  type: SET_PICKUP_TIME_END,
  time,
});

export const createPickup = (plsWaybillNumber: string, pickupDate: Moment | null): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const params = { waybills: [{ pls_waybill_number: plsWaybillNumber }], pickup_date: '' };
  if (pickupDate) params.pickup_date = pickupDate.format('YYYY-MM-DD');
  return http.post('/api/v1/shipping/waybill/pickup/', params);
};

export const createWaybill = (rateResultId: string, senderCastId: string, receiverCastId: string, extraData?: anyObject): ThunkAction => async (
  dispatch, getState, http,
) => {
  const state = getState();
  const { results } = state.calculation;
  const {
    cargoId, returnCargoId, additionalServices, returnCargoType,
  } = state.cargo;
  const {
    pickupDate, bonusValue, availableCreationDates, pickupTimeEnd, pickupTimeStart,
  } = state.creation;
  const { currentBalance, user } = state.user;
  let pickup = null;
  if (pickupDate) pickup = availableCreationDates.find((_) => _.date.isSame(pickupDate, 'date'));
  const currentResult = results.find((result) => result.rateResultId === rateResultId);
  if (bonusValue && currentResult) {
    if (bonusValue > currentBalance.bonusBalance) return Promise.reject({ bonusValue: 'Недостаточно бонусов' });
    if (Math.floor(parseInt(currentResult.price, 10) / 2) < bonusValue) return Promise.reject({ bonusValue: 'Бонусами можно оплатить не более 50% стоимости отправки' });
  }
  let pickupPeriod;
  if (pickupTimeStart && pickupTimeEnd) pickupPeriod = `${pickupTimeStart.format('HH:mm')}-${pickupTimeEnd.format('HH:mm')}`;
  await dispatch(updateCurrentCargo());
  let data: anyObject = {
    sender_contact_id: senderCastId,
    receiver_contact_id: receiverCastId,
    cargo_id: cargoId,
    rate_result_id: rateResultId,
    pickup_date: pickupDate?.format('YYYY-MM-DD'),
    bonus_value: bonusValue || 0,
    delivery_date: '',
    // delivery_date: pickup?.deliveryDate ? pickup?.deliveryDate?.format(SERVER_DATE_FORMAT) : '',
    pickup_period: pickupPeriod,
    amo_id: state.calculation.amoId,
  };
  let withReturn = false;
  if (additionalServices.find((_) => _ === AdditionalServiceCode.RETURN)) {
    if (returnCargoType === CargoType.DOCS) {
      data.return_cargo_id = returnCargoId;
      withReturn = true;
    } else if (returnCargoType === CargoType.PACKAGE && isWip(user, 'returnCargo')) {
      data.return_cargo_id = returnCargoId;
      withReturn = true;
    }
  }

  if (extraData) {
    data = { ...data, ...extraData };
  }

  return http.post(withReturn ? '/api/v1/shipping/create/with_return/' : '/api/v1/shipping/create/', data).then(
    (response: creationResultType) => {
      dispatch(loadUserBalance());
      dispatch(setCreationResult(response));
      return Promise.resolve(response);
    },
  );
};

type GetShippingSchedulerParams = {
  startDate: Moment,
  endDate: Moment,
  days: number,
  deliveryService: string,
  lat?: number | null,
  long?: number | null,
  useState?: boolean,
  tariffList?: string[],
};

export const getShippingScheduler = ({
  startDate,
  endDate,
  days,
  deliveryService,
  lat = 0,
  long = 0,
  useState = true,
  tariffList = [],
}: GetShippingSchedulerParams): ThunkAction<Promise<creationDate[]>> => (dispatch, getState, http) => {
  const params = {
    start_date: startDate.format(SERVER_DATE_FORMAT),
    end_date: endDate.format(SERVER_DATE_FORMAT),
    days,
    delivery_service: deliveryService,
    lat,
    long,
    tariff_list: tariffList,
  };

  return http.get('/api/v1/reference/scheduler/', params).then(
    ({ days: d }: { days: Array<{ date: string, deliveryDate: string, pickupAvailable: number, deliveryAvailable: number }> }) => {
      const working = d.filter((_) => _.pickupAvailable && _.deliveryAvailable).map((_) => ({
        ..._,
        date: moment(_.date),
        deliveryDate: moment(_.deliveryDate),
      }));
      if (useState) dispatch(setAvailableCreationDates(working));
      return Promise.resolve(working);
    },
  );
};

export const getDayScheduler = (pickupDate: Moment, lat: number, long: number, deliveryService: string, tariffList: string[] = []): ThunkAction<Promise<DayScheduler>> => (dispatch, getState, http) => {
  const params = {
    pickup_date: pickupDate.format(SERVER_DATE_FORMAT),
    lat,
    long,
    delivery_service: deliveryService,
    tariff_list: tariffList,
  };
  return http.get('/api/v1/reference/scheduler/day/', params);
};

type CreateClaimResponse = {
  claimNumber: string,
};

export const createCreationClaim = (rateResultId: string, senderCastId: string, receiverCastId: string, comment: string, pickupDate?: Moment): ThunkAction<Promise<CreateClaimResponse>> => async (
  dispatch, getState, http,
) => {
  const state = getState();
  const { results } = state.calculation;
  const { cargoId } = state.cargo;
  const {
    bonusValue, availableCreationDates, pickupTimeEnd, pickupTimeStart,
  } = state.creation;
  const { currentBalance } = state.user;
  let pickup = null;
  if (pickupDate) pickup = availableCreationDates.find((_) => _.date.isSame(pickupDate, 'date'));
  const currentResult = results.find((result) => result.rateResultId === rateResultId);
  if (bonusValue && currentResult) {
    if (bonusValue > currentBalance.bonusBalance) return Promise.reject({ bonusValue: 'Недостаточно бонусов' });
    if (Math.floor(parseInt(currentResult.price, 10) / 2) < bonusValue) return Promise.reject({ bonusValue: 'Бонусами можно оплатить не более 50% стоимости отправки' });
  }
  let pickupPeriod;
  if (pickupTimeStart && pickupTimeEnd) pickupPeriod = `${pickupTimeStart.format('HH:mm')}-${pickupTimeEnd.format('HH:mm')}`;

  await dispatch(updateCurrentCargo());
  return http.post('/api/v1/shipping/claim/create/', {
    sender_contact_id: senderCastId,
    receiver_contact_id: receiverCastId,
    cargo_id: cargoId,
    rate_result_id: rateResultId,
    pickup_date: pickupDate?.format('YYYY-MM-DD'),
    bonus_value: bonusValue || 0,
    delivery_date: pickup?.deliveryDate ? pickup?.deliveryDate?.format(SERVER_DATE_FORMAT) : '',
    pickup_period: pickupPeriod,
    comment,
    problem_type: 'creation',
    amo_id: state.calculation.amoId,
  });
};
