import {
  AccountRank,
  ADD_ORDER_ITEM,
  AddOrderItem,
  clientMarketing,
  DELETE_ORDER_ITEM,
  DeleteOrderItem,
  MarketingAction,
  OrderItem,
  REPLACE_ORDER_ITEM,
  ReplaceOrderItem,
  RESET_ORDER_ITEMS,
  RESET_ORDER_RECEIVER_CONTACT_CAST,
  ResetOrderItems,
  ResetOrderReceiverContactCast,
  SET_CLIENT_MARKETING,
  SET_CURRENT_ACCOUNT_RANK,
  SET_MARKETING_ACTION_COUNT,
  SET_MARKETING_ACTION_LIST,
  SET_ORDER_RECEIVER_CONTACT_CAST,
  SET_STORE_ITEMS,
  SetClientMarketing,
  SetClientMarketingActionCount,
  SetClientMarketingActionList,
  SetCurrentAccountRank,
  SetOrderReceiverContactCast,
  SetStoreItems,
  StoreItem,
} from './types';
import { ThunkAction } from '../../util/types';
import { ContactCast } from '../contact/types';

export const setClientMarketing = (marketing: clientMarketing): SetClientMarketing => ({
  type: SET_CLIENT_MARKETING,
  marketing,
});


export const setClientMarketingActionList = (marketingActionList: MarketingAction[]): SetClientMarketingActionList => ({
  type: SET_MARKETING_ACTION_LIST,
  marketingActionList,
});

export const setClientMarketingActionCount = (count: number): SetClientMarketingActionCount => ({
  type: SET_MARKETING_ACTION_COUNT,
  count,
});


export const setStoreItems = (items: StoreItem[]): SetStoreItems => ({
  type: SET_STORE_ITEMS,
  items,
});


export const addOrderItem = (item: OrderItem): AddOrderItem => ({
  type: ADD_ORDER_ITEM,
  item,
});


export const deleteOrderItem = (itemId: number): DeleteOrderItem => ({
  type: DELETE_ORDER_ITEM,
  itemId,
});


export const replaceOrderItem = (item: OrderItem): ReplaceOrderItem => ({
  type: REPLACE_ORDER_ITEM,
  item,
});

export const resetOrderItems = (): ResetOrderItems => ({
  type: RESET_ORDER_ITEMS,
});


export const setOrderReceiverContactCast = (cast: ContactCast): SetOrderReceiverContactCast => ({
  type: SET_ORDER_RECEIVER_CONTACT_CAST,
  cast,
});


export const resetOrderReceiverContactCast = (): ResetOrderReceiverContactCast => ({
  type: RESET_ORDER_RECEIVER_CONTACT_CAST,
});


export const setCurrentAccountRank = (rank: AccountRank): SetCurrentAccountRank => ({
  type: SET_CURRENT_ACCOUNT_RANK,
  rank,
});

export const loadCurrentUserMarketing = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/marketing/current/').then(
  (response: clientMarketing) => {
    dispatch(setClientMarketing(response));
    return Promise.resolve(response);
  },
);


export const loadMarketingActions = (page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/marketing/log/', { page }).then(
  (response: {marketingActions: MarketingAction[], count: number}) => {
    dispatch(setClientMarketingActionList(response.marketingActions));
    dispatch(setClientMarketingActionCount(response.count));
    return Promise.resolve(response);
  },
);


export const loadStoreItems = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/marketing/store/items/',
).then(
  (response: {storeItems: StoreItem[]}) => {
    dispatch(setStoreItems(response.storeItems));
    return Promise.resolve(response);
  },
);

export const createStoreOrder = (values: anyObject): ThunkAction => (dispatch, getState, http) => {
  const { orderItems, receiverCast } = getState().marketing;

  return http.post('/api/v1/marketing/store/order/create/',
    {
      receiver_contact_id: receiverCast?.contactCastId,
      order_items: orderItems.map(({ itemId, quantity, denomination }) => ({ item_id: itemId, quantity, denomination, })),
      ...values,
    });
};


export const loadAccountRank = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/marketing/current/rank/',
).then(
  (response: AccountRank) => {
    dispatch(setCurrentAccountRank(response));
    return Promise.resolve();
  },
);
