import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from '../views/NotFound';
import NonAuthorizedRoute from './NonAuthorizeRoute';
import asyncComponent from '../util/asyncComponent';
import RegistrationFirstStageView from '../app/user/views/registration/RegistrationFirstStageView';
import TermsOfUseView from '../app/page/views/TermsOfUseView';
import EmailVerifyView from '../app/user/views/EmailVerifyView';
import UserLoginView from '../app/user/views/UserLoginView';
import AuthorizedRoute from './AuthorizedRoute';
import LoginAsView from '../app/user/views/LoginAsView';
import AcceptInviteView from '../app/user/views/AcceptInviteView';
import PrivateRouter from './PrivateRoute';
import ContractFillView from '../app/user/views/ContractFillView';
import SharedShippingDetailView from '../app/shipping/views/SharedShippingDetailView';
import { SuspendedUserForgotPassword } from '../app/user/views/UserForgotPassword';
import TelegramBotAuthorizeView from '../app/user/views/TelegramBotAuthorizeView';

function BaseRouter() {
  return (
    <Switch>

      <NonAuthorizedRoute
        exact
        path="/login"
        component={UserLoginView}
      />
      <NonAuthorizedRoute
        exact
        path="/signup"
        component={RegistrationFirstStageView}
        emailVerifyRoute
      />

      <NonAuthorizedRoute
        exact
        path="/forgot-password"
        component={SuspendedUserForgotPassword}
      />

      <NonAuthorizedRoute
        exact
        path="/invite-accept"
        component={AcceptInviteView}
      />

      <Route
        exact
        path="/login_as"
        component={LoginAsView}
      />

      <Route exact path="/email/verify" component={EmailVerifyView} />

      {/* <Route exact path="/terms" component={TermsOfUseView} /> */}
      <Route exact path="/404" component={NotFound} />

      <PrivateRouter
        exact
        path="/contract"
        component={ContractFillView}
        contract
      />

      <PrivateRouter
        component={TelegramBotAuthorizeView}
        exact
        path="/bot/telegram/authorize/:code"
      />

      <Route path="/waybill/shared/:key" component={SharedShippingDetailView} />

      <Route path="/" component={AuthorizedRoute} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default BaseRouter;
