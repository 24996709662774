import moment from 'moment';
import {
  ADD_MULTI_RATE_GROUP,
  ADD_MULTIPLE_REGISTRY_ITEMS,
  ADD_REGISTRY_ITEM,
  AddMultipleRegistryItems,
  AddMultiRateGroup,
  AddRegistryItem,
  CALC_AVAILABLE_REGISTRY_FILTERS,
  CalcAvailableRegistryFilters,
  CHECK_CURRENT_REGISTRY_FILTERS,
  CheckCurrentRegistryFilters,
  MultiRateGroup,
  Registry,
  RegistryItem,
  RegistryItemStatus,
  RegistryListResponse,
  REMOVE_REGISTRY_ITEM,
  RemoveRegistryItem,
  REPLACE_REGISTRY_ITEM,
  ReplaceRegistryItem,
  RESET_CURRENT_REGISTRY,
  RESET_REGISTRY,
  RESET_REGISTRY_CALCULATION,
  ResetCurrentRegistry,
  ResetRegistry,
  ResetRegistryCalculation,
  SET_CURRENT_DATE,
  SET_CURRENT_MULTI_CALC_TASK_ID,
  SET_CURRENT_REGISTRY,
  SET_CURRENT_REGISTRY_DATE,
  SET_CURRENT_REGISTRY_WAYBILL_LIST,
  SET_REGISTRY_ITEMS,
  SET_REGISTRY_LIST,
  SET_SELECTED_ITEMS,
  SetCurrentDate,
  SetCurrentMultiTaskId,
  SetCurrentRegistry,
  SetCurrentRegistryDate,
  SetCurrentRegistryWaybillList,
  SetRegistryItems,
  SetRegistryList,
  SetSelectedItems,
  SWITCH_CURRENT_REGISTRY_FILTER,
  SwitchCurrentRegistryFilter,
  UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER,
  UpdateCurrentRegistryDeliveryServiceFilter,
} from './types';
import { ThunkAction } from '../../util/types';
import { SERVER_DATE_FORMAT } from '../../util/constants';
import { Waybill } from '../shipping/types';
import { ContactImportResult } from '../contacts_import/types';
import date from '../../i18n/date';

export const addRegistryItem = (item: Omit<RegistryItem, 'uid'>): AddRegistryItem => ({
  type: ADD_REGISTRY_ITEM,
  item,
});

export const removeRegistryItem = (uid: string): RemoveRegistryItem => ({
  type: REMOVE_REGISTRY_ITEM,
  uid,
});

export const setCurrentRegistry = (registry: Registry | null): SetCurrentRegistry => ({
  type: SET_CURRENT_REGISTRY,
  registry,
});

export const resetRegistry = (): ResetRegistry => ({
  type: RESET_REGISTRY,
});

export const setCurrentMultiTaskId = (taskId: string): SetCurrentMultiTaskId => ({
  type: SET_CURRENT_MULTI_CALC_TASK_ID,
  taskId,
});

export const addMultiRateGroup = (group: MultiRateGroup): AddMultiRateGroup => ({
  type: ADD_MULTI_RATE_GROUP,
  group,
});

export const replaceRegistryItem = (item: RegistryItem): ReplaceRegistryItem => ({
  type: REPLACE_REGISTRY_ITEM,
  item,
});

export const setRegistryList = (items: Array<RegistryListResponse>): SetRegistryList => ({
  type: SET_REGISTRY_LIST,
  items,
});

export const setRegistryItems = (items: Array<Omit<RegistryItem, 'uid'>>): SetRegistryItems => ({
  type: SET_REGISTRY_ITEMS,
  items,
});

export const resetCurrentRegistry = (): ResetCurrentRegistry => ({
  type: RESET_CURRENT_REGISTRY,
});

export const setCurrentDate = (date: moment.Moment): SetCurrentDate => ({
  type: SET_CURRENT_DATE,
  date,
});

export const resetRegistryCalculation = (): ResetRegistryCalculation => ({
  type: RESET_REGISTRY_CALCULATION,
});

export const updateCurrentRegistryDeliveryServiceFilter = (deliveryServices: Array<string>): UpdateCurrentRegistryDeliveryServiceFilter => ({
  type: UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER,
  deliveryServices,
});

export const switchCurrentRegistryFilter = (filter: 'deliveryServices'): SwitchCurrentRegistryFilter => ({
  type: SWITCH_CURRENT_REGISTRY_FILTER,
  filter,
});

export const calcAvailableRegistryFilters = (): CalcAvailableRegistryFilters => ({
  type: CALC_AVAILABLE_REGISTRY_FILTERS,
});

export const checkCurrentRegistryFilters = (): CheckCurrentRegistryFilters => ({
  type: CHECK_CURRENT_REGISTRY_FILTERS,
});

export const setCurrentRegistryWaybillList = (waybills: Waybill[]): SetCurrentRegistryWaybillList => ({
  type: SET_CURRENT_REGISTRY_WAYBILL_LIST,
  waybills,
});

export const setSelectedItems = (items: string[]): SetSelectedItems => ({
  type: SET_SELECTED_ITEMS,
  items,
});

export const addMultipleRegistryItems = (items: Omit<RegistryItem, 'uid'>[]): AddMultipleRegistryItems => ({
  type: ADD_MULTIPLE_REGISTRY_ITEMS,
  items,
});

export const setCurrentRegistryDate = (date: moment.Moment): SetCurrentRegistryDate => ({
  type: SET_CURRENT_REGISTRY_DATE,
  date,
});

export const createRegistry = (): ThunkAction => (dispatch, getState, http) => {
  const { currentDate } = getState().registry;

  return http.post('/api/v1/registry/create/', { pickup_date: currentDate?.format(SERVER_DATE_FORMAT) });
};

export const addItemRegistryServer = (data: RegistryItem): ThunkAction => (dispatch, getState, http) => {
  const state = getState();
  const { currentRegistry } = state.registry;
  const params = {
    sender_address_id: data.sender?.addressCastId,
    sender_contact_info_id: data.sender?.contactCastId,
    sender_counterparty_id: data.sender?.counterpartyId,
    receiver_address_id: data.receiver?.addressCastId,
    receiver_contact_info_id: data.receiver?.contactCastId,
    receiver_counterparty_id: data.receiver?.counterpartyId,
    cargo_id: data.cargo?.cargoId,
  };
  if (currentRegistry?.registryId) {
    return http.post('/api/v1/registry/item/add/', { ...params, registry_id: currentRegistry.registryId }).then(
      ({ destinationId }: { destinationId: number }) => {
        dispatch(replaceRegistryItem({ ...data, destinationId }));
        return Promise.resolve();
      },
    );
  }
  return dispatch(createRegistry()).then(
    (response: { registryId: number }) => {
      dispatch(setCurrentRegistry({ ...response, used: false }));
      return http.post('/api/v1/registry/item/add/', { ...params, registry_id: response.registryId }).then(
        ({ destinationId }: { destinationId: number }) => {
          dispatch(replaceRegistryItem({ ...data, destinationId }));
          return Promise.resolve();
        },
      );
    },
  );
};

export const registryAddItemsFromImport = (items: ContactImportResult[]): ThunkAction => (dispatch, getState, http) => {
  const { currentRegistry } = getState().registry;
  console.debug('items', items);
  const registryItems: Omit<RegistryItem, 'uid'>[] = items.filter((_) => !!_.parsedData).map((_) => ({
    sender: _.parsedData?.senderData,
    receiver: _.parsedData?.receiverData,
    cargo: _.parsedData?.cargoData,
    status: 'new',
  }));

  if (currentRegistry?.registryId) {
    return http.put(`/api/v1/registry/${currentRegistry.registryId}/add_items/`, {
      destinations: registryItems.map((_) => ({
        sender_address_id: _.sender?.addressCastId,
        sender_contact_info_id: _.sender?.contactCastId,
        sender_counterparty_id: _.sender?.counterpartyId,
        receiver_address_id: _.receiver?.addressCastId,
        receiver_contact_info_id: _.receiver?.contactCastId,
        receiver_counterparty_id: _.receiver?.counterpartyId,
        cargo_id: _.cargo?.cargoId,
      })),
    }).then(
      (response: { destinations: { destinationId: number }[] }) => {
        dispatch(addMultipleRegistryItems(registryItems.map((_, i) => ({
          ..._,
          destinationId: response.destinations[i].destinationId,
        }))));
        Promise.resolve(response);
      },
    );
  }

  return dispatch(createRegistry()).then(
    (response: { registryId: number }) => {
      dispatch(setCurrentRegistry({ ...response, used: false }));
      return http.put(`/api/v1/registry/${response.registryId}/add_items/`, {
        destinations: registryItems.map((_) => ({
          sender_address_id: _.sender?.addressCastId,
          sender_contact_info_id: _.sender?.contactCastId,
          sender_counterparty_id: _.sender?.counterpartyId,
          receiver_address_id: _.receiver?.addressCastId,
          receiver_contact_info_id: _.receiver?.contactCastId,
          receiver_counterparty_id: _.receiver?.counterpartyId,
          cargo_id: _.cargo?.cargoId,
        })),
      }).then(
        (resp: { destinations: { destinationId: number }[] }) => {
          dispatch(addMultipleRegistryItems(registryItems.map((_, i) => ({
            ..._,
            destinationId: resp.destinations[i].destinationId,
          }))));
          Promise.resolve(resp);
        },
      );
    },
  );
};

interface AddItemRegistryData extends Omit<RegistryItem, 'uid' | 'status'> {
  uid?: string
}

export const addItemRegistry = (data: AddItemRegistryData): ThunkAction => (dispatch) => {
  if (data.uid) {
    dispatch(replaceRegistryItem(data as RegistryItem));
    if (data.sender && data.receiver && data.cargo) return dispatch(addItemRegistryServer(data as RegistryItem));
    return Promise.resolve();
  }

  dispatch(addRegistryItem({ ...data, status: 'new' }));
  return Promise.resolve();
};

export const deleteItemRegistry = (uid: string): ThunkAction => (dispatch, getState, http) => {
  const { currentRegistryItems, currentRegistry } = getState().registry;
  const item = currentRegistryItems.find((_) => _.uid === uid);
  if (item && !item.destinationId) {
    dispatch(removeRegistryItem(uid));
    return Promise.resolve();
  }

  if (item && currentRegistry) {
    return http.delete(`/api/v1/registry/item/${item.destinationId}/delete/`, { registry_id: currentRegistry.registryId }).then(
      () => {
        dispatch(removeRegistryItem(uid));
        if (!getState().registry.currentRegistryItems.length) dispatch(setCurrentRegistry(null));
        return Promise.resolve();
      },
    );
  }
  return Promise.reject();
};

export const editItemRegistryServer = (data: RegistryItem): ThunkAction => (dispatch, getState, http) => {
  const state = getState();
  const { currentRegistry } = state.registry;
  const params = {
    sender_address_id: data.sender?.addressCastId,
    sender_contact_info_id: data.sender?.contactCastId,
    sender_counterparty_id: data.sender?.counterpartyId,
    receiver_address_id: data.receiver?.addressCastId,
    receiver_contact_info_id: data.receiver?.contactCastId,
    receiver_counterparty_id: data.receiver?.counterpartyId,
    cargo_id: data.cargo?.cargoId,
    destination_id: data.destinationId,
  };
  if (currentRegistry?.registryId) {
    return http.put(`/api/v1/registry/${currentRegistry.registryId}/edit_item/`, { ...params, registry_id: currentRegistry.registryId }).then(
      ({ destinationId }: { destinationId: number }) => {
        dispatch(replaceRegistryItem({ ...data, destinationId }));
        return Promise.resolve();
      },
    );
  }
  return dispatch(createRegistry()).then(
    (response: { registryId: number }) => {
      dispatch(setCurrentRegistry({ ...response, used: false }));
      return http.post('/api/v1/registry/item/add/', { ...params, registry_id: response.registryId }).then(
        ({ destinationId }: { destinationId: number }) => {
          dispatch(replaceRegistryItem({ ...data, destinationId }));
          return Promise.resolve();
        },
      );
    },
  );
};

export const editItemRegistry = (item: RegistryItem): ThunkAction => (dispatch) => {
  console.debug('item', item);
  if (item.sender && item.receiver && item.cargo) {
    dispatch(replaceRegistryItem(item));
    return dispatch(editItemRegistryServer(item));
  }
  dispatch(replaceRegistryItem(item));
  return Promise.resolve();
};

export const startCalculationMulti = (): ThunkAction => (dispatch, getState, http) => {
  const { currentRegistryItems, selectedItems } = getState().registry;
  if (currentRegistryItems.length) {
    dispatch(resetRegistryCalculation());
    const tasks = currentRegistryItems.filter((_) => selectedItems.includes(_.uid)).map((item) => ({
      destination_id: item.destinationId,
      sender_address_id: item.sender?.addressCastId,
      receiver_address_id: item.receiver?.addressCastId,
      cargo_id: item.cargo?.cargoId,
    }));
    return http.post('/api/v1/calculation/multi/start/', { tasks }).then(
      (response: { multiTaskId: string }) => {
        dispatch(setCurrentMultiTaskId(response.multiTaskId));
        return Promise.resolve(response);
      },
    );
  }
  return Promise.reject();
};

export const acceptMultiRateGroupWS = (multiTaskId: string, command: string, group: MultiRateGroup): ThunkAction<void> => (dispatch, getState) => {
  const { currentMultiCalcTaskId } = getState().registry;
  if (multiTaskId === currentMultiCalcTaskId) {
    if (command === 'results') {
      dispatch(addMultiRateGroup(group));
    }
  }
};

export const createRegistryShipping = (multiGroupId: string): ThunkAction => (dispatch, getState, http) => {
  const {
    registry: {
      currentRegistry, currentDate, selectedItems, currentRegistryItems,
    }, creation: { bonusValue },
  } = getState();
  const destinations = currentRegistryItems.filter((_) => selectedItems.includes(_.uid));
  const data: any = {
    pickup_date: currentDate?.format(SERVER_DATE_FORMAT),
    registry_id: currentRegistry?.registryId,
    multi_group_id: multiGroupId,
  };

  if (bonusValue) data.bonus_value = bonusValue;
  return http.post('/api/v1/registry/group/create/', {
    registry_id: currentRegistry?.registryId,
    destination_id_list: destinations.map((_) => _.destinationId),
  }).then(
    ({ destinationGroupId }: { destinationGroupId: number }) => http.post('/api/v1/shipping/create/multi/', {
      ...data,
      destination_group_id: destinationGroupId,
    }),
  );
  // return http.post('/api/v1/shipping/create/multi/', data);
};

export const loadRegistryList = (startDate: moment.Moment, endDate: moment.Moment): ThunkAction => (dispatch, getState, http) => {
  const params = {
    start_date: startDate.format(SERVER_DATE_FORMAT),
    end_date: endDate.format(SERVER_DATE_FORMAT),
  };

  return http.get('/api/v1/registry/', params).then(
    (response: { items: Array<RegistryListResponse> }) => {
      dispatch(setRegistryList(response.items));
      return Promise.resolve(response);
    },
  );
};

export const loadRegistry = (date: moment.Moment): ThunkAction => (dispatch, getState, http) => {
  if (date.isValid()) {
    return http.get(`/api/v1/registry/${date.format(SERVER_DATE_FORMAT)}/detail/`).then(
      ({
        destinations,
        waybills,
        registryId,
      }: { destinations: Array<Omit<RegistryItem, 'uid'>>, waybills: Waybill[], registryId: number }) => {
        dispatch(setRegistryItems(destinations));
        dispatch(setCurrentRegistryWaybillList(waybills));
        dispatch(setCurrentRegistry({ registryId, used: false }));
        return Promise.resolve();
      },
      (reject: any) => {
        // dispatch(resetCurrentRegistry());
        if (!getState().registry.currentRegistry) {
          dispatch(setCurrentRegistry(null));
          dispatch(setRegistryItems([]));
        }
        return Promise.reject(reject);
      },
    );
  }
  return Promise.reject();
};

export const transferToDate = (registryId: string | number, date: moment.Moment | null | undefined): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/registry/${registryId}/transfer/`, { date: date?.format(SERVER_DATE_FORMAT) });

export const clearRegistry = (registryId: string | number): ThunkAction => (dispatch, getState, http) => http.delete(`/api/v1/registry/${registryId}/clear/`);

export const acceptRegistryWs = ({
  destinationId,
  status,
  waybillNumber,
}: { destinationId: number, status: RegistryItemStatus, waybillNumber: string }): ThunkAction<void> => (dispatch, getState, http) => {
  const { currentRegistryItems } = getState().registry;
  const item = currentRegistryItems.find((_) => _.destinationId === destinationId);
  if (item) {
    if (status === 'success' && waybillNumber) {
      http.get(`/api/v1/shipping/waybill/${waybillNumber}/`).then(
        (waybill: Waybill) => {
          const { currentRegistryWaybillList } = getState().registry;
          dispatch(setCurrentRegistryWaybillList([waybill, ...currentRegistryWaybillList]));
          dispatch(removeRegistryItem(item.uid));
          // dispatch(replaceRegistryItem({ ...item, status, waybill }));
        },
      );
    } else {
      dispatch(replaceRegistryItem({ ...item, status }));
    }
  }
};

export const deleteSelectedItems = (): ThunkAction => (dispatch, getState, http) => {
  const { selectedItems, currentRegistryItems, currentRegistry } = getState().registry;
  const items = currentRegistryItems.filter((_) => selectedItems.includes(_.uid));
  const remote = items.filter((_) => !!_.destinationId);

  if (remote.length && currentRegistry) {
    return http.delete(`/api/v1/registry/${currentRegistry.registryId}/delete_items/`, { destination_id_list: remote.map((_) => _.destinationId) }).then(
      () => {
        items.forEach(({ uid }) => dispatch(removeRegistryItem(uid)));
        return Promise.resolve();
      },
    );
  }
  items.forEach(({ uid }) => dispatch(removeRegistryItem(uid)));
  return Promise.resolve();
};

export const createMultiClaim = (comment?: string): ThunkAction => (dispatch, getState, http) => {
  const {
    currentRegistry, currentDate, selectedItems, currentRegistryItems,
  } = getState().registry;
  const destinations = currentRegistryItems.filter((_) => selectedItems.includes(_.uid) && _.status === 'fail');

  return http.post('/api/v1/registry/group/create/', {
    registry_id: currentRegistry?.registryId,
    destination_id_list: destinations.map((_) => _.destinationId),
  }).then(
    ({ destinationGroupId }: { destinationGroupId: number }) => http.post('/api/v1/shipping/claim/multi/create/', {
      registry_id: currentRegistry?.registryId,
      pickup_date: currentDate?.format(SERVER_DATE_FORMAT),
      destination_group_id: destinationGroupId,
      comment,
    }),
  );
};

export const transferSelectedItems = (date: moment.Moment | null | undefined): ThunkAction => (
  dispatch, getState, http,
) => {
  const { selectedItems, currentRegistryItems, currentRegistry } = getState().registry;
  const items = currentRegistryItems.filter((_) => selectedItems.includes(_.uid));
  const remote = items.filter((_) => !!_.destinationId);

  if (remote.length && currentRegistry) {
    return http.put(`/api/v1/registry/${currentRegistry.registryId}/transfer_items/`, { destination_id_list: remote.map((_) => _.destinationId), date: date?.format(SERVER_DATE_FORMAT) }).then(
      () => {
        items.forEach(({ uid }) => dispatch(removeRegistryItem(uid)));
        return Promise.resolve();
      },
    );
  }

  return Promise.reject();
};

export const getMultiResultDetail = (multiGroupId: string): ThunkAction<Promise<MultiRateGroup>> => (dispatch, getState, http) => http.get(
  `/api/v1/calculation/multi/${multiGroupId}/result/detail/`,
);
