import React, { useCallback, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Row } from 'antd';
import Col from 'antd/lib/grid/col';

function UpdateDesignPopup() {
    const [isViewed, setIsViewed] = useState(!!localStorage.getItem("isUpdatedInfo"));

  const handleConfirm = useCallback(
    () => {
      setIsViewed(true);
      localStorage.setItem("isUpdatedInfo", "success");
    },
    [],
  );

  return (
    <SweetAlert
      show={!isViewed}
      title="Новый дизайн"
      showConfirm={false}
      onConfirm={console.debug}
    >
      <Row>
        <Col span={24} className="gx-mb-3">
            Мы делаем личный кабинет удобнее, не переживайте за его внешний вид.
        </Col>
        <Col span={24}>
          <Button type="primary" onClick={handleConfirm}>Хорошо</Button>
        </Col>
      </Row>
    </SweetAlert>
  );
}

export default UpdateDesignPopup;
