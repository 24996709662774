import React, { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useSelector from '../util/hooks/useSelector';
import useWIPFeatures from '../util/hooks/useWIPFeatures';
import useOwner from '../util/hooks/useOwner';
import CalculationFastView from '../app/calculation_new/views/CalculationFastView';

export interface PrivateRouteProps extends RouteProps {
  component: JSXElementConstructor<any>,
  contract?: boolean,
  wip?: boolean,
  ownerOnly?: boolean,
  // [key: string]: any
}

const getNext = (pathname: string, search: string) => escape(`${pathname}${search}`);

function PrivateRoute({
  component: Component, contract, wip, ownerOnly, ...rest
}: PrivateRouteProps) {
  const user = useSelector((state) => state.user.user);
  const isWip = useWIPFeatures();
  const isOwner = useOwner();
  if (!user) return <Route {...rest} render={(props) => <Redirect to={`/login?next=${getNext(props.location.pathname, props.location.search)}`} from={props.location.pathname} />} />;
  if (ownerOnly && !isOwner) return <Route {...rest} render={() => <Redirect to="/orders" />} />;
  if (wip && !isWip) return <Route {...rest} render={(props) => <Redirect to={`/login?next=${getNext(props.location.pathname, props.location.search)}`} />} />;
  if (!user.emailVerified) return <Route {...rest} render={(props) => <Redirect to="/signup" from={props.location.pathname} />} />;
  if (user.contractId === 0) {
    if (!contract) return <Route {...rest} render={(props) => <Redirect to="/contract" from={props.location.pathname} />} />;
  } else if (contract) return <Route {...rest} render={(props) => <Redirect to="/orders" from={props.location.pathname} />} />;
  if (rest.path === "/" && user.isUpdatedDesign) return (
    <Route
      {...rest}
      render={(props) => <CalculationFastView {...props} />}
    />
  )
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
}

export default PrivateRoute;
