import React, { useEffect, Suspense } from 'react';
import { Layout } from 'antd';

import Drawer from 'antd/es/drawer';
import { toggleCollapsedSideNav } from '../../app/settings/action-creators';
import useDispatch from '../../util/hooks/useDispatch';
import useSelector from '../../util/hooks/useSelector';
import lazyPreload from '../../util/lazyPreload';
import CircularProgress from '../../components_base/CircularProgress';

const { Sider } = Layout;

const DesktopContent = lazyPreload(() => import('./SidebarContent'));
const MobileContent = lazyPreload(() => import('./MobileSidebarContent'));


function Sidebar() {
  const dispatch = useDispatch();
  const { navCollapsed, mobile } = useSelector((state) => state.settings);

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };


  useEffect(() => {
    if (mobile && MobileContent && MobileContent.preload) MobileContent.preload();
    else if (DesktopContent && DesktopContent.preload) DesktopContent.preload();
  }, []);

  return (
    <Sider
      className="gx-app-sidebar gx-layout-sider-dark"
      trigger={null}
      theme="dark"
    >
      {
        mobile ? (
          <Drawer
            className="gx-drawer-sidebar gx-drawer-sidebar-dark"
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            visible={navCollapsed}
          >
            <Suspense fallback={<CircularProgress />}>
              <MobileContent />
            </Suspense>
          </Drawer>
        )
          : (
            <Suspense fallback={<CircularProgress />}>
              <DesktopContent />
            </Suspense>
          )
      }
    </Sider>
  );
}

export default Sidebar;
