import {
  ContactImportResult,
  CurrentImportState,
  ImportActions,
  RESET_CURRENT_IMPORT,
  SET_CURRENT_IMPORT_ID,
  SET_CURRENT_IMPORT_ITEMS,
  SET_CURRENT_IMPORT_STATE,
} from './types';

type ImportState = {
  currentImportState: CurrentImportState,
  currentImportId: string | null,
  currentImportItems: ContactImportResult[]
};

const initialState: ImportState = {
  currentImportState: CurrentImportState.NEW,
  currentImportId: null,
  currentImportItems: [],
};

export default function (state = initialState, action: ImportActions) {
  switch (action.type) {
    case SET_CURRENT_IMPORT_STATE:
      return { ...state, currentImportState: action.state };
    case SET_CURRENT_IMPORT_ID:
      return { ...state, currentImportId: action.importId };
    case RESET_CURRENT_IMPORT:
      return { ...initialState };
    case SET_CURRENT_IMPORT_ITEMS:
      return { ...state, currentImportItems: action.items };
    default: return state;
  }
}
