import {
  Act,
  ActReconcillation,
  ADD_ACT_LIST,
  InvoicesActions,
  Payment,
  SET_ACT_LIST,
  SET_ACT_RECONCILLATION_LIST,
  SET_ACTS_COUNT,
  SET_PAYMENT_COUNT,
  SET_PAYMENT_LIST,
} from './types';

export interface InvoiceState {
  acts: Array<Act>,
  actsCount: number,
  payments: Array<Payment>,
  paymentCount: number,
  actReconcillationList: ActReconcillation[],
}

const initialState: InvoiceState = {
  acts: [],
  actsCount: 0,
  payments: [],
  paymentCount: 0,
  actReconcillationList: []
};

export default function (state = initialState, action: InvoicesActions) {
  switch (action.type) {
    case ADD_ACT_LIST:
      return { ...state, acts: [...state.acts, ...action.acts] };
    case SET_ACT_LIST:
      return { ...state, acts: action.acts };
    case SET_ACTS_COUNT:
      return { ...state, actsCount: action.count };
    case SET_PAYMENT_LIST:
      return { ...state, payments: action.list };
    case SET_PAYMENT_COUNT:
      return { ...state, paymentCount: action.count };
    case SET_ACT_RECONCILLATION_LIST:
      return { ...state, actReconcillationList: action.list };
    default: return state;
  }
}
