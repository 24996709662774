import { ReactNode } from 'react';

export const ADD_ALERT = 'ADD_ALERT';

export type CustomAlert = {
  closable?: boolean,
  message: string,
  description?: string,
  icon?: ReactNode,
  type?: 'success' | 'info' | 'warning' | 'error'
}

export interface AddAlert {
  type: typeof ADD_ALERT,
  alert: CustomAlert,
}

export type AlertAction = AddAlert;
