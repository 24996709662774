import React from 'react';

const TopBarSales = () => (
    <svg className="custom-navbar__menu-img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0052 4H9.99523C6.21523 4 4.32523 4 3.15023 5.172C2.34023 5.978 2.08923 7.123 2.01023 8.989C1.99523 9.359 1.98723 9.545 2.05623 9.668C2.12623 9.791 2.40123 9.946 2.95323 10.254C3.26416 10.4273 3.52317 10.6806 3.70347 10.9875C3.88378 11.2945 3.97884 11.644 3.97884 12C3.97884 12.356 3.88378 12.7055 3.70347 13.0125C3.52317 13.3194 3.26416 13.5727 2.95323 13.746C2.40123 14.055 2.12523 14.209 2.05623 14.332C1.98723 14.455 1.99523 14.64 2.01123 15.01C2.08923 16.877 2.34123 18.022 3.15023 18.828C4.32423 20 6.21423 20 9.99523 20H14.0052C17.7852 20 19.6752 20 20.8502 18.828C21.6602 18.022 21.9112 16.877 21.9902 15.011C22.0052 14.641 22.0132 14.455 21.9442 14.332C21.8742 14.209 21.5992 14.055 21.0472 13.746C20.7363 13.5727 20.4773 13.3194 20.297 13.0125C20.1167 12.7055 20.0216 12.356 20.0216 12C20.0216 11.644 20.1167 11.2945 20.297 10.9875C20.4773 10.6806 20.7363 10.4273 21.0472 10.254C21.5992 9.946 21.8752 9.791 21.9442 9.668C22.0132 9.545 22.0052 9.36 21.9892 8.989C21.9112 7.123 21.6592 5.979 20.8502 5.172C19.6762 4 17.7862 4 14.0052 4Z" stroke="currentColor" strokeWidth="2"/>
        <path d="M9.00024 15L15.0002 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        <circle cx="9" cy="9" r="0.5" stroke="currentColor"/>
        <circle cx="15" cy="15" r="0.5" stroke="currentColor"/>
    </svg>
)

export { TopBarSales }