import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import getDomainConfig from '../domain-config';
import { money } from '../util/format';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(intervalPlural)
  .init({
    fallbackLng: getDomainConfig().defaultLanguage,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, format, lng) {
        if (lng && (value instanceof Date || value instanceof moment)) {
          return moment(value).format(format);
        }
        if (format === 'moneyFormat') return money(value);
        if (format === 'moneyFormatFloat') return money(value, false);
        return value;
      },
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: false,
      wait: false,
    },
  });

const getMomentLocale = (lng: string): string => {
  if (lng === 'en') return 'en-ca';
  if (lng.toLowerCase() === 'ru-ru') return 'ru';
  return lng;
};


// i18n.on('languageChanged', (lng) => {
//   let _ = lng;
//   if (_ === 'en') _ = 'en-ca';
//   import(`moment/locale/${_}`).then(
//     (module) => {
//       try {
//         moment.updateLocale(_, module);
//       } catch (e) {
//         console.error(`Failed load moment locale ${e}`)
//       }
//     }
//   )
// });

export default i18n;
export { getMomentLocale };
