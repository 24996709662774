import { uniqueId } from 'lodash';
import moment from 'moment';
import {
  ADD_MULTI_RATE_GROUP,
  ADD_MULTIPLE_REGISTRY_ITEMS,
  ADD_REGISTRY_ITEM,
  CALC_AVAILABLE_REGISTRY_FILTERS,
  CHECK_CURRENT_REGISTRY_FILTERS,
  MultiRateGroup,
  Registry,
  RegistryAction,
  RegistryItem,
  RegistryListItem,
  RegistryListResponse,
  REMOVE_REGISTRY_ITEM,
  REPLACE_REGISTRY_ITEM,
  RESET_CURRENT_REGISTRY,
  RESET_REGISTRY,
  RESET_REGISTRY_CALCULATION,
  SET_CURRENT_DATE,
  SET_CURRENT_MULTI_CALC_TASK_ID,
  SET_CURRENT_REGISTRY,
  SET_CURRENT_REGISTRY_DATE,
  SET_CURRENT_REGISTRY_WAYBILL_LIST,
  SET_REGISTRY_ITEMS,
  SET_REGISTRY_LIST,
  SET_SELECTED_ITEMS,
  SWITCH_CURRENT_REGISTRY_FILTER,
  UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER,
} from './types';
import removeByFieldValue from '../../util/removeByFieldValue';
import { CalculationFilter, CurrentCalculationFilter } from '../calculation/types';
import { Waybill } from '../shipping/types';
import { replaceByFieldValue } from '../../util/array/replace';

export interface RegistryState {
  currentRegistryItems: Array<RegistryItem>,
  currentRegistry: Registry | null,
  currentMultiCalcTaskId: string | null,
  multiRateGroups: Array<MultiRateGroup>,
  registryListDict: { [key: string]: RegistryListItem },
  currentDate: moment.Moment | null,
  currentFilters: CurrentCalculationFilter,
  availableFilters: CalculationFilter,
  currentRegistryWaybillList: Waybill[],
  selectedItems: string[],
  registryDate: moment.Moment,
}

const initialState: RegistryState = {
  currentRegistryItems: [],
  currentRegistry: null,
  currentMultiCalcTaskId: null,
  multiRateGroups: [],
  registryListDict: {},
  currentDate: null,
  currentFilters: {
    deliveryServices: {
      touched: false,
      values: [],
      open: true,
    },
    services: {
      touched: false,
      values: [],
      open: true,
    },
    deliveryWay: {
      touched: false,
      values: [],
      open: true,
    }
  },
  availableFilters: {
    deliveryServices: [],
    services: [],
    deliveryWay: []
  },
  currentRegistryWaybillList: [],
  selectedItems: [],
  registryDate: moment(),
};

function prepareRegistryList(items: Array<RegistryListResponse>): { [key: string]: RegistryListItem } {
  const tmp: { [key: string]: RegistryListItem } = {};
  items.forEach((_) => {
    tmp[_.pickupDate] = { ..._, pickupDate: moment(_.pickupDate) };
  });
  return tmp;
}

function calcFilter(currentRates: Array<MultiRateGroup>): CalculationFilter {
  const deliveryServices: Array<string> = [];
  currentRates.forEach((rate) => {
    if (!deliveryServices.includes(rate.deliveryService)) deliveryServices.push(rate.deliveryService);
  });
  return {
    deliveryServices,
    services: [],
    deliveryWay: []
  };
}

function checkCurrentFilter(currentCalculationFilter: CurrentCalculationFilter, availableFilters: CalculationFilter) {
  const tmp = { ...currentCalculationFilter };
  if (!tmp.deliveryServices.touched) tmp.deliveryServices.values = availableFilters.deliveryServices;
  if (!tmp.deliveryWay.touched) tmp.deliveryWay.values = availableFilters.deliveryWay;
  return tmp;
}

function switchFilter(currentFilters: CurrentCalculationFilter, filter: 'deliveryServices'): CurrentCalculationFilter {
  const tmp = { ...currentFilters };
  tmp[filter].open = !tmp[filter].open;

  return tmp;
}

export default function (state = initialState, action: RegistryAction): RegistryState {
  switch (action.type) {
    case ADD_REGISTRY_ITEM:
      return {
        ...state,
        currentRegistryItems: [...state.currentRegistryItems, { ...action.item, uid: uniqueId('registry-') }],
      };
    case REMOVE_REGISTRY_ITEM:
      // @ts-ignore
      return { ...state, currentRegistryItems: removeByFieldValue(state.currentRegistryItems, 'uid', action.uid) };
    case REPLACE_REGISTRY_ITEM:
      return { ...state, currentRegistryItems: replaceByFieldValue(state.currentRegistryItems, 'uid', action.item) };
    case SET_CURRENT_REGISTRY:
      return { ...state, currentRegistry: action.registry };
    case RESET_REGISTRY:
      return { ...initialState };
    case SET_CURRENT_MULTI_CALC_TASK_ID:
      return { ...state, currentMultiCalcTaskId: action.taskId };
    case ADD_MULTI_RATE_GROUP:
      return { ...state, multiRateGroups: [...state.multiRateGroups, action.group] };
    case SET_REGISTRY_LIST:
      return { ...state, registryListDict: prepareRegistryList(action.items) };
    case SET_REGISTRY_ITEMS:
      return { ...state, currentRegistryItems: action.items.map((item) => ({ ...item, uid: uniqueId('registry-') })) };
    case RESET_CURRENT_REGISTRY:
      return {
        ...state,
        currentRegistryItems: initialState.currentRegistryItems,
        currentRegistry: initialState.currentRegistry,
        currentDate: null,
      };
    case SET_CURRENT_DATE:
      return { ...state, currentDate: action.date };
    case RESET_REGISTRY_CALCULATION:
      return { ...state, multiRateGroups: initialState.multiRateGroups };
    case CALC_AVAILABLE_REGISTRY_FILTERS:
      return { ...state, availableFilters: calcFilter(state.multiRateGroups) };
    case CHECK_CURRENT_REGISTRY_FILTERS:
      return { ...state, currentFilters: checkCurrentFilter(state.currentFilters, state.availableFilters) };
    case SWITCH_CURRENT_REGISTRY_FILTER:
      return { ...state, currentFilters: switchFilter(state.currentFilters, action.filter) };
    case UPDATE_CURRENT_REGISTRY_DELIVERY_SERVICE_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          deliveryServices: {
            ...state.currentFilters.deliveryServices,
            touched: true,
            values: action.deliveryServices,
          },
        },
      };
    case SET_CURRENT_REGISTRY_WAYBILL_LIST:
      return { ...state, currentRegistryWaybillList: action.waybills };
    case SET_SELECTED_ITEMS:
      return { ...state, selectedItems: action.items };
    case ADD_MULTIPLE_REGISTRY_ITEMS:
      return {
        ...state,
        currentRegistryItems: [...state.currentRegistryItems, ...action.items.map((_) => ({
          ..._,
          uid: uniqueId('registry-'),
        }))],
      };
    case SET_CURRENT_REGISTRY_DATE:
      return { ...state, registryDate: action.date };
    default:
      return state;
  }
}
