import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const TerminalCalcCreate = React.lazy(() => import('../components/TerminalCalcCreate'));
function TerminalCalcCreateView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TerminalCalcCreate />
    </Suspense>
  );
}

export default TerminalCalcCreateView;
