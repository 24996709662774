import {
  ADD_CART_DESTINATION,
  ADD_MULTIPLE_CART_DESTINATIONS,
  CartActions,
  CartDestination,
  CartDestinationStatus,
  REMOVE_CART_DESTINATION,
  RESET_CART,
  SET_CART_DESTINATIONS,
  UPDATE_MULTIPLE_DESTINATIONS,
  UPDATE_SINGLE_DESTINATION,
} from './types';
import { removeByFieldValue } from '../../util/array/remove';
import { mergeAndReplaceByFieldValue } from '../../util/array/replace';

type CartStateType = {
  destinations: CartDestination[],
};

const initialState: CartStateType = {
  destinations: [],
};

const updateMultipleDestinations = (arr: Array<CartDestination>, newDestinations: Partial<CartDestination>[]): Array<CartDestination> => {
  let tmp = arr.slice();

  newDestinations.forEach((destination) => {
    tmp = mergeAndReplaceByFieldValue(tmp, 'destinationId', destination);
  });
  return tmp;
};

export default function (state = initialState, action: CartActions): CartStateType {
  switch (action.type) {
    case SET_CART_DESTINATIONS:
      return {
        ...state,
        destinations: action.destinations.map((_) => ({ ..._, status: _.status || CartDestinationStatus.INIT })),
      };
    case ADD_CART_DESTINATION:
      return {
        ...state,
        destinations: [...state.destinations, {
          ...action.destination,
          status: action.destination.status || CartDestinationStatus.INIT,
        }],
      };
    case REMOVE_CART_DESTINATION:
      return { ...state, destinations: removeByFieldValue(state.destinations, 'destinationId', action.destinationId) };
    case UPDATE_SINGLE_DESTINATION:
      return {
        ...state,
        destinations: mergeAndReplaceByFieldValue(state.destinations, 'destinationId', action.destination),
      };
    case UPDATE_MULTIPLE_DESTINATIONS:
      return { ...state, destinations: updateMultipleDestinations(state.destinations, action.destinations) };
    case ADD_MULTIPLE_CART_DESTINATIONS:
      return {
        ...state,
        destinations: [...state.destinations, ...action.destinations.map((_) => ({ ..._, status: _.status || CartDestinationStatus.INIT }))],
      };
    case RESET_CART:
      return { ...initialState }
    default:
      return state;
  }
}
