export const toggleCollapsedSideNav = (navCollapsed) => ({
  type: 'TOGGLE_COLLAPSED_NAV',
  navCollapsed,
});

export const updateWindowWidth = (width) => ({
  type: 'WINDOW_WIDTH',
  width,
});

export const setThemeType = (themeType) => ({
  type: 'THEME_TYPE',
  themeType,
});

export const setThemeColorSelection = (colorSelection) => ({
  type: 'THEME_COLOR_SELECTION',
  colorSelection,
});

export const onNavStyleChange = (navStyle) => ({
  type: 'ON_NAV_STYLE_CHANGE',
  navStyle,
});

export const onLayoutTypeChange = (layoutType) => ({
  type: 'ON_LAYOUT_TYPE_CHANGE',
  layoutType,
});

export const setI18nInited = (inited) => ({
  type: 'SET_I18N_INITED',
  inited,
});


export const updateWindowHeight = (height) => ({
  type: 'WINDOW_HEIGHT',
  height,
});
