import React, { useState } from 'react';

import classNames from 'classnames';
import { Input } from 'antd';

type Props = {
    label?: string;
    placeholder?: string;
    type?: string;
    value?: number | string;
    onChange: (value: any) => void;
    onFocus: () => void;
    inputError: any;
    min?: number;
    customClassName: string;
}

const CustomSeparatedInput = ({ 
    label,
    placeholder,
    type,
    value,
    onChange,
    onFocus,
    inputError,
    min,
    customClassName
}: Props) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <>
            <div className={classNames(`${customClassName} gx-flex-column`, {"is-filled": isFocused || value, "is-focused": isFocused, "is-error": !!inputError, "is-no-label": !label})}>
                { label ? <label className={`${customClassName}__label`}>{label}</label> : null }
                <Input
                    type={type || "text"}
                    value={value}
                    onChange={onChange}
                    onFocus={() => {
                        setIsFocused(true);
                        onFocus();
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    className={`${customClassName}__input`}
                    placeholder={placeholder}
                    min={min}/>
            </div>
        </>
    )
}

export { CustomSeparatedInput };