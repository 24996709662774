import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const OrderCreate = React.lazy(() => import('../components/OrderCreate'));

function OrderCreateView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <OrderCreate />
    </Suspense>
  );
}

export default OrderCreateView;
