import {
  internationalAction,
  internationalCalculationPreStage,
  internationalConfig,
  internationalInvoice,
  internationalSideCast,
  RESET_CURRENT_SHIPPING_TYPE,
  RESET_INTERNATIONAL_CALCULATION,
  RESET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  SET_CURRENT_SHIPPING_TYPE,
  SET_FROM_COUNTRY,
  SET_INTERNATIONAL_CALCULATION_PRE_STAGE,
  SET_INTERNATIONAL_CONFIG,
  SET_INTERNATIONAL_INVOICE,
  SET_INTERNATIONAL_RECEIVER_CAST,
  SET_INTERNATIONAL_SENDER_CAST,
  SET_TO_COUNTRY,
  ShippingType,
  UPDATE_INTERNATIONAL_RECEIVER_CAST,
  UPDATE_INTERNATIONAL_SENDER_CAST,
} from './types';

export interface InternationalState {
  currentShippingType: ShippingType,
  preStage: internationalCalculationPreStage,
  senderCast: null | internationalSideCast,
  receiverCast: null | internationalSideCast,
  invoice: null | internationalInvoice,
  fromCountry: null | string,
  toCountry: null | string,
  internationalConfig: null | internationalConfig,
}

const initialState: InternationalState = {
  currentShippingType: 'cargo_export',
  preStage: 'sender',
  receiverCast: null,
  senderCast: null,
  invoice: null,
  fromCountry: null,
  toCountry: null,
  internationalConfig: null,
};


export default function (state = initialState, action: internationalAction): InternationalState {
  switch (action.type) {
    case SET_CURRENT_SHIPPING_TYPE:
      return {
        ...state, currentShippingType: action.currentType,
      };
    case RESET_CURRENT_SHIPPING_TYPE:
      return { ...state, currentShippingType: initialState.currentShippingType };
    case SET_INTERNATIONAL_CALCULATION_PRE_STAGE:
      return { ...state, preStage: action.preStage };
    case RESET_INTERNATIONAL_CALCULATION_PRE_STAGE:
      return { ...state, preStage: initialState.preStage };
    case SET_INTERNATIONAL_SENDER_CAST:
      return { ...state, senderCast: action.cast };
    case UPDATE_INTERNATIONAL_SENDER_CAST:
      if (state.senderCast) {
        // @ts-ignore
        return { ...state, senderCast: { ...state.senderCast, cast: { ...state.senderCast.cast, ...action.cast } } };
      }
      return state;
    case SET_INTERNATIONAL_RECEIVER_CAST:
      return { ...state, receiverCast: action.cast };
    case UPDATE_INTERNATIONAL_RECEIVER_CAST:
      if (state.receiverCast) {
        // @ts-ignore
        return { ...state, receiverCast: { ...state.receiverCast, cast: { ...state.receiverCast.cast, ...action.cast } } };
      }
      return state;
    case SET_INTERNATIONAL_INVOICE:
      return { ...state, invoice: action.invoice };
    case RESET_INTERNATIONAL_CALCULATION:
      return {
        ...initialState, toCountry: state.toCountry, fromCountry: state.fromCountry, internationalConfig: state.internationalConfig, currentShippingType: state.currentShippingType,
      };
    case SET_FROM_COUNTRY:
      return { ...state, fromCountry: action.country };
    case SET_TO_COUNTRY:
      return { ...state, toCountry: action.country };
    case SET_INTERNATIONAL_CONFIG:
      return { ...state, internationalConfig: action.config };
    default: return state;
  }
}
