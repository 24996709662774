import React, { useCallback, useState } from 'react';
import Spin from 'antd/es/spin';
import { AutoComplete } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';


interface AutoCompleteItem {
  value: string,
  text: string,
  component?: React.ReactNode
}

export interface AutoCompleteProps {
  onChange: (val: SelectValue) => void,
  onSearch: (search: string) => Promise<Array<AutoCompleteItem | any>>,
  value?: any,
  pause?: number,
  onSelect?: (value: SelectValue) => void,
  [key: string]: any
}

function CustomAutoComplete({
  onSearch, onChange, value, pause = 800, onSelect, ...rest
}: AutoCompleteProps) {
  const { t, i18n } = useTranslation('components');
  const [results, setResults] = useState<Array<AutoCompleteItem>>([]);
  const [fetching, setFetching] = useState(false);
  const search = useCallback(
    (val: string) => {
      if (!fetching) {
        setFetching(true);
        setResults([]);
        onSearch(val).then(
          (res) => {
            setFetching(false);
            setResults(res);
          },
          () => setFetching(false),
        );
      }
    },
    [onSearch],
  );

  const handleSelect = (val: SelectValue) => {
    if (onSelect) onSelect(val);
  };

  const handleSearch = useCallback(
    debounce(search, pause),
    [search],
  );

  return (
    <AutoComplete
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin size="small" /> : t('not_found')}
      filterOption={false}
      value={value}
      defaultActiveFirstOption={false}
      {...rest}
    >
      {results.map((res) => <AutoComplete.Option value={res.value} key={res.value}>{res.component ? res.component : res.text}</AutoComplete.Option>)}
    </AutoComplete>
  );
}

export default CustomAutoComplete;
