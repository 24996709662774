import {
  Question,
  RESET_CURRENT_WAYBILL_QUESTIONS,
  reviewActions,
  SET_CURRENT_WAYBILL_QUESTIONS,
  SET_QUESTION_ANSWER,
} from './types';

export type ReviewState = {
  currentWaybillQuestions: Question[]
}

const initialState: ReviewState = {
  currentWaybillQuestions: [],
};

function setQuestionAnswer(questions: Question[], questionId: number, answer: string): Question[] {
  const tmp = [...questions];
  const index = questions.findIndex((_) => _.questionId === questionId);
  if (index > -1) {
    tmp[index].answer = answer;
  }
  return tmp;
}

export default function (state = initialState, action: reviewActions): ReviewState {
  switch (action.type) {
    case SET_CURRENT_WAYBILL_QUESTIONS:
      return { ...state, currentWaybillQuestions: action.questions };
    case RESET_CURRENT_WAYBILL_QUESTIONS:
      return { ...state, currentWaybillQuestions: initialState.currentWaybillQuestions };
    case SET_QUESTION_ANSWER:
      return { ...state, currentWaybillQuestions: setQuestionAnswer(state.currentWaybillQuestions, action.questionId, action.answer) };
    default:
      return state;
  }
}
