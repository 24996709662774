import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE_SEMI_DARK,
} from '../../constants/ThemeSetting';

export interface SettingsState {
  navCollapsed: boolean,
  navStyle: string,
  layoutType: string,
  themeType: string,
  colorSelection: string,
  pathname: string,
  width: number,
  height: number,
  isDirectionRTL: boolean,
  locale: {
    languageId: string,
    locale: string,
    name: string,
    icon: string,
  },
  mobile: boolean,
  i18nInited: boolean
}

const initialState: SettingsState = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  colorSelection: THEME_COLOR_SELECTION_PRESET,
  pathname: '',
  width: window.innerWidth,
  height: window.innerHeight,
  isDirectionRTL: false,
  locale: {
    languageId: 'russian',
    locale: 'ru',
    name: 'Russian',
    icon: 'ru',
  },
  mobile: window.innerWidth < 991,
  i18nInited: false,
};

export default function (state = initialState, action: any): SettingsState {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case 'TOGGLE_COLLAPSED_NAV':
      return { ...state, navCollapsed: action.navCollapsed };
    case 'WINDOW_WIDTH':
      return { ...state, width: action.width, mobile: window.innerWidth < 991 };
    case 'THEME_TYPE':
      return { ...state, themeType: action.themeType };
    case 'THEME_COLOR_SELECTION':
      return { ...state, colorSelection: action.colorSelection };
    case 'ON_NAV_STYLE_CHANGE':
      return { ...state, navStyle: action.navStyle };
    case 'ON_LAYOUT_TYPE_CHANGE':
      return { ...state, layoutType: action.layoutType };

    case 'SWITCH_LANGUAGE':
      return { ...state, locale: action.payload };
    case 'SET_I18N_INITED':
      return { ...state, i18nInited: action.inited };
    case 'WINDOW_HEIGHT':
      return { ...state, height: action.height, };
    default:
      return state;
  }
}
