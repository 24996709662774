import React, { useCallback } from 'react';
import { Card } from 'antd';
import { Col, Row } from 'antd/es/grid';
import Avatar from 'antd/es/avatar';
import { Link } from 'react-router-dom';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Badge from 'antd/es/badge';
import { User as UserType } from '../../app/user/types';
import useSelector from '../../util/hooks/useSelector';
import { signOut, switchAccount } from '../../app/user/action-creators';
import useDispatch from '../../util/hooks/useDispatch';
import { renderPhone } from '../../util/helpers';
import {useTranslation} from 'react-i18next';

const getUserAbr = (user: null | UserType) => {
  if (user) return `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`;
  return '';
};


function UserDropdown() {
  const { t, i18n } = useTranslation('menu');
  const dispatch = useDispatch();
  const { user, userAccountList } = useSelector((state) => state.user);

  const handleSignOut = useCallback(
    () => {
      dispatch(signOut());
    },
    [dispatch],
  );

  const handleSwitch = useCallback(
    (accountId) => {
      dispatch(switchAccount(accountId));
    },
    [dispatch],
  );


  return (
    <Card className="gx-card gx-mb-0" style={{ width: 300 }}>
      <Row>
        <Col xs={24} className="gx-d-flex gx-justify-content-center gx-mb-3">
          <Avatar size="large" className="pls-avatar pls-avatar-lg" src={user?.avatar} alt={getUserAbr(user)}>
            {getUserAbr(user)}
          </Avatar>
        </Col>
        <Col xs={24} className="gx-d-flex gx-justify-content-center">
          {user?.firstName} {user?.lastName}
        </Col>
        <Col xs={24} className="gx-d-flex gx-justify-content-center gx-text-muted gx-mb-3">
          {renderPhone(user?.phone || '')}
        </Col>
        <Col xs={24} className="gx-d-flex gx-justify-content-center">
          <Link to="/settings">
            <Button type="ghost">
              {t('settings')}
            </Button>
          </Link>
        </Col>
        {userAccountList.length > 1 && (
          <>
            <Col xs={24}>
              <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
                {t('my_teams')}
                {/*<Button size="small" type="link" className="gx-mb-0" onClick={onNewTeam}>Добавить компанию</Button>*/}
              </div>
              <Divider />
            </Col>
              {userAccountList.map((account) => (
                account.accountId === user?.accountId
                  ? (
                    <Col xs={24}>
                      <Badge status="warning" className="gx-m-0" />
                      {account.title}
                      <Divider />
                    </Col>
                  )
                  : (
                    <Col xs={24} className="gx-pointer" onClick={() => handleSwitch(account.accountId)}>
                      {account.title}
                      <Divider />
                    </Col>
                  )
              ))}
          </>
        )}

        <Col xs={24} className="gx-d-flex gx-justify-content-center">
          <span onClick={handleSignOut} className="gx-pointer">
            {t('logout')}
          </span>
        </Col>
      </Row>
    </Card>
  );
}

export default UserDropdown;
