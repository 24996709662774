import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { AppContainer } from 'react-hot-loader';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Helmet } from 'react-helmet';
import { unregister } from './registerServiceWorker';
import App from './App';
import httpService from './util/httpService';
import configureStore from './store';
import { setToken } from './app/user/action-creators';
import getDomainConfig from './domain-config';
import { history } from './reducers';
import { initAmplitude } from './util/amplitudeUtils';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://f97babd9402245b789f536dc58e00940@sentry.belkapost.ru/40',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      let isNonErrorException = false;
      /* tslint:disable:no-string-literal only-arrow-functions */
      try {
        isNonErrorException = (event.exception?.values ? event.exception?.values[0]?.value?.startsWith('Non-Error promise rejection captured') : false)
          || hint.originalException?.message?.startsWith('Non-Error promise rejection captured');
        // eslint-disable-next-line no-empty
      } catch (_) {}
      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }
      return event;
    },
  });
}

initAmplitude();

const store = configureStore({}, httpService);

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
let expiredAt = localStorage.getItem('tokenExpire');
if (expiredAt) expiredAt = parseInt(expiredAt, 10);

store.dispatch(setToken(accessToken, refreshToken, expiredAt, false));

const render = (Component) => {
  const domainConfig = getDomainConfig();

  // Хак что бы все не рассыпалось если юзер использует перевод страницы
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }

  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <>
        <Helmet>
          <title>{domainConfig.title}</title>
        </Helmet>
        <Component store={store} />
      </>
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
unregister();

// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
