import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import BaseRouter from '../routing/BaseRouter';
import { getCurrentUserData } from '../app/user/action-creators';
import CircularProgress from '../components_base/CircularProgress';
import { loadCountriesList, loadCurrencyList, loadOperators } from '../app/reference/action-creators';
import { updateWindowHeight, updateWindowWidth } from '../app/settings/action-creators';
import useSelector from '../util/hooks/useSelector';
import { sendAmplitudeData } from '../util/amplitudeUtils';
import { getDialogUnreadCount } from '../app/chat/action-creators';
import { FallbackView } from '../components_base/FallbackView';

function Base(props) {
  const dispatch = useDispatch();
  const [userInited, setUserInited] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCurrentUserData()).finally(() => {
      setUserInited(true);
      sendAmplitudeData('TEST_EVENT');
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadOperators());
    dispatch(loadCountriesList());
    dispatch(loadCurrencyList());
    dispatch(getDialogUnreadCount())
  }, [user]);


  const onWindowWidthUpdate = () => {
    dispatch(updateWindowWidth(window.innerWidth));
    dispatch(updateWindowHeight(window.innerHeight));
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowWidthUpdate);
    return () => {
      window.removeEventListener('resize', onWindowWidthUpdate);
    };
  }, []);


  const { location } = props;
  return (
    <>
      <ErrorBoundary onReset={() => {}} fallback={<FallbackView/>}>
        {
          userInited
            ? <BaseRouter location={location} />
            : <CircularProgress />
        }
      </ErrorBoundary>
      <NotificationContainer />
    </>
  );
}

export default Base;
