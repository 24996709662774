import React, { memo, useMemo } from 'react';
import Alert from 'antd/es/alert';
import { t } from '@lingui/macro';

import useSelector from '../../../../util/hooks/useSelector';
import UrgentNotificationsList from '../UrgentNotificationsList';
import { ACCOUNT_STATUS_BLOCKED } from '../../contants';
import GlobalNotification from '../GlobalNotification';

const NotificationsList = () => {

  const decodedToken = useSelector((state) => state.user.decodedToken);

  const accountBanned = useMemo(() => {
    if (decodedToken) {
      return decodedToken.account_status === ACCOUNT_STATUS_BLOCKED;
    }
    return false;
  }, [decodedToken]);


  return (
    <>
      {accountBanned && (
        <Alert
          message={t`Ваш аккаунт временно заблокирован. Для разблокировки свяжитесь с вашим менеджером`}
          banner
          className="gx-mb-0"
          type="error"
        />
      )}
      <GlobalNotification />
      <UrgentNotificationsList />
    </>
  );
};

export default memo(NotificationsList);
