import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const ContractFill = React.lazy(() => import('../components/ContractFill'));
function ContractFillView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ContractFill />
    </Suspense>
  );
}

export default ContractFillView;
