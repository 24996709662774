export const SET_NOTICE_LIST = 'SET_NOTICE_LIST';
export const SET_NOTICE_COUNT = 'SET_NOTICE_COUNT';
export const SET_CARRIER_INFO_COUNT = 'SET_CARRIER_INFO_COUNT';
export const SET_BILL_COUNT = 'SET_BILL_COUNT';
export const SET_CARGO_STATUS_COUNT = 'SET_CARGO_STATUS_COUNT';
export const REMOVE_NOTICE = 'REMOVE_NOTICE';
export const ADD_NOTICE = 'ADD_NOTICE';
export const ADD_NOTICE_LIST = 'ADD_NOTICE_LIST';
export const SET_NOTICE_READ = 'SET_NOTICE_READ';
export const SET_ALL_NOTICE_READ = 'SET_ALL_NOTICE_READ';
export const SET_NOT_VIEWED_NOTICE_COUNT = 'SET_NOT_VIEWED_NOTICE_COUNT';
export const SET_NOTIFICATIONS_OPEN = 'SET_NOTIFICATIONS_OPEN';
export const UPDATE_NOTICE_LIST = 'UPDATE_NOTICE_LIST';
export const SET_FCM_TOKEN = 'SET_FCM_TOKEN';
export const SET_URGENT_NOTIFICATIONS = 'SET_URGENT_NOTIFICATIONS';
export const SET_GLOBAL_NOTIFICATION = 'SET_GLOBAL_NOTIFICATION';
export const SET_CURRENT_NOTICE_TYPE = 'SET_CURRENT_NOTICE_TYPE';

type BaseNotice = {
  noticeId: string,
  content?: string,
  isNew: boolean,
  isViewed: boolean,
  date: string,
  url?: string
};

export type GeneticNotice = BaseNotice & {
  type: 'WAYBILL_CHAT_MESSAGE' | 'WAYBILL_DELIVERED' | 'NEW_BILL',
  context: {
    [key: string]: any
  }
};

export type Notice = GeneticNotice;

export enum UrgentNotificationUrgency {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export enum NotificationTypes {
  ALL = "ALL",
  CARRIER_INFO = "CARRIER_INFO",
  CARGO_STATUS = "CARGO_STATUS",
  BILL = "BILL"
}

export type NotificationType =
  | NotificationTypes.ALL
  | NotificationTypes.CARRIER_INFO
  | NotificationTypes.CARGO_STATUS
  | NotificationTypes.BILL;

export type UrgentNotification = {
  urgency: UrgentNotificationUrgency,
  content: string,
  notification_type: string,
  hiddable: boolean,
  notification_id: string,
};

export interface SetNoticeList {
  type: typeof SET_NOTICE_LIST,
  list: Array<Notice>
}

export interface SetNoticeCount {
  type: typeof SET_NOTICE_COUNT,
  count: number
}

export interface SetCurrentNoticeType {
  type: typeof SET_CURRENT_NOTICE_TYPE,
  payload: NotificationType
}

export interface SetCarrierInfoCount {
  type: typeof SET_CARRIER_INFO_COUNT,
  count: number
}

export interface SetBillCount {
  type: typeof SET_BILL_COUNT,
  count: number
}

export interface SetCargoStatusCount {
  type: typeof SET_CARGO_STATUS_COUNT,
  count: number
}

export interface RemoveNotice {
  type: typeof REMOVE_NOTICE,
  noticeId: string
}

export interface AddNotice {
  type: typeof ADD_NOTICE,
  notice: Notice
}

export interface AddNoticeList {
  type: typeof ADD_NOTICE_LIST,
  noticeList: Array<Notice>
}

export interface SetNotceRead {
  type: typeof SET_NOTICE_READ,
  noticeId: string
}

export interface SetAllNoticeRead {
  type: typeof SET_ALL_NOTICE_READ
}

export interface SetNotViewedNoticeCount {
  type: typeof SET_NOT_VIEWED_NOTICE_COUNT,
  count: number,
}

export interface SetNotificationsOpen {
  type: typeof SET_NOTIFICATIONS_OPEN,
  open: boolean,
}

export interface UpdateNoticeList {
  type: typeof UPDATE_NOTICE_LIST,
  list: Array<Notice>
}

export interface SetFCMToken {
  type: typeof SET_FCM_TOKEN,
  token: string | null,
}

export interface SetUrgentNotifications {
  type: typeof SET_URGENT_NOTIFICATIONS,
  notifications: UrgentNotification[],
}

export interface SetGlobalNotification {
  type: typeof SET_GLOBAL_NOTIFICATION,
  notification: string,
  closedNotification?: string,
}

export type NotificationActions =
  SetNoticeList
  | SetNoticeCount
  | RemoveNotice
  | AddNotice
  | AddNoticeList
  | SetNotceRead
  | SetAllNoticeRead
  | SetNotViewedNoticeCount
  | SetNotificationsOpen
  | UpdateNoticeList
  | SetFCMToken
  | SetUrgentNotifications
  | SetGlobalNotification
  | SetCarrierInfoCount
  | SetBillCount
  | SetCargoStatusCount
  | SetCurrentNoticeType;
