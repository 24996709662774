import { Moment } from 'moment';
import {
  AdditionalServiceDescription,
  Country,
  Currency,
  Operator,
  SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  SET_COUNTRIES_LIST,
  SET_CURRENCY_LIST,
  SET_OPERATORS,
  SetAdditionalServiceDescList,
  SetCountriesList,
  SetCurrencyList,
  SetOperators,
} from './types';
import { ThunkAction } from '../../util/types';
import { SERVER_DATE_FORMAT } from '../../util/constants';

export const setOperators = (operators: Array<Operator>): SetOperators => ({
  type: SET_OPERATORS,
  operators,
});

export const setCountriesList = (countries: Array<Country>): SetCountriesList => ({
  type: SET_COUNTRIES_LIST,
  countries,
});

export const setCurrencyList = (list: Array<Currency>): SetCurrencyList => ({
  type: SET_CURRENCY_LIST,
  list,
});

export const setAdditionalServiceDescList = (payload: Array<AdditionalServiceDescription>): SetAdditionalServiceDescList => ({
  type: SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  payload,
});

export const loadOperators = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/reference/operator/').then(
  (response: {deliveryServices: Array<Operator> }) => {
    dispatch(setOperators(response.deliveryServices));
    return Promise.resolve(response);
  },
);


export const loadCalendar = (dateFrom: Moment, dateTo: Moment): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/reference/calendar/', { date_from: dateFrom.format(SERVER_DATE_FORMAT), date_to: dateTo.format(SERVER_DATE_FORMAT) });

export const loadCountriesList = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/geo/country/').then(
  ({ countries }: { countries: Array<Country> }) => {
    dispatch(setCountriesList(countries));
    return Promise.resolve({ countries });
  },
);


export const loadCurrencyList = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/geo/currency/').then(
  ({ currencyList }: { currencyList: Array<Currency> }) => {
    dispatch(setCurrencyList(currencyList));
    return Promise.resolve({ currencyList });
  },
);


type tnvedResponseItem = {
  code: string,
  title: string
};

export const getTnvedCodes = (page = 1, query = '', limit = 10): ThunkAction<Promise<{codes: Array<tnvedResponseItem>}>> => (dispatch, getState, http) => http.get('/api/v1/reference/tnved/', { page, limit, query });
