import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { InterPackage } from "../../types";
import { CustomSeparatedTextArea } from "../../../../components/CustomSeparatedTextArea";
import { COMMENT_CHARACTERS_LIMIT } from "../../../../util/constants";
import { CargoInvoiceTnvedSelect } from "./CargoInvoiceTnvedSelect";
import { CargoCountrySelect } from "./CargoCountrySelect";
import { CustomSeparatedInput } from "../../../../components/CustomSeparatedInput";

type InterCargoPackageError = {
    description?: [] | string,
    descriptionInter?: [] | string,
    tnved?: [] | string,
    country?: [] | string,
    quantity?: [] | string,
    price?: [] | string,
    currency?: [] | string,
    width?: [] | string,
    height?: [] | string,
    length?: [] | string,
    weight?: [] | string,
    amount?: [] | string
}  

type Props = {
    id: number,
    data: InterPackage,
    onChange: (data: InterPackage, packageId: number) => any,
    errors: InterCargoPackageError | null,
}

const CargoInvoiceItem = ({
    id,
    data,
    onChange,
    errors,
}: Props) => {

    const [curErrors, setCurErrors] = useState(errors);

    const handleChange = useCallback(
        (e: SyntheticEvent | string, field: string, onlyDigits = false) => {
            const floatRe = /\d+[.,]?(\d+)?/g;
            const intRe = /\d+/g;
            let newData = { ...data };
            let value;
            if (typeof e === 'string') {
                value = e;
            } else {
                // @ts-ignore
                value = e.target.value;
            }
            if (onlyDigits) {
                if (field === 'price') value = value.match(floatRe)?.join('');
                else value = value.match(intRe)?.join('');
            }
            newData = { ...newData, [field]: onlyDigits ? value?.replaceAll(",", ".") : value };
            onChange(newData, id);
            if (curErrors && Object.prototype.hasOwnProperty.call(curErrors, field)) {
                const newErrors = { ...curErrors, [field]: undefined };
                setCurErrors(newErrors);
            }
        },
        [data, id, onChange, errors],
    );

    const handleFocus = useCallback(
        (field: string) => {
            if (!!curErrors && Object.prototype.hasOwnProperty.call(curErrors, field)) {
                setCurErrors({ ...curErrors, [field]: undefined });
            }
        },
        [curErrors],
    );

    useEffect(() => {
        setCurErrors(errors);
    }, [errors]);

    const placeDescription = useMemo(() => {
        return `${data.length} x ${data.width} x ${data.height} см ${data.weight} кг`
    }, [data])
    
    return (
        <>
            <div className="gx-d-flex gx-flex-column">
                <div className="custom-calc-invoice__sub-title gx-mb-1">Грузовое место {id + 1}</div>
                <div className="custom-calc-invoice__text gx-mb-2">{placeDescription}</div>
            </div>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="custom-calc-invoice-group-half">
                    <CustomSeparatedTextArea
                        label="Описание англ"
                        value={data.descriptionInter}
                        onChange={(e) => handleChange(e, 'descriptionInter')}
                        onFocus={() => handleFocus('descriptionInter')}
                        maxLength={COMMENT_CHARACTERS_LIMIT}
                        inputError={curErrors?.descriptionInter}
                        customClassName="custom-cargo-invoice-textarea"
                        />
                </div>
                <div className="custom-calc-invoice-group-half">
                    <div className="gx-d-flex gx-flex-column gx-w-100 gx-justify-content-between">
                        <CustomSeparatedInput
                            label="Количество"
                            value={data.quantity}
                            onChange={(e) => handleChange(e, 'quantity', true)}
                            onFocus={() => handleFocus('quantity')}
                            inputError={curErrors?.quantity}
                            customClassName="custom-cargo-invoice-input" />
                        <CustomSeparatedInput
                            label="Цена за единицу"
                            value={data.price}
                            onChange={(e) => handleChange(e, 'price', true)}
                            onFocus={() => handleFocus('price')}
                            inputError={curErrors?.price}
                            customClassName="custom-cargo-invoice-input" />

                    </div>
                </div>
            </div>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-4">
                <div className="custom-calc-invoice-group-half">
                    <CargoInvoiceTnvedSelect
                        value={data.tnved}
                        errors={curErrors?.tnved}
                        onChange={(e) => handleChange(e, 'tnved')}
                        onFocus={() => handleFocus('tnved')} />
                </div>
                <div className="custom-calc-invoice-group-half">
                    <CargoCountrySelect
                        value={data.country}
                        errors={curErrors?.country}
                        onChange={(e) => handleChange(e, 'country')}
                        onFocus={() => handleFocus('country')} />
                </div>
            </div>
        </>
    )
}

export { CargoInvoiceItem };