import { ThunkAction } from '../../util/types';
import { adaptFromApi } from '../../util/adapter';
import {
  dadataBank, DadataCompany, GeoSuggest, GlobalNotificationUpdateMessage, WSUpdateMessage,
} from './types';
import { waybillUpdate } from '../shipping/action-creators';
import { setGlobalNotification } from '../notifications/action-creators';

export const dadataBankAutocomplete = (query: string): ThunkAction<Promise<{ suggestions: Array<dadataBank> }>> => (dispatch, getState, http) => http.post('/api/v1/external/suggestions/api/4_1/rs/suggest/bank', {
  query,
  count: 10,
}).then(
  (response: any) => {
    let data = response;
    try {
      data = adaptFromApi(JSON.parse(data));
    } catch (e) {
    }
    return Promise.resolve(data);
  },
);

export const geoSuggest = (query: string, iso?: string, isCity?: boolean): ThunkAction<Promise<{ suggestions: Array<GeoSuggest> }>> => (dispatch, getState, http) => http.get(
  '/api/v1/geo/suggest/', { query, iso: iso || 'RU', is_city: Number(!!isCity) },
);

export const datadaInnAutocomplete = (query: string): ThunkAction<Promise<{ suggestions: Array<DadataCompany> }>> => (dispatch, getState, http) => http.post(
  '/api/v1/external/suggestions/api/4_1/rs/suggest/party', { query, count: 10 },
).then(
  (response: any) => {
    let data = response;
    try {
      data = JSON.parse(data);
    } catch (e) {
    }
    return Promise.resolve(data);
  },
);

export enum MorphCase {
  NOMN = 'nomn',
  GENT = 'gent',
  DATV = 'datv',
  ACCS = 'accs',
  ABLT = 'ablt',
  LOCT = 'loct',
  VOCT = 'voct',
  GEN2 = 'gen2',
  ACC2 = 'acc2',
  LOC2 = 'loc2'
}

export const morphInflect = (text: string, _case:MorphCase): ThunkAction<Promise<string>> => (dispatch, getState, http) => http.post(
  '/api/v1/external/morph/inflect/',
  { text, case: _case },
).then(
  (result: string) => Promise.resolve(result),
  () => Promise.resolve(text),
);

export const acceptUpdateWS = (message: WSUpdateMessage): ThunkAction<void> => (dispatch, getState) => {
  switch (message.event) {
    case 'waybill_update':
      dispatch(waybillUpdate(message.pls_waybill_number));
      break;
    default:
      break;
  }
};

export const acceptGlobalUpdateWS = (message: GlobalNotificationUpdateMessage): ThunkAction<void> => (dispatch, getState) => {
  switch (message.event) {
    case 'global_notification_update':
      dispatch(setGlobalNotification(message.data.text));
      break;
    default:
      break;
  }
};
