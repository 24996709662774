import moment from 'moment';
import {UploadFile} from 'antd/es/upload/interface';
import {
  ADD_DIALOG_MESSAGE,
  ADD_DIALOG_MESSAGES,
  ADD_UPLOAD_FILE,
  ChatReducerAction,
  Dialog,
  DialogListItem,
  Message,
  REMOVE_UPLOAD_FILE,
  REPLACE_DIALOG,
  REPLACE_DIALOG_MESSAGE,
  RESET_UPLOAD_FILES,
  SET_CURRENT_DIALOG,
  SET_DIALOG_COUNT,
  SET_DIALOG_LIST,
  SET_DIALOG_MESSAGES,
  SET_DIALOG_MESSAGES_COUNT,
  SET_DIALOG_UNREAD_COUNT,
} from './types';
import {replaceByFieldValue} from "../../util/array/replace";

export interface ChatState {
  currentDialog: Dialog | null,
  dialogMessages: Message[],
  dialogMessagesCount: number,
  uploadFiles: Array<UploadFile>,
  dialogList: DialogListItem[],
  dialogCount: number,
  dialogUnreadCount: number,
}

const initialState: ChatState = {
  currentDialog: null,
  dialogMessages: [],
  dialogMessagesCount: 0,
  uploadFiles: [],
  dialogList: [],
  dialogCount: 0,
  dialogUnreadCount: 0
};

const sortMessagesByDate = (list: Message[]) => list.sort((a, b) => {
  const newA = moment(a.createdAt);
  const newB = moment(b.createdAt);
  if (newA.isBefore(newB)) return -1;
  if (newA.isAfter(newB)) return 1;
  return 0;
}).reverse();

const removeFile = (files: Array<UploadFile>, file: UploadFile) => {
  const index = files.indexOf(file);
  const newFileList = files.slice();
  newFileList.splice(index, 1);
  return newFileList;
};

const replaceMessage = (messages: Array<Message>, message: Message, messageId: string) => {
  const index = messages.findIndex((_) => _.messageId === messageId);
  const newMessagesList = messages.slice();
  newMessagesList[index] = message;
  return newMessagesList;
};

const filterUniqueMessage = (arr: Array<Message>) => {
  const uniqueArr: Array<Message> = [];
  arr.forEach((item) => {
    if (!uniqueArr.find((_) => _.messageId === item.messageId)) uniqueArr.push(item);
  });
  return uniqueArr;
};

export default function (state = initialState, action: ChatReducerAction): ChatState {
  switch (action.type) {
    case SET_CURRENT_DIALOG:
      return { ...state, currentDialog: action.dialog };
    case SET_DIALOG_MESSAGES:
      return { ...state, dialogMessages: filterUniqueMessage(action.messages) };
    case ADD_DIALOG_MESSAGES:
      return { ...state, dialogMessages: filterUniqueMessage(sortMessagesByDate([...state.dialogMessages, ...action.messages])) };
    case ADD_DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessages: filterUniqueMessage(sortMessagesByDate([...state.dialogMessages, action.message])),
        dialogMessagesCount: state.dialogMessagesCount + 1,
      };
    case ADD_UPLOAD_FILE:
      return { ...state, uploadFiles: [...state.uploadFiles, action.file] };
    case RESET_UPLOAD_FILES:
      return { ...state, uploadFiles: initialState.uploadFiles };
    case REMOVE_UPLOAD_FILE:
      return { ...state, uploadFiles: removeFile(state.uploadFiles, action.file) };
    case REPLACE_DIALOG_MESSAGE:
      return { ...state, dialogMessages: filterUniqueMessage(replaceMessage(state.dialogMessages, action.message, action.messageId)) };
    case SET_DIALOG_MESSAGES_COUNT:
      return { ...state, dialogMessagesCount: action.count };
    case SET_DIALOG_LIST:
      return { ...state, dialogList: action.list };
    case SET_DIALOG_COUNT:
      return { ...state, dialogCount: action.count };
    case SET_DIALOG_UNREAD_COUNT:
      return { ...state, dialogUnreadCount: action.count };
    case REPLACE_DIALOG:
      return { ...state, dialogList: replaceByFieldValue(state.dialogList, 'dialogId', action.dialog) };
    default:
      return state;
  }
}
