import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const Notifications = React.lazy(() => import('../components/Notifications'));
function NotificationsView(params: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Notifications {...params}/>
    </React.Suspense>
  );
}

export default NotificationsView;