import {UploadFile} from 'antd/es/upload/interface';
import {
  ContactImportResult,
  CurrentImportState,
  RESET_CURRENT_IMPORT,
  ResetCurrentImport,
  SET_CURRENT_IMPORT_ID,
  SET_CURRENT_IMPORT_ITEMS,
  SET_CURRENT_IMPORT_STATE,
  SetCurrentImportId,
  SetCurrentImportItems,
  SetCurrentImportState,
} from './types';
import {ThunkAction} from '../../util/types';
import blobToBase64 from '../../util/blobToBase64';
import {RcFile} from "antd/lib/upload/interface";

export const setCurrentImportState = (state: CurrentImportState): SetCurrentImportState => ({
  type: SET_CURRENT_IMPORT_STATE,
  state,
});

export const setCurrentImportId = (importId: string): SetCurrentImportId => ({
  type: SET_CURRENT_IMPORT_ID,
  importId,
});

export const resetCurrentImport = (): ResetCurrentImport => ({
  type: RESET_CURRENT_IMPORT,
});

export const setCurrentImportItems = (items: ContactImportResult[]): SetCurrentImportItems => ({
  type: SET_CURRENT_IMPORT_ITEMS,
  items,
});

export const startImport = (rawData: anyObject[], file: RcFile | null): ThunkAction => async (dispatch, getState, http) => {
  const data: anyObject = {
    raw_data: rawData,
  };

  console.log(`rawData ${rawData}`);

  if (file) {
    data.file = await blobToBase64(file);
    console.log(`data.file ${data.file}`);
  }

  return http.post(
    '/api/v1/contact/import/start/', data,
  ).then(
    (response: { importId: string }) => {
      dispatch(setCurrentImportState(CurrentImportState.RUNNING));
      dispatch(setCurrentImportId(response.importId));
      return Promise.resolve(response);
    },
  );
};

export const loadCurrentImportItems = (importId: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/contact/import/${importId}/result/`,
).then(
  (response: { results: ContactImportResult[], status: 'new' | 'done' }) => {
    dispatch(setCurrentImportItems(response.results));
    return Promise.resolve(response);
  },
);

export const handleImportWS = (data: anyObject): ThunkAction<void> => (dispatch, getState) => {
  const { currentImportId } = getState().contacts_import;
  if (currentImportId && data.importId === currentImportId) {
    if (data.command === 'done') {
      dispatch(setCurrentImportState(CurrentImportState.DONE));
      dispatch(loadCurrentImportItems(currentImportId));
    }
  }
};

export const uploadImportFile = (file: RcFile | null): ThunkAction => (dispatch, getState, http) => {
  const req = new FormData();
  // @ts-ignore
  req.append('file', file);
  return http.post(
    '/api/v1/contact/import/start/',
    req, { json: false },
  ).then(
    (response: { importId: string }) => {
      dispatch(setCurrentImportState(CurrentImportState.RUNNING));
      dispatch(setCurrentImportId(response.importId));
      return Promise.resolve(response);
    },
  );
};
