// Пикер с поддержкой мультиязычности, почему-то конфиг момента в rc-picker не хочет подхватывать локаль
import moment, { Moment } from 'moment';
import generatePicker, {
  PickerProps,
  PickerDateProps,
  RangePickerProps as BaseRangePickerProps,
} from 'antd/es/date-picker/generatePicker';
import { noteOnce } from 'rc-util/es/warning';

const customMomentGenerateConfig = {
  // get
  getNow: () => moment(),
  getFixedDate: (string: string) => moment(string, 'YYYY-MM-DD'),
  getEndDate: (date: Moment) => {
    const clone = date.clone();
    return clone.endOf('month');
  },
  getWeekDay: (date: Moment) => {
    const clone = date.clone().locale('en_US');
    return clone.weekday() + clone.localeData().firstDayOfWeek();
  },
  getYear: (date: Moment) => date.year(),
  getMonth: (date: Moment) => date.month(),
  getDate: (date: Moment) => date.date(),
  getHour: (date: Moment) => date.hour(),
  getMinute: (date: Moment) => date.minute(),
  getSecond: (date: Moment) => date.second(),

  // set
  addYear: (date: Moment, diff: number) => {
    const clone = date.clone();
    return clone.add(diff, 'year');
  },
  addMonth: (date: Moment, diff: number) => {
    const clone = date.clone();
    return clone.add(diff, 'month');
  },
  addDate: (date: Moment, diff: number) => {
    const clone = date.clone();
    return clone.add(diff, 'day');
  },
  setYear: (date: Moment, year: number) => {
    const clone = date.clone();
    return clone.year(year);
  },
  setMonth: (date: Moment, month: number) => {
    const clone = date.clone();
    return clone.month(month);
  },
  setDate: (date: Moment, num: number) => {
    const clone = date.clone();
    return clone.date(num);
  },
  setHour: (date: Moment, hour: number) => {
    const clone = date.clone();
    return clone.hour(hour);
  },
  setMinute: (date: Moment, minute: number) => {
    const clone = date.clone();
    return clone.minute(minute);
  },
  setSecond: (date: Moment, second: number) => {
    const clone = date.clone();
    return clone.second(second);
  },

  // Compare
  isAfter: (date1: Moment, date2: Moment) => date1.isAfter(date2),
  isValidate: (date: Moment) => date.isValid(),

  locale: {
    getWeekFirstDay: (locale: string) => {
      const date = moment().locale(locale);
      return date.localeData().firstDayOfWeek();
    },
    getWeekFirstDate: (locale: string, date: Moment) => {
      const clone = date.clone();
      const result = clone.locale(locale);
      return result.weekday(0);
    },
    getWeek: (locale: string, date: Moment) => {
      const clone = date.clone();
      const result = clone.locale(locale);
      return result.week();
    },
    getShortWeekDays: (locale: string) => {
      const date = moment().locale(locale);
      return date.localeData().weekdaysMin();
    },
    getShortMonths: (locale: string) => {
      const date = moment().locale(locale);
      return date.localeData().monthsShort();
    },
    format: (locale: string, date: Moment, format: string) => {
      const clone = date.clone();
      const result = clone.locale(locale);
      return result.format(format);
    },
    parse: (locale: string, text: string, formats: string[]) => {
      const fallbackFormatList: string[] = [];

      for (let i = 0; i < formats.length; i += 1) {
        let format = formats[i];
        let formatText = text;

        if (format.includes('wo') || format.includes('Wo')) {
          format = format.replace(/wo/g, 'w').replace(/Wo/g, 'W');
          const matchFormat = format.match(/[-YyMmDdHhSsWwGg]+/g);
          const matchText = formatText.match(/[-\d]+/g);

          if (matchFormat && matchText) {
            format = matchFormat.join('');
            formatText = matchText.join('');
          } else {
            fallbackFormatList.push(format.replace(/o/g, ''));
          }
        }

        const date = moment(formatText, format, locale, true);
        if (date.isValid()) {
          return date;
        }
      }

      // Fallback to fuzzy matching, this should always not reach match or need fire a issue
      for (let i = 0; i < fallbackFormatList.length; i += 1) {
        const date = moment(text, fallbackFormatList[i], locale, false);

        /* istanbul ignore next */
        if (date.isValid()) {
          noteOnce(
            false,
            'Not match any format strictly and fallback to fuzzy match. Please help to fire a issue about this.',
          );
          return date;
        }
      }

      return null;
    },
  },
};

export type DatePickerProps = PickerProps<Moment>;
export type MonthPickerProps = Omit<PickerDateProps<Moment>, 'picker'>;
export type WeekPickerProps = Omit<PickerDateProps<Moment>, 'picker'>;
export type RangePickerProps = BaseRangePickerProps<Moment>;

const DatePicker = generatePicker<Moment>(customMomentGenerateConfig);

export default DatePicker;
