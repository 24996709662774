import { ContactCast } from "../cast/types";

export const SET_WAYBILL_TRACKING_LIST = 'SET_WAYBILL_TRACKING_LIST';
export const ADD_WAYBILL_TRACKING = 'ADD_WAYBILL_TRACKING';
export const ADD_WAYBILL_TRACKING_LIST = 'ADD_WAYBILL_TRACKING_LIST';
export const SET_WAYBILL_TRACKING_COUNT = 'SET_WAYBILL_TRACKING_COUNT';

const WaybillTrackingStatus = ['registered', 'processed', 'performed', 'canceled'];

export enum TrackingType {
  TRACKING = 'TRACKING',
  HISTORY = 'HISTORY'
}

export type WaybillTrackingItem = {
  plsStatus: typeof WaybillTrackingStatus[number],
  description: string,
  date: string,
  dataType: TrackingType.TRACKING,
};

export enum WaybillHistoryItemSide {
  SENDER = 'SENDER',
  RECEIVER = "RECEIVER"
}

export type WaybillHistoryItem = {
  side: WaybillHistoryItemSide,
  date: string,
  data: Partial<ContactCast>,
  dataType: TrackingType.HISTORY
};

export type WaybillTracking = WaybillTrackingItem | WaybillHistoryItem;

export interface SetWaybillTrackingList {
  type: typeof SET_WAYBILL_TRACKING_LIST,
  trackingList: Array<WaybillTracking>
}

export interface AddWaybillTracking {
  type: typeof ADD_WAYBILL_TRACKING,
  tracking: WaybillTracking
}

export interface AddWaybillTrackingList {
  type: typeof ADD_WAYBILL_TRACKING_LIST,
  trackingList: Array<WaybillTracking>
}

export interface SetWaybillTrackingCount {
  type: typeof SET_WAYBILL_TRACKING_COUNT,
  count: number
}

export type TrackingActions =
  SetWaybillTrackingList
  | AddWaybillTracking
  | AddWaybillTrackingList
  | SetWaybillTrackingCount;
