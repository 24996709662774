import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { Action, combineReducers } from 'redux';
import settings from './app/settings/reducer';
import contact from './app/contact/reducer';
import user from './app/user/reducer';
import calculation from './app/calculation/reducer';
import cargo from './app/cargo/reducer';
import reference from './app/reference/reducer';
import shipping from './app/shipping/reducer';
import creation from './app/creation/reducer';
import chat from './app/chat/reducer';
import tracking from './app/tracking/reducer';
import invoice from './app/invoice/reducer';
import registry from './app/registry/reducer';
import terminal from './app/terminal/reducer';
import international from './app/international/reducer';
import { DESTROY_SESSION } from './util/types';
import notifications from './app/notifications/reducer';
import contacts_import from './app/contacts_import/reducer';
import counterparty_import from './app/counterparty_import/reducer';
import cart from './app/cart/reducer';
import calculation_new from './app/calculation_new/reducer';
// eslint-disable-next-line camelcase
import online_store from './app/online_store/reducer';
import review from './app/review/reducer';
import marketing from './app/marketing/reducer';
import alert from './app/alert/reducer';
import billing from './app/billing/reducer';
import { MODULE_NAME } from './app/calculation_new/constant';

const history = createBrowserHistory();

const appReducer = combineReducers({
  settings,
  contact,
  user,
  calculation,
  cargo,
  reference,
  shipping,
  creation,
  chat,
  tracking,
  invoice,
  registry,
  terminal,
  international,
  notifications,
  online_store,
  review,
  marketing,
  alert,
  billing,
  contacts_import,
  counterparty_import,
  cart,
  [MODULE_NAME]: calculation_new,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (state: RootState | undefined, action: Action): RootState => {
  if (action.type === DESTROY_SESSION) {
    // @ts-ignore
    return appReducer({ reference: state?.reference }, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
export { history };
