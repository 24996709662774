type anyObject = {
  [ket: string]: any
}
type removeByFieldType = (list: Array<anyObject>, key: string, value: any) => Array<anyObject>;


const removeByFieldValue: removeByFieldType = (list, key, value) => {
  const newList = list.slice();
  const removeIndex = newList.findIndex((_) => _[key] === value);
  if (removeIndex > -1) newList.splice(removeIndex, 1);
  return newList;
};

export default removeByFieldValue;
