import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const FastCalc = React.lazy(() => import('../components/FastCalc'));

function FastCalcView(props: any) {
  return (
    <Suspense fallback={CircularProgress}>
      <FastCalc {...props}/>
    </Suspense>
  );
}

export default FastCalcView;
