import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

function NonAuthorizedRoute({ component: Component, emailVerifyRoute, ...rest }) {
  const { user } = useSelector((state) => state.user);
  const redirect = user ? emailVerifyRoute ? user.emailVerified : true : false;

  return (
    <Route
      {...rest}
      render={(props) => (redirect
        ? <Redirect to={user.accountStatus === 1 ? '/contract' : '/'} from={props.location} />
        : <Component {...props} />)}
    />
  );
}

export default NonAuthorizedRoute;
