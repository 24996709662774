import React, { useCallback, useRef, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { Select } from 'antd';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

interface SelectItem {
  value: string,
  text: string,
  component?: React.ReactNode,
  payload?: any
}

export interface CustomSelectProps {
  onChange: (val: SelectValue) => void,
  onSearch: (search: string) => Promise<Array<SelectItem | any>>,
  value?: SelectValue,
  pause?: number,
  onSelect?: (value: SelectValue) => void,
  [key: string]: any
}

function CustomSelect({
  onSearch, onChange, value, pause = 800, onSelect, ...rest
}: CustomSelectProps) {
  const { t, i18n } = useTranslation('components');
  const selectRef = useRef<null>(null);

  const [results, setResults] = useState<Array<SelectItem>>([]);
  const [fetching, setFetching] = useState(false);
  const search = useCallback(
    (val: string) => {
      if (!fetching) {
        setFetching(true);
        setResults([]);
        onSearch(val).then(
          (res) => {
            setFetching(false);
            setResults(res);
          },
          () => setFetching(false),
        );
      }
    },
    [onSearch],
  );

  const handleSelect = (val: SelectValue) => {
    if (onSelect) onSelect(val);
  };

  const handleSearch = useCallback(
    debounce(search, pause),
    [search],
  );


  const handleFocus = useCallback(
    () => {
      // @ts-ignore
      if (typeof selectRef.current?.rcSelect?.setInputValue === 'function' && value) selectRef.current.rcSelect.setInputValue(value);
    },
    [selectRef, value],
  );

  return (
    <Select
      {...rest}
      value={value || undefined}
      notFoundContent={fetching ? <Spin size="small" /> : t('not_found')}
      onChange={onChange}
      loading={fetching}
      onSelect={handleSelect}
      onSearch={handleSearch}
      defaultActiveFirstOption={false}
      filterOption={false}
      showArrow={false}
      showSearch
      allowClear
      onFocus={handleFocus}
      autoClearSearchValue={false}
      ref={selectRef}
    >
      {results.map((res) => <Select.Option value={res.value} key={res.value}>{res.component ? res.component : res.text}</Select.Option>)}
    </Select>
  );
}

export default CustomSelect;
