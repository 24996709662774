import React from 'react';
import moment from 'moment';
import qs from 'query-string';
import { SERVER_DATE_FORMAT } from './constants';

export enum ConfigValueType {
  DATE,
  ARRAY,
  INTEGER,
  PAGE_NUMBER,
  STRING,
  FLOAT
}

type ConfigValue = { type: ConfigValueType, defaultValue?: string | number | null };

type ConfigType = {
  [key: string]: ConfigValue
};

export type SetFieldValueFunc<T extends anyObject> = (data: Partial<T>, callback?: (newParams: T) => void) => void;
export type ResetAllFieldsFunc = () => void;

export type WithQueryParamsProps<T extends anyObject> = {
  params: T,
  setFieldValue: SetFieldValueFunc<T>,
  resetAllFields: ResetAllFieldsFunc,
};

function withQueryParams(config: ConfigType): <T extends anyObject>(WrappedComponent: React.ComponentType<T>) => React.ComponentClass<Omit<T, keyof WithQueryParamsProps<any>>> {
  return <T extends anyObject>(WrappedComponent: React.ComponentType<T>) => class WithQueryParams extends React.Component<T> {
    constructor(props: any) {
      super(props);
      // @ts-ignore
      const { location: { search } } = this.props;
      let state: anyObject = {};
      if (search && search.length > 1) {
        const rawFilters = qs.parse(search.slice(1));
        state = {
          ...Object.keys(config).reduce((acc: anyObject, name) => {
            if (name in rawFilters) {
              acc[name] = this.normalizeFieldValue(name, rawFilters[name]);
            }
            return acc;
          }, {}),
        };
      }
      state = {
        ...this.generateStateFromConfig(),
        ...state,
      };
      this.state = state;
    }

    normalizeFieldValue = (name: string, value: any) => {
      if (value === null) {
        return null;
      }
      switch (config[name].type) {
        case ConfigValueType.DATE:
          return moment(value);
        case ConfigValueType.ARRAY:
          return Array.isArray(value) ? value : [value];
        case ConfigValueType.INTEGER:
          return parseInt(value, 10);
        case ConfigValueType.PAGE_NUMBER:
          return parseInt(value, 10);
        case ConfigValueType.FLOAT:
          return value ? parseFloat(value.replace(',', '.')) : null;
        case ConfigValueType.STRING:
        default:
          return value;
      }
    };

    writeFiltersToQueryString = () => {
      const searchParams = new URLSearchParams();
      // @ts-ignore
      const { history } = this.props;
      Object.keys(config).forEach((name) => {
        // @ts-ignore
        this.prepareFieldValue(name, this.state[name], searchParams);
      });
      history.push(`?${searchParams.toString()}`);
    };

    prepareFieldValue = (name: string, value: any, searchParams: any) => {
      if (value === null) {
        return;
      }
      switch (config[name].type) {
        case ConfigValueType.DATE:
          if (value) searchParams.append(name, value.format(SERVER_DATE_FORMAT));
          return;
        case ConfigValueType.ARRAY:
          if (value && value.length) value.forEach((_: string) => searchParams.append(name, _));
          return;
        case ConfigValueType.PAGE_NUMBER:
          if (value && value > 1) searchParams.append(name, value);
          return;
        case ConfigValueType.INTEGER:
        case ConfigValueType.FLOAT:
        case ConfigValueType.STRING:
        default:
          if (value) searchParams.append(name, value);
      }
    };

    setFieldValue: SetFieldValueFunc<anyObject> = (data: anyObject, callback?: (newParams: anyObject) => void) => {
      const newState: anyObject = {};
      Object.entries(data).forEach(([name, value]) => {
        newState[name] = this.normalizeFieldValue(name, value);
      });
      this.setState(newState, () => {
        this.writeFiltersToQueryString();
        if (callback) callback(this.state);
      });
    };

    generateStateFromConfig = () => Object.entries(config).reduce((acc: anyObject, [name, field]) => {
      switch (field.type) {
        case ConfigValueType.DATE:
          acc[name] = field.defaultValue || null;
          return acc;
        case ConfigValueType.ARRAY:
          acc[name] = field.defaultValue || [];
          return acc;
        case ConfigValueType.INTEGER:
          acc[name] = field.defaultValue || null;
          return acc;
        case ConfigValueType.PAGE_NUMBER:
          acc[name] = field.defaultValue || 1;
          return acc;
        case ConfigValueType.FLOAT:
          acc[name] = field.defaultValue || null;
          return acc;
        case ConfigValueType.STRING:
        default:
          acc[name] = field.defaultValue || '';
          return acc;
      }
    }, {});

    resetAllFields = () => {
      // @ts-ignore
      const { history } = this.props;
      history.push('?');
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          setFieldValue={this.setFieldValue}
          resetAllFields={this.resetAllFields}
          params={this.state}
        />
      );
    }
  };
}

// @ts-ignore;
export default withQueryParams;
