import React, { useCallback, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import classNames from 'classnames';

import { FastCalculationContactFormData } from '../../../redux';
import useDispatch from '../../../../../util/hooks/useDispatch';
import { datadaInnAutocomplete } from '../../../../contact/action-creators';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { AntAutocomplete } from '../../../../../components/CreateAntField';


type Props = {
  side: "sender" | "receiver";
  label: string;
  handleFocusField: () => void;
}

const renderInnOption = (suggest: any) => {
    let title = suggest.value;
  
    if (suggest.data.name?.short) {
      title = suggest.data.name.short;
  
      if (suggest.data.opf?.short) {
        title += `, ${suggest.data.opf.short}`;
      }
    }
  
    return (
      <div>
        <div>{title}</div>
        <div>{suggest.data.inn}</div>
      </div>
    );
};
  

function CompanySelect({
  side,
  label,
  handleFocusField
}: Props) {

    const dispatch = useDispatch();
    const [suggestions, setSuggestions] = useState<Array<any>>([]);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const {
        values, setFieldValue, errors
    } = useFormikContext<FastCalculationContactFormData>();

    const handleInnSearch = useCallback(
        (query: string) => {
            setSuggestions([]);
            return dispatch(datadaInnAutocomplete(query)).then(
                (response: any) => {
                if (response.suggestions) {
                    setSuggestions(response.suggestions);
                    return Promise.resolve(response.suggestions.map((suggest: { data: { inn: string } }, index: number) => ({
                        value: index,
                        text: suggest.data.inn,
                        component: renderInnOption(suggest),
                    })));
                }
                return Promise.resolve([]);
                },
            );
        },
        [dispatch],
    );


  const handleInnSelect = useCallback(
    (index: number) => {
      if (suggestions.length > index) {
        const selected = suggestions[index];
        let title = selected.value;

        if (selected.data.name?.short) {
          title = selected.data.name.short;

          if (selected.data.opf?.short) {
            title += `, ${selected.data.opf.short}`;
          }
        }

        setFieldValue(side, { 
            ...values[side],
            inn: selected.data.inn,
            ogrn: selected.data.ogrn,
            kpp: selected.data.kpp,
            legalAddress: selected.data.address?.value || '',
            title,
            opfShort: selected.data.opf.short,
            opfFull: selected.data.opf.full,
            titleShort: selected.data.name.short,
            titleFull: selected.data.name.full,
            counterpartyId: undefined,
            companyName: title,
        })
      }
    },
    [side, values, suggestions],
  );

  const handleCompanyChange = useCallback(
    (value) => {
        setFieldValue(side, { 
            ...values[side],
            inn: undefined,
            ogrn: undefined,
            kpp: undefined,
            legalAddress: undefined,
            title: undefined,
            opfShort: undefined,
            opfFull: undefined,
            titleShort: undefined,
            titleFull: undefined,
            counterpartyId: undefined,
            companyName: value
        })
    },
    [side, values],
  );

  return (
    <div className={classNames("custom-select-field gx-flex-column", {"is-filled": values[side].companyName, "is-focused": isFocused, "is-error": !!errors[side]?.companyName})}>
      { label ? <label className="custom-select-field__label">{label}</label> : null }
      <Field
        component={AntAutocomplete}
        name={`${side}.companyName`}
        onSearch={handleInnSearch}
        onSelect={handleInnSelect}
        onChange={handleCompanyChange}
        onBlur={() => setIsFocused(false)}
        className="custom-select-field__input"
        onFocus={() => {
          setIsFocused(true);
          handleFocusField();
        }}
      />
    </div>
  );
}

export default CompanySelect;
