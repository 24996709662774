import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';


const TerminalCalcResult = React.lazy(() => import('../components/TerminalCalcResults'));
function TerminalCalcResultView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TerminalCalcResult {...props} />
    </Suspense>
  );
}

export default TerminalCalcResultView;
