import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';


const CounterpartyList = React.lazy(() => import('../components/CounterpartyList'));
function CounterpartyListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CounterpartyList {...props} />
    </Suspense>
  );
}

export default CounterpartyListView;
