import { Moment } from 'moment';

export const RESET_CREATION_PARAMS = 'RESET_CREATION_PARAMS';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_CREATION_RESULT = 'SET_CREATION_RESULT';
export const RESET_CREATION_RESULT = 'RESET_CREATION_RESULT';
export const SET_BONUS_VALUE = 'SET_BONUS_VALUE';
export const SET_AVAILABLE_CREATION_DATES = 'SET_AVAILABLE_CREATION_DATES';
export const RESET_AVAILABLE_CREATION_DATES = 'RESET_AVAILABLE_CREATION_DATES';
export const SET_PICKUP_TIME_START = 'SET_PICKUP_TIME_START';
export const SET_PICKUP_TIME_END = 'SET_PICKUP_TIME_END';

export interface creationResultType {
  plsWaybillNumber: string,
  plsOrderNumber: string,
  serviceWaybillNumber: string,
  serviceOrderNumber: string,
  invoiceUrl: string,
}

export interface creationDate {
  date: Moment,
  deliveryDate: Moment,
  pickupAvailable: number,
  deliveryAvailable: number
}


export interface ResetCreationParams {
  type: typeof RESET_CREATION_PARAMS
}

export interface SetPickupDate {
  type: typeof SET_PICKUP_DATE,
  date: Moment | null
}

export interface SetCreationResult {
  type: typeof SET_CREATION_RESULT,
  result: creationResultType
}

export interface ResetCreationResult {
  type: typeof RESET_CREATION_RESULT,
}

export interface SetAvailableCreationDates {
  type: typeof SET_AVAILABLE_CREATION_DATES,
  dates: Array<creationDate>
}

export interface SetBonusValue {
  type: typeof SET_BONUS_VALUE,
  bonus: number | null
}

export interface ResetAvailableCreationDates {
  type: typeof RESET_AVAILABLE_CREATION_DATES
}

export interface SetPickupTimeStart {
  type: typeof SET_PICKUP_TIME_START,
  time: Moment | null,
}

export interface SetPickupTimeEnd {
  type: typeof SET_PICKUP_TIME_END,
  time: Moment | null,
}


export type CreationReducerAction = ResetCreationParams | SetPickupDate | SetCreationResult | ResetCreationResult |
  SetBonusValue | SetAvailableCreationDates | ResetAvailableCreationDates | SetPickupTimeStart | SetPickupTimeEnd;
