import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'styles/new-belka.less';
import '@pathofdev/react-tag-input/build/index.css';
import { YMaps } from 'geo/yandex';
import { Worker } from '@phuocng/react-pdf-viewer';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ConfigProvider from 'antd/es/config-provider';
import moment from 'moment';
import antRu from 'antd/es/locale/ru_RU';
import antEn from 'antd/es/locale/en_US';
import CircularProgress from './components_base/CircularProgress';
import { history } from './reducers';
import Base from './containers/Base';
import i18n, { getMomentLocale } from './i18n';
import 'moment/locale/ru';
import { i18n as linguiI18n } from '@lingui/core';

import { I18nProvider } from '@lingui/react';
import { en, ru } from 'make-plural/plurals';
import { messages as ruMessages } from './locales/ru/messages';
import { messages as enMessages } from './locales/en/messages';
import { GoogleMapsContextProvider } from './geo/google/GoogleMapsContext';

linguiI18n.load({
  ru: ruMessages,
  // en: enMessages,
});
linguiI18n.activate(i18n.language);
linguiI18n.loadLocaleData('ru', { plurals: ru });
// linguiI18n.loadLocaleData('en', { plurals: en });
moment.locale('ru');

const App = ({ store }) => {
  const [i18nInited, setI18nInited] = useState(process.env.NODE_ENV === 'development');
  const [currentLanguage, setCurrentLanguage] = useState();
  const lng = i18n.language;

  useEffect(() => {
    i18n.on('initialized', () => {
      // const _lng = i18n.language;
      const _lng = 'ru';
      // if (_lng === 'ru-RU') i18n.changeLanguage('ru');
      i18n.changeLanguage('ru');
      setCurrentLanguage(_lng);
      moment.locale(getMomentLocale(_lng));
      linguiI18n.activate(_lng);
      setI18nInited(true);
      // store.dispatch(setI18nInited(true));
      // setCurrentLanguage(_lng);
    });

    // i18n.on('languageChanged', (_lng) => {
    //   setCurrentLanguage(_lng);
    //   linguiI18n.activate(_lng);
    //   setI18nInited(true);
    //   // store.dispatch(setI18nInited(true));
    //   moment.locale(getMomentLocale(_lng));
    // });
  }, []);

  const locale = useMemo(() => {
    // if (lng) {
    //   if (lng === 'ru' || lng === 'ru_RU' || lng === 'ru-RU') return antRu;
    //   return antEn;
    // }
    return antRu;
  }, [lng]);

  return (
    <div className="belka_new">
      <Worker workerUrl="/js/pdf.worker.min.js">
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {i18nInited
              ? (

                <I18nProvider i18n={linguiI18n} forceRenderOnLocaleChange>
                  <GoogleMapsContextProvider>
                    <YMaps
                      key={`ymap_${currentLanguage}`}
                      query={{
                        apikey: 'f8ab95fb-92de-438e-8c6e-ab3be839813b',
                        lang: (currentLanguage === 'ru' || currentLanguage === 'ru_RU' || currentLanguage === 'ru-RU') ? 'ru_RU' : 'en_RU',
                        ns: 'ymaps',
                        load: 'geocode',
                      }}
                      preload
                    >
                      <ConfigProvider locale={locale}>
                        <Base />
                      </ConfigProvider>
                    </YMaps>
                  </GoogleMapsContextProvider>
                </I18nProvider>
              )
              : <CircularProgress />}
          </ConnectedRouter>
        </Provider>
      </Worker>
    </div>
  );
};

export default Sentry.withProfiler(App);
