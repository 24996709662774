import React from "react";

const TopBarCart = () => (
    <svg className="custom-navbar__menu-img" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 1H2.96495C3.84275 1 4.28165 1 4.61209 1.22909C4.94252 1.45818 5.08125 1.85818 5.35872 2.65818L6.04481 4.63636"
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path
            d="M18.657 16.7576H6.73866C6.55578 16.7576 6.46372 16.7576 6.39309 16.7503C6.21797 16.7315 6.0489 16.6777 5.89675 16.5923C5.74461 16.5069 5.61278 16.3917 5.50976 16.2544C5.40674 16.117 5.33481 15.9604 5.29862 15.7946C5.26243 15.6289 5.26277 15.4577 5.29963 15.2921C5.32874 15.1849 5.36283 15.0789 5.40178 14.9745C5.46737 14.7879 5.4989 14.6945 5.53547 14.6109C5.715 14.1976 6.01015 13.8402 6.38816 13.5783C6.76617 13.3165 7.21228 13.1603 7.67699 13.1273C7.77032 13.1212 7.87248 13.1212 8.0768 13.1212H14.8734"
            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
        <path
            d="M14.4684 13.1212H10.6331C9.01878 13.1212 8.21161 13.1212 7.58101 12.7212C6.94915 12.3212 6.63133 11.6085 5.99568 10.183L5.78254 9.70546C4.76096 7.41455 4.25144 6.27152 4.81141 5.45334C5.37391 4.63637 6.67042 4.63637 9.26093 4.63637H15.9201C18.8209 4.63637 20.27 4.63637 20.8173 5.54182C21.3634 6.44728 20.6446 7.65697 19.2055 10.0764L18.8486 10.6788C18.1398 11.8703 17.7854 12.4667 17.1977 12.7939C16.6112 13.1212 15.8961 13.1212 14.4684 13.1212Z"
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <circle cx="17.5" cy="20" r="0.5" stroke="currentColor" />
        <circle cx="8.5" cy="20" r="0.5" stroke="currentColor" />
    </svg>
)

export { TopBarCart };