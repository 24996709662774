import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const AcceptOwnershipTransfer = React.lazy(() => import('../components/AcceptOwnershipTransfer'));

function AcceptOwnershipTransferView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AcceptOwnershipTransfer />
    </Suspense>
  );
}

export default AcceptOwnershipTransferView;
