import {
  ADD_INTER_PACKAGE,
  ADD_RETURN_PACKAGE,
  AddInterPackage,
  AdditionalServiceCode,
  AddPackage,
  AddReturnPackage,
  Cargo,
  CargoType,
  DELETE_INTER_PACKAGE,
  DELETE_RETURN_PACKAGE,
  DeleteInterPackage,
  DeletePackage,
  DeleteReturnPackage,
  InsuranceResponse,
  InterPackage,
  InterReason,
  Package,
  REPLACE_INTER_PACKAGE,
  REPLACE_RETURN_PACKAGE,
  ReplaceInterPackage,
  ReplacePackage,
  ReplaceReturnPackage,
  RESET_CARGO,
  RESET_INTER_REASON,
  ResetCargo,
  ResetInterReason,
  SET_ADDITIONAL_SERVICES,
  SET_CARGO_INSURANCE,
  SET_CARGO_INSURANCE_RATE_VALUE,
  SET_CARGO_TYPE,
  SET_INSURANCE_RESPONSE,
  SET_INTER_CARGO_ID,
  SET_INTER_CURRENCY,
  SET_INTER_REASON,
  SET_PACKAGES,
  SET_RETURN_CARGO_ID,
  SET_RETURN_CARGO_TYPE,
  SetAdditionalServices,
  SetCargoId,
  SetCargoInsurance,
  SetCargoInsuranceRateValue,
  SetCargoType,
  SetInsuranceResponse,
  SetInterCargoId,
  SetInterCurrency,
  SetInterReason,
  SetPackages,
  SetReturnCargoId,
  SetReturnCargoType,
  RESET_CARGO_SEARCH,
  ResetCargoSearch,
  SET_INTER_CARGO_FROM_LOCAL,
  SetInterCargoFromLocal,
  SET_INTER_CARGO_FROM_INVOICE,
  SetInterCargoFromInvoice,
} from './types';
import { ThunkAction } from '../../util/types';
import { adaptToApi } from '../../util/adapter';
import { WIPAccounts } from '../../util/hooks/useWIPFeatures';
import { updateServicesFilter } from '../calculation/action-creators';
import { RateAdditionalServiceCode } from '../calculation/types';
import { docsPackage } from './constants';
import fixGRPCFloat from '../../util/fixGRPCFloat';
import { AccountLoyaltyChoices } from '../user/types';
import { CargoCast } from '../cast/types';

export const addPackage = (): AddPackage => ({
  type: 'ADD_PACKAGE',
});

export const deletePackage = (packageId: number): DeletePackage => ({
  type: 'DELETE_PACKAGE',
  packageId,
});

export const replacePackage = (packageId: number, _package: Package): ReplacePackage => ({
  type: 'REPLACE_PACKAGE',
  packageId,
  package: _package,
});

export const setCargoId = (cargoId: string): SetCargoId => ({
  type: 'SET_CARGO_ID',
  cargoId,
});

export const resetCargo = (): ThunkAction<void> => (dispatch, getState): ResetCargo => dispatch({
  type: RESET_CARGO,
  insurance: getState().user.decodedToken?.loyalty !== AccountLoyaltyChoices.TENDER,
});

export const resetFastCargo = (): ThunkAction<void> => (dispatch, getState): ResetCargo => dispatch({
  type: RESET_CARGO,
  insurance: false
});

export const resetCargoSearch = (): ThunkAction<void> => (dispatch, getState): ResetCargoSearch => dispatch({
  type: RESET_CARGO_SEARCH,
  insurance: getState().user.decodedToken?.loyalty !== AccountLoyaltyChoices.TENDER,
});

export const setCargoInsurance = (insurance: boolean): SetCargoInsurance => ({
  type: SET_CARGO_INSURANCE,
  insurance,
});

export const setCargoInsuranceRateValue = (rateValue: number): SetCargoInsuranceRateValue => ({
  type: SET_CARGO_INSURANCE_RATE_VALUE,
  rateValue,
});

export const setInsuranceResponse = (insurance: InsuranceResponse): SetInsuranceResponse => ({
  type: SET_INSURANCE_RESPONSE,
  insurance,
});

export const addInterPackage = (): AddInterPackage => ({
  type: ADD_INTER_PACKAGE,
});

export const deleteInterPackage = (packageId: number): DeleteInterPackage => ({
  type: DELETE_INTER_PACKAGE,
  packageId,
});

export const replaceInterPackage = (packageId: number, _package: InterPackage): ReplaceInterPackage => ({
  type: REPLACE_INTER_PACKAGE,
  packageId,
  package: _package,
});

export const setInterReason = (reason: InterReason): SetInterReason => ({
  type: SET_INTER_REASON,
  reason,
});

export const resetInterReason = (): ResetInterReason => ({
  type: RESET_INTER_REASON,
});

export const setInterCargoId = (cargoId: string): SetInterCargoId => ({
  type: SET_INTER_CARGO_ID,
  cargoId,
});

export const setPackages = (packages: Array<Package>): SetPackages => ({
  type: SET_PACKAGES,
  packages,
});

export const setCargoType = (cargoType: CargoType): SetCargoType => ({
  type: SET_CARGO_TYPE,
  cargoType,
});

export const setInterCurrency = (currency: string): SetInterCurrency => ({
  type: SET_INTER_CURRENCY,
  currency,
});

export const setAdditionalServices = (services: string[]): SetAdditionalServices => ({
  type: SET_ADDITIONAL_SERVICES,
  services,
});

export const addReturnPackage = (): AddReturnPackage => ({
  type: ADD_RETURN_PACKAGE,
});

export const deleteReturnPackage = (packageId: number): DeleteReturnPackage => ({
  type: DELETE_RETURN_PACKAGE,
  packageId,
});

export const replaceReturnPackage = (packageId: number, _package: Package): ReplaceReturnPackage => ({
  type: REPLACE_RETURN_PACKAGE,
  packageId,
  package: _package,
});

export const setReturnCargoId = (cargoId: string): SetReturnCargoId => ({
  type: SET_RETURN_CARGO_ID,
  cargoId,
});

export const setReturnCargoType = (cargoType: CargoType): SetReturnCargoType => ({
  type: SET_RETURN_CARGO_TYPE,
  cargoType,
});

export const setInterCargoFromLocal = (): SetInterCargoFromLocal => ({
  type: SET_INTER_CARGO_FROM_LOCAL
})

export const setInterCargoFromInvoice = (payload: CargoCast): SetInterCargoFromInvoice => ({
  type: SET_INTER_CARGO_FROM_INVOICE,
  payload
})

export const createCargo = (): ThunkAction => (dispatch, getState, http) => {
  const {
    packages, insurance, insuranceRateValue, cargoType
  } = getState().cargo;
  const newPackages = packages.map((_) => {
    let { weight, ...rest } = _;
    if (weight) weight = parseFloat(String(weight).replace(',', '.'));
    return { ...rest, weight };
  });
  const insuranceRequest = insurance ? 1 : 0;
  const services = [];

  if (insurance) services.push(AdditionalServiceCode.INSURANCE);

  return http.post('/api/v1/cargo/create/', {
    packages: newPackages, insurance_request: insuranceRequest, insurance_value_rate: insuranceRateValue, type: cargoType, services: services.map((_) => ({ service_code: _ })),
  }).then(
    (response: any) => {
      dispatch(setCargoId(response.cargoId));
      if (response.insurance) {
        dispatch(setInsuranceResponse(response.insurance));
      }
      const resp = { ...response };
      resp.packages = resp.packages.map((_: anyObject) => ({ ..._, weight: fixGRPCFloat(_.weight) }));
      return Promise.resolve(resp);
    },
  );
};

export const createInterCargo = (): ThunkAction => (dispatch, getState, http) => {
  const {
    insurance, insuranceRateValue, interReason, interPackages, cargoType, interCurrency,
  } = getState().cargo;
  const newPackages = interPackages.map((_) => {
    let { weight, ...rest } = _;
    if (weight) weight = parseFloat(String(weight).replace(',', '.'));
    return { ...rest, weight, currency: interCurrency };
  });
  const insuranceRequest = insurance ? 1 : 0;
  return http.post('/api/v1/cargo/inter/create/', {
    packages: adaptToApi(newPackages),
    insurance_request: insuranceRequest,
    insurance_value_rate: insuranceRateValue,
    reason_domestic: interReason.reasonDomestic,
    reason_inter: interReason.reasonInter,
    type: cargoType,
  }).then(
    (response: any) => {
      dispatch(setCargoId(response.cargoId));
      if (response.insurance) {
        dispatch(setInsuranceResponse(response.insurance));
      }
      return Promise.resolve(response);
    },
  );
};

export const getRecentCargo = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/cargo/recent/').then(
  ({ items }: { items: Array<Cargo>}) => Promise.resolve({ items: items.map((_) => ({ ..._, packages: _.packages.map((p) => ({ ...p, weight: fixGRPCFloat(p.weight) })) })) }),
);

export const updateCargoInsurance = (cargoId: string, insurance: { insurance_request: number, insurance_value_rate: number }): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/cargo/${cargoId}/insurance/update/`, insurance);

export const updateFastCargoInsurance = (cargoId: string, insurance: { insurance_request: number, insurance_value_rate: number }): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/cargo/${cargoId}/insurance/update/`, insurance).then(
  (response: any) => {
    if (response.insurance) {
      dispatch(setInsuranceResponse(response.insurance));
      dispatch(setCargoInsuranceRateValue(response.insurance.insuranceValueRate));
      dispatch(setCargoInsurance(true));
    }
    return Promise.resolve(response);
  },
);

export const updateFastCargoServices = (
  cargoId: string, 
  insurance: { 
    insurance_request: number, 
    insurance_value_rate: number 
  },
  services: {service_code: string}[]): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/cargo/${cargoId}/services/update/`, {...insurance, services}).then(
  (response: any) => {
    if (response.insurance) {
      dispatch(setInsuranceResponse(response.insurance));
      dispatch(setCargoInsuranceRateValue(response.insurance.insuranceValueRate));
      dispatch(setCargoInsurance(true));
    }
    if (response.services) {
      dispatch(setAdditionalServices(services.map(_ => _.service_code)))
    }
    return Promise.resolve(response);
  },
);


type insuranceConfig = {
  insuranceRate: number,
};

export const getInsuranceConfig = (): ThunkAction<Promise<insuranceConfig>> => (dispatch, getState, http) => http.get('/api/v1/cargo/insurance_config/').then(
  ({ insuranceRate }: {insuranceRate: string}) => Promise.resolve({
    insuranceRate: parseFloat(insuranceRate),
  }),
);

export const updateCurrentInterCargo = (): ThunkAction => (dispatch, getState, http) => {
  const {
    insurance, insuranceRateValue, interReason, interPackages, cargoType, interCurrency, cargoId,
  } = getState().cargo;
  const newPackages = interPackages.map((_) => {
    let { weight, ...rest } = _;
    if (weight) weight = parseFloat(String(weight).replace(',', '.'));
    return { ...rest, weight, currency: interCurrency };
  });
  const insuranceRequest = insurance ? 1 : 0;
  return http.put(`/api/v1/cargo/inter/${cargoId}/update/`, {
    packages: adaptToApi(newPackages),
    insurance_request: insuranceRequest,
    insurance_value_rate: insuranceRateValue,
    reason_domestic: interReason.reasonDomestic,
    reason_inter: interReason.reasonInter,
    type: cargoType,
  }).then(
    (response: any) => {
      dispatch(setCargoId(response.cargoId));
      if (response.insurance) dispatch(setInsuranceResponse(response.insurance));
      return Promise.resolve(response);
    },
  );
};

export const updateCurrentCargo = (): ThunkAction => (dispatch, getState, http) => {
  const {
    packages, insurance, insuranceRateValue, cargoType, additionalServices, cargoId,
  } = getState().cargo;

  const newPackages = packages.map((_) => {
    let { weight, ...rest } = _;
    if (weight) weight = parseFloat(String(weight).replace(',', '.'));
    return { ...rest, weight };
  });
  const insuranceRequest = insurance ? 1 : 0;
  const services = [...additionalServices];
  if (insurance && !services.includes(AdditionalServiceCode.INSURANCE)) services.push(AdditionalServiceCode.INSURANCE);

  return http.put(`/api/v1/cargo/${cargoId}/update/`, {
    packages: newPackages,
    insurance_request: insuranceRequest,
    insurance_value_rate: insuranceRateValue,
    type: cargoType,
    services: services.map((_) => ({ service_code: _ })),
  }).then(
    (response: any) => {
      dispatch(setCargoId(response.cargoId));
      if (response.insurance) {
        dispatch(setInsuranceResponse(response.insurance));
      }
      const resp = { ...response };
      resp.packages = resp.packages.map((_: anyObject) => ({ ..._, weight: fixGRPCFloat(_.weight) }));
      return Promise.resolve(resp);
    },
  );
};

export const getReturnCargo = (): ThunkAction => (dispatch, getState, http) => {
  const { additionalServices } = getState().cargo;
  if (additionalServices.find((_) => _ === AdditionalServiceCode.RETURN)) {
    return http.post(
      '/api/v1/cargo/create/',
      {
        packages: [docsPackage],
        type: 'docs',
      },
    ).then(
      ({ cargoId }: anyObject) => Promise.resolve(cargoId),
    );
  }
  return Promise.resolve(null);
};

export const createReturnCargo = (): ThunkAction => (dispatch, getState, http) => {
  const { returnCargoType } = getState().cargo;

  if (returnCargoType === CargoType.DOCS) {
    return http.post(
      '/api/v1/cargo/create/',
      {
        packages: [docsPackage],
        type: 'docs',
      },
    ).then(
      (response: any) => {
        dispatch(setReturnCargoId(response.cargoId));
        return Promise.resolve(response);
      },
    );
  }

  const {
    packages
  } = getState().cargo;
  const newPackages = packages.map((_) => {
    let { weight, ...rest } = _;
    if (weight) weight = parseFloat(String(weight).replace(',', '.'));
    return { ...rest, weight };
  });
  return http.post('/api/v1/cargo/create/', {
    packages: newPackages, insurance_request: 0, type: 'package',
  }).then(
    (response: any) => {
      dispatch(setReturnCargoId(response.cargoId));
      return Promise.resolve(response);
    },
  );
};

export const createFastCargo = (_package: anyObject): ThunkAction => (dispatch, getState, http) => {
  const newPackage: anyObject = { ..._package, description: 'Документы', amount: 1 };
  if (newPackage.weight !== undefined && newPackage.weight != null) newPackage.weight = parseFloat(String(newPackage.weight).replace(',', '.'));

  return http.post(
    '/api/v1/cargo/create/',
    {
      packages: [newPackage],
    },
  );
};
