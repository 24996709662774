import React from "react";

const Footer = () => (
    <div className="gx-w-100 gx-mt-auto custom-footer gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center">
        <div className="gx-d-flex gx-align-items-start gx-justify-content-between custom-footer-social gx-mb-4 gx-w-100">
            {/* <div className="custom-footer-social__col gx-d-flex gx-flex-column">
                <div className="custom-footer-social__title">Клиентам</div>
                <a href="/" className="custom-footer-social__link">О сервисе</a>
                <a href="/" className="custom-footer-social__link">Заключить договор</a>
                <a href="/" className="custom-footer-social__link">Служба поддержки</a>
            </div>
            <div className="custom-footer-social__col gx-d-flex gx-flex-column">
                <div className="custom-footer-social__title">Документы</div>
                <a href="/" className="custom-footer-social__link">Публичная оферта</a>
                <a href="/" className="custom-footer-social__link">Пользовательское соглашение</a>
                <a href="/" className="custom-footer-social__link">Политика конфиденциальности</a>
                <a href="/" className="custom-footer-social__link">Правила программы лояльности Белка Пост</a>

            </div>
            <div className="custom-footer-social__col gx-d-flex gx-flex-column">
                <div className="custom-footer-social__title">Партнерам</div>
                <a href="/" className="custom-footer-social__link">Белка Пост для перевозчиков</a>
                <a href="/" className="custom-footer-social__link">Программа для агентов по продажам</a>
                <a href="/" className="custom-footer-social__link">Предложение для интеграторов и IT компаний</a>
            </div> */}
            <div className="gx-d-flex gx-flex-column custom-footer-details custom-footer-social__col">
                <div className="custom-footer-details__text gx-text-left">2000 - 2023 Группа компаний Белка Пост</div>
                <div className="custom-footer-details__text gx-text-left gx-mt-1 gx-w-50">Мы используем файлы cookie для того, чтобы предоставить пользователям больше возможностей при посещении сайта.</div>
            </div>
            <div className="custom-footer-social__col gx-d-flex gx-flex-column">
                <a href="tel:+74951210799" className="custom-footer-social__phone">+7 (495) 121-07-99</a>
                <div className="custom-footer-social__text">
                    Круглосуточно
                </div>
            </div>
        </div>
        {/* <div className="gx-d-flex gx-flex-column custom-footer-details gx-w-100">
                <div className="custom-footer-details__text gx-text-left">2000 - 2023 Группа компаний Белка Пост</div>
                <div className="custom-footer-details__text gx-text-left gx-mt-1 gx-w-50">Мы используем файлы cookie для того, чтобы предоставить пользователям больше возможностей при посещении сайта. Подробнее об условиях <a href="/" className="custom-footer-details__link">пользовательского соглашения</a></div>
            </div> */}
    </div>
)

export { Footer };