import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const Scheduler = React.lazy(() => import('../components/Registry'));
function RegistryView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Scheduler />
    </Suspense>
  );
}

export default RegistryView;
