import React, { memo, MouseEventHandler, ComponentProps } from 'react';
import type IconComponent from '@ant-design/icons';
import classNames from 'classnames';

import styles from './IconButton.module.css';

export interface IconButtonProps extends ComponentProps<typeof IconComponent> {
  wrapperClassName?: string,
  onClick: MouseEventHandler<HTMLButtonElement>,
  Icon: typeof IconComponent,
}

const IconButton = (props: IconButtonProps) => {
  const { onClick, wrapperClassName, Icon, ...rest } = props;

  return (
    <button
      type="button"
      className={classNames(wrapperClassName, styles.root)}
      onClick={onClick}
    >
      <Icon {...rest} />
    </button>
  );
};

export default memo(IconButton);
