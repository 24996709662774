import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const InterCalculationResultList = React.lazy(() => import('../components/InterCalculationResultList'));
function InterCalculationResultListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <InterCalculationResultList {...props} />
    </Suspense>
  );
}

export default InterCalculationResultListView;
