export const MODULE_NAME = 'new_calculation';

export const EMPTY_ADDRESS_DATA = {
  city: '',
  cityType: '',
  district: '',
  house: '',
  iso: '',
  latitude: 0,
  longitude: 0,
  postcode: '',
  region: '',
  street: '',
  addressLine: undefined,
  terminal: undefined,
};

export const declensionedMonthNames = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export const deliveryServices = ["Курьер Сервис Экспресс", "DPD", "Pony Express", "CDEK", "Почта России", "Деловые линии"];

export const COUNTRIES_CIS = { 
  AM: "Армения", 
  BY: "Беларусь", 
  KZ: "Казахстан", 
  KG: "Кыргызстан",
  RU: "Россия"
}

export const transliteratedNames: Record<string, string> = {
  companyName: "companyNameTransliterate",
  contactName: "contactNameTransliterate",
  addressLine: "addressTransliterate",
  legalAddress: "legalAddressTransliterate"
}