import { Moment } from 'moment';

export interface Phone {
  number: string
}

export interface Contact {
  contactId: string,
  companyId: string,
  userId: string,
  geoPlaceId: string,

  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  doorNumber: string,
  email: string,

  companyName: string,
  contactName: string,

  longitude: string,
  latitude: string,

  comment: string,

  phones: Phone[],
  office: string,
  cityType?: string,
}

export interface Counterparty {
  counterpartyId: string,
  accountId: number,
  createdBy: number,
  inn: string,
  contactsCount: number,
  title?: string,
  ogrn?: string,
  kpp?: string,
  legalAddress?: string,
  pinned: boolean,
  opfFull: string,
  opfShort: string,
  titleFull: string,
  titleShort: string,
}

export interface CounterpartyAddressContactInfo {
  accountId: number,
  createdBy: number,
  phones: Array<Phone>,
  name: string,
  email: string,
  addressId: string,
  contactInfoId: string,
  position?: string,
}

export interface CounterpartyAddress {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  office: string,
  longitude: number,
  latitude: number,
  account_id: number,
  created_by: number,
  comment: string,
  counterpartyId: string,
  addressId: string,

  contactsInfo: Array<CounterpartyAddressContactInfo>,
  cityType?: string,
}

export enum ContactCastType {
  RECENT_CONTACT = 'RECENT_CONTACT',
  COUNTERPARTY = 'COUNTERPARTY'
}

export interface ContactCast {
  iso: string,
  postcode?: string,
  region?: string,
  district?: string,
  city?: string,
  street?: string,
  house?: string,
  office?: string,
  longitude?: number | null,
  latitude?: number | null,
  comment?: string,
  contactName?: string,
  companyName?: string,
  email?: string,
  phones?: Array<Phone>,
  inn?: string,
  addressCastId?: string,
  contactInfoId?: string,
  contactCastId?: string,
  counterpartyId?: string,
  googlePlaceId?: string,
  position?: string,
  legalAddress?: string,
  kpp?: string,
  ogrn?: string,
  cityType?: string,
  intercomNumber?: string,
  intercomCode?: string,
  preCallPeriod?: number,
  workingFrom?: string | Moment,
  workingTo?: string | Moment,
  lunchFrom?: string | Moment,
  lunchTo?: string | Moment,
  needPass?: boolean,
  needIdCard?: boolean,
  recentId?: string,
  type?: ContactCastType,
  noHouseNumber?: boolean,
}

export type FullContactInfoType = ContactCast & {
  type: ContactCastType.COUNTERPARTY,
};

export interface InternationalContactCast extends ContactCast{
  companyNameTransliterate?: string,
  contactNameTransliterate?: string,
  addressTransliterate?: string,
  officeTransliterate?: string,
  commentTransliterate?: string,
}

export interface ForeignContactCast {
  iso: string,
  postcode: string,
  city: string,
  addressLine: string,
  comment: string,
  googlePlaceId: string,
  office: string,
  contactName: string,
  companyName: string,
  email: string,
  longitude: number,
  latitude: number,
  phones: Array<Phone>,
  addressId?: string,
  addressCastId?: string,
  contactInfoId?: string,
  contactCastId?: string,
}

export enum ContactAutocompleteType {
  RECENT_CONTACT = 'RECENT_CONTACT',
  COUNTERPARTY = 'COUNTERPARTY'
}

export type ContactAutocomplete = ContactCast & {
  type: ContactAutocompleteType,
};
