import React from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const TagList = React.lazy(() => import('../components/TagList'));

function TagListView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <TagList {...props} />
    </React.Suspense>
  );
}

export default TagListView;
