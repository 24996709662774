export const MAX_FILE_UPLOAD_SIZE = 20 * 1024 * 1024;
export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';
export const CLIENT_TIME_FORMAT = 'HH:mm';
export const EXPANDED_CLIENT_DATE_FORMAT = 'DD MMMM YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
declare const ProgressStatuses: ['normal', 'exception', 'active', 'success'];
export const SHIPPING_STATUSES: {[key: string]: {color: string, percent: number, status: (typeof ProgressStatuses)[number]}} = {
  registered: { color: 'blue', percent: 10, status: 'active' },
  processed: { color: 'yellow', percent: 50, status: 'active' },
  performed: { color: 'green', percent: 100, status: 'success' },
  canceled: { color: 'red', percent: 100, status: 'exception' },
};
export const LIST_LIMIT = 10;
export const COMMENT_CHARACTERS_LIMIT = 100;
