import {
  BasicWaybill, Claim, ForeignInvoice, Pickup, ShippingTag, Waybill, WaybillDetail, WaybillService,
} from '.';

export const SET_CURRENT_WAYBILL = 'SET_CURRENT_WAYBILL';
export const SET_CURRENT_PICKUP = 'SET_CURRENT_PICKUP';
export const SET_WAYBILL_LIST = 'SET_WAYBILL_LIST';
export const SET_WAYBILL_COUNT = 'SET_WAYBILL_COUNT';
export const RESET_CURRENT_WAYBILL = 'RESET_CURRENT_WAYBILL';
export const SET_SUPPORT_LIST_TOOL = 'SET_SUPPORT_LIST_TOOL';
export const SET_TRACKING_LIST_TOOL = 'SET_TRACKING_LIST_TOOL';
export const SET_WAYBILL_FILTER_VALUE = 'SET_WAYBILL_FILTER_VALUE';
export const SET_REVIEW_LIST_TOOL = 'SET_REVIEW_LIST_TOOL';

export const SET_SHARED_BASIC_WAYBILL_DATA = 'SET_SHARED_BASIC_WAYBILL_DATA';
export const RESET_SHARED_WAYBILL_DATA = 'RESET_SHARED_WAYBILL_DATA';
export const SET_SHARED_EXTENDED_WAYBILL_DATA = 'SET_SHARED_EXTENDED_WAYBILL_DATA';

export const SET_CURRENT_WAYBILL_SERVICE_LIST = 'SET_CURRENT_WAYBILL_SERVICE_LIST';
export const SET_CURRENT_CLAIM = 'SET_CURRENT_CLAIM';
export const RESET_CURRENT_CLAIM = 'RESET_CURRENT_CLAIM';

export const SET_TAG_LIST = 'SET_TAG_LIST';
export const SET_TAG_COUNT = 'SET_TAG_COUNT';
export const ADD_TAG_TO_LIST = 'ADD_TAG_TO_LIST';
export const REMOVE_TAG = 'REMOVE_TAG';
export const ADD_TAGS_TO_LIST = 'ADD_TAGS_TO_LIST';

export const SET_FOREIGN_INVOICE_LIST = 'SET_FOREIGN_INVOICE_LIST';
export const SET_CURRENT_FOREIGN_INVOICE = 'SET_CURRENT_FOREIGN_INVOICE';
export const RESET_CURRENT_FOREIGN_INVOICE = 'RESET_CURRENT_FOREIGN_INVOICE';

export const SET_CLAIM_LIST = 'SET_CLAIM_LIST';

export interface SetForeingInvoiceList {
  type: typeof SET_FOREIGN_INVOICE_LIST,
  payload: ForeignInvoice[]
}

export interface SetCurrentForeignInvoice {
  type: typeof SET_CURRENT_FOREIGN_INVOICE,
  payload: ForeignInvoice
}

export interface ResetCurrentForeignInvoice {
  type: typeof RESET_CURRENT_FOREIGN_INVOICE,
}

export interface SetClaimList {
  type: typeof SET_CLAIM_LIST,
  claims: Claim[]
}

export interface SetCurrentWaybill {
  type: typeof SET_CURRENT_WAYBILL,
  waybill: WaybillDetail
}

export interface SetCurrentPickup {
  type: typeof SET_CURRENT_PICKUP,
  pickup: Pickup,
}

export interface SetWaybillList {
  type: typeof SET_WAYBILL_LIST,
  waybills: Array<Waybill>,
  hasWaybills: boolean
}

export interface SetWaybillCount {
  type: typeof SET_WAYBILL_COUNT,
  count: number,
}

export interface ResetCurrentWaybill {
  type: typeof RESET_CURRENT_WAYBILL,
}

export interface SetSupportListTool {
  type: typeof SET_SUPPORT_LIST_TOOL,
  shipping: string | null
}

export interface SetTrackingListTool {
  type: typeof SET_TRACKING_LIST_TOOL,
  shipping: string | null
}

export interface SetWaybillFilterValue {
  type: typeof SET_WAYBILL_FILTER_VALUE,
  value: any,
  field: string
}

export interface SetSharedBasicWaybillData {
  type: typeof SET_SHARED_BASIC_WAYBILL_DATA,
  waybill: BasicWaybill
}

export interface ResetSharedWaybillData {
  type: typeof RESET_SHARED_WAYBILL_DATA,
}

export interface SetSharedExtendedWaybillData {
  type: typeof SET_SHARED_EXTENDED_WAYBILL_DATA,
  waybill: Waybill
}

export interface SetReviewListTool {
  type: typeof SET_REVIEW_LIST_TOOL,
  shipping: string | null,
}

export interface SetCurrentWaybillServiceList {
  type: typeof SET_CURRENT_WAYBILL_SERVICE_LIST,
  list: WaybillService[],
}

export interface SetCurrentClaim {
  type: typeof SET_CURRENT_CLAIM,
  claim: Claim,
}

export interface ResetCurrentClaim {
  type: typeof RESET_CURRENT_CLAIM,
}

export interface SetTagList {
  type: typeof SET_TAG_LIST,
  list: ShippingTag[],
}
export interface SetTagCount {
  type: typeof SET_TAG_COUNT,
  count: number,
}
export interface AddTagToList {
  type: typeof ADD_TAG_TO_LIST,
  tag: ShippingTag,
}
export interface RemoveTag {
  type: typeof REMOVE_TAG,
  tagId: number,
}

export interface AddTagsToList {
  type: typeof ADD_TAGS_TO_LIST,
  tags: ShippingTag[],
}

export type ShippingActions = SetCurrentWaybill | SetCurrentPickup | SetWaybillList | SetWaybillCount
| ResetCurrentWaybill | SetSupportListTool | SetTrackingListTool | SetWaybillFilterValue | SetSharedBasicWaybillData
| ResetSharedWaybillData | SetSharedExtendedWaybillData | SetReviewListTool | SetCurrentWaybillServiceList
| SetCurrentClaim | ResetCurrentClaim | SetTagList | SetTagCount | AddTagToList | RemoveTag | AddTagsToList | SetClaimList
| SetForeingInvoiceList | SetCurrentForeignInvoice | ResetCurrentForeignInvoice;
