import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  DatePicker, Input, TimePicker, Select,
} from 'antd';
import get from 'lodash/get';
import set from 'lodash/set';
import InputNumber from 'antd/es/input-number';
import GeoAutoComplete from './GeoAutoComplete';
import CustomGeoAutoComplete from './CustomGeoAutoComplete';
import MaskInput from './MaskInput';
import CustomAutoComplete from './CustomAutoComplete';
import CustomSelect from './CustomSelect';
import CustomCheckbox from './CustomCheckbox';
import classNames from 'classnames';
import StringTimeRangePicker from './StringTimeRangePicker';
import CustomTextArea from './CustomTextArea';

const FormItem = Form.Item;
const { Option } = Select;

export const CreateAntField = (AntComponent, childrenInComponent = false) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  type,
  getError,
  errorName,
  children,
  className,
  itemStyle,
  componentClassName,
  showAllErrors,
  warningMessage,
  colon = true,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = form.submitCount > 0;
  let hasError = null;
  if (typeof getError === 'function') {
    hasError = getError();
  } else {
    hasError = get(form.errors, field.name) || get(form.errors, errorName);
  }
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const alwaysError = hasError && showAllErrors;
  const onInputChange = ({ target: { value } }) => {
    const err = { ...form.errors };
    if (err.hasOwnProperty(field.name)) {
      err[field.name] = undefined;
      form.setErrors(err);
    }
    if (get(err, errorName)) {
      form.setErrors(set(err, errorName, undefined));
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onChange = (value) => {
    const err = { ...form.errors };
    if (err.hasOwnProperty(field.name)) {
      err[field.name] = undefined;
      form.setErrors(err);
    }
    if (get(err, errorName)) {
      form.setErrors(set(err, errorName, undefined));
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onBlur = () => form.setFieldTouched(field.name, true);
  const selectItems = selectOptions ? selectOptions.map((name, value) => <Option value={value} key={value}>{name}</Option>) : undefined
  return (
    <FormItem
      label={label}
      hasFeedback={
        !!((hasFeedback && submitted) || (hasFeedback && touched) || warningMessage)
      }
      help={submittedError || touchedError ? hasError : warningMessage || false}
      validateStatus={submittedError || touchedError || alwaysError ? 'error' : warningMessage ? 'warning' : 'success'}
      wrapperCol={{ span: 24 }}
      labelCol={{ xs: 24 }}
      labelAlign="left"
      className={classNames('ant-custom-form-item', className)}
      style={itemStyle}
      colon={colon}
    >
      <AntComponent
        {...field}
        {...props}
        onBlur={onBlur}
        onChange={type ? onInputChange : onChange}
        type={type}
        className={componentClassName}
        innerChildren={childrenInComponent ? children : undefined}
      >
        {selectItems}
      </AntComponent>
      {!childrenInComponent && children}
    </FormItem>
  );
};

CreateAntField.whyDidYouRender = true;

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntRangePicker = CreateAntField(TimePicker.RangePicker);
export const AntStringRangePicker = CreateAntField(StringTimeRangePicker);
export const AntTextarea = CreateAntField(CustomTextArea);
export const AntPassword = CreateAntField(Input.Password);
export const AntMaskInput = CreateAntField(MaskInput);
export const AntGeoAutoComplete = CreateAntField(GeoAutoComplete);
export const AntAutocomplete = CreateAntField(CustomAutoComplete);
export const AntCustomGeoAutocomplete = CreateAntField(CustomGeoAutoComplete);
export const AntCustomSelect = CreateAntField(CustomSelect);
export const AntCheckbox = CreateAntField(CustomCheckbox, true);