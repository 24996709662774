import React from 'react';

function ShoppingBasket() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M24.2676 8.83788H21.6899L16.0504 1.78857C15.7978 1.47279 15.3369 1.42148 15.021 1.67421C14.7051 1.9269 14.6539 2.38778 14.9066 2.70365L19.814 8.83788H5.18599L10.0934 2.70365C10.3461 2.38778 10.2949 1.92685 9.97905 1.67421C9.66313 1.42148 9.20225 1.47275 8.94961 1.78857L3.31011 8.83788H0.732422C0.32793 8.83788 0 9.16581 0 9.5703V11.0351C0 11.4396 0.32793 11.7676 0.732422 11.7676H24.2676C24.6721 11.7676 25 11.4396 25 11.0351V9.5703C25 9.16581 24.6721 8.83788 24.2676 8.83788Z"
          fill="currentColor"
        />
        <path
          d="M4.4229 22.9551C4.51274 23.2696 4.80015 23.4863 5.12715 23.4863H19.8732C20.2002 23.4863 20.4876 23.2696 20.5775 22.9551L23.3554 13.2324H1.64502L4.4229 22.9551ZM15.8549 19.7039L16.5873 15.3093C16.6538 14.9103 17.0312 14.6406 17.4302 14.7073C17.8292 14.7738 18.0987 15.1511 18.0322 15.5501L17.2998 19.9447C17.2401 20.3029 16.9298 20.5568 16.5782 20.5568C16.5382 20.5568 16.4977 20.5536 16.4569 20.5467C16.0579 20.4802 15.7884 20.1028 15.8549 19.7039ZM11.7678 15.4297C11.7678 15.0252 12.0957 14.6973 12.5002 14.6973C12.9047 14.6973 13.2326 15.0252 13.2326 15.4297V19.8242C13.2326 20.2287 12.9047 20.5566 12.5002 20.5566C12.0957 20.5566 11.7678 20.2287 11.7678 19.8242V15.4297ZM7.61904 14.7073C8.01812 14.6408 8.39541 14.9103 8.46192 15.3093L9.19434 19.7039C9.26084 20.1028 8.99131 20.4802 8.59229 20.5467C8.55156 20.5535 8.51099 20.5568 8.471 20.5568C8.11939 20.5568 7.80913 20.303 7.74941 19.9447L7.01699 15.5501C6.95049 15.1511 7.22002 14.7737 7.61904 14.7073Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default ShoppingBasket;
