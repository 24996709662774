import React from "react";

const FallbackView = () => (
    <div className="belka_fallback gx-align-items-center gx-d-flex gx-justify-content-center gx-m-auto">
        <div className="gx-text-dark gx-font-weight-medium gx-text-center">
            Если Вы увидели это сообщение, перезагрузите страницу. 
            Если Вы видите это сообщение снова, пожалуйста сообщите это в техническую поддержку по телефону:
            <br/>
            <a href="tel:+74951210799">+7 (495) 121-07-99</a>, <a href="mailto:hello@belkapost.ru">hello@belkapost.ru</a>
        </div>
    </div>
)

export { FallbackView };