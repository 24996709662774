import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CartDetail = React.lazy(() => import('../components/CartDetail'));

function CartDetailView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CartDetail />
    </Suspense>
  );
}

export default CartDetailView;
