export type ContactCastPhone = {
  number: string,
};

export type ContactCast = {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  office: string,
  longitude: number,
  latitude: number,
  comment: string,
  addressId: string,
  addressLine: string,

  companyName: string,
  contactName: string,
  email: string,
  phones: ContactCastPhone[],
  inn: string,

  companyNameTransliterate: string,
  contactNameTransliterate: string,
  addressTransliterate: string,
  officeTransliterate: string,
  commentTransliterate: string,
  kpp: string,
  ogrn: string,
  legalAddress: string,
  legalAddressTransliterate: string,
  createdAt: string,
  contactId: string,

  intercomNumber?: string,
  intercomCode?: string,

  needPass?: boolean,
  needIdCard?: boolean,

  workingFrom?: string,
  workingTo?: string,

  lunchFrom?: string,
  lunchTo?: string,

  preCallPeriod?: number,
  fiasId?: string
};

export enum CargoCastAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  BY_HAND = 'BY_HAND',
  PICKUP_IN_60_MIN = 'PICKUP_IN_60_MIN',
  PRIORITY_CONTROL = 'PRIORITY_CONTROL'
}

export type CargoAdditionalService = {
  serviceCode: string;
};

export type CaroCastPackageGood = {
  description: string, // Описание
  weight: number, // Вес
  width: number, // Ширина
  height: number, // Высота
  length: number, // Длина
  article: string, // Артикул
  quantity: number, // Кол-во
  price: number, // Цена за единицу
  amount: number, // Общая стоимость
};

export type CargoCastPackage = {
  width: number, // Ширина
  height: number, // Высота
  length: number, // Длина
  weight: number, // Вес
  description: string, // Описание
  goods: CaroCastPackageGood[], // Товары
  amount: number, // Кол-во
  descriptionInter?: string,
  tnved?: string,
  country?: string,
  quantity?: number,
  price?: number,
  currency?: string,
  cost?: number
};

export type CargoCastInsurance = {
  insuranceValueRate: number, // Оценочная стоимость
  insuranceAmount: number, // Сумма страховки
  insuranceRate: string, // Процентная ставка страхования
};

export type CargoCast = {
  cargoCastId: string, // ID слепка
  packages: CargoCastPackage[], // Упаковки
  insurance: CargoCastInsurance, // Страховка
  type: string, // Тип
  createdAt: string,
  cargoId: string,
  services: CargoAdditionalService[]
  reasonDomestic?: string,
  reasonInter?: string,
  totalAmount?: number,
};

export type Pricing = {
  recommendPrice: number;
  listPrice: number;
  supplierPrice: number;
  potentialMargin: number;
  discount: number;
  clientPrice: number;
  margin: number;
};

export type RateCast = {
  rateCastId: string,
  deliveryService: string,
  code: string,
  name: string,
  price: number,
  minDays: number,
  maxDays: number,
  listPrice: number,
  sellerId: number,
  pricing: Pricing,
  createdAt: string,
};

export type TerminalCast = {
  terminalId: number,
  code: string,
  title: string,
  iso: string,
  city: string,
  postalCode: string,
  address: string,
  comment: string,
  longitude: number,
  latitude: number,
  deliveryService: string,
  geographyId: string,
  createdAt: string,
};
