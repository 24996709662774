import Cookies from 'js-cookie';
import {
  AccountNotificationPreference,
  DecodedToken,
  RESET_USER_INVITES,
  SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  SET_NEW_ACCOUNT,
  SET_USER_ACCOUNTS,
  SET_USER_BALANCE,
  SET_USER_INVITE_COUNT,
  SET_USER_INVITE_LIST,
  SET_USER_UI,
  Token,
  User,
  UserAccount,
  UserActions,
  UserBalance,
  UserInvite,
} from './types';
import { cookieDomain } from '../../util/config';
import { decode } from 'jsonwebtoken';

export interface UserState {
  user: User | null,
  token: Token | null,
  cent: any,
  showPostRegistrationModal: boolean,
  invites: Array<UserInvite>,
  invitesCount: number,
  currentBalance: UserBalance,
  userAccountList: Array<UserAccount>,
  newAccount: boolean,
  decodedToken: DecodedToken | null,
  accountNotificationPreferences: AccountNotificationPreference[],
}

const initialState: UserState = {
  user: null,
  token: null,
  cent: null,
  showPostRegistrationModal: false,
  invites: [],
  invitesCount: 0,
  currentBalance: {
    balance: 0,
    bonusBalance: 0,
    bonusHoldBalance: 0,
  },
  userAccountList: [],
  newAccount: false,
  decodedToken: null,
  accountNotificationPreferences: [],
};

export default function (state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user };
    case 'SET_TOKEN':
      if (action.refreshToken) {
        localStorage.setItem('refreshToken', action.refreshToken);
        Cookies.set('refreshToken', action.refreshToken, { domain: cookieDomain });
      } else {
        localStorage.removeItem('refreshToken');
        Cookies.remove('refreshToken', { domain: cookieDomain });
      }
      if (action.accessToken) {
        localStorage.setItem('accessToken', action.accessToken);
        Cookies.set('accessToken', action.accessToken, { domain: cookieDomain });
      } else {
        localStorage.removeItem('accessToken');
        Cookies.remove('accessToken', { domain: cookieDomain });
      }
      localStorage.setItem('tokenExpire', String(action.expiredAt));
      Cookies.set('expiredAt', String(action.expiredAt), { domain: cookieDomain });

      return {
        ...state,
        token: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          expiredAt: action.expiredAt,
        },
        decodedToken: action.accessToken ? decode(action.accessToken) as DecodedToken : null,
      };
    case 'SET_CENT':
      return { ...state, cent: action.cent };
    case 'SET_SHOW_POST_REGISTRATION_MODAL':
      return { ...state, showPostRegistrationModal: action.show };
    case SET_USER_INVITE_LIST:
      return { ...state, invites: action.invites };
    case SET_USER_INVITE_COUNT:
      return { ...state, invitesCount: action.count };
    case RESET_USER_INVITES:
      return { ...state, invites: initialState.invites, invitesCount: initialState.invitesCount };
    case SET_USER_BALANCE:
      return { ...state, currentBalance: action.balance };
    case SET_USER_ACCOUNTS:
      return { ...state, userAccountList: action.list };
    case SET_NEW_ACCOUNT:
      return { ...state, newAccount: action.new };
    case SET_ACCOUNT_NOTIFICATION_PREFERENCES:
      return { ...state, accountNotificationPreferences: action.preferences };
    case SET_USER_UI:
      return { ...state, user: state.user ? { ...state.user, isUpdatedDesign: action.payload } : null }
    default:
      return state;
  }
}
