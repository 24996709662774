import { ContactCast, Phone } from '../contact/types';

export const SET_TERMINAL_LIST = 'SET_TERMINAL_LIST';
export const SET_CURRENT_TERMINAL = 'SET_CURRENT_TERMINAL';
export const SET_TERMINAL_PRE_CALC_STATE = 'SET_TERMINAL_PRE_CALC_STATE';
export const SET_TERMINAL_SENDER = 'SET_TERMINAL_SENDER';
export const SET_TERMINAL_RECEIVER = 'SET_TERMINAL_RECEIVER';
export const RESET_TERMINAL_CALC = 'RESET_TERMINAL_CALC';
export const SET_TERMINAL_CALCULATION_TYPE = 'SET_TERMINAL_CALCULATION_TYPE';

export type PreCalcStateType = 'cargo' | 'sender' | 'receiverTerminal' | 'senderTerminal' | 'receiver';
export enum TerminalCalculationType {
  D2W = 'd2w',
  W2W = 'w2w',
  W2D = 'w2d'
}

export enum TimetableWeekday {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6
}

export type TerminalTimetable = {
  weekday: TimetableWeekday,
  start: string,
  end: string,
};

export interface TerminalListItem {
  terminalId: number,
  code: string,
  title: string,
  address: string,
  comment: string,
  longitude: number,
  latitude: number,
  deliveryService: string,
  city?: string,
  pickupTimetable: TerminalTimetable[],
  deliveryTimetable: TerminalTimetable[],
}

export interface TerminalDetail {
  terminalId: number,
  code: string,
  title: string,
  iso: string,
  city: string,
  postalCode: string,
  address: string,
  comment: string,
  longitude: number,
  latitude: number,
  deliveryService: string,
  geographyId: string,
}

export type TerminalContactDetail = {
  contactName?: string,
  companyName?: string,
  email?: string,
  phones?: Array<Phone>,
  inn?: string,
  contactCastId?: string,
};

export type TerminalContact = {
  type: 'terminal',
  data: TerminalListItem,
  contact?: TerminalContactDetail
} | {
  type: 'contact',
  data: ContactCast
};

export interface RecentTerminal{
  contactName: string,
  companyName: string,
  email: string,
  phones: Array<Phone>,
  inn: string,
  terminalId: number,
  terminal: TerminalListItem
}

export interface SetTerminalList {
  type: typeof SET_TERMINAL_LIST,
  terminals: Array<TerminalListItem>
}

export interface SetCurrentTerminal {
  type: typeof SET_CURRENT_TERMINAL,
  terminal: TerminalListItem
}

export interface SetTerminalPreCalcState {
  type: typeof SET_TERMINAL_PRE_CALC_STATE,
  state: PreCalcStateType
}

export interface SetTerminalSender {
  type: typeof SET_TERMINAL_SENDER,
  contact: TerminalContact | null
}

export interface SetTerminalReceiver {
  type: typeof SET_TERMINAL_RECEIVER,
  contact: TerminalContact | null
}

export interface ResetTerminalCalc {
  type: typeof RESET_TERMINAL_CALC,
}

export interface SetTerminalCalculationType {
  type: typeof SET_TERMINAL_CALCULATION_TYPE,
  calculationType: TerminalCalculationType,
}

export type TerminalAction = SetTerminalList | SetCurrentTerminal | SetTerminalPreCalcState | SetTerminalSender |
SetTerminalReceiver | ResetTerminalCalc | SetTerminalCalculationType;
