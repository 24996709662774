import React, { useState } from 'react';

import classNames from 'classnames';
import { Input } from 'antd';

type Props = {
    label: string;
    value?: string;
    maxLength: number;
    onChange: (value: any) => void;
    onFocus: () => void;
    inputError: any;
    customClassName: string;
}

const CustomSeparatedTextArea = ({ 
    label,
    value,
    maxLength,
    onChange,
    onFocus,
    inputError,
    customClassName
}: Props) => {
    const { TextArea } = Input;

    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <>
            <div className={classNames(`${customClassName} gx-flex-column`, {"is-filled": isFocused || value, "is-focused": isFocused, "is-error": !!inputError})}>
                <label className={`${customClassName}__label`}>{label}</label>
                <TextArea
                    value={value}
                    onChange={onChange}
                    onFocus={() => {
                        setIsFocused(true);
                        onFocus();
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    maxLength={maxLength}
                    className={`${customClassName}__input`}/>
            </div>
        </>
    )
}

export { CustomSeparatedTextArea };