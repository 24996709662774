export const SET_CURRENT_WAYBILL_QUESTIONS = 'SET_CURRENT_WAYBILL_QUESTIONS';
export const RESET_CURRENT_WAYBILL_QUESTIONS = 'RESET_CURRENT_WAYBILL_QUESTIONS';
export const SET_QUESTION_ANSWER = 'SET_QUESTION_ANSWER';

export type Answer = {
  text: string,
  key: string,
}

export type Question = {
  text: string,
  answers: Answer[],
  questionId: number,
  answer: string
}

export interface SetCurrentWaybillQuestion {
  type: typeof SET_CURRENT_WAYBILL_QUESTIONS,
  questions: Question[]
}

export interface ResetCurrentWaybillQuestion {
  type: typeof RESET_CURRENT_WAYBILL_QUESTIONS,
}


export interface SetQuestionAnswer {
  type: typeof SET_QUESTION_ANSWER,
  questionId: number,
  answer: string,
}

export type reviewActions = SetCurrentWaybillQuestion | ResetCurrentWaybillQuestion | SetQuestionAnswer;
