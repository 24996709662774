import { Moment } from 'moment';
import {
  creationDate,
  CreationReducerAction,
  creationResultType,
  RESET_AVAILABLE_CREATION_DATES,
  RESET_CREATION_PARAMS,
  RESET_CREATION_RESULT,
  SET_AVAILABLE_CREATION_DATES,
  SET_BONUS_VALUE,
  SET_CREATION_RESULT,
  SET_PICKUP_DATE, SET_PICKUP_TIME_END,
  SET_PICKUP_TIME_START,
} from './types';

export interface CreationState {
  pickupDate: Moment | null,
  creationResult: null | creationResultType,
  bonusValue: null | number,
  availableCreationDates: Array<creationDate>,
  pickupTimeStart: Moment | null,
  pickupTimeEnd: Moment | null,
}

const initialState: CreationState = {
  pickupDate: null,
  creationResult: null,
  bonusValue: null,
  availableCreationDates: [],
  pickupTimeStart: null,
  pickupTimeEnd: null,
};

export default function (state = initialState, action: CreationReducerAction): CreationState {
  switch (action.type) {
    case RESET_CREATION_PARAMS:
      return { ...initialState };
    case SET_PICKUP_DATE:
      return { ...state, pickupDate: action.date };
    case SET_CREATION_RESULT:
      return { ...state, creationResult: action.result };
    case RESET_CREATION_RESULT:
      return { ...state, creationResult: initialState.creationResult };
    case SET_BONUS_VALUE:
      return { ...state, bonusValue: action.bonus };
    case SET_AVAILABLE_CREATION_DATES:
      return { ...state, availableCreationDates: action.dates };
    case RESET_AVAILABLE_CREATION_DATES:
      return { ...state, availableCreationDates: initialState.availableCreationDates };
    case SET_PICKUP_TIME_START:
      return { ...state, pickupTimeStart: action.time };
    case SET_PICKUP_TIME_END:
      return { ...state, pickupTimeEnd: action.time };
    default:
      return state;
  }
}
