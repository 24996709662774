export const SET_ACT_LIST = 'SET_ACT_LIST';
export const ADD_ACT_LIST = 'ADD_ACT_LIST';
export const SET_ACTS_COUNT = 'SET_ACTS_COUNT';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_PAYMENT_COUNT = 'SET_PAYMENT_COUNT';
export const SET_ACT_RECONCILLATION_LIST = 'SET_ACT_RECONCILLATION_LIST';

export interface Act {
  act_id: number,
  date: string,
  amount: number,
  number: string,
  dateFrom: string,
  dateTo: string,
  actFileUrl: string,
  billInvoiceFileUrl: string,
  detailFileUrl: string,
  invoiceFileUrl?: string,
  commonInvoiceFileUrl?: string
}

export interface Payment {
  date: string,
  number: string,
  amount: number,
}

export enum ActReconcillationFormat {
  PDF,
  XLS
}

export type ActReconcillation = {
  actId: number;
  startDate: string;
  endDate: string;
  fileFormat: ActReconcillationFormat;
  downloadUrl: string;
};

export interface SetActList {
  type: typeof SET_ACT_LIST,
  acts: Array<Act>
}

export interface AddActList {
  type: typeof ADD_ACT_LIST,
  acts: Array<Act>
}

export interface SetActsCount {
  type: typeof SET_ACTS_COUNT,
  count: number,
}

export interface SetPaymentList {
  type: typeof SET_PAYMENT_LIST,
  list: Array<Payment>
}

export interface SetPaymentCount {
  type: typeof SET_PAYMENT_COUNT,
  count: number,
}

export interface SetActReconcillationList {
  type: typeof SET_ACT_RECONCILLATION_LIST,
  list: ActReconcillation[]
}


export type InvoicesActions = SetActList | AddActList | SetActsCount | SetPaymentList | SetPaymentCount | SetActReconcillationList;
