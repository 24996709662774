import React  from 'react';
import { Trans } from '@lingui/macro';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import useSelector from '../../../util/hooks/useSelector';
import { calcDestinationStateList } from '../constants';
import { TopBarCart } from '../../../components/icons/TopBarCart';

type Props = {
  isGlobalMenuShown: boolean
}

function CartIndicator({
  isGlobalMenuShown
}: Props) {
  const { destinations } = useSelector((state) => state.cart);

  const calcDestinations = React.useMemo(() => destinations.filter((_) => calcDestinationStateList.includes(_.status)), [destinations]);

  return (
    <>
      <Link title="Корзина" className="gx-d-flex gx-align-items-center gx-position-relative custom-navbar__menu-item gx-text-nowrap" to="/cart">
        <Badge status={calcDestinations.length ? 'error' : undefined}>
          <TopBarCart/>
        </Badge>
        {
          isGlobalMenuShown ? null : <><Trans>Корзина</Trans> {!!calcDestinations.length && `(${calcDestinations.length})`}</>
        }
      </Link>
    </>
  );
}

export default CartIndicator;
