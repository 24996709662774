import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';


const ScheduleDetail = React.lazy(() => import('../components/RegistryDetail'));
function RegistryDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ScheduleDetail {...props} />
    </Suspense>
  );
}

export default RegistryDetailView;
