import React, {
  useCallback, useEffect, useState,
} from 'react';
import Drawer from 'antd/es/drawer';
import Icon from '@ant-design/icons';
import List from 'antd/es/list';
import { Button, Tooltip } from 'antd';
import Empty from 'antd/es/empty';

import Spin from 'antd/es/spin';
import Badge from 'antd/es/badge';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '../../../util/InfinitScroll';
import {
  getNoticeTypeCount,
  loadMoreNoticeList, loadNoticeList, readAllNotice, reloadNoticeList, setCurrentNoticeType, setNotificationsOpen,
} from '../action-creators';
import useDispatch from '../../../util/hooks/useDispatch';
import useSelector from '../../../util/hooks/useSelector';
import NotificationItem from './NotificationItem';
import NotificationBell from '../../../components/icons/NotificationBell';
import { NotificationType, NotificationTypes } from '../types';
import { NOTIFICATION_TYPES_CORRELATION } from '../contants';
import { BillNotificationsIcon, InfoNotificationsIcon, StatusNotificationsIcon } from '../../../components/Icons';
import classNames from 'classnames';
import NotificationsIcon from '../../../assets/navbar/notifications.gif';

function UserNotifications() {
  const { t, i18n } = useTranslation('notifications');
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { noticeCount, noticeList, notViewedNoticeCount, carrierInfoCount, billCount, cargoStatusCount, currentNoticeType } = useSelector((state) => state.notifications);
  const [loading, setLoading] = useState(false);

  const loadNotices = (page: number) => {
    if (!loading) {
      setLoading(true);
      const type = NOTIFICATION_TYPES_CORRELATION[currentNoticeType]
      return dispatch(loadMoreNoticeList(page, type)).then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    }
    return Promise.reject();
  };

  const readAll = useCallback(
    () => {
      setLoading(true);
      dispatch(readAllNotice()).then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [dispatch],
  );

  const handleVisibleChange = useCallback(
    (visible) => {
      dispatch(setNotificationsOpen(visible));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isVisible) {
      dispatch(setNotificationsOpen(isVisible));
      dispatch(reloadNoticeList());
    }
  }, [isVisible]);

  useEffect(() => {
    dispatch(getNoticeTypeCount(NotificationTypes.CARRIER_INFO));
    dispatch(getNoticeTypeCount(NotificationTypes.BILL));
    dispatch(getNoticeTypeCount(NotificationTypes.CARGO_STATUS));
  }, [])

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      const type = NOTIFICATION_TYPES_CORRELATION[currentNoticeType]
      dispatch(loadNoticeList(type)).then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    }
  }, [currentNoticeType])

  const getPaddingNotifiactions = () => {
    const el = document.querySelector("#main-layout-header");
    if (window.innerWidth > 575) return `${el ? el.getBoundingClientRect().top + 72 : 72}px`
    return `${el ? el.getBoundingClientRect().top + 50 : 50}px`
  }

  const currentPadding = getPaddingNotifiactions();

  return (
    <>
      <div className="gx-pt-1 custom-navbar__menu-item is-without-text gx-pointer">
        <Badge
          status={notViewedNoticeCount ? 'error' : undefined}
          offset={[-5, 5]}
          className="gx-mr-0"
        >

          <img src={NotificationsIcon} alt="notifications" onClick={() => setIsVisible(!isVisible)}/>
          
        </Badge>
      </div>
      <Drawer
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        className={`notification-drawer ${!notViewedNoticeCount ? 'notification-drawer_no-header' : ''}`}
        closable={false}
        afterVisibleChange={handleVisibleChange}
        style={{paddingTop: currentPadding}}
      >
        <div className="gx-h-100">
          <div className="gx-d-flex gx-justify-content-between">
            <h1 className="gx-mb-0">{t('title')}</h1>
            <Badge
              count={notViewedNoticeCount}
              // color="warning"
              style={{ backgroundColor: '#fa8c16' }}
            />
          </div>
          {!!notViewedNoticeCount
          && <Button type="link" className="gx-mb-0 gx-pl-0" onClick={readAll}>{t('read_all')}</Button>}
          <Divider className="gx-mb-0" />

          <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-mt-4">
            <Tooltip placement="top" title={t('sorting.carrier_info')}>
              <button
                type="button"
                className={classNames("gx-d-flex gx-align-items-center gx-justify-content-center custom-notifications-btn", {"is-active": currentNoticeType === NotificationTypes.CARRIER_INFO})}
                onClick={() => dispatch(setCurrentNoticeType(NotificationTypes.CARRIER_INFO))}>
                  <Badge count={carrierInfoCount}>
                    <InfoNotificationsIcon/>
                  </Badge>
              </button>
            </Tooltip>
            <Tooltip placement="top" title={t('sorting.cargo_status')}>
              <button
                type="button"
                className={classNames("gx-d-flex gx-align-items-center gx-justify-content-center custom-notifications-btn", {"is-active": currentNoticeType === NotificationTypes.CARGO_STATUS})}
                onClick={() => dispatch(setCurrentNoticeType(NotificationTypes.CARGO_STATUS))}>
                  <Badge count={cargoStatusCount}>
                    <StatusNotificationsIcon/>
                  </Badge>
              </button>
            </Tooltip>
            <Tooltip placement="top" title={t('sorting.bill')}>
              <button
                type="button"
                className={classNames("gx-d-flex gx-align-items-center gx-justify-content-center custom-notifications-btn", {"is-active": currentNoticeType === NotificationTypes.BILL})}
                onClick={() => dispatch(setCurrentNoticeType(NotificationTypes.BILL))}>
                  <Badge count={billCount}>
                    <BillNotificationsIcon/>
                  </Badge>
              </button>
            </Tooltip>
          </div>

          <Spin spinning={loading}>
            <List
              locale={{ emptyText: <Empty description={t('empty_list')} /> }}
            >
              <div className="gx-h-100" style={{ overflowX: 'auto' }}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={1}
                  loadMore={loading ? undefined : loadNotices}
                  hasMore={noticeCount > noticeList.length}
                  threshold={100}
                  useWindow={false}
                >
                  {noticeList.map((item) => <NotificationItem key={item.noticeId} data={item} close={() => setIsVisible(false)} />)}
                  {!loading && !noticeCount && <Empty className="gx-mt-4" description={t('empty_list')} />}
                </InfiniteScroll>
              </div>

            </List>
          </Spin>
        </div>
      </Drawer>
    </>
  );
}

export default UserNotifications;
