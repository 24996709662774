import {
  ADD_WAYBILL_TRACKING,
  ADD_WAYBILL_TRACKING_LIST,
  SET_WAYBILL_TRACKING_COUNT,
  SET_WAYBILL_TRACKING_LIST,
  TrackingActions,
  WaybillTracking,
} from './types';

export interface TrackingState {
  waybillTrackingList: Array<WaybillTracking>,
  waybillTrackingCount: number,
}

const initialState: TrackingState = {
  waybillTrackingList: [],
  waybillTrackingCount: 0,
};

export default function (state = initialState, action: TrackingActions): TrackingState {
  switch (action.type) {
    case ADD_WAYBILL_TRACKING:
      return { ...state, waybillTrackingList: [...state.waybillTrackingList, action.tracking]};
    case SET_WAYBILL_TRACKING_LIST:
      return { ...state, waybillTrackingList: action.trackingList };
    case ADD_WAYBILL_TRACKING_LIST:
      return { ...state, waybillTrackingList: [...state.waybillTrackingList, ...action.trackingList] };
    case SET_WAYBILL_TRACKING_COUNT:
      return { ...state, waybillTrackingCount: action.count };
    default: return state;
  }
}
