import React from 'react';

type GoogleMapsContextState = {
  api?: typeof google,
  loading: boolean,
  hideGMap: boolean,
};

type GoogleMapsContextDispatch = {
  changeLanguage: (lang: string) => Promise<any>,
};

const GoogleMapsContext: React.Context<[GoogleMapsContextState, GoogleMapsContextDispatch]> = React.createContext(null as any);

type MapsLangCache = {
  [key: string]: typeof google,
};

const GMAPS_ONLOAD = '__googleMapsApiOnLoadCallback';
const API_URL = 'https://maps.googleapis.com/maps/api/js';

function fetchScript(url: string) {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.onload = () => {
    document.head.removeChild(script);
  };
  script.src = url;
  script.async = true;
  document.head.appendChild(script);
}

export function GoogleMapsContextProvider({ children }: { children: React.ReactNode }) {
  const [cache, setCache] = React.useState<MapsLangCache>({});
  const [loading, setLoading] = React.useState(false);
  const [language, setLanguage] = React.useState<string>();
  const [hideGMap, setHideGMap] = React.useState(false);

  const handleChangeLanguage = React.useCallback(
    (newLanguage: string) => new Promise( (resolve) => {
      if (newLanguage !== language) {
        document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach((script) => {
          script.remove();
        });
        delete window.google;
        setLoading(true);
        setLanguage(newLanguage);
        if (cache[newLanguage]) {
          window.google = cache[newLanguage];
          resolve(window.google);
          setLoading(false);
          setHideGMap(true);
          setTimeout(() => setHideGMap(false), 10);
        } else {
          const hash = Date.now().toString(32);
          const onload = `${GMAPS_ONLOAD}${hash}`;

          const query: any = {
            callback: onload,
            v: '3.exp',
            libraries: 'places,geometry,drawing',
            key: 'AIzaSyAip9rw5YWInsopdCar7zVVNNgM_nz-aTs',
            language: newLanguage,
          };

          const queryString = Object.keys(query)
            .map((key) => `${key}=${query[key]}`)
            .join('&');

          // @ts-ignore;
          window[onload] = () => {
            // @ts-ignore;
            delete window[onload];
            console.debug('ON LOAD', window.google);
            setCache({ ...cache, [newLanguage]: window.google });
            resolve(window.google);
            setLoading(false);
          };
          const url = `${API_URL}?${queryString}`;
          fetchScript(url);
        }
      }
    }),
    [language, cache],
  );

  return (
    <GoogleMapsContext.Provider
      value={
        [
          // @ts-ignore
          { api: language ? cache[language] : null, loading, hideGMap },
          { changeLanguage: handleChangeLanguage },
        ]
      }
    >
      {children}
    </GoogleMapsContext.Provider>
  );
}

export default GoogleMapsContext;
