import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CounterpartyAddressAdd = React.lazy(() => import('../components/CounterpartyAddressAdd'));
function CounterpartyAddressAddView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CounterpartyAddressAdd {...props} />
    </Suspense>
  );
}

export default CounterpartyAddressAddView;
