import React, { useState } from 'react';
import AntSelect from 'antd/es/select';
import classNames from 'classnames';
import useSelector from '../../../../util/hooks/useSelector';

type Props = {
    value: string | undefined,
    errors: any,
    onChange: (val: string) => void,
    onFocus: () => void,
}

const CargoCurrencySelect = ({
    value,
    errors,
    onChange,
    onFocus,
}: Props) => {
    const { currencyList } = useSelector((state) => state.reference);
    
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const currencyOptions = React.useMemo(
        () => currencyList.map(({
            code,
        }) => ({
            label: <div className="gx-d-flex gx-align-items-center gx-h-100">{code}</div>,
            value: code,
        })), [currencyList],
    );


    return (
        <div className={classNames("custom-select-field gx-flex-column", {"is-filled": value, "is-focused": isFocused, "is-error": errors})}>
            <label className="custom-select-field__label">Валюта</label>
            <AntSelect
                value={value}
                onChange={onChange}
                onFocus={() => {
                    setIsFocused(true);
                    onFocus();
                }}
                onBlur={() => setIsFocused(false)}
                showSearch
                filterOption={(input, option) => String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={currencyOptions}
                className="custom-select-field__input"
                showArrow={false}
                />
        </div>
    );
}

export { CargoCurrencySelect }