import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const RegistryCalcResult = React.lazy(() => import('../components/RegistryCalcResult'));
function RegistryCalcResultView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RegistryCalcResult {...props} />
    </Suspense>
  );
}

export default RegistryCalcResultView;
