import React, { useCallback, Suspense } from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import { Button } from 'antd';
import Icon from '@ant-design/compatible/es/icon';
import Bonus from './Bonus';
import User from './User';
import Buttons from './Buttons';
import useSelector from '../../util/hooks/useSelector';
import useDispatch from '../../util/hooks/useDispatch';
import { toggleCollapsedSideNav } from '../../app/settings/action-creators';
import useWIPFeatures from '../../util/hooks/useWIPFeatures';
import CartIndicator from '../../app/cart/components/CartIndicator';

function TopBar() {
  const dispatch = useDispatch();
  const { mobile, navCollapsed } = useSelector((state) => state.settings);

  const { user, userAccountList } = useSelector((state) => state.user);

  const handleToggleNav = useCallback(
    () => {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    },
    [dispatch, navCollapsed],
  );

  const isUserOwnerOfCurrentAccount = React.useMemo(() => {
    if (user && userAccountList) {
      const accountFromList = userAccountList.find(i => i.accountId === user.accountId);
      return accountFromList?.ownerId === user.userId;
    }
    return false;
  }, [user, userAccountList])

  return (
    <Row className="gx-w-100 gx-align-items-center">
      <Col xs={mobile ? 10 : 8}>
        {mobile
          ? <Button type="link" onClick={handleToggleNav} className="gx-text-dark gx-mb-0"><Icon type="menu" /></Button>
          : 
          (
            isUserOwnerOfCurrentAccount 
            ? 
            <Suspense fallback={null}>
              <Bonus />
            </Suspense> 
            : 
            null
          )
          }
      </Col>
      <Col xs={mobile ? 14 : 16}>
        <Suspense fallback={null}>
          <User />
        </Suspense>
      </Col>
    </Row>
  );
}

export default TopBar;
