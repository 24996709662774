import React, { Suspense } from 'react';
import CircularProgress from '../../../components_base/CircularProgress';

const CounterpartyChildCreate = React.lazy(() => import('../components/CounterpartyChildCreate'));

function CounterpartyChildCreateView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CounterpartyChildCreate {...props}/>
    </Suspense>
  );
}

export default CounterpartyChildCreateView;