import { ContactCast } from '../contact/types';

export const SET_CLIENT_MARKETING = 'SET_CLIENT_MARKETING';
export const SET_MARKETING_ACTION_LIST = 'SET_MARKETING_ACTION_LIST';
export const SET_MARKETING_ACTION_COUNT = 'SET_MARKETING_ACTION_COUNT';
export const SET_STORE_ITEMS = 'SET_STORE_ITEMS';
export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM';
export const REPLACE_ORDER_ITEM = 'REPLACE_ORDER_ITEM';
export const RESET_ORDER_ITEMS = 'RESET_ORDER_ITEMS';
export const SET_ORDER_RECEIVER_CONTACT_CAST = 'SET_ORDER_RECEIVER_CONTACT_CAST';
export const RESET_ORDER_RECEIVER_CONTACT_CAST = 'RESET_ORDER_RECEIVER_CONTACT_CAST';
export const SET_CURRENT_ACCOUNT_RANK = 'SET_CURRENT_ACCOUNT_RANK';

export type clientMarketing = {
  reviewMarketing: number,
  newAccountUser: boolean,
  firstWaybill: boolean,
  secondCounterparty: boolean,
};

export type MarketingAction = {
  type: string,
  createdAt: string,
  bonusTransactionId: number,
  value: number
};

export type StoreItem = {
  title: string,
  description: string,
  image: string,
  itemId: number,
  denominationList: number[],
};

export type OrderItem = {
  itemId: number,
  quantity: number,
  denomination: number,
};

const AccountRankChoices = ['bronze', 'silver', 'gold', 'platinum', 'vip'];

export type AccountRank = {
  account_id: number,
  rank: typeof AccountRankChoices[number],
  beginDate: string,
  endDate: string,
  futureRank: typeof AccountRankChoices[number],
  futureBeginDate: string,
  futureEndDate: string,
  orderCount: number,
  serviceAmount: number,
};

export interface SetClientMarketing {
  type: typeof SET_CLIENT_MARKETING,
  marketing: clientMarketing,
}

export interface SetClientMarketingActionList {
  type: typeof SET_MARKETING_ACTION_LIST,
  marketingActionList: MarketingAction[]
}

export interface SetClientMarketingActionCount {
  type: typeof SET_MARKETING_ACTION_COUNT,
  count: number,
}

export interface SetStoreItems {
  type: typeof SET_STORE_ITEMS,
  items: StoreItem[]
}

export interface AddOrderItem {
  type: typeof ADD_ORDER_ITEM,
  item: OrderItem
}

export interface DeleteOrderItem {
  type: typeof DELETE_ORDER_ITEM,
  itemId: number
}

export interface ReplaceOrderItem {
  type: typeof REPLACE_ORDER_ITEM,
  item: OrderItem
}

export interface ResetOrderItems {
  type: typeof RESET_ORDER_ITEMS
}

export interface SetOrderReceiverContactCast {
  type: typeof SET_ORDER_RECEIVER_CONTACT_CAST,
  cast: ContactCast
}

export interface ResetOrderReceiverContactCast {
  type: typeof RESET_ORDER_RECEIVER_CONTACT_CAST,
}

export interface SetCurrentAccountRank {
  type: typeof SET_CURRENT_ACCOUNT_RANK,
  rank: AccountRank,
}

export type marketingAction = SetClientMarketing | SetClientMarketingActionList | SetClientMarketingActionCount | SetStoreItems |
AddOrderItem | DeleteOrderItem | ReplaceOrderItem | ResetOrderItems | SetOrderReceiverContactCast | ResetOrderReceiverContactCast |
SetCurrentAccountRank;
