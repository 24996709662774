import { UploadFile as UploadFileType } from 'antd/es/upload/interface';
import { ThunkAction } from '../../util/types';
import { loadClaimList, loadWaybillList } from '../shipping/action-creators';
import {
  ADD_DIALOG_MESSAGE,
  ADD_DIALOG_MESSAGES,
  ADD_UPLOAD_FILE,
  AddDialogMessage,
  AddDialogMessages,
  AddUploadFile,
  Dialog,
  DialogListItem,
  Message,
  REMOVE_UPLOAD_FILE,
  RemoveUploadFile,
  REPLACE_DIALOG,
  REPLACE_DIALOG_MESSAGE,
  ReplaceDialog,
  ReplaceDialogMessage,
  RESET_UPLOAD_FILES,
  ResetUploadFiles,
  SET_CURRENT_DIALOG,
  SET_DIALOG_COUNT,
  SET_DIALOG_LIST,
  SET_DIALOG_MESSAGES,
  SET_DIALOG_MESSAGES_COUNT,
  SetCurrentDialogParams,
  SetDialogCount,
  SetDialogList,
  SetDialogMessages,
  SetDialogMessagesCount,
  SetDialogUnreadCount,
  SET_DIALOG_UNREAD_COUNT,
} from './types';

export const setCurrentDialog = (dialog: Dialog): SetCurrentDialogParams => ({
  type: SET_CURRENT_DIALOG,
  dialog,
});

export const resetCurrentDialog = (): SetCurrentDialogParams => ({
  type: SET_CURRENT_DIALOG,
  dialog: null,
});

export const setDialogMessages = (messages: Message[]): SetDialogMessages => ({
  type: SET_DIALOG_MESSAGES,
  messages,
});

export const addDialogMessages = (messages: Message[]): AddDialogMessages => ({
  type: ADD_DIALOG_MESSAGES,
  messages,
});

export const addDialogMessage = (message: Message): AddDialogMessage => ({
  type: ADD_DIALOG_MESSAGE,
  message,
});

export const addUploadFile = (file: UploadFileType): AddUploadFile => ({
  type: ADD_UPLOAD_FILE,
  file,
});

export const resetUploadFiles = (): ResetUploadFiles => ({
  type: RESET_UPLOAD_FILES,
});

export const removeUploadFile = (file: UploadFileType): RemoveUploadFile => ({
  type: REMOVE_UPLOAD_FILE,
  file,
});

export const replaceDialogMessage = (message: Message, messageId: string): ReplaceDialogMessage => ({
  type: REPLACE_DIALOG_MESSAGE,
  message,
  messageId,
});

export const setDialogMessagesCount = (count: number): SetDialogMessagesCount => ({
  type: SET_DIALOG_MESSAGES_COUNT,
  count,
});

export const setDialogList = (list: DialogListItem[]): SetDialogList => ({
  type: SET_DIALOG_LIST,
  list,
});

export const setDialogCount = (count: number): SetDialogCount => ({
  type: SET_DIALOG_COUNT,
  count,
});

export const replaceDialog = (dialog: DialogListItem): ReplaceDialog => ({
  type: REPLACE_DIALOG,
  dialog,
});

export const setDialogUnreadCount = (count: number): SetDialogUnreadCount => ({
  type: SET_DIALOG_UNREAD_COUNT,
  count,
});

export const getWaybillDialog = (waybill: string, useStore = true): ThunkAction<Promise<any>> => (dispatch, getState, http) => http.get(`/api/v1/chat/dialog/${waybill}/`).then(
  (dialog: Dialog) => {
    if (useStore) dispatch(setCurrentDialog(dialog));
    return Promise.resolve(dialog);
  },
);

export const createDialog = (waybill: string, deliveryService: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/chat/dialog/create/', {
  waybill_id: waybill,
  delivery_service: deliveryService,
}).then(
  (dialog: Dialog) => {
    dispatch(setCurrentDialog(dialog));
    return Promise.resolve(dialog);
  },
);

export const createMessage = (message: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const { chat: { currentDialog, dialogMessagesCount }, user: { user } } = getState();
  const dialogId = currentDialog?.dialogId;
  if (!dialogId) return Promise.reject();
  return http.post('/api/v1/chat/message/create/', {
    message,
    dialog_id: dialogId,
  }).then(
    (data: Message) => {
      dispatch(addDialogMessage({ ...data, user: { firstName: user?.firstName, lastName: user?.lastName, avatar: user?.avatar } }));
      return Promise.resolve(data);
    },
  );
};

export const readDialog = (dialogId: string): ThunkAction<void> => (dispatch, getState, http) => http.put(
  `/api/v1/chat/dialog/${dialogId}/read/`,
).then(
  () => {
    const { dialogList, dialogUnreadCount } = getState().chat;
    if (dialogUnreadCount > 0) {
      dispatch(setDialogUnreadCount(dialogUnreadCount - 1));
    }
    const index = dialogList.findIndex((_) => _.dialogId === dialogId);
    if (index > -1) {
      if (dialogList[index].hasUnread) {
        dispatch(replaceDialog({
          ...dialogList[index],
          hasUnread: false,
        }));
      }
    }
  },
);

export const loadDialogMessages = (dialogId: string, page = 1, useStore = true): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const limit = 10;
  const skip = (limit * page) - limit;
  return http.get('/api/v1/chat/message/', { dialog_id: dialogId, limit, skip }).then(
    (response: { messages: Message[], count: number }) => {
      if (useStore) {
        dispatch(setDialogMessagesCount(response.count));
        dispatch(addDialogMessages(response.messages));
      }
      dispatch(readDialog(dialogId));
      return Promise.resolve(response);
    },
  );
};

export const uploadFiles = (): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const { chat: { currentDialog, uploadFiles }, user: { user } } = getState();
  const dialogId = currentDialog?.dialogId;
  if (!dialogId || !user) return Promise.reject();
  const promises: Array<Promise<any>> = [];
  uploadFiles.filter((_) => _.status !== 'error').forEach((file) => {
    const message: Message = {
      messageId: file.uid,
      createdAt: new Date().toISOString(),
      userId: user.userId,
      accountId: user.accountId,
      dialogId,
      type: 'attachment',
      sender: 'user',
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: '',
      },
      file: {
        name: file.name,
        fileName: file.fileName,
        uploadPercent: 0,
        status: 'uploading',
      },
    };
    dispatch(addDialogMessage(message));
    const req = new FormData();
    req.append('dialog_id', dialogId);
    // @ts-ignore
    req.append('file', file);
    const call = http.post('/api/v1/chat/message/upload/', req, { json: false }, (progress: any) => {
      if (message.file) {
        message.file.uploadPercent = Math.floor((progress.loaded * 100) / progress.total);
        // if (cur === 100) message.file.status = 'done';
      }
      dispatch(replaceDialogMessage(message, message.messageId));
    }).then(
      (response: Message) => dispatch(replaceDialogMessage({ ...response, user: message.user }, message.messageId)),
    );
    dispatch(resetUploadFiles());
    promises.push(call);
  });
  return Promise.all(promises);
};

export const WSDialogEvent = (dialogId: string, event: 'new_message'): ThunkAction<void> => (dispatch, getState, http) => {
  const { chat: { currentDialog, dialogList }, shipping: { waybillList, claimList } } = getState();
  switch (event) {
    case 'new_message':
      if (currentDialog && currentDialog.dialogId === dialogId) {
        dispatch(readDialog(currentDialog.dialogId));
        http.get('/api/v1/chat/message/', { dialog_id: dialogId, limit: 10 }).then(
          (response: { messages: Array<Message>, count: number }) => {
            dispatch(setDialogMessagesCount(response.count));
            dispatch(addDialogMessages(response.messages));
          },
        );
      } else {
        const { dialogUnreadCount } = getState().chat;
        dispatch(setDialogUnreadCount(dialogUnreadCount + 1));
        const index = dialogList.findIndex((_) => _.dialogId === dialogId);
        if (index > -1 && !dialogList[index].hasUnread) {
          dispatch(replaceDialog({
            ...dialogList[index],
            hasUnread: true,
          }));
        }
      }
      if (window.location.pathname === "/") {
        dispatch(loadClaimList(1, 20));
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        const query = urlParams.get('query');
        const status = urlParams.getAll('status');
        const pickupDateFrom = urlParams.get('pickupDateFrom');
        const pickupDateTo = urlParams.get('pickupDateTo');
        const deliveryService = urlParams.getAll('deliveryService');
        const deliveryDateFrom = urlParams.get('deliveryDateFrom');
        const deliveryDateTo = urlParams.get('deliveryDateTo');
        const tagList = urlParams.getAll('tagList');
        dispatch(loadWaybillList(Number(page) || 1, 10, query, { status, pickupDateFrom, pickupDateTo, deliveryService, deliveryDateFrom, deliveryDateTo, tagList }))  
      }
      break;
    default:
      break;
  }
};

export const loadDialogList = (page = 1, filter: anyObject): ThunkAction => (dispatch, getState, http) => {
  const limit = 25;
  const skip = page * limit - limit;
  const { status } = filter;
  const params: anyObject = {
    skip,
    limit,
  };

  if (status) params.status = status;
  return http.get('/api/v1/chat/dialog/list/', params).then(
    (response: { count: number, dialogs: DialogListItem[] }) => {
      dispatch(setDialogCount(response.count));
      dispatch(setDialogList(response.dialogs));
      return Promise.resolve(response);
    },
  );
};

export const createClaimDialog = (claimNumber: string): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/chat/claim/dialog/create/',
  { claim_number: claimNumber },
).then(
  (dialog: Dialog) => {
    dispatch(setCurrentDialog(dialog));
    return Promise.resolve(dialog);
  },
);

export const getClaimDialog = (claimNumber: string, useStore = true): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/chat/claim/dialog/${claimNumber}/`,
).then(
  (dialog: Dialog) => {
    if (useStore) dispatch(setCurrentDialog(dialog));
    return Promise.resolve(dialog);
  },
);

export const getDialogUnreadCount = (): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/chat/dialog/unread/count/`,
).then(
  (response: { count: number }) => {
    dispatch(setDialogUnreadCount(response.count))    
  },
);
